import { TAction } from 'loop-front';
import { Reducer } from 'redux';
import { OUser } from '.';
import utilities from '../../Resources/Utils';
import { ODestinationTransaction } from '../DestinationTransactions';
import { TUserState } from './@types';

export const RUser: Reducer<TUserState, TAction> = (
    state = { showMainForm: false, userSavedLists: [], selectedUserSavedListItems: [], userListCities: [] },
    action
): TUserState => {
    const {
        APP_USER_RECEIVED,
        SINGLE_ITEM_RECEIVED,
        FETCHING_SINGLE_ITEM,
        ACTIVITY_RECEIVED,
        ENTITY_OF_ITEM_RECEIVED,
        LIST_RECEIVED,
        SHOW_USER_FORM,
        HIDE_USER_FORM,
        ACTIVITY_POST_SUCCESS,
        ITEM_PATCH_SUCCESS,
        ITEM_ACTIVITY_RECEIVED,
        SINGLE_REVIEW_PUBLSIHED_OF_PLACE,
        CUSTOM_ENTITY_OF_USER_RECIEVED,
        SET_EMAIL_VERIFIED,
        USER_SAVED_LIST_DETAIL_FETCHED,
        USER_SAVED_LIST_ITEMS_FETCHED,
        CLEAR_USER_SAVED_LIST_DETAIL,
        CLEAR_USER_SAVED_LIST_ITEMS,
        GET_USER_SUBSCRIBED_CITIES
    } = OUser.Actions;

    switch (action.type) {
        case ODestinationTransaction.Actions.DESTINATION_TRANSACTION_MULTI_USER_TRANSACTION_SUCCESS: {
            if (!state.user) return state;
            return { ...state, user: { ...state.user, subscribedDestinationIds: [...(state.user?.subscribedDestinationIds || []), ...[...action.data?.map(item => item.destinationId) || []]] } };
        }
        case CLEAR_USER_SAVED_LIST_ITEMS: {
            return { ...state, selectedUserSavedListItems: [] };
        }
        case USER_SAVED_LIST_ITEMS_FETCHED: {
            return { ...state, selectedUserSavedListItems: action.data };
        }
        case USER_SAVED_LIST_DETAIL_FETCHED:
            return { ...state, selectedUserSavedList: action.data };
        case CLEAR_USER_SAVED_LIST_DETAIL:
            return { ...state, selectedUserSavedList: undefined };
        case APP_USER_RECEIVED:
            return { ...state, appUser: { ...action.data, roles: ['ADMIN'] } };
        case SET_EMAIL_VERIFIED: {
            return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), user: { ...state.user, ...action.data } };
        }
        case SINGLE_ITEM_RECEIVED:
            return { ...state, user: action.data };
        case ITEM_PATCH_SUCCESS:
            return {
                ...state,
                user: { ...state.user, ...action.additionalDispatchData },
                list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'),
            };
        case FETCHING_SINGLE_ITEM:
            return { ...state, user: undefined };
        case SINGLE_REVIEW_PUBLSIHED_OF_PLACE:
            return { ...state, selectedUserReviews: action.data };
        case ITEM_ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OUser.Activities.DETAILS:
                    return { ...state, user: action.data };
                default:
                    return state;
            }
        }
        case SHOW_USER_FORM:
            return { ...state, showMainForm: true, editedUser: action.data || undefined };
        case HIDE_USER_FORM:
            return { ...state, showMainForm: false, editedUser: undefined };
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OUser.Activities.COUNT:
                    return { ...state, totalCount: action.data.count };
                case OUser.Activities.DETAILS:
                    return { ...state, user: action.data };
                case OUser.Activities.PROFILE:
                    return { ...state, user: action.data };
                default:
                    return state;
            }
        }
        case CUSTOM_ENTITY_OF_USER_RECIEVED: {
            switch (action.entity) {
                case OUser.Entities.FAVOURITEDBY:
                    return { ...state, favouritedBy: action.data };
                case OUser.Entities.WISHEDBY:
                    return { ...state, savedBy: action.data };
                case OUser.Entities.SUGGESTED:
                    return { ...state, suggested: action.data };
                default:
                    return state;
            }
        }
        case ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case OUser.Entities.REVIEWS:
                    return { ...state, selectedUserReviews: action.data };
                case OUser.Entities.SAVED_LISTS:
                    return { ...state, userSavedLists: action.data };
                default:
                    return state;
            }
        }

        case LIST_RECEIVED:
            return { ...state, list: action.data };

        case ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case OUser.Activities.LOGOUT: {
                    return { ...state, appUser: undefined };
                }
                default:
                    return state;
            }
        }

        case 'LOGOUT':
            return { ...state, appUser: undefined };
        case GET_USER_SUBSCRIBED_CITIES: {
            return {
                ...state,
                userListCities: action.data,
            };
        }
        default:
            return { ...state };
    }
};
