import { TAction } from 'loop-front';
import { Reducer } from 'redux';
import { ODestinationPack } from '.';
import utilities from '../../Resources/Utils';
import { TDestinationPackState } from './@types';

export const RDestinationPack: Reducer<TDestinationPackState, TAction> = (state = { showMainForm: false }, action) => {
    const {
        SINGLE_ITEM_RECEIVED,
        ACTIVITY_RECEIVED,
        LIST_RECEIVED,
        POST_ITEM_SUCCESS,
        ITEM_PATCH_SUCCESS,
        ITEM_DELETED,
        ITEM_ACTIVITY_POST_SUCCESS,
        ENTITY_OF_ITEM_RECEIVED,
        SINGLE_ENTITY_BY_ITEM_DELETED,
        SHOW_DESTINATION_PACK_DETAIL_FORM,
        HIDE_DESTINATION_PACK_DETAIL_FORM,
        ITEM_ACTIVITY_RECEIVED,
        DESTINATION_PACK_CITITES_RECIVED,
    } = ODestinationPack.Actions;

    switch (action.type) {
        case POST_ITEM_SUCCESS:
            return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'ADD') };
        case ITEM_PATCH_SUCCESS:
            return {
                ...state,
                list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'),
                details: { ...state.details, ...action.additionalDispatchData },
            };
        case LIST_RECEIVED:
            return { ...state, list: action.data };
        case SHOW_DESTINATION_PACK_DETAIL_FORM:
            return { ...state, showMainForm: true, editedDish: action.data };
        case HIDE_DESTINATION_PACK_DETAIL_FORM:
            return { ...state, editedDish: undefined, showMainForm: false };
        case SINGLE_ITEM_RECEIVED:
            return { ...state, details: action.data };
        case ITEM_DELETED:
            return { ...state, list: utilities.updateItemList(state.list || [], action.additionalDispatchData, 'DELETE') };
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case ODestinationPack.Activities.COUNT:
                    return { ...state, totalCount: action.data.count };
                default:
                    return state;
            }
        }
        case DESTINATION_PACK_CITITES_RECIVED: {
            return {
                ...state,
                cities: action.data,
            };
        }
        default:
            return state;
    }
};
