import { createStyles, Fab, FormControl, Icon, makeStyles, MenuItem, Select, Theme, Tooltip } from '@material-ui/core';
import { IconProps } from '@material-ui/core/Icon';
import React from 'react';
import CopyButton from './Buttons/CopyButton';
import DeleteButton from './Buttons/DeleteButton';

export interface HeaderActionButtonProps {
    items: Array<{
        type: string
        icon?: string
        onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
        color?: IconProps['color']
        size?: string
        textUrl?: string
        handleDelete?: () => Promise<void>
        alertMessage?: string
        selectList?: Array<{ name: string, value: string }>
        onSelectClick?: (event: React.ChangeEvent<{ value: unknown }>) => Promise<void>
        selectedValue?: string,
        helpText?: string
    }>


}

const HeaderActionButtons: React.FC<HeaderActionButtonProps> = (props) => {

    const classes = useStyles()
    const { items } = props;
    const handleDelete = () => { }
    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => { }

    return (
        <div className={classes.operationBtns}>
            {
                items.map((item, index) => {
                    if (item.type === 'icon') {
                        if (item.helpText) {
                            return <Tooltip key={index} title={item.helpText}>
                                <Fab color={'inherit'} size={"small"} onClick={item.onClick}>
                                    <Icon color={item.color ? item.color : 'action'} className={'material-icons'}>{item.icon || 'Icon'}</Icon>
                                </Fab>
                            </Tooltip>
                        }
                        return <Fab color={'inherit'} size={"small"} onClick={item.onClick}>
                            <Icon color={item.color ? item.color : 'action'} className={'material-icons'}>{item.icon || 'Icon'}</Icon>
                        </Fab>
                    }
                    else if (item.type === 'delete') {
                        return <DeleteButton
                            key={index}
                            alertMessage={item.alertMessage || 'Are you sure you want to delete'}
                            backgroundColor={'white'}
                            iconColor={'black'}
                            handleDelete={() => { (item.handleDelete) ? item.handleDelete() : handleDelete() }}
                            helpText={item.helpText}
                        />
                    }
                    else if (item.type === 'select') {
                        return <FormControl style={{ width: 'auto' }} key={index}>
                            <Select
                                value={item.selectedValue || ''}
                                onChange={item.onSelectClick ? item.onSelectClick : handleSelectChange}
                            >
                                {
                                    (item.selectList || []).map((data, sIndex) => {
                                        return (

                                            <MenuItem
                                                value={data.value}
                                                key={sIndex}>
                                                {data.name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    }
                    else {
                        return <CopyButton key={index} backgroundColor={'white'} text={item.textUrl || ''} />
                    }

                })
            }
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        '& > button': {
            margin: '0 10px'
        },
        '& > div': {
            margin: '0 10px'
        }
    },
}))

export default HeaderActionButtons