import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import PlacesCardList from '../../../../Components/Cards/CardLists/PlacesCardList'
import PlacesForm from '../../../../Components/Forms/PlacesForm'
import HeaderActionButtons from '../../../../Components/HeaderActionButtons'
import { ITEM_PER_PAGE_COUNT } from '../../../../Constants/FilterConstants'
import { OApp } from '../../../../Models/App'
import { OCity } from '../../../../Models/City'
import { TCityState } from '../../../../Models/City/@types'
import { OPlace } from '../../../../Models/Place'
import { TPlace } from '../../../../Models/Place/@types'
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'
type IState = Pick<TCityState, 'details' | 'places'>

interface IProps extends RouteComponentProps<{ id: string }> {

}

const Places: React.FC<IProps> = ({ match, history }, props) => {

    const cityId = match.params['id'];
    const classes = useStyles()
    const { details, } = useSelector<TReduxStore, IState>(({ City }) => ({ details: City.details, places: City.places }));
    const textUrl = `${DOMAIN_URL}/cities/${details?.slug || cityId}/places`;

    const dispatch = useDispatch<TDispatch>();
    const [pageNumber, setPageNumber] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [displayList, setDisplayList] = useState<TPlace[]>([]);
    const places = details?.establishments || []
    useEffect(() => {
        if (cityId) {
            const low = ITEM_PER_PAGE_COUNT * (pageNumber - 1);
            const high = ITEM_PER_PAGE_COUNT * (pageNumber)
            const tList: TPlace[] = (places || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, places.toString()])
    const handleAddPlace = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowForm(true);
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddPlace,
            helpText: 'Add a place',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]

    const onFormSubmit = async (newList: TPlace[]) => {
        try {
            await dispatch(OCity.putActivityOfItem(cityId, OCity.Activities.UPDATE_ESTABLISHMENTS,
                {},
                {
                    establishmentIds: newList.length > 0 ? newList.map(i => i.id) : []
                }, newList));
            if (!(pageNumber === 1))
                setPageNumber(1)
            dispatch(OApp.showToast({ message: 'Places Added Successfully', variant: 'success' }))
        } catch (err) {
            dispatch(OApp.showToast({ message: 'Places Add Unsuccessful', variant: 'error' }))
            console.log(err)
        }
        setShowForm(false)
    }
    const deleteItem = async (item: TPlace) => {
        try {
            item.destinationId = '';
            await dispatch(OPlace.patchItem(item.id, item))
            dispatch({ type: OCity.Actions.SINGLE_ESTABLISHMENT_DELETED_FROM_CITY, data: item });
            dispatch(OApp.showToast({ message: 'Place Deleted', variant: 'success' }))

        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: 'Place Delete Unsuccessful', variant: 'error' }))
        }
    }
    const handlePageChange = (newPage: number) => { setPageNumber(newPage) }
    const closeForm = () => { setShowForm(false) }
    return (
        <div className={classes.destinationPlaces}>
            <>
                <div className={classes.operationBtns}>
                    <HeaderActionButtons items={headerItems} />
                    {showForm && <PlacesForm
                        heading={'Add places to city'}
                        closeForm={closeForm}
                        open={showForm}
                        onFormSubmit={onFormSubmit}
                        places={places}
                    />}

                </div>
                <div className={classes.wrapper}>
                    {
                        // loading ? <CircularProgress /> :
                        <> <PlacesCardList
                            deleteItem={deleteItem}
                            list={displayList}
                            pageNumber={pageNumber}
                            totalCount={(details ? (details.counts.establishments || 0) : 0)}
                            handlePageChange={handlePageChange}

                        />
                        </>
                    }
                </div>
            </>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    destinationPlaces: {
        position: 'relative',
        margin: '0px 40px',
    },

    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        '& > button': {
            margin: '0 10px'
        },
        '& > div': {
            margin: '0 10px'
        }
    },
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        },
        margin: '40px auto'
    },

}))

export default Places