import MomentUtils from '@date-io/moment';
import { Box, createStyles, Fab, Icon, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import TimePicker from 'material-ui-pickers/TimePicker';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { TTimePeriod } from '../../../../Models/Place/@types';
import { UPDATE } from './Reducer';

export interface OpeningDaysSectionProps {

    dispatchReducer: React.Dispatch<{
        type: string,
        name: string;
        data: any;
    }>

    openingHours?: {
        periods: TTimePeriod[]
    }
}


const OpeningDaysSection: FC<OpeningDaysSectionProps> = (props) => {
    const { dispatchReducer, openingHours } = props;

    const theme = useTheme<Theme>();


    const [_openingDays, setOpeningDays] = useState<{ periods: TTimePeriod[] }>();

    useEffect(() => {
        if (openingHours) {
            setOpeningDays(openingHours);
        }
    }, [openingHours])

    const WEEKDAYS = [
        {
            name: 'Sunday',
            short: 'Sun',

        },
        {
            name: 'Monday',
            short: 'Mon',
        },
        {
            name: 'Tuesday',
            short: 'Tues',
        },
        {
            name: 'Wednesday',
            short: 'wed',
        },
        {
            name: 'Thursday',
            short: 'Thurs',
        },
        {
            name: 'Friday',
            short: 'Fri',
        },
        {
            name: 'Saturday',
            short: 'Sat',
        },

    ]

    const classes = useStyles();


    const handleTimeChange = (date: any, day: number, slotIndex: number, type: any) => {

        if (_openingDays && _openingDays.periods) {

            let dayTimes = _.filter(_openingDays.periods, ['day', day]);
            switch (type) {
                case 'open_time': {
                    // dayTimes[slotIndex].open = new Date(moment(date, 'HHmm'));
                    const openingDays = { ..._openingDays }
                    dayTimes[slotIndex].open = date.format('HHmm')
                    let index = _.findIndex(openingDays.periods, dayTimes[slotIndex]);
                    openingDays.periods[index].open = dayTimes[slotIndex].open;

                    dispatchReducer({ type: UPDATE, name: '_openingDays', data: _openingDays })
                    break;
                }
                case 'close_time': {
                    const openingDays = { ..._openingDays }
                    dayTimes[slotIndex].close = date.format('HHmm');
                    let index = _.findIndex(openingDays.periods, dayTimes[slotIndex]);
                    openingDays.periods[index].close = dayTimes[slotIndex].close;
                    dispatchReducer({ type: UPDATE, name: '_openingDays', data: _openingDays })
                    break;
                }
                default: console.log('Wrong type received', type);

            }
        }
    }

    const toggleDay = (day: TTimePeriod['day']) => {
        if (_openingDays && _openingDays.periods) {
            const openingDays = { ..._openingDays };

            let dayTimes = _.filter(openingDays.periods, ['day', day]);

            if (dayTimes.length > 0) {
                let index = _.findIndex(openingDays.periods, dayTimes[0]);
                openingDays.periods.splice(index, dayTimes.length);
                // setOpeningDays(openingDays);
                dispatchReducer({ type: UPDATE, name: '_openingDays', data: _openingDays })
                return;
            }

            // openingDays[val].isOpen = !openingDays[val].isOpen
            const newPeriodObject = {
                open: undefined,
                close: undefined,
                day: day
            }
            // Adding on Sunday
            if (day === 0) {
                openingDays.periods.splice(0, 0, newPeriodObject);
            }
            // Adding day other than Sunday
            else {
                let prevDayTimes = _.filter(openingDays.periods, ['day', day - 1]);
                let index = _.findIndex(openingDays.periods, prevDayTimes[prevDayTimes.length - 1]);
                openingDays.periods.splice(index, 0, newPeriodObject);
            }
            dispatchReducer({ type: UPDATE, name: '_openingDays', data: _openingDays })
            // setOpeningDays(openingDays);
        }

    }
    const removeTimeSlot = (day: number, slotIndex: number) => {
        if (_openingDays && _openingDays.periods) {
            const openingDays = { ..._openingDays };
            let dayTimes = _.filter(openingDays.periods, ['day', day]);

            let index = _.findIndex(openingDays.periods, dayTimes[slotIndex]);

            openingDays.periods.splice(index, 1);

            dispatchReducer({ type: UPDATE, name: '_openingDays', data: _openingDays })
        }
    }
    const addTimeSlot = (day: TTimePeriod['day']) => {
        if (_openingDays && _openingDays.periods) {
            let openingDays = { ..._openingDays };
            let dayTimes = _.filter(openingDays.periods, ['day', day]);
            let index = _.findIndex(openingDays.periods, dayTimes[dayTimes.length - 1]);
            const newPeriodObject = {
                open: undefined,
                close: undefined,
                day: day
            }
            openingDays.periods.splice(index + 1, 0, newPeriodObject);
            dispatchReducer({ type: UPDATE, name: '_openingDays', data: _openingDays })
        }

    }
    return (
        <Box width={800} mt={5} >
            <Paper className={classes.root}>
                <Typography variant='h6'>Open Days</Typography>
                <div className={classes.tagContainer}>
                    {
                        _.map(WEEKDAYS, (value, index: TTimePeriod['day']) => {
                            let selected = _.find(_.get(_openingDays, 'periods'), ['day', index]);
                            return (
                                <div
                                    key={_.uniqueId('tag_')}
                                    className={classes.tag}
                                    onClick={() => toggleDay(index)}
                                    style={
                                        selected ? { background: theme.palette.primary.main }
                                            : { background: theme.palette.grey['700'] }
                                    }
                                >
                                    <Typography style={
                                        selected ?
                                            { color: theme.palette.common.white } :
                                            { color: theme.palette.common.black }
                                    }>
                                        {value.short}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={classes.slotContainer}>
                    {
                        _.map(WEEKDAYS, (value, index: TTimePeriod['day']) => {
                            let timeSlots = _.filter(_.get(_openingDays, 'periods'), ['day', index])
                            return (
                                timeSlots.length ?
                                    <div key={_.uniqueId()} className={classes.dayContainer}>
                                        <Typography>{value.name}</Typography>
                                        {

                                            <div className={classes.fieldsRoot}>
                                                {
                                                    _.map(timeSlots, (slot, slotIndex: TTimePeriod['day']) => {

                                                        return (

                                                            <Box key={_.uniqueId('weekday_field_')} display="flex" flexDirection='row' alignItems='center' justifyContent="space-between" height='140' marginBottom='15px'>
                                                                <div key={_.uniqueId('weekday_field_')} className={classes.field} >
                                                                    <div className={classes.openingField}>
                                                                        <MuiPickersUtilsProvider utils={MomentUtils} >
                                                                            <TimePicker
                                                                                className={classes.inputField}
                                                                                keyboard
                                                                                keyboardIcon={<i className="material-icons">access_time</i>}
                                                                                mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                                                                                placeholder="Select Time"
                                                                                value={moment(slot.open, 'HHmm').isValid() ? moment(slot.open, 'HHmm') : null}
                                                                                onChange={(date) => (handleTimeChange(date, index, slotIndex, 'open_time'))}
                                                                                disableOpenOnEnter
                                                                                disabled={timeSlots ? false : true}

                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                    <div className={classes.closingField}>
                                                                        <MuiPickersUtilsProvider utils={MomentUtils} >
                                                                            <TimePicker
                                                                                className={classes.inputField}
                                                                                keyboard
                                                                                keyboardIcon={<i className="material-icons">access_time</i>}

                                                                                mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                                                                                placeholder="Select Time"
                                                                                value={moment(slot.close, 'HHmm').isValid() ? moment(slot.close, 'HHmm') : null}
                                                                                onChange={(date) => (handleTimeChange(date, index, slotIndex, 'close_time'))}
                                                                                disableOpenOnEnter
                                                                                disabled={timeSlots ? false : true}

                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                    <div className={classes.clearIconContainer} onClick={() => removeTimeSlot(index, slotIndex)} ><Icon className="material-icons">clear</Icon></div>
                                                                    {(timeSlots.length > 0 && slotIndex === timeSlots.length - 1) ? <div className={classes.addIcon} onClick={() => addTimeSlot(index)}><Fab size="small" color="primary" ><Icon>add_icon</Icon></Fab></div> : ''}
                                                                </div>

                                                            </Box>

                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                    : null
                            )
                        })
                    }

                </div>
            </Paper>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({

        root: {
            padding: '40px 32px 40px',
            height: 'auto'
        },
        chip: {
            marginRight: '20px',

            height: ' 50px',
            width: 'auto'
        },
        paper: {
            paddingLeft: 40,
            paddingTop: 30,
            paddingRight: 40,
            paddingBottom: 30
        },
        slotContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        tag: {
            borderRadius: 30,
            marginLeft: 10,
            padding: 6,
            minWidth: 50,
            textAlign: 'center',
            "&:first-child": {
                marginLeft: 0
            },
            cursor: "pointer"
        },
        tagContainer: {
            marginTop: 10,
            display: "flex",

        },
        dayContainer: {
            marginTop: 20,
            marginBottom: 30
        },
        fieldsRoot: {
        },
        fieldContainer: {
            marginTop: 40
        },
        field: {
            display: "flex",

            flexDirection: 'row',
            ".input": {
                width: 70
            }
        },
        openingField: {
            position: 'relative',
            padding: '0px 10px'
        },
        closingField: {
            position: 'relative',
            padding: '0px 10px'
        },
        inputField: {
            width: 155
        },
        clearIconContainer: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        addIcon: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 10
        }

    }))
})

export default OpeningDaysSection


