import { createStyles, Divider, ListItem, ListItemText, makeStyles, Theme, ListItemSecondaryAction } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, RouteComponentProps, RouteProps } from 'react-router-dom';
import ListDashboard from '../../Components/Dashboard/ListDashboard';
import { OCuisine } from '../../Models/Cuisines';
import { TCuisine, TCuisineState } from '../../Models/Cuisines/@types';
import { TDispatch } from '../../Resources/GlobalTypes';
import { TReduxStore } from '../../RootReducer';
import MainForm from './Forms/MainForm';
import Details from './Tabs/Details';
import Dishes from './Tabs/Dishes';
import Places from './Tabs/Places';
import { TFilterConfig } from '../../Models/App/@types';
import { getCuisineList } from '../../Models/Cuisines/actions';
import classNames from 'classnames';
import Images from './Tabs/Images';
import UsersListSortMenu from '../../Features/Users/UsersListSortMenu';

const BASE_URL = '/dashboard/cuisines';
const initialFilter: TFilterConfig[] = [
    { name: 'All', isActive: true },
    { name: 'Published', isActive: false },
    { name: 'Unpublished', isActive: false },
    { name: 'Newly Added', isActive: false },
    { name: 'Recently Updated', isActive: false },
];
const Cuisines: FC<RouteComponentProps> = () => {
    const url = window.location.href;
    const urlArray: string[] = url.split('/');
    const cuisineId = urlArray.length >= 6 ? urlArray[5] : '';
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<TCuisine[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [sortMenuEl, setSortMenuEl] = useState<HTMLElement>();
    const [sortFilter, setSortFilter] = useState<any>({});
    const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>(JSON.parse(JSON.stringify(initialFilter)));
    const classes = useStyles();

    const {
        details,
        list = [],
        totalCount = 0,
    } = useSelector<TReduxStore, Pick<TCuisineState, 'details' | 'list' | 'totalCount'>>(({ Cuisine }) => ({
        details: Cuisine.details,
        list: Cuisine.list,
        totalCount: Cuisine.totalCount,
    }));

    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string; name: string; component: RouteProps['component'] }> = [
        {
            name: 'Details',
            url: 'details',
            component: Details,
        },
        {
            url: 'places',
            name: 'Places - ' + (details && details.counts ? details.counts.establishments || 0 : 0),
            component: Places,
        },
        {
            url: 'dishes',
            name: 'Dishes - ' + (details && details.counts ? details.counts.foodDrinks || 0 : 0),
            component: Dishes,
        },
        {
            url: 'images',
            name: 'Images - ' + (details && details._pictures ? details._pictures.length : 0),
            component: Images,
        },
    ];
    useEffect(() => {
        if (cuisineId) getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuisineId]);
    const getDetails = () => {
        dispatch(OCuisine.getItemActivity(cuisineId, OCuisine.Activities.DETAILS));
    };
    const dispatch = useDispatch<TDispatch>();

    const getData = (filterConfig: TFilterConfig[] = JSON.parse(JSON.stringify(initialFilter)), sortFilter: any = {}) => {
        setLoading(true);
        try {
            dispatch(getCuisineList(pageNumber, filterConfig, { ...sortFilter }));
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const handleSearchResult = (result: Array<TCuisine>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    };

    useEffect(() => {
        getData(filterConfig, sortFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);
    const handleFilterChange = (item: { name: string; isActive: boolean }) => {
        const updatedFilter = filterConfig.map((i) => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        });
        setFilterConfig([...updatedFilter]);
    };
    const handleFilterSubmit = () => {
        if (pageNumber === 1) getData(filterConfig, sortFilter);
        else setPageNumber(1);
    };
    const handleFilterReset = () => {
        setFilterConfig(JSON.parse(JSON.stringify(initialFilter)));
        if (pageNumber === 1) getData(undefined, sortFilter);
        else setPageNumber(1);
    };
    const handleSortClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSortMenuEl(e.currentTarget);
    };

    const handleSortingChange = (filter: any) => {
        // dispatch(OUser.getItemsList({ filter: { limit: 20, skip: 20 * (pageNumber - 1), ...filter } }));
        setSortFilter(filter);
        if (pageNumber === 1) getData(filterConfig, filter);
        else setPageNumber(1);
    };
    const renderListItem = useCallback(
        (place: TCuisine): ReactElement => {
            const isActive = (match: any, location: any) => {
                const regex = new RegExp(`${BASE_URL}/${place.id}/`, 'ig');
                return regex.test(location.pathname);
            };
            return (
                <span key={place.id}>
                    <NavLink className={classes.listItem} to={`${BASE_URL}/${place.id}/details`} isActive={isActive} activeClassName={'selected'}>
                        <ListItem button>
                            <ListItemText primary={`${place.name || ''}`} />
                            <ListItemSecondaryAction>
                                {!place.isPublished ? <i className={classNames('material-icons', classes.lockIcon)}>lock</i> : null}
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider className={classes.divider} />
                    </NavLink>
                </span>
            );
        },
        [classes]
    );
    const onAddButtonClick = () => {
        dispatch({ type: OCuisine.Actions.SHOW_CUISINE_DETAIL_FORM });
    };
    return (
        <>
            <ListDashboard
                baseUrl={BASE_URL}
                csvConfig={{
                    modelName: 'Cuisine',
                }}
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                itemsCountPerPage={20}
                onPageChange={setPageNumber}
                onItemAddRequest={onAddButtonClick}
                filterConfig={filterConfig}
                onFilterChange={handleFilterChange}
                onFilterSubmit={handleFilterSubmit}
                onFilterReset={handleFilterReset}
                currentPageNumber={pageNumber}
                listProps={{
                    searchProps: {
                        type: 'Cuisine',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}
                listActionButtonConfig={[
                    {
                        icon: 'sort',
                        id: 'sort',
                        label: 'Sort',
                        onClick: handleSortClick,
                    },
                ]}
            />
            <UsersListSortMenu anchorEl={sortMenuEl} onSortingChange={handleSortingChange} handleCloseSortMenu={() => setSortMenuEl(undefined)} />
            <MainForm />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            backgroundColor: theme.palette.grey['500'],
        },
        listItem: {
            color: theme.palette.text.primary,
            '&.selected': {
                background: fade(theme.palette.primary.main, 0.4),
                display: 'block',
            },
        },
        subDashboardContainer: {
            marginLeft: 250,
        },
        headLinks: {
            marginBottom: 50,
        },
        lockIcon: {
            fontSize: 18,
            color: '#B71840',
        },
    })
);

export default Cuisines;
