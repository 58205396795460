import React from 'react'
import { Box, Checkbox, createStyles, Divider, FormControlLabel, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import { OApp } from '../../../../Models/App';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import { TExplore, TExploreState } from '../../../../Models/Explore/@types';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import { TUserReviewState } from '../../../../Models/UserReview/@types';
import { OUserReview } from '../../../../Models/UserReview';
import dimensions from '../../../../Constants/dimensions';
import { DATE_FORMAT } from '../../../../Constants/vars';

interface IProps { }

const Details: React.FC<IProps> = (props) => {

    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();
    const classes = useStyles();
    const { detail: details } = useSelector<TReduxStore, Pick<TUserReviewState, 'detail'>>(({ Review: { detail } }) => ({ detail }));

    const handleEdit = () => {
        dispatch({ type: OUserReview.Actions.SHOW_USER_REVIEW_FORM, data: details })
    }

    const setField = async (field: keyof TExplore, value: any) => {
        try {
            if (details)
                await dispatch(OUserReview.patchItem(details.id, { [field]: value }, undefined, undefined, { [field]: value }))
        } catch (error) {

        }
    }


    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(OUserReview.deleteItem(details.id, {}, undefined, details))
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }))
            }
            if (history) {
                history.push('/dashboard/reviews')
            }
        }
    }
    const handlePublish = async () => {
        if (!details) return;
        try {
            const flag = !!!details.isPublished;
            dispatch(OUserReview.togglePublish(details.id, flag));
            dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))
        }
    }
    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this List.',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this List?',
            handleDelete: handleDelete,
            helpText: 'Delete this List.',
        },
        {
            type: 'icon',
            icon: (details && details.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (details && details.isPublished) ? 'secondary' : 'primary',
            helpText: (details && details.isPublished) ? 'Unpublish this List.' : 'Publish this List.',
        }

    ]
    return (
        <div className={classes.foodDrinkDetail}>
            {
                details &&
                <>

                    <HeaderActionButtons items={headerItems} />
                    <Paper className={classes.paper}>
                        <Box display='flex'>
                            <Typography variant="overline">{`Created - ` + moment(details.created).format(DATE_FORMAT) + ` Modified - ` + moment(details.updated).format(DATE_FORMAT)}</Typography>
                        </Box>
                        <div>
                            <Typography gutterBottom variant="h5">{details.title}</Typography>
                            <Typography variant="body2">{details.subtitle}</Typography>
                            <div className={classes.imageInputContainer}>
                                <div className={classes.imageContainer}>
                                    <img src={details.lgPicture?.url} />
                                    <Typography variant='caption'>Desktop Image</Typography>
                                    
                                </div>
                                <div className={classes.imageContainer}>
                                    <img src={details.smPicture?.url} />
                                    <Typography variant='caption'>Mobile Image</Typography>
                                    
                                </div>
                            </div>
                        </div>


                    </Paper>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    foodDrinkDetail: {
        position: 'relative',
        margin: '0px 40px',
    },
    paper: {
        width: dimensions.screenMaxWidth,
        margin: 'auto',
        padding: '30px',
        marginTop: 20
    },
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            margin: '0 10px'
        }
    },
    imageContainer: {
        width: '360px',
        aspectRatio: '3/2',
        backgroundColor: '#ddd',
        position: 'relative',
        '& img': {
            width: '100%',
            aspectRatio: '3/2',
        }
    },
    imageInputContainer: {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'space-between',
    },
}))

export default Details


