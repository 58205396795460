import LoopFront from "loop-front";
import { TEatineraryFilterConfig } from "../../Components/Filters/EatineraryFilterMenu";
import { TDispatch } from "../../Resources/GlobalTypes";
import { OApp } from "../App";
import _ from 'lodash';
import { TFilterConfig } from "../App/@types";



const CustomActions = {
    SHOW_EATINERARY_FORM: 'SHOW_EATINERARY_FORM',
    HIDE_EATINERARY_FORM: 'HIDE_EATINERARY_FORM',
}

const CustomEntities = {

}
const CustomActivities = {
    COUNT: 'count',
    PUBLISH: 'publish',
}


class Eatinerary extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
    constructor() {
        super('eateries', CustomActions, CustomEntities, CustomActivities);
    }

    togglePublish(id: string, publish: boolean) {
        return (async (dispatch: TDispatch) => {
            const { data } = await LoopFront.request({
                url: this.ModelName + `/${id}/publish`,
                method: 'post',
                params: { publish }
            });
            dispatch({ type: this.Actions.ITEM_PATCH_SUCCESS, data })
        })
    }

    parseEatineraryFilters(config: TEatineraryFilterConfig) {
        let where: Record<string, any> = {};

        let baseFilter = OApp.parseBaseFilter(config.type || {})
        let cities = config.cities.filter(s => s.isActive).map(s => s.value);

        // if (sec1Filter.All != true) {
        //     Object.keys(sec1Filter)
        //         .filter((k) => sec1Filter[k] == true)
        //         .forEach((key) => {
        //             switch (key) {
        //                 case 'Recommender':
        //                     where['isRecommender'] = true;
        //                     break;
        //                 case 'Non recommender':
        //                     where['isRecommender'] = false;
        //                     break;
        //                 default:
        //                     break;
        //             }
        //         });
        // }

        if (cities.length > 0) {
            where.destinationId = { inq: cities };
        }

        return { ...baseFilter, where: { ...where, ...baseFilter?.where } };
    }
    getEatineraries(pageNumber: number, filterConfig: TEatineraryFilterConfig, cityIds: string[], sortFilter: any = {}) {
        let cityFilter = cityIds.length ? { destinationId: { inq: cityIds } } : {}
        return (async (dispatch: TDispatch) => {
            const filter = this.parseEatineraryFilters(filterConfig);
            // const filter = OApp.parseBaseFilter(filterConfig)
            await dispatch(this.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    // include: {
                    //     relation: 'destination',
                    //     scope: {
                    //         fields: ['name']
                    //     }

                    // },
                    ...filter,
                    ...sortFilter
                }
            }))
            if (pageNumber === 1)
                await dispatch(this.getActivity(this.Activities.COUNT, { ...filter, ...cityFilter }))
        })
    }

}

export const OEatinerary = new Eatinerary();


