import React, { FC, useState } from 'react'
import { Box, Fab, Menu, Paper, Typography, Icon, MenuItem } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TPicture } from '../../Resources/GlobalTypes';
import LockIcon from '../LockIcon';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';

export interface ReorderableItemProps {
    title: string
    isPublished?: boolean
    onDelete?: () => void
    onViewDetails: () => void
    logo?: TPicture
    draghandleProps?: any
}

const ReorderableItem: FC<ReorderableItemProps> = (props) => {
    const classes = useStyles();
    const { title, isPublished = true, onDelete, onViewDetails, logo, draghandleProps = {} } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dialog, toggleDialog] = useState(false)
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (anchorEl === null)
            setAnchorEl(e.currentTarget)

    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleDelete = () => { toggleDialog(!dialog) }
    const handleDialogDelete = () => {
        onDelete?.();
        toggleDialog(!dialog)
    }
    const handleMenuClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, fun: () => void) => {
        setAnchorEl(null);
        fun();
    }
    const options = [{
        text: 'View details',
        onClick: onViewDetails
    }, {
        text: 'Delete',
        onClick: onDelete ? handleDelete : null
    }]
    return (
        <Paper elevation={1} className={classes.paper}>
            <img className={classes.img} alt='' src={logo?.url} />
            <Typography variant='subtitle2'>{title}</Typography>
            <Box display='flex' alignSelf='flex-end' flex={1} justifyContent='flex-end' alignItems='center'>
                {!isPublished ? <LockIcon size='medium' /> : null}
                <Fab size={'small'} color={'inherit'} onClick={handleClick}>
                    <Icon className={'material-icons'}>
                        more_vert
                    </Icon>
                    <Menu
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                    >
                        {
                            //menuOption
                            options.map((item, index) => {
                                if (!item.onClick) return null
                                return (<MenuItem key={item.text} onClick={(e) => { handleMenuClick(e, item.onClick!) }}>
                                    {item.text}
                                </MenuItem>)
                            })
                        }
                    </Menu>
                </Fab>
            </Box>
            <div
                id='drag'
                style={{ top: 11, right: '-25px', position: 'absolute' }}
                className={'dragHandle'} {...draghandleProps}>
                <Fab color='inherit' size={"small"} className={classes.dragIcon}>
                    <Icon fontSize='small' color='primary' className={'material-icons'} >unfold_more</Icon>
                </Fab>
            </div>
            <ConfirmationDialog
                open={dialog}
                onAgree={handleDialogDelete}
                onDisagree={handleDelete}
                message={"Are you sure you want to delete this item?"}
                onClose={handleDelete}
            />
        </Paper>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: 300,
            padding: 8,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            margin: theme.spacing(1, 0)
        },
        img: {
            height: 42,
            marginRight: 8,
            width: 42,
            background: theme.palette.grey['600']
        },
        dragIcon: {
            height: 35,
            width: 35
        }
    }))
})

export default ReorderableItem