import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom';
import Page404 from '../Screens/Page404';
import Landing from '../Screens/Landing';
import DashboardLaout from '../Screens/Dashboard/DashboardLaout';
import Toast from '../Components/Toast';


const Appnavigation: FC = () => {
    return (
        <>
        <Switch>
            <Route path='/' exact component={Landing} />
            <Route path='/dashboard' component={DashboardLaout} />
            <Route path='/*' exact component={Page404} />
        </Switch>
         <Toast/>
        </>
    )
}

export default Appnavigation;