import { Box, createStyles, Fab, Paper, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { RatingValues, TRating } from '../../../../Models/Review/@types';
import { UPDATE } from './Reducer';
export interface RatingSectionProps {
    info?: google.maps.places.PlaceResult
    dispatchReducer: React.Dispatch<{
        type: string,
        name: string;
        data: any;
    }>
    externalRatings: TRating[]
}

export type externalRating = {
    name?: string
    value?: string
}
const RatingSection: FC<RatingSectionProps> = (props) => {
    const { dispatchReducer, externalRatings } = props;
    const classes = useStyles();
    const [googleRating, setGoogleRating] = useState<RatingValues>()
    const [yelpRating, setYelpRating] = useState<RatingValues>()
    const [ratingList, setRatingList] = useState<TRating[]>([{ name: 'Google', value: null }, { name: 'Yelp', value: null }]);
    useEffect(() => {
        if (externalRatings.length > 0) {
            const list: TRating[] = []
            const gIndex = externalRatings.findIndex(i => i.name === 'Google');
            const yIndex = externalRatings.findIndex(i => i.name === 'Yelp')
            if (gIndex === -1) {
                list.push({ name: 'Google', value: null })
                setGoogleRating(null)
            }
            else {
                list.push({ name: 'Google', value: externalRatings[gIndex].value })
                setGoogleRating(externalRatings[gIndex].value)
            }
            if (yIndex === -1) {
                list.push({ name: 'Yelp', value: null })
                setYelpRating(null)
            }
            else {
                list.push({ name: 'Yelp', value: externalRatings[yIndex].value })
                setYelpRating(externalRatings[yIndex].value)
            }
            _.map(externalRatings, (value, index) => {
                if (index === gIndex || index === yIndex) { }
                else
                    list.push(value);
            })
            setRatingList([...list])

        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault();
        const list: TRating[] = [...ratingList]
        const index = list.findIndex(i => i.name === e.target.name)
        if (index === -1) {
            list.push({ name: e.target.name, value: Number(e.target.value) })
        } else {
            list.splice(index, 1, { name: e.target.name, value: Number(e.target.value) })
        }
        dispatchReducer({ type: UPDATE, name: 'externalRatings', data: list })
        setRatingList([...list])
        if (e.target.name === 'Yelp')
            setYelpRating(Number(e.target.value));
        else
            setGoogleRating(Number(e.target.value))
    }

    const handleRatingChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string, index: number) => {
        e.preventDefault();
        var list = [...ratingList];

        if (name === 'name')
            list[index].name = e.target.value
        else
            list[index].value = Number(e.target.value)
        setRatingList([...list])
        dispatchReducer({ type: UPDATE, name: 'externalRatings', data: list })
    }

    const handleDeleteRating = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
        e.preventDefault();
        var list = [...ratingList];
        list.splice(index, 1)
        setRatingList([...list])
        dispatchReducer({ type: UPDATE, name: 'externalRatings', data: [...list] })
    }

    const handleAddRating = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const list = [...ratingList];
        list.push({ name: '', value: null })
        setRatingList([...list])
        dispatchReducer({ type: UPDATE, name: 'externalRatings', data: list })

    }



    return (
        <Box width={800} mt={5} >
            <Paper className={classes.root}>
                <Box display='flex' flexDirection='column' >
                    <Box display='flex' alignItems='flex-start' marginBottom='20px'>
                        <TextField
                            className={classes.textField}
                            name='Google'
                            label='Google Rating'
                            type='number'
                            value={googleRating || ''}
                            onChange={handleChange} />
                        <TextField
                            className={classes.textField}
                            name='Yelp'
                            type='number'
                            label='Yelp Rating'
                            value={yelpRating || ''}
                            onChange={handleChange} />
                    </Box>
                    {
                        _.map(ratingList, (value, index) => {

                            return (
                                (value.name === 'Google' || value.name === 'Yelp') ? null :
                                    <Box key={`external_rating_${index}`} marginBottom='20px'>
                                        <TextField
                                            label="Rating Name"
                                            fullWidth
                                            name="rating"
                                            value={value.name || ''}
                                            className={classes.textField}
                                            color="primary"
                                            required
                                            onChange={(e) => handleRatingChange(e, 'name', index)}
                                        />
                                        <TextField
                                            required
                                            label="Value"
                                            fullWidth
                                            type='number'
                                            name="rating"
                                            color="primary"
                                            value={value.value || ''}
                                            className={classes.textField}
                                            onChange={(e) => handleRatingChange(e, 'value', index)}
                                        />

                                        <Fab color='inherit' size='small' onClick={(e) => handleDeleteRating(e, index)}>
                                            <i className="material-icons" >close</i>
                                        </Fab>
                                    </Box>)
                        })
                    }
                    <Box>
                        <Fab color="primary" onClick={handleAddRating}>
                            <i className="material-icons">add</i>
                        </Fab>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            padding: '40px 32px 40px',
            height: 'auto'
        },
        textField: {
            width: '130px',
            marginRight: '20px',
            height: '50px',
        },
    }))
})

export default RatingSection