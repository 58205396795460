import { Box } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import ReviewCard from '../../../../Components/Cards/ReviewCard'
import { OApp } from '../../../../Models/App'
import { OPlace } from '../../../../Models/Place'
import { TPlaceState } from '../../../../Models/Place/@types'
import { OReview } from '../../../../Models/Review'
import { TReview } from '../../../../Models/Review/@types'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'

interface IProps extends RouteComponentProps<{ id: string }> { }

const Reviews: React.FC<IProps> = (props) => {
    const { match } = props
    const placeId = match.params['id'];
    const dispatch = useDispatch<TDispatch>();
    const { reviews = [] } = useSelector<TReduxStore, Pick<TPlaceState, 'reviews'>>(({ Place }) => ({ reviews: Place.reviews }))
    const getReviews = async () => {
        await dispatch(OPlace.getEntityByItem(placeId, OPlace.Entities.REVIEWS, { filter: { include: ['user', 'subject'] } }))
    }

    useEffect(() => {
        if (placeId)
            getReviews();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeId])
    const handlePublish = async (item: TReview) => {

        try {
            const currentIndex = reviews.findIndex(i => item.id === i.id)
            reviews.splice(currentIndex, 1, { ...item, isPublished: !item.isPublished })
            await dispatch(OReview.postItemActivity(item.id, OReview.Activities.PUBLISH, {}, { publish: !item.isPublished }))
            dispatch(OApp.showToast({ variant: 'success', message: item.isPublished ? 'Unpublished!' : "Published!" }))
            dispatch({ type: OPlace.Actions.SINGLE_REVIEW_PUBLSIHED_OF_PLACE, data: [...reviews] })

        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ variant: 'error', message: 'Item Publish/Unpublish fail' }))
        }
    }
    return (
        <Box width={700} margin={'auto'} >
            {
                reviews.map((item, i) => {
                    return <Box mb={5} key={i} ><ReviewCard review={item} handlePublish={handlePublish} /></Box>
                })
            }
        </Box>
    )
}



export default Reviews


