import {
    Button,
    CircularProgress,
    createStyles,
    makeStyles,
    Checkbox,
    Paper,
    Theme,
    Typography,
    Divider,
    Box,
    FormControlLabel,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import { OApp } from '../../../../Models/App';
import { OCity } from '../../../../Models/City';
import { TCity, TCityState } from '../../../../Models/City/@types';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import moment from 'moment';
import { TAppState } from '../../../../Models/App/@types';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import TypographyWMention from '../../../../Components/TypographyWMention';
import { renderHtmlStr } from '../../../../Features/Mention';
import dimensions from '../../../../Constants/dimensions';
import { CONTINENT_SETTING_KEY, DATE_FORMAT } from '../../../../Constants/vars';
import { TSettingsState } from '../../../../Models/Settings/@types';

export type IStateProps = Pick<TCityState, 'details'> & Pick<TAppState, 'constants' | 'destinationPacks'> & Pick<TSettingsState, 'list'>;

const Details: FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {
    const cityId = match.params['id'];
    const classes = useStyles();
    const [destinationPack, setDestinatinonPack] = useState('');
    const dispatch = useDispatch<TDispatch>();

    const {
        details,
        constants,
        list: settings,
        destinationPacks,
    } = useSelector<TReduxStore, IStateProps>(({ City: { details }, App: { constants, destinationPacks }, Settings: { list } }) => ({
        details,
        constants,
        list,
        destinationPacks,
    }));
    const cityDetails = details;
    const textUrl = `${DOMAIN_URL}/cities/${details?.slug || cityId}`;

    const continentSetting = settings?.find((item) => item.key === CONTINENT_SETTING_KEY);
    const continentList = continentSetting?.value || [];

    const handleEdit = useCallback(() => {
        dispatch({ type: OCity.Actions.SHOW_CITY_DETAIL_FORM, data: cityDetails });
    }, [dispatch, cityDetails]);

    const handleDelete = async () => {
        if (cityDetails) {
            try {
                await dispatch(OCity.deleteItem(cityDetails.id, { deleted: true }, undefined, cityDetails));
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'Destination' } });
                dispatch(OApp.showToast({ message: 'Success Delete!', variant: 'success' }));
            } catch (err) {
                console.log('City delete:', err);
                dispatch(OApp.showToast({ message: 'Deletion Failed!', variant: 'error' }));
            }
            if (history) history.push('/dashboard/cities');
        }
    };

    const handlePublish = async () => {
        if (cityDetails) {
            try {
                const flag = !cityDetails.isPublished;
                await dispatch(OCity.postItemActivity(cityDetails.id, OCity.Activities.PUBLISH, {}, { publish: !cityDetails.isPublished }));
                dispatch(OApp.showToast({ message: flag ? 'Published!' : 'Unpublished!', variant: 'success' }));
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }));
                console.log('City publish', err);
            }
        }
    };
    const handleAddToMamakooToggle = async () => {
        try {
            if (cityDetails) await dispatch(OCity.patchItem(cityDetails.id, { inMamakoo: !cityDetails.inMamakoo }));
        } catch (err) {
            console.log('Error in add to mamakoo', err);
        }
    };
    const handleDestinationPackUpdate = async (value: string) => {
        try {
            const dp = destinationPacks?.find((item) => item.id === value);
            if (!dp) return;
            if (cityDetails) await dispatch(OCity.patchItem(cityDetails.id, { destinationPackId: value }, {}, undefined, { destinationPack: dp }));
            dispatch(OApp.showToast({ message: 'Subscription value updated', variant: 'success' }));
        } catch (err) {
            console.log('Error in add to mamakoo', err);
        }
    };
    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this place',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this destination?',
            handleDelete: handleDelete,
            helpText: 'Delete this place',
        },
        {
            type: 'icon',
            icon: cityDetails && cityDetails.isPublished ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: cityDetails && cityDetails.isPublished ? 'secondary' : 'primary',
            helpText: cityDetails && cityDetails.isPublished ? 'Unpublish this city' : 'Publish this city',
        },
        {
            type: 'copy_button',
            textUrl: textUrl,
        },
    ];

    const handleNoteSave = async (notes: string) => {
        try {
            if (cityDetails) await dispatch(OCity.patchItem(cityDetails.id, { notes }));
            dispatch(OApp.showToast({ message: `Notes updated.`, variant: 'success' }));
        } catch (err) {
            dispatch(OApp.showToast({ message: `Notes update failed.`, variant: 'success' }));
            console.log('Error in add to mamakoo', err);
        }
    };

    const setField = async (field: keyof TCity, value: any) => {
        try {
            if (cityDetails) await dispatch(OCity.patchItem(cityDetails.id, { [field]: value }));
        } catch (error) { }
    };

    const handleDestinationPack = (e: React.ChangeEvent<{ value: unknown }>) => {
        setDestinatinonPack(e.target.value as string);
        handleDestinationPackUpdate(e.target.value as string);
    };

    return (
        <div className={classes.destinationDetail}>
            {cityDetails ? (
                <>
                    <HeaderActionButtons items={headerItems} />
                    <Box mt={3} m="auto">
                        <NotesInput onSave={handleNoteSave} text={cityDetails.notes || ''} />
                    </Box>
                    <Paper className={classes.paper}>
                        <section style={{ display: 'flex' }}>
                            {cityDetails.created ? (
                                <Typography variant="body2" color="textPrimary">
                                    Created - {moment(cityDetails.created).format(DATE_FORMAT)}&nbsp;
                                </Typography>
                            ) : null}
                            {cityDetails.updated ? (
                                <Typography variant="body2" color="textPrimary">
                                    Modified - {moment(cityDetails.updated).format(DATE_FORMAT)}
                                </Typography>
                            ) : null}
                        </section>
                        <Box display="flex" flexDirection="column" my={2} ml={0.5}>
                            <FormControlLabel
                                onClick={(e) => setField('newlyAdded', !cityDetails?.newlyAdded)}
                                label="Newly added"
                                control={<Checkbox color="primary" checked={!!cityDetails?.newlyAdded} />}
                            />
                            <FormControlLabel
                                onClick={(e) => setField('recentlyUpdated', !cityDetails?.recentlyUpdated)}
                                label="Recently updated"
                                control={<Checkbox color="primary" checked={!!cityDetails?.recentlyUpdated} />}
                            />
                        </Box>
                        <Divider style={{ margin: '15px 0' }} />
                        <section>
                            <Typography gutterBottom variant="h4" className={classes.mainTitle}>
                                {cityDetails.name}{' '}
                                {cityDetails.isPublished ? <div></div> : <i className={clsx('material-icons', classes.lockIcon)}>lock</i>}
                            </Typography>
                            <Divider />
                            <Box py={4}>
                                <Typography gutterBottom variant="h6" color="textPrimary">
                                    Subscription
                                </Typography>
                                <Typography>
                                    {cityDetails.destinationPack?.currencySymbol} {cityDetails.destinationPack?.currencyValue} per{' '}
                                    {cityDetails?.destinationPack?.durationValue} {cityDetails?.destinationPack?.durationType}
                                </Typography>
                                {/* <FormControl className={classes.formControl}>
                                    <InputLabel id="destination-pack-label">Subscription</InputLabel> */}
                                <Select
                                    labelId="destination-pack-labe"
                                    id="destination-pack-select"
                                    value={cityDetails.destinationPackId}
                                    onChange={handleDestinationPack}
                                >
                                    {destinationPacks?.map((dp, index) => {
                                        return (
                                            <MenuItem value={dp.id} key={index}>
                                                {dp.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {/* </FormControl> */}
                            </Box>
                            <div className={classes.addToMamakooButtonContainer}>
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body2">{cityDetails.country}</Typography>
                                    {constants ? (
                                        <Typography variant="body2">
                                            {continentList.find((i) => i.value === cityDetails.continent)?.name || ''}
                                        </Typography>
                                    ) : null}
                                </Box>
                                <Button
                                    variant={'contained'}
                                    onClick={handleAddToMamakooToggle}
                                    color="primary"
                                    style={{
                                        borderRadius: '16px',
                                        height: '32px',
                                    }}
                                >
                                    {cityDetails.inMamakoo ? 'Remove from Mamakoo' : 'Add to Mamakoo'}
                                </Button>
                            </div>

                            {cityDetails.whatsInside ? (
                                <>
                                    <Divider style={{ margin: '15px 0' }} />
                                    <Typography variant={'subtitle1'}>What's Inside?</Typography>
                                    <TypographyWMention variant="body2" className={classes.paragraph} renderMentionHTMLStr={renderHtmlStr}>
                                        {(cityDetails.whatsInside || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                    </TypographyWMention>
                                </>
                            ) : null}

                            {cityDetails.shortDescription ? (
                                <>
                                    <Divider style={{ margin: '15px 0' }} />
                                    <Typography variant={'subtitle1'}>Short Description</Typography>
                                    <TypographyWMention variant="body2" className={classes.paragraph} renderMentionHTMLStr={renderHtmlStr}>
                                        {(cityDetails.shortDescription || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                    </TypographyWMention>
                                </>
                            ) : null}

                            {cityDetails.description ? (
                                <>
                                    <Divider style={{ margin: '15px 0' }} />
                                    <Typography variant={'subtitle1'}>Long Description</Typography>
                                    <TypographyWMention variant="body2" className={classes.paragraph} renderMentionHTMLStr={renderHtmlStr}>
                                        {(cityDetails.description || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                    </TypographyWMention>
                                </>
                            ) : null}
                        </section>
                    </Paper>
                </>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};

const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        destinationDetail: {
            position: 'relative',
            margin: '0px 40px',
        },
        paper: {
            width: dimensions.screenMaxWidth,
            margin: 'auto',
            padding: '30px',
            marginTop: 20,
        },
        addToMamakooButtonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
        },
        operationBtns: {
            display: 'flex',
            justifyContent: 'center',
            color: 'white',
            '& > button': {
                margin: '0 10px',
            },
            '& > div': {
                margin: '0 10px',
            },
        },
        mainTitle: {
            color: theme.palette.grey['100'],
        },
        paragraph: {
            marginTop: 8,
        },
        lockIcon: {
            color: '#B71840',
        },
        formControl: {
            minWidth: 160,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

export default Details;
