import LoopFront from "loop-front";



const CustomActions = {
    SHOW_EXPLORE_FORM: 'SHOW_EXPLORE_FORM',
    HIDE_EXPLORE_FORM: 'HIDE_EXPLORE_FORM',
}

const CustomEntities = {

}
const CustomActivities = {
    COUNT: 'count',
    PUBLISH: 'publish',
}


class Explore extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
    constructor() {
        super('lists', CustomActions, CustomEntities, CustomActivities);
    }

}

export const OExplore = new Explore();


