import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import LoopFront, { TAction } from 'loop-front';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import Loader from './Components/Loader';
import { OApp } from './Models/App';
import MuiTheme from './MuiTheme';
import Config from './Resources/Config';
import { TReduxStore } from './RootReducer';
import RootLayout from './Screens/RootLayout';
import { attachField, setDefaultProps } from 'react-forms';
import { OPack } from './Models/Pack';
import RFTextFieldWMention from './Components/Inputs/TextFieldWMention/RFTextFieldWMention';
import { OCity } from './Models/City';
export const HISTORY = createBrowserHistory();

axios.interceptors.response.use(
    res => res,
    err => {
        if (err?.response?.data?.statusCode === 401) {
            HISTORY.push('/');
        }
    })


attachField('mention', <RFTextFieldWMention />)

const App: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<TReduxStore, {}, TAction>>();
    const history = useHistory();

    const [splash, setSplash] = useState(true);

    const appInit = async () => {
        const location = window.location.pathname;
        const newLocation = (!location || location === '/') ? '/dashboard' : location;
        try {
            // await dispatch(OPack.getPacks());
            const res = await dispatch(OApp.init())
            if (res)
                history.push(newLocation);
            else
                history.push('/');
            console.log(res);
        } catch (err) {
            console.log(err)
            history.push('/');
        }
        setSplash(false);
    }

    useEffect(() => {
        LoopFront.init(Config.API_BASE_URL, { log: false });
        appInit().then(err => {
            setDefaultProps('text', { autoComplete: 'off', inputProps: { autoComplete: 'nope' } });
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={MuiTheme} >
                {!splash ? <RootLayout /> : <Loader />}

            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default App;
