import { Chip, createStyles, FormControl, Input, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import { OApp } from '../../../Models/App';
import { OTagCategories } from '../../../Models/Tags';
import { TTagCategoriesState } from '../../../Models/Tags/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';

export interface TagCategoryFormProps {
    getData: () => Promise<void>
}

const TagCategoryForm: FC<TagCategoryFormProps> = (props) => {
    const classes = useStyles();
    const { showTagCategoryForm = false, editedCategory } = useSelector<TReduxStore, Pick<TTagCategoriesState, 'editedCategory' | 'showTagCategoryForm'>>(({ TagCategories }) => ({ showTagCategoryForm: TagCategories.showTagCategoryForm, editedCategory: TagCategories.editedCategory }))
    const [editedName, setEditedName] = useState<string>('')
    const dispatch = useDispatch<TDispatch>()
    const [tagCategoryList, setTagCategoryList] = useState<Array<{ name: string }>>([])
    const [newName, setNewName] = useState<string>('')
    const handleEditNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setEditedName(e.target.value as string)
    }

    const onSubmit = async () => {
        try {
            if (editedCategory && editedCategory.id) {
                await dispatch(OTagCategories.patchItem(editedCategory.id, { name: editedName }))
                props.getData();
                dispatch(OApp.showToast({ message: "TAGS Added Successfully", variant: 'success' }))
            } else {
                await dispatch(OTagCategories.postItem(tagCategoryList))
                props.getData();
                dispatch(OApp.showToast({ message: "TAG Edited Successfully", variant: 'success' }))
            }
            closeForm();
        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ message: "TAGS Add /Edit Unsuccessful", variant: 'error' }))
        }
    }
    useEffect(() => {
        if (editedCategory)
            setEditedName(editedCategory.name || '')
    }, [editedCategory])
    const closeForm = () => {
        dispatch({ type: OTagCategories.Actions.HIDE_TAG_CATEGORY_FORM })
    }
    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {

        if (newName === '') {
            return
        }
        else {

            if (e.key === 'Enter') {
                tagCategoryList.push({ name: newName });
                setNewName('');
                e.preventDefault()
            } else
                return
        }
    }
    const handleDelete = (index: number) => {
        const tempList = [...tagCategoryList];
        tempList.splice(index, 1);
        setTagCategoryList([...tempList]);
    }
    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault();
        setNewName(e.target.value)
    }
    return (
        <FormDialog
            open={showTagCategoryForm}
            heading='ADD / Edit Tag category'
            onSubmit={onSubmit}
            onClose={closeForm}
        >
            {
                editedCategory ?
                    <Paper className={classes.paper}>
                        <TextValidator
                            fullWidth={true}
                            underlineStyle={{ borderColor: '#ffffff' }}
                            placeholder='Tag Name'
                            name='CategoryName'
                            validators={['required']}
                            errorMessages={['This field cannot be empty']}
                            value={editedName || ''}
                            onChange={handleEditNameChange}
                            onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                            color='primary'
                        >

                        </TextValidator>
                    </Paper>
                    :
                    <>
                        <Paper className={classes.paper}>
                            <FormControl fullWidth>
                                <Input
                                    fullWidth
                                    value={newName || ''}
                                    placeholder='Enter name and press enter'
                                    onKeyPress={onKeyPress}
                                    onChange={handleTextChange}
                                    disableUnderline={true}
                                />
                            </FormControl>
                        </Paper>
                        <Paper className={classes.paper}>
                            <div style={{ width: '500px', height: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    tagCategoryList.map((item, index) => {
                                        return <Chip
                                            style={{ margin: 5 }}
                                            label={item.name}
                                            key={index}
                                            color='default'
                                            onDelete={(e) => { handleDelete(index) }}
                                        />
                                    })
                                }
                            </div>
                        </Paper>
                    </>
            }
        </FormDialog>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            marginBottom: '20px',
            width: '500px',
            minHeight: '50px',
            height: 'auto',
            display: 'flex',
            padding: '5px 10px'
        }
    }))
})

export default TagCategoryForm