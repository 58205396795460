import React, { useState } from 'react'
import { createStyles, makeStyles, Theme, Grid, Paper, Typography, Fab, Icon, Menu, MenuItem, Checkbox, FormControlLabel, GridProps } from '@material-ui/core'
import { Link } from 'react-router-dom'

export interface ItemCardProps {
    title?: string
    subtitle?: string
    gridProps?: GridProps
    status?: string
    published?: boolean
    link?: string
    options?: Array<{
        text: string
        onClick: () => void
    }>
    sortable?: boolean
    checkboxText?: string
    onCheckboxChange?: (item: any) => void
    isChecked?: boolean
    // TODO: Change to Promise<void> once backend is ready.
}

const ItemCard: React.FC<ItemCardProps> = (props) => {
    const { title, subtitle = '', status = '', link = '#', gridProps, options, published, sortable = false } = props
    const classes = useStyles(props)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (anchorEl === null)
            setAnchorEl(e.currentTarget)

    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleMenuClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, fun: () => void) => {
        setAnchorEl(null);
        fun();
    }

    return (
        <Grid item xs={6} key={title} {...gridProps}>
            <Link to={link}>
                <Paper className={classes.paper}>
                    {sortable === true && <Icon color='action' className={classes.icon} fontSize='default' id='drag'>open_with</Icon>}
                    <div>
                        <Typography className={classes.mainTitle} >
                            {title}
                        </Typography>
                        <Typography className={classes.address}>
                            {subtitle}
                        </Typography>
                        {
                            props.checkboxText && props.onCheckboxChange ? <> <FormControlLabel color="primary" onChange={props.onCheckboxChange} control={<Checkbox color="primary" checked={props.isChecked} />} label={props.checkboxText} /> </>
                                : null
                        }
                    </div>
                    <div className={classes.bottom}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {
                                published === undefined ? null :
                                    <Icon fontSize='small' style={{ color: '#B71840' }} className={'material-icons'} >
                                        {published ? '' : 'lock'}
                                    </Icon>
                            }
                            {

                                <Typography variant="subtitle2">
                                    {status}
                                </Typography>
                            }

                        </div>
                        {options ? (
                            <Fab size={'small'} color={'inherit'} onClick={handleClick}>
                                <Icon className={'material-icons'}>
                                    more_vert
                                </Icon>
                                <Menu
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorEl={anchorEl}
                                >
                                    {
                                        //menuOption
                                        options.map((item, index) => {
                                            return (<MenuItem key={item.text} onClick={(e) => { handleMenuClick(e, item.onClick) }}>
                                                {item.text}
                                            </MenuItem>)
                                        })
                                    }
                                </Menu>
                            </Fab>
                        ) : null}
                    </div>
                </Paper>
            </Link>
        </Grid>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        minWidth: '260px',
        minHeight: '100px',
        height: 'auto',
        borderRadius: '5px',
        padding: '20px',
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'
    },
    mainTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    address: {
        fontSize: '14px',
    },
    bottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    fab: {
        background: 'transparent'
    },
    icon: {
        position: 'absolute',
        top: 5,
        right: 5,
        color: theme.palette.grey['600'],
        // opacity: '40%',
        // '&:hover': {
        //     // color: 'white',
        //     opacity: '100%',
        // }
    }
}))

export default ItemCard