import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import PageSectionConfigItem, { PageSectionConfigItemProps } from '../../../Features/Settings/PageSectionConfigItem';
import dimensions from '../../../Constants/dimensions';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../RootReducer';
import { TSettingsState } from '../../../Models/Settings/@types';
import { SETTINGS_CITY_PAGE } from '../../../Constants/settings';
import { OSettings } from '../../../Models/Settings';
import { TDispatch } from '../../../Resources/GlobalTypes';

export interface CityPageProps {

}

const keys = [
    SETTINGS_CITY_PAGE.dishes,
    SETTINGS_CITY_PAGE.cuisines,
    SETTINGS_CITY_PAGE.explore,
    SETTINGS_CITY_PAGE.bookWeeksAhead,
    SETTINGS_CITY_PAGE.landmarks,
    SETTINGS_CITY_PAGE.eatinerary,
    SETTINGS_CITY_PAGE.allPlaces,
]



const CityPage: FC<CityPageProps> = (props) => {
    const classes = useStyles({});
    const dispatch = useDispatch<TDispatch>();
    const { list } = useSelector<TReduxStore, Pick<TSettingsState, 'list'>>(({ Settings: { list } }) => ({ list }))
    const cityPageSetting = list.find(s => s.key === SETTINGS_CITY_PAGE.id)

   
    const configs = cityPageSetting ? keys.map(key => ({
        key, ...getPageSectionConfig(cityPageSetting?.value?.[key])
    })) : []


    const handleChange = (key: string) => async (title: string, subtitle: string, isVisible: boolean) => {
        if(!cityPageSetting) return;

        const newSettingsValue = {
                ...cityPageSetting.value,
                [key]: {
                    title,
                    subtitle,
                    isVisible
                }
        }
        await dispatch(OSettings.patchItem(cityPageSetting.id, {value:newSettingsValue}))
    }

    if(!cityPageSetting) return null;
    return (
        <Box pb={10} width={dimensions.formPaperMaxWidth} mx="auto">
            {configs.map(config => (
                <PageSectionConfigItem 
                    {...config}
                    onChange={handleChange(config.key)}
                />
            ))}
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

function getPageSectionConfig(record: Record<string, any>): PageSectionConfigItemProps  {
    return {
        title: record.title,
        subtitle: record.subtitle,
        isVisible: record.isVisible,
    }
}
export default CityPage;