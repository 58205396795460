import { Paper, Theme, Typography, Divider, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import TypographyWMention from '../../../../Components/TypographyWMention';
import dimensions from '../../../../Constants/dimensions';
import { renderHtmlStr } from '../../../../Features/Mention';
import { OApp } from '../../../../Models/App';
import { OCuisine } from '../../../../Models/Cuisines';
import { TCuisine, TCuisineState } from '../../../../Models/Cuisines/@types';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

const Details: FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {

    const cuisineId = match.params['id'];

    const dispatch = useDispatch<TDispatch>();

    const { details } = useSelector<TReduxStore, Pick<TCuisineState, 'details'>>(({ Cuisine }) => ({ details: Cuisine.details }));

    const cuisine = details;

    const classes = useStyles();

    const textUrl = `${DOMAIN_URL}/cuisines/${details?.slug || cuisineId}`;
    const handleEdit = () => {
        if (details) {
            dispatch({ type: OCuisine.Actions.SHOW_CUISINE_DETAIL_FORM, data: details })
        }
    }
    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(OCuisine.deleteItem(details.id, { deleted: true }, undefined, details))
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'Cuisine' } })
                dispatch(OApp.showToast({ message: 'Success Delete!', variant: 'success' }))
            }
            catch (err) {
                console.log("Cuisine delete:", err)
                dispatch(OApp.showToast({ message: 'Deletion Failed!', variant: 'error' }))
            }
            if (history)
                history.push("/dashboard/cuisines");
        }
    }
    const handlePublish = async () => {
        if (details) {
            try {
                const flag = !details.isPublished;
                await dispatch(OCuisine.postItemActivity(details.id, OCuisine.Activities.PUBLISH, {}, { publish: !details.isPublished }))
                dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
            }
            catch (err) {
                dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))
                console.log('Cuisine publish', err)
            }
        }
    }
    const handleNoteSave = async (notes: string) => {
        try {
            if (details)
                await dispatch(OCuisine.patchItem(details.id, { notes }, {}, undefined, { notes }));
            dispatch(OApp.showToast({ message: `Notes updated.`, variant: 'success' }))

        } catch (err) {
            dispatch(OApp.showToast({ message: `Notes update failed.`, variant: 'success' }))
            console.log('Error in add to mamakoo', err);
        }
    }

    const setField = async (field: keyof TCuisine, value: any) => {
        try {
            if (cuisine)
                await dispatch(OCuisine.patchItem(cuisine.id, { [field]: value }, undefined, undefined, { [field]: value }))
        } catch (error) {

        }
    }



    // const handleAddToMamakooToggle = () => { }
    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this cuisine',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this cuisine?',
            handleDelete: handleDelete,
            helpText: 'Delete this cuisine',
        },
        {
            type: 'icon',
            icon: (details && details.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (details && details.isPublished) ? 'secondary' : 'primary',
            helpText: (details && details.isPublished) ? 'Unpublish this cuisine' : 'Publish this cuisine',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]


    return (
        <div className={classes.foodDrinkDetail}>
            {
                cuisine &&
                <>
                    <HeaderActionButtons items={headerItems} />
                    <Box mt={3} m='auto'>
                        <NotesInput
                            onSave={handleNoteSave}
                            text={cuisine.notes || ''}
                        />
                    </Box>
                    <Paper className={classes.paper}>
                        <Box display='flex' flexDirection='column' mb={2} ml={0.5}>
                            <FormControlLabel
                                onClick={e => setField('newlyAdded', !cuisine?.newlyAdded)}
                                label='Newly added'
                                control={<Checkbox color='primary' checked={!!cuisine?.newlyAdded} />}
                            />
                            <FormControlLabel
                                onClick={e => setField('recentlyUpdated', !cuisine?.recentlyUpdated)}
                                label='Recently updated'
                                control={<Checkbox color='primary' checked={!!cuisine?.recentlyUpdated} />}
                            />
                        </Box>
                        <div>
                            <Typography variant="h4" gutterBottom >{cuisine.name}</Typography>
                            {
                                cuisine.shortDescription ?
                                    <>
                                        <Divider style={{ margin: '16px 0px' }} />
                                        <Typography variant={'subtitle1'} >Short Description</Typography>
                                        <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>
                                            {(cuisine.shortDescription || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                        </TypographyWMention>
                                    </>
                                    : null
                            }
                            {cuisine.description ?
                                <>
                                    <Divider style={{ margin: '16px 0px' }} />
                                    <Typography variant={'subtitle1'} >Long Description</Typography>
                                    <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>
                                        {(cuisine.description || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                    </TypographyWMention>
                                </> : null}
                        </div>
                    </Paper>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    foodDrinkDetail: {
        position: 'relative',
        margin: '0px 40px',
    },
    paper: {
        width: dimensions.screenMaxWidth,
        margin: 'auto',
        padding: '30px',
        marginTop: 20
    },
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            margin: '0 10px'
        }
    },
}));

export default Details