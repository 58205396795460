import { Chip, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import useAsyncTask from '../../Hooks/useAsyncTask';

export interface NotesInputProps {
    text: string
    onSave: (value: string) => Promise<void> | void
}

const NotesInput: FC<NotesInputProps> = ({ text, onSave }) => {
    const [value, setValue] = useState<string>('');
    useEffect(() => {
        setValue(text);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])
    const classes = useStyles();
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    }
    const handleSave = useAsyncTask(async () => {
        await onSave(value)
    })
    return (
        <Paper className={classes.paperNote}>
            <textarea
                placeholder={"Add a note"}
                value={value}
                onChange={handleChange}
                className={classes.noteField}

            />
            {(value !== text) && <Chip className={classes.addNoteButton} disabled={handleSave.status === 'PROCESSING'} color={'primary'} label={"SAVE"} onClick={handleSave.run} />}
        </Paper>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paperNote: {
            position: 'relative',
            padding: 0,
        },
        noteField: {
            width: '100%',
            background: 'transparent',
            minHeight: '100px',

            fontFamily: 'lato',
            fontSize: 14,
            border: '0px',
            outline: 'none',
            fontWeight: 'bold',
            maxWidth: '100%',
            padding: 20
        },
        addNoteButton: {
            position: 'absolute',
            right: '16px',
            bottom: '5px',
        },
    }))
})

export default NotesInput