import { Box, Button, Checkbox, Chip, createStyles, FormControl, FormControlLabel, Input, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import NewEntitySuggest from '../../../Components/NewEntitySuggest';
import { OTagCategories } from '../../../Models/Tags';
import { TTag, TTagCategoriesState } from '../../../Models/Tags/@types';
import { addTags, patchTag } from '../../../Models/Tags/actions';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';

export interface TagFormProps {
    getData: () => Promise<void>;
}

const TagForm: FC<TagFormProps> = (props) => {
    const classes = useStyles();
    const {
        editedtag,
        showtagForm = false,
        category,
    } = useSelector<TReduxStore, Pick<TTagCategoriesState, 'editedtag' | 'showtagForm' | 'category'>>(({ TagCategories }) => ({
        editedtag: TagCategories.editedtag,
        showtagForm: TagCategories.showtagForm,
        category: TagCategories.category,
    }));
    const dispatch = useDispatch<TDispatch>();
    const [showInput, setShowInput] = useState<boolean>(false);
    const [applyOnFilters, setApplyOnFilters] = useState<boolean>(false);
    const [showToUsers, setShowToUsers] = useState<boolean>(false);
    const [tagName, setTagName] = useState<string>('');
    const [tagList, setTagList] = useState<{ name: string; id?: string }[]>([]);
    const toggleApplyOnFilter = () => {
        setApplyOnFilters(!applyOnFilters);
    };
    const toggleShowToUsers = () => {
        setShowToUsers(!showToUsers);
    };
    useEffect(() => {
        if (editedtag) {
            setTagName(editedtag.name);
            setApplyOnFilters(editedtag.applyOnFilters);
            setShowToUsers(editedtag.showToUsers);
        }
    }, [editedtag]);
    const TAG_OPTIONS = [
        // { label: 'Show to users on search', onClick: () => toggleShowToUsers(), checked: showToUsers },
        { label: 'Appears on filters', onClick: () => toggleApplyOnFilter(), checked: applyOnFilters },
        { label: 'Show to users while suggesting', onClick: () => toggleShowToUsers(), checked: showToUsers },
    ];
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        setTagName(event.target.value);
    };
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            if (tagName === '') {
                event.preventDefault();
                return;
            } else {
                const tempList = [...tagList];
                tempList.push({ name: tagName });
                setTagList([...tempList]);
                event.preventDefault();
            }
        } else {
            return;
        }
    };
    const onSelection = (item: TTag) => {
        const tempList = [...tagList];
        tempList.push(item);
        setTagList([...tempList]);
    };
    const handleDelete = (index: number) => {
        const tempList = [...tagList];
        tempList.splice(index, 1);
        setTagList([...tempList]);
    };
    const onSubmit = async () => {
        if (editedtag && editedtag.id) {
            try {
                await dispatch(patchTag(editedtag.id, tagName, applyOnFilters, showToUsers));
                props.getData();
                closeForm();
            } catch (err) {
                console.log(err);
            }
        } else {
            if (category?.id) {
                let newList = tagList.filter((i) => !i.id);
                //filtering out those existing tags which are not already not included in this categories.
                let oldList = tagList.filter((i) => i.id).filter((i) => !category.tagIds.includes(i.id || ''));
                try {
                    await dispatch(
                        addTags(
                            category.id,
                            [...newList, ...oldList].map((i) => {
                                return { ...i, applyOnFilters: applyOnFilters, showToUsers: showToUsers };
                            })
                        )
                    );
                    props.getData();
                    closeForm();
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };
    const closeForm = () => {
        dispatch({ type: OTagCategories.Actions.HIDE_TAG_FORM });
    };
    const searchProps = {
        type: 'Tag',
        filters: { fields: ['id', 'name'] },
    };
    return (
        <FormDialog onSubmit={onSubmit} onClose={closeForm} heading={'Add / Edit Tag'} open={showtagForm}>
            {editedtag && editedtag.id ? (
                <Paper className={classes.paper}>
                    <FormControl fullWidth>
                        <Input
                            placeholder="Enter Tag name"
                            value={tagName || ''}
                            onChange={handleChange}
                            disableUnderline={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') e.preventDefault();
                            }}
                        />
                    </FormControl>
                </Paper>
            ) : (
                <>
                    <Box className={classes.seachField} mb={showInput ? 3 : 0}>
                        <FormControl fullWidth>
                            <NewEntitySuggest placeholder="Search tags" onSelection={(t) => onSelection(t as TTag)} {...searchProps} />
                        </FormControl>
                    </Box>
                    {!showInput ? (
                        <div className={classes.captionDiv}>
                            <Typography variant="body2" display="block">
                                Couldn't see what you're looking for?
                            </Typography>
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => {
                                    setShowInput(true);
                                }}
                            >
                                CREATE NEW
                            </Button>
                        </div>
                    ) : (
                        <Paper className={classes.paper}>
                            <FormControl fullWidth>
                                <Input
                                    placeholder="Enter Tag name"
                                    value={tagName || ''}
                                    onChange={handleChange}
                                    disableUnderline={true}
                                    onKeyPress={handleKeyPress}
                                />
                            </FormControl>
                        </Paper>
                    )}
                    <Paper className={classes.paper}>
                        <div style={{ width: '500px', height: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                            {tagList.map((item, index) => {
                                return (
                                    <Chip
                                        style={{ margin: 5 }}
                                        label={item.name || ''}
                                        key={index}
                                        color="default"
                                        onDelete={(e) => {
                                            handleDelete(index);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </Paper>
                </>
            )}
            {
                <Paper className={classes.paper}>
                    <Box width="400px">
                        {TAG_OPTIONS.map((option) => (
                            <Box mb="12px" key={option.label}>
                                <FormControlLabel
                                    className={classes.formLabel}
                                    control={<Checkbox checked={option.checked} onChange={option.onClick} color="primary" />}
                                    label={<Typography variant="caption">{option.label}</Typography>}
                                />
                            </Box>
                        ))}
                    </Box>
                </Paper>
            }
        </FormDialog>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            marginBottom: '20px',
            width: '500px',
            minHeight: '70px',
            height: 'auto',
            display: 'flex',
            padding: '5px 10px',
        },
        seachField: {
            // marginBottom: '20px',
            width: '500px',
            height: 'auto',
            display: 'flex',
        },
        captionDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
        },
    });
});

export default TagForm;
