import { ThunkDispatch } from "redux-thunk";
import { TReduxStore } from "../../RootReducer";
import { TAction } from "loop-front";


export type TDispatch = ThunkDispatch<TReduxStore, {}, TAction>

export type TDate = string
export type TPicture = {
    id: string
    created: string
    height: number
    width: number
    imagePath: string
    thumbnail: string
    url: string
    caption?: string | null
    isDetail?: boolean
    isAnimated?: boolean
    isPortrait: boolean
    name?: string
    isMarketingImage?: boolean
    desktop?:boolean
    mobile?:boolean
    imageWidth?: string
    imageWidthSm?: string
    imageHeight?: string
    imageHeightSm?: string
    startX?: string
    startXSm?: string
    startY?: string
    startYSm?: string
    endX?: string
    endXSm?: string
    endY?: string
    endYSm?: string
    duration?: string
    durationSm?: string
    fileType?:string
    filePath: string
}
export const animationDimention = {
    imageWidth: '',
    imageWidthSm: '',
    imageHeight: '',
    imageHeightSm: '',
    startX: '',
    startXSm: '',
    startY: '',
    startYSm: '',
    endX: '',
    endXSm: '',
    endY: '',
    endYSm: '',
    duration: '',
    durationSm: '',
}