import { BoxProps, Chip, ChipProps, TextField, TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { TConstant } from '../../Models/App/@types';

export interface KeywordInputProps {
    initialKeywords?: Array<TConstant>;
    onKeywordsUpdate: (keywords: Array<TConstant>) => void;
    textFieldProps?: TextFieldProps;
    chipProps?: ChipProps;
    removable?: boolean;
    editable?: boolean;
    containerClass?: BoxProps['className'];
}
export const stringToSnackCase = (value: string) => {
    return value
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_');
};
const KeywordInput: FC<KeywordInputProps> = (props) => {
    const { initialKeywords = [], containerClass } = props;

    const [keywords, setKeywords] = useState<TConstant[]>(initialKeywords);
    const [keywordField, setKeywordField] = useState('');

    const addKeyword = (value: string) => {
        setKeywordField('');
        let updatedKeywords = keywords;
        const newValue = stringToSnackCase(value);
        if (updatedKeywords.findIndex((i) => i.value === newValue) === -1 && value) {
            updatedKeywords.unshift({ name: value, value: newValue });
            setKeywords(updatedKeywords);
            props.onKeywordsUpdate(updatedKeywords);
        }
    };

    const handleKeyPressed: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key !== 'Enter') return;
        e.preventDefault();
        addKeyword((e.target as HTMLInputElement)['value']);
    };

    const removeKeyword = (index: number) => {
        let updatedKeywords = [...(keywords || [])];
        updatedKeywords.splice(index, 1);
        setKeywords(updatedKeywords);
        props.onKeywordsUpdate(updatedKeywords);
    };

    const onClick = (i: number) => {
        if (!keywordField) {
            setKeywordField(keywords[i].name);
            removeKeyword(i);
        }
    };

    useEffect(() => {
        let keywords = keywordField.split(',');
        if (keywords.length > 1) {
            keywords.forEach((item, index) => {
                if (index + 1 === keywords.length) {
                    setKeywordField(item);
                    return;
                }
                addKeyword(item);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keywordField]);

    const classes = useStyles();

    return (
        <div className={clsx(classes.keywordsPaper, containerClass)}>
            <TextField
                label="Keywords"
                fullWidth
                name="keywordField"
                value={keywordField || ''}
                onKeyPress={handleKeyPressed}
                onChange={(e) => setKeywordField(e.target.value)}
                {...(props.textFieldProps || {})}
            />
            <div>
                {keywords.map((item, i) => (
                    <Chip
                        variant={'outlined'}
                        key={item.value}
                        label={item.name}
                        onDelete={(e) => removeKeyword(i)}
                        clickable
                        onClick={() => onClick(i)}
                        {...(props.chipProps || {})}
                    />
                ))}
            </div>
        </div>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    const {
        palette: { text },
    } = theme;

    return createStyles({
        keywordsPaper: {
            padding: 12,
            paddingTop: 0,
            '& > div:nth-child(2)': {
                marginTop: 12,
                ' & > div': {
                    marginRight: 5,
                    marginBottom: 5,
                    color: text['primary'],
                },
            },
        },
    });
});

export default KeywordInput;
