import LoopFront from "loop-front";



const CustomActions = {
    SHOW_COUPON_FORM: 'SHOW_COUPON_FORM',
    HIDE_COUPON_FORM: 'HIDE_COUPON_FORM',
}

const CustomEntities = {
    CLAIM: 'claims'
}
const CustomActivities = {
    COUNT: 'count',
    PUBLISH: 'publish',

}


class Coupons extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
    constructor() {
        super('coupons', CustomActions, CustomEntities, CustomActivities);
    }

}

export const OCoupons = new Coupons();


