import React, { FC, memo } from 'react';
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { TPosition } from '../Models/Location/@types';



interface MapsViewProps {
    style?: object
    points: TPosition | TPosition[]
    zoom?: 1 | 5 | 10 | 15 | 20
}


const MapView: FC<MapsViewProps> = memo((props) => {

    const { style, points, zoom = 15 } = props;

    let bounds = new google.maps.LatLngBounds();
    let initialCenter: TPosition
    if (Array.isArray(points)) {
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }
        initialCenter = { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() }
    }
    else {
        initialCenter = points;
    }

    return (
        <div className="location-map" style={style} >
            <GoogleMap
                defaultZoom={zoom}
                center={initialCenter}
                options={{
                    gestureHandling: 'cooperative'
                }}
            >
                {
                    Array.isArray(points) ?
                        points.map((point, index) => {
                            return (
                                <Marker
                                    key={index}
                                    position={point}
                                    clickable
                                    visible
                                    animation={google.maps.Animation.DROP}
                                />
                            )
                        }) :
                        <Marker
                            position={points}
                            clickable
                            visible
                            animation={google.maps.Animation.DROP}
                        />

                }
            </GoogleMap>
        </div>
    )
}
)

export default withGoogleMap(MapView);