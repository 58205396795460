import { Box, createStyles, Divider, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import ListDashboard from '../../Components/Dashboard/ListDashboard';
import CityChipList from '../../Components/Filters/CityChipList';
import EatineraryFilterMenu, { TEatineraryFilterConfig } from '../../Components/Filters/EatineraryFilterMenu';
import LockIcon from '../../Components/LockIcon';
import { DEFAULT_EATINERARY_FILTER_OPTIONS } from '../../Constants/FilterConstants';
import UsersListSortMenu from '../../Features/Users/UsersListSortMenu';
import { TFilterConfig } from '../../Models/App/@types';
import { OCity } from '../../Models/City';
import { TCity } from '../../Models/City/@types';
import { OEatinerary } from '../../Models/Eatinerraies';
import { TEatinerraies } from '../../Models/Eatinerraies/@types';
import { OExplore } from '../../Models/Explore';
import { TExplore } from '../../Models/Explore/@types';
import ExploreModel from '../../Models/Explore/actions';
import { TDispatch } from '../../Resources/GlobalTypes';
import utilities from '../../Resources/Utils';
import { TReduxStore } from '../../RootReducer';
import EatineraryForm from './Forms';
import Details from './Tabs/Details';
import Images from './Tabs/Images';

interface IProps { }
const BASE_URL = '/dashboard/eatineraries';
const initialFilter: TFilterConfig[] = [
    { name: 'All', isActive: true },
    { name: 'Published', isActive: false },
    { name: 'Unpublished', isActive: false },
    { name: 'Newly Added', isActive: false },
    { name: 'Recently Updated', isActive: false },
];

interface SelecterProps {
    totalCount: number;
    list: TEatinerraies[];
    showMainForm: boolean | undefined;
    cityList: TCity[];
    // details?: TExplore | undefined;
    // destinations: TCity[];
}

const Eatineraries: React.FC<IProps> = (props) => {
    const url = window.location.href;
    const urlArray: string[] = url.split('/');
    const eatineraryId = urlArray.length >= 6 ? urlArray[5] : '';
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<TEatinerraies[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedList, setSelectedList] = useState<string[]>([]);
    // const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>(JSON.parse(JSON.stringify(initialFilter)));
    const classes = useStyles(props);
    const [sortMenuEl, setSortMenuEl] = useState<HTMLElement>();
    const [sortFilter, setSortFilter] = useState<any>({});
    const dispatch = useDispatch<TDispatch>();

    const {
        list = [],
        totalCount = 0,
        showMainForm = false,
        cityList = []
    } = useSelector<TReduxStore, SelecterProps>(({ Eatinerary: { list, totalCount, showMainForm }, App: { cityList } }) => ({
        list,
        totalCount,
        showMainForm,
        cityList
    }));
    const defaultCityList = cityList.map(c => ({ name: c.name, value: c.id, isActive: false }))
    let filterInitialState: TEatineraryFilterConfig = { ...DEFAULT_EATINERARY_FILTER_OPTIONS, cities: defaultCityList };
    const [filterConfig, setFilterConfig] = useState<TEatineraryFilterConfig>(filterInitialState);

    useEffect(() => {
        getData(filterConfig, sortFilter);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);


    useEffect(() => {
        // dispatch(
        //     OCity.getItemsList({
        //         filter: {
        //             order: 'name ASC',
        //         },
        //     })
        // ); 
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (eatineraryId) {
            getDetails(eatineraryId);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eatineraryId]);
    const toggleList = (id: string) => {
        setSelectedList((e) => utilities.toggleItemFromList(e, id, '', (a, b) => a === b));
    };
    const getDetails = async (id: string) => {
        await dispatch(
            // OEatinerary.getItem(id)
            OEatinerary.getItem(id, { filter: { include: ['destination'] } })
        );
    };
    const handleFilterChange = (item: { name: string; isActive: boolean }) => {
        // const updatedFilter = filterConfig.map((i) => {
        //     if (i.name === item.name) {
        //         i.isActive = true;
        //     } else {
        //         i.isActive = false;
        //     }
        //     return i;
        // });
        // setFilterConfig([...updatedFilter]);
    };
    const handleFilterSubmit = () => {
        if (pageNumber === 1) getData(filterConfig, sortFilter);
        else setPageNumber(1);
    };
    const handleFilterReset = () => {
        setFilterConfig(JSON.parse(JSON.stringify(filterInitialState)));
        setSelectedList([]);
        if (pageNumber === 1) getData();
        else setPageNumber(1);
    };
    const handleSortClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSortMenuEl(e.currentTarget);
    };

    const handleSortingChange = (filter: any) => {
        // dispatch(OUser.getItemsList({ filter: { limit: 20, skip: 20 * (pageNumber - 1), ...filter } }));
        setSortFilter(filter);
        if (pageNumber === 1) getData(filterConfig, filter);
        else setPageNumber(1);
    };
    const onAddButtonClick = () => {
        dispatch({ type: OEatinerary.Actions.SHOW_EATINERARY_FORM });
    };
    const handleSearchResult = (result: Array<TEatinerraies>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    };
    const getData = async (filterConfig: TEatineraryFilterConfig = JSON.parse(JSON.stringify(initialFilter)), sortFilter: any = {}) => {
        setLoading(true);
        try {
            await dispatch(OEatinerary.getEatineraries(pageNumber, filterConfig, selectedList, sortFilter));
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };
    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string; name: string; component: RouteProps['component'] }> = [
        {
            url: 'details',
            name: 'Details',
            component: Details,
        },
        {
            url: 'images',
            name: `Images`,
            component: Images,
        },
    ];
    const renderListItem = useCallback(
        (item: TEatinerraies): ReactElement => {
            const isActive = (match: any, location: any) => {
                const regex = new RegExp(`${BASE_URL}/${item.id}/`, 'ig');
                return regex.test(location.pathname);
            };
            return (
                <span key={item.id}>
                    <NavLink className={classes.listItem} to={`${BASE_URL}/${item.id}/details`} isActive={isActive} activeClassName={'selected'}>
                        <ListItem button>
                            <ListItemText primary={`${item.name || ''}`} secondary={item.destination?.name || ''} />
                            <ListItemSecondaryAction>{!item.isPublished ? <LockIcon size="medium" /> : null}</ListItemSecondaryAction>
                        </ListItem>
                        <Divider className={classes.divider} />
                    </NavLink>
                </span>
            );
        },
        [classes]
    );
    return (
        <>
            <ListDashboard
                baseUrl={BASE_URL}
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                itemsCountPerPage={20}
                onPageChange={setPageNumber}
                onItemAddRequest={onAddButtonClick}
                filterConfig={[]}
                filterComponent={
                    <EatineraryFilterMenu
                        filterConfig={filterConfig}
                        handleFilterChange={setFilterConfig}
                    />
                }
                onFilterChange={handleFilterChange}
                onFilterSubmit={handleFilterSubmit}
                onFilterReset={handleFilterReset}
                currentPageNumber={pageNumber}
                listProps={{
                    searchProps: {
                        type: 'Eateries',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}

            />
            {showMainForm ? <EatineraryForm /> : null}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            backgroundColor: theme.palette.grey['500'],
        },
        listItem: {
            color: theme.palette.text.primary,
            '&.selected': {
                background: fade(theme.palette.primary.main, 0.4),
                display: 'block',
            },
        },
        subDashboardContainer: {
            marginLeft: 250,
        },
        headLinks: {
            marginBottom: 50,
        },
    })
);

export default Eatineraries;