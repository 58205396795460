import { Box, createStyles, Fab, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { TPlace, TSocialUrl } from '../../../../Models/Place/@types';
import { UPDATE } from './Reducer';

interface IProps {
    dispatchReducer: React.Dispatch<{
        type: string,
        name: string;
        data: any;
    }>
    state: Partial<TPlace>
}
type ChangeEvent = React.ChangeEvent<HTMLInputElement>
// const urlRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)
const SocialMediaUrl: React.FC<IProps> = (props) => {
    const [values, setValues] = useState<TSocialUrl[]>([]);
    const [igLink, setIgLink] = useState<string>('');
    const { state, dispatchReducer } = props;
    useEffect(() => {
        if (state.socialUrls)
            setValues(state.socialUrls);
        if (state.instagramLink)
            setIgLink(state.instagramLink)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.id])
    const classes = useStyles(props);
    const handleChange = (name: 'url' | 'name', value: string, index: number) => {
        let newValues = [...values];
        newValues[index] = { ...newValues[index], [name]: value };
        setValues(v => [...newValues]);
    }
    const handleSocialUrlBlur = (index: number) => {
        dispatchReducer({ type: UPDATE, name: 'socialUrls', data: [...values] })
    }
    const onAdd = () => {
        let newValues = [...values];
        newValues.push({ name: '', url: '' })
        setValues(v => [...newValues]);
        dispatchReducer({ type: UPDATE, name: 'socialUrls', data: [...newValues] })
    }
    const onRemove = (index: number) => {
        let newValues = [...values];
        newValues.splice(index, 1)
        setValues(v => [...newValues]);
        dispatchReducer({ type: UPDATE, name: 'socialUrls', data: [...newValues] })
    }
    const handleInstaChange = (e: ChangeEvent) => {
        setIgLink((e.target.value as string) || '');
        // dispatchReducer({ type: UPDATE, name: 'instagramLink', data: (e.target.value as string) || '' })
    }
    const handleBlur = () => {
        dispatchReducer({ type: UPDATE, name: 'instagramLink', data: igLink })
    }
    return (
        <Box width={800} mt={5} >
            <Paper className={classes.root} key='paper'>
                <Typography variant='h6'>Social media urls:</Typography>

                {
                    map(values, (item, index) => {
                        return (
                            <Box key={`social_${index}`} display='flex' alignItems='center' marginBottom='20px' >
                                <TextValidator
                                    className={classes.textField}
                                    name='platformName'
                                    autoComplete='none'
                                    label='Platform Name'
                                    value={item.name}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={(e: ChangeEvent) => { e.preventDefault(); handleChange('name', e.target.value, index) }}
                                    onBlur={() => handleSocialUrlBlur(index)}
                                />
                                <TextValidator
                                    className={classes.textFieldUrl}
                                    name='url'
                                    autoComplete='none'
                                    label='Url'
                                    value={item.url}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={(e: ChangeEvent) => { e.preventDefault(); handleChange('url', e.target.value, index) }}
                                    onBlur={() => handleSocialUrlBlur(index)}
                                />
                                <Fab className={classes.gab} color='primary' size='small' onClick={() => onRemove(index)} >
                                    <i style={{ fontSize: 20 }} className="material-icons" >clear</i>
                                </Fab>
                            </Box>
                        )
                    })
                }
                <Fab className={classes.gab} color="primary" size='small' onClick={onAdd}>
                    <i style={{ fontSize: 20 }} className="material-icons">add</i>
                </Fab>
            </Paper>

        </Box>

    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            padding: '40px 32px 40px',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column'
        },
        textField: {
            width: '130px',
            marginRight: '20px'
        },
        textFieldUrl: {
            width: 230,
            marginRight: '20px'
        },
        fab: {
            height: 30,
            width: 36
        }
    }))
})

export default SocialMediaUrl