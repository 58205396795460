import { Reducer } from "redux";
import { TExploreState } from './@types';
import { TAction } from 'loop-front';
import { OExplore } from '.';
import utilities from "../../Resources/Utils";


const initialState: TExploreState = {
    list: [],
    totalCount: 0
}


export const RExplore: Reducer<TExploreState, TAction> = (state = initialState, action): TExploreState => {
    const {
        LIST_RECEIVED,
        ACTIVITY_RECEIVED,
        POST_ITEM_SUCCESS,
        ITEM_ACTIVITY_POST_SUCCESS,
        SINGLE_ITEM_RECEIVED,
        ITEM_DELETED,
        ITEM_PATCH_SUCCESS,
        SHOW_EXPLORE_FORM,
        HIDE_EXPLORE_FORM
    } = OExplore.Actions;

    switch (action.type) {
        case LIST_RECEIVED: return { ...state, list: action.data }
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OExplore.Activities.COUNT: return { ...state, totalCount: action.data.count }
                default: return state;
            }
        }
        case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), details: { ...state.details, ...action.additionalDispatchData } }
        case ITEM_DELETED: return { ...state, list: utilities.updateItemList(state.list || [], action.additionalDispatchData, 'DELETE') }
        case SINGLE_ITEM_RECEIVED: return { ...state, details: action.data }
        case ITEM_ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case OExplore.Activities.PUBLISH: return { ...state, details: { ...state.details, ...action.data }, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE') }
                default: return state
            }
        }
        case SHOW_EXPLORE_FORM: return { ...state, showMainForm: true, editedExplore: action.data || undefined }
        case HIDE_EXPLORE_FORM: return { ...state, showMainForm: false }
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'ADD') };

        default: return state;
    }
}