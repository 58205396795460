import React, { FC , useState} from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import dimensions from '../../../Constants/dimensions';
import FileInput from '../../../Components/FileInput';
import { getTransformedUrl, uploadPicture } from '../../../Resources/PictureUtils';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../RootReducer';
import { TSettingsState } from '../../../Models/Settings/@types';
import { SETTINGS_AUTH_SCREEN } from '../../../Constants/settings';
import clsx from 'clsx';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { OSettings } from '../../../Models/Settings';
import Loader from '../../../Components/Loader';

export interface AuthScreenProps {

}

const AuthScreen: FC<AuthScreenProps> = (props) => {
    const classes = useStyles({});
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    const { list } = useSelector<TReduxStore, Pick<TSettingsState, 'list'>>(({ Settings: { list } }) => ({ list }))
    const authScreenSetting = list.find(s => s.key === SETTINGS_AUTH_SCREEN.id)
    
    const uploadFiles = (key: keyof typeof SETTINGS_AUTH_SCREEN) => async (files: any[]) => {
        if(!authScreenSetting) return;
        setUploading(true);
        if (files.length === 0) return;
        try {
            const { data } = await uploadPicture(files[0], 'reviews');
            await dispatch(OSettings.patchItem(authScreenSetting.id, {value: {...authScreenSetting.value, [key]: data}}))
        } catch (error) {
            // TODO: handle error
        }
        setUploading(false);
    }

    const removeImage = (key: keyof typeof SETTINGS_AUTH_SCREEN) => async () => {
        if(!authScreenSetting) return;
        await dispatch(OSettings.patchItem(authScreenSetting.id, {value: {...authScreenSetting.value, [key]: null}}))
    }

    if(!authScreenSetting) return null;

    return (
        <Box pb={10} width={dimensions.formPaperMaxWidth} mx="auto">
            {uploading && <Loader overlayed />}
            <div className={classes.imageInputContainer}>
                    <div className={clsx(classes.imageContainer, classes.mobile)}>
                        <Tooltip title="Click to add new mobile image">
                            <Box position='relative'>
                                <img src={getTransformedUrl(authScreenSetting.value[SETTINGS_AUTH_SCREEN.smPicture], 600)} />
                                {!authScreenSetting.value[SETTINGS_AUTH_SCREEN.smPicture] && <Typography className={classes.placeholderText} variant='body2'>Click to add new mobile image</Typography>}
                                <Typography className={classes.pd} variant='caption'>Mobile Image</Typography>
                                <FileInput
                                    accept={"image/*"}
                                    multiple={false}
                                    onDone={uploadFiles('smPicture')}
                                />
                            </Box>
                        </Tooltip>
                        <Tooltip title='Remove Image'>
                            <IconButton className={classes.imageDeleteButton} onClick={removeImage('smPicture')}>
                                <i className='material-icons'>delete</i>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={clsx(classes.imageContainer, classes.desktop)}>
                        <Tooltip title="Click to add new desktop image">
                            <Box position='relative'>
                                <img src={getTransformedUrl(authScreenSetting.value[SETTINGS_AUTH_SCREEN.lgPicture], 600)} />
                                {!authScreenSetting.value[SETTINGS_AUTH_SCREEN.lgPicture] && <Typography className={classes.placeholderText} variant='body2'>Click to add new desktop image</Typography>}
                                <Typography className={classes.pd} variant='caption'>Desktop Image</Typography>
                                <FileInput
                                    accept={"image/*"}
                                    multiple={false}
                                    onDone={uploadFiles('lgPicture')}
                                />
                            </Box>
                        </Tooltip>
                        <Tooltip title='Remove Image'>
                            <IconButton className={classes.imageDeleteButton} onClick={removeImage('lgPicture')}>
                                <i className='material-icons'>delete</i>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    imageInputContainer: {
        marginTop: 16
    },
    imageContainer: {
        width: '100%',
        backgroundColor: '#ddd',
        position: 'relative',
        margin: theme.spacing(8, 0)
    },
    mobile: {
        aspectRatio: '2/1',
        '& img': {
            width: '100%',
            aspectRatio: '2/1',
        }
    },
    desktop: {
        aspectRatio: '1/2',
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        '& img': {
            width: '100%',
            aspectRatio: '1/2',
        }
    },
    placeholderText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
    pd: {
        padding: 8
    },
}));

export default AuthScreen;