import React, { FC, useState } from 'react'
import { createStyles, Theme, Box, Avatar, Select, MenuItem, Button, Typography, TextField, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { TPicture } from '../Resources/GlobalTypes';
import { pictureOrientations } from '../Models/App/@types';
import ConfirmationDialog from './Dialogs/ConfirmationDialog';

export interface ImageContainerProps {
    onOrientationChange?: (id: string, orientation: string) => void
    OrientationList?: Array<any>
    image: TPicture
    orientation?: string
    onCaptionChange?: (caption: string, id: string) => Promise<void>
    onImageDelete?: (id: string) => Promise<void>
    doReOrder?: boolean
    showCourousel?: (id: string) => void
    showAnimationForm?: (flag: boolean, id: string) => void
}

const ImageContainer: FC<ImageContainerProps> = (props) => {
    const classes = useStyles();
    const [isCaption, setIsCaption] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const toggleDialog = () => { setDeleteDialog(!deleteDialog) }
    const { image, OrientationList = [] } = props
    const [caption, setCaption] = useState(image.caption || '')

    var src = image.url;
    const onOrientationChange = (orientation: string) => {
        if (props.onOrientationChange)
            props.onOrientationChange(image.id, orientation)
    }
    const onCaptionClick = () => {
        setIsCaption(!isCaption);
    }
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCaption(event.target.value)
    }
    const handleDelete = () => {
        if (props.onImageDelete)
            props.onImageDelete(image.id);
    }
    const onSubmit = async () => {
        if (props.onCaptionChange)
            await props.onCaptionChange(image.id, caption)
        setIsCaption(!isCaption);
    }
    const showCourousel = () => {
        if (props.showCourousel)
            props.showCourousel(image.id);
    }
    const showAnimationForm = () => {
        if (props.showAnimationForm)
            props.showAnimationForm(true, image.id)
    }
    return (
        <Box className={classes.imageCard}>
            <Box padding='5px 10px' zIndex='1' width='100%' display='flex' alignContent='flex-end' justifyContent='space-between' className={classes.topDiv}>
                {props.doReOrder === true && <Icon color='action' className={classes.icon} fontSize='default' id='drag'>open_with</Icon>}
                <Icon color='action' className={classes.icon} fontSize='default' onClick={toggleDialog}>delete</Icon>

            </Box>
            {image.fileType == 'video'
                ? <iframe title="youtube_video" width="273" height="240" src={src}> </iframe>
                : <Avatar className={classes.imageItem}
                    src={src}
                    alt=''
                    onClick={showCourousel}
                />}


            <Box display='flex' alignItems='center' width='100%' margin='10px 0px' justifyContent='space-around'>
                {
                    props.onCaptionChange ?
                        isCaption ?
                            <Box display='flex' flexDirection='column' width='90%' justifyContent='space-between'>
                                <TextField value={caption} fullWidth onChange={onChange} multiline />
                                <Box display='flex' ><Button variant='text' color='inherit' onClick={onCaptionClick}><Typography variant='caption' >Close</Typography>
                                </Button>
                                    <Button variant='text' color='inherit' onClick={onSubmit}><Typography variant='caption' >Save</Typography></Button></Box>
                            </Box>
                            :
                            <Box display='flex' flexDirection='column' width='90%' justifyContent='space-between'>
                                {
                                    image.caption && <Typography variant='body2' style={{ width: '100%', height: 'auto', borderBottom: '1px solid black' }}>{image.caption || ''}</Typography>
                                }
                                <Box display='flex'>{
                                    props.onOrientationChange && <Select value={props.orientation || 'Portrait'} style={{ width: 'auto' }} onChange={(e) => { onOrientationChange(e.target.value as string) }}>
                                        {
                                            OrientationList.map((item, index) => {
                                                return <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                }
                                    <Button variant='text' color='inherit' onClick={onCaptionClick}> <Typography variant='caption' >{image.caption ? 'Edit' : 'Caption'}</Typography> </Button>

                                </Box>
                                <Box margin='10px'>
                                    {
                                        props.showAnimationForm && props.orientation === 'Animated' &&
                                        <Button variant='text' color='inherit' onClick={() => { showAnimationForm() }} ><Typography variant='caption' >Configure Animation </Typography></Button>
                                    }
                                </Box>
                            </Box>
                        : null}
            </Box>
            <ConfirmationDialog
                open={deleteDialog}
                onAgree={handleDelete}
                onDisagree={toggleDialog}
                message={'Do you really wish to delete this Image ?'}
                onClose={toggleDialog}
            />
        </Box >
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        imageCard: {
            position: 'relative',
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: 8,
            paddingBottom: '5px',
            width: '100%',
            height: 'auto',
        },
        imageItem: {
            borderRadius: 0,
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            width: '100%',
            height: 'auto',

            cursor: 'pointer'
        },
        topDiv: {
            cursor: 'pointer',
            background: 'linear-gradient(#000000, rgba(0, 0, 0, 0.1))',
            color: '#ff0',
            position: 'absolute'
        },
        icon: {
            color: 'white',
            opacity: '40%',
            '&:hover': {
                // color: 'white',
                opacity: '100%',
            }
        }
    }))
})

export default ImageContainer