import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { FC } from 'react';
import { attachField, IReactFormProps, MLFormContent } from 'react-forms';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import dimensions from '../../../Constants/dimensions';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { OApp } from '../../../Models/App';
import { TAppConstants } from '../../../Models/App/@types';
import { OCoupons } from '../../../Models/Coupons';
import { TCoupons } from '../../../Models/Coupons/@types';
import { TSubscriptionPack } from '../../../Models/Pack/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';
import DateTimeInput from './DateTimeInput';

// Yup.addMethod((args) => {
//     console.log(args);
//     return true;
// },'endDate', (args) => {
//     console.log(args);
//     return true;
// })
const validationSchema = Yup.object({
    name: Yup.string().required('This is required'),
    discountType: Yup.string().required('This is required'),
    startDate: Yup.string().required('This is required'),
    code: Yup.string().required('This is required'),
    claimLimit: Yup.number().integer('Invalid value').min(1, 'Should be 1 or more'),
    endDate: Yup.string().test({
        name: 'endDateTest',
        message: 'End date cannot be less than start date',
        test: function (value) {
            let startDate = this.parent.startDate as unknown as string;
            if (!startDate)
                return true as boolean;
            return moment(value).isAfter(startDate);
        }
    }
    ),
    discountValue: Yup.number().required('This is required').positive('Should be greater than 0').when(
        'discountType',
        {
            is: (discountType: any) => (discountType || '').length > 0 && discountType === 'percent',
            then: Yup.number().max(100, 'Should be less than equal to 100')
        }).when(
            'discountType',
            {
                is: (discountType: any) => (discountType || '').length > 0 && discountType === 'flat',
                then: Yup.number().integer('Should be an integer')
            })
})
export interface CouponFormProps { }
interface IProps {
    showMainForm?: boolean,
    editedCoupon?: TCoupons,
    appConstants?: TAppConstants
    packs: TSubscriptionPack[]
}
//@ts-ignore
attachField('date-time', <DateTimeInput />)
const CouponForm: FC<CouponFormProps> = (props) => {
    const classes = useStyles();
    const { showMainForm = false, editedCoupon, appConstants, packs } = useSelector<TReduxStore, IProps>(({ Coupons, App, Packs: { packs } }) => ({ showMainForm: Coupons.showMainForm, editedCoupon: Coupons.editedCoupon, appConstants: App.constants, packs }))
    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();
    const onClose = () => {
        dispatch({ type: OCoupons.Actions.HIDE_COUPON_FORM })
    }

    const defaultPack = packs.find(p => p.roleName === 'WORLD');

    const onSubmit = useAsyncTask(async (values: Partial<TCoupons>) => {
        const packBenefits: TCoupons['packBenefits'] = (values.packBenefits && defaultPack) ? { ...values.packBenefits, durationType: 'month', packId: defaultPack.id } : undefined;
        try {
            if (values.id) {
                await dispatch(OCoupons.patchItem(values.id, { ...values, packBenefits, claimLimit: values.claimLimit || 0 }, {}, undefined, { ...values, packBenefits, claimLimit: values.claimLimit || 0 }))
                dispatch(OApp.showToast({ message: 'Coupon edited successfully', variant: 'success' }))
            } else {
                let res: { data: TCoupons } = await OCoupons.requestPostItem({ ...values, packBenefits, claimLimit: values.claimLimit || 0 })
                dispatch({ type: OCoupons.Actions.POST_ITEM_SUCCESS, data: res.data })
                history.push('/dashboard/coupons/' + res.data.id + '/details')
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'Coupon' } })
                dispatch(OApp.showToast({ message: 'Coupon Added successfully', variant: 'success' }))

            }
            onClose();
        } catch (err) {
            dispatch(OApp.showToast({ message: 'Coupon add / edit failed', variant: 'error' }))

        }

    })
    const formConfig: IReactFormProps['config'] = [
        {
            valueKey: 'name',
            type: 'text',
            fieldProps: {
                fullWidth: true,
                label: 'Name of Coupon',
                autoComplete: 'new-password',
                InputProps: {
                    autoComplete: 'new-password',

                },
                inputProps: {
                    autoComplete: 'new-password',
                    form: {
                        autocomplete: 'off',
                    },
                }
            }
        },
        {
            valueKey: 'code',
            type: 'text',
            fieldProps: {
                fullWidth: true,
                label: 'Coupon code',
                autoComplete: 'new-password',
                inputProps: {
                    autoComplete: 'new-password',
                    form: {
                        autocomplete: 'off',
                    },
                }

            }
        },
        [
            {
                valueKey: 'discountType',
                type: 'select',
                fieldProps: {
                    options: appConstants?.Coupon.types,
                    formControlProps: {
                        fullWidth: true,
                    },
                    fullWidth: true,
                    label: 'Type'
                }
            },
            {
                valueKey: 'discountValue',
                type: 'text',
                fieldProps: {
                    fullWidth: true,
                    label: 'Input percentage',
                    type: 'number'
                },
                condition: {
                    values: [{
                        key: 'discountType',
                        compareValue: 'flat',
                        operator: '==='
                    }],
                    postEffectProps: {
                        label: 'Input $ amount'

                    }
                }
            }
        ],
        [
            {
                valueKey: 'startDate',
                type: 'date-time',
                fieldProps: {
                    type: 'Start',
                    label: 'Start (MM/DD/YYYY)',
                }
            },
            {
                valueKey: 'endDate',
                type: 'date-time',
                fieldProps: {
                    type: 'End',
                    label: 'End (MM/DD/YYYY)',
                }
            }
        ],
        {
            valueKey: 'claimLimit',
            type: 'text',
            fieldProps: {
                type: 'number',
                label: 'Claim limit',
                fullWidth: true,
            }
        }
    ]


    const getAdditionalConfig = (formikProps: FormikProps<Partial<TCoupons>>): IReactFormProps['config'] => [
        [
            {
                valueKey: 'packBenefits.durationValue',
                type: 'text',
                fieldProps: {
                    type: 'number',
                    fullWidth: true,
                    label: 'Duration (months)',
                    helperText: 'Total duration after applying the coupon will be: ' + getPackDurationWithBenefits(defaultPack, formikProps.values?.packBenefits?.durationValue) + ' month(s)'
                }
            },
        ]
    ]
    return (
        <Formik
            initialValues={editedCoupon || {}}
            onSubmit={onSubmit.run}
            validationSchema={validationSchema}
        >
            {
                formikProps =>
                    <FormDialog
                        heading={editedCoupon?.id ? `Edit ${editedCoupon.name}` : 'Add Coupon'}
                        onSubmit={() => { formikProps.handleSubmit() }}
                        open={showMainForm}
                        loading={onSubmit.status === 'PROCESSING'}
                        onClose={onClose}
                    >
                        <Paper className={classes.paper}>
                            <MLFormContent
                                schema={formConfig}
                                settings={{
                                    verticalSpacing: 32,
                                    horizontalSpacing: 24
                                }}
                                formId='coupon-forms'
                                formikProps={formikProps}
                                actionConfig={{
                                    displayActions: false
                                }}
                            />
                        </Paper>
                        {defaultPack ? (
                            <Paper className={classes.paper}>
                                <Typography gutterBottom>Additional Benefits</Typography>
                                <MLFormContent
                                    schema={getAdditionalConfig(formikProps)}
                                    settings={{
                                        verticalSpacing: 32,
                                        horizontalSpacing: 24
                                    }}
                                    formId='coupon-forms'
                                    formikProps={formikProps}
                                    actionConfig={{
                                        displayActions: false
                                    }}
                                />
                            </Paper>
                        ) : null}
                    </FormDialog>
            }
        </Formik>
    )
}


function getPackDurationWithBenefits(pack?: TSubscriptionPack, durationInMonth: number = 0) {
    if (pack)
        return pack.durationValue + durationInMonth
    return durationInMonth
}


const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            marginTop: '32px',
            width: dimensions.formPaperMaxWidth,
            padding: theme.spacing(3),
            height: 'auto'
        },
    }))
})

export default CouponForm