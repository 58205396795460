import LoopFront from "loop-front";
import { TDispatch } from "../../Resources/GlobalTypes";
import _ from 'lodash';


const CustomActivity = {

}

const CustomEntities = {
    sectionEntity: 'entity-sections'
}

const CustomActions = {
    HOME_SECTION_ENTITIES_UPDATED: 'HOME_SECTION_ENTITIES_UPDATED',
    HOME_SECTION_SET_LOADING: 'HOME_SECTION_SET_LOADING'
}




class HomeSection extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('homeSections', CustomActions, CustomEntities, CustomActivity);
    }

    setLoading(loading: boolean) {
        return (dispatch: TDispatch) => {
            dispatch({ type: this.Actions.HOME_SECTION_SET_LOADING, data: loading })
        }
    }


    reorderItem(homeSectionId: string, sectionEntityId: string, order: number) {
        return async (dispatch: TDispatch) => {
            dispatch(this.setLoading(true));
            await LoopFront.request({
                url: this.ModelName + `/${homeSectionId}/sectionEntity/${sectionEntityId}/change-order`,
                method: 'PATCH',
                data: {
                    order
                }
            })
            dispatch(this.setLoading(false));
        }
    }

    updateSections(homeSectionId: string, old: any[], updated: any[]) {
        return async (dispatch: TDispatch) => {
            const toRemove: any[] = _.filter(_.difference(old, updated, 'id'), d => !!d);
            const toAdd: any[] = updated.filter(u => !u.sectionId);
            console.log("to add", updated, toAdd);
            dispatch(this.addRemove(homeSectionId, toAdd.map(d => ({ name: d.name, subjectId: d.id, subjectType: d.doc_type, destinationId: d.destination?.id })), toRemove.map(d => d.sectionId)))
            // const { data } = await LoopFront.request({
            //     url: this.ModelName + `/${homeSectionId}/entity-sections/add-remove-bulk`,
            //     method: 'POST',
            //     data: {
            //         toRemove: toRemove.map(d => d.sectionId),
            //         toAdd: toAdd.map(d => ({ name: d.name, subjectId: d.id, subjectType: d.doc_type }))
            //     }
            // });
            // dispatch({ type: this.Actions.HOME_SECTION_ENTITIES_UPDATED, data, additionalDispatchData: { homeSectionId } })
        }
    }

    addRemove(homeSectionId: string, toAdd: any[], toRemove: string[]) {
        return async (dispatch: TDispatch) => {
            const { data } = await LoopFront.request({
                url: this.ModelName + `/${homeSectionId}/entity-sections/add-remove-bulk`,
                method: 'POST',
                data: {
                    toRemove: toRemove,
                    toAdd: toAdd
                }
            });
            dispatch({ type: this.Actions.HOME_SECTION_ENTITIES_UPDATED, data, additionalDispatchData: { homeSectionId } })
        }
    }

}

export const OHomeSection = new HomeSection();