import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TAction } from 'loop-front';
import React, { FC } from 'react';
import { IReactFormProps, ReactForm } from 'react-forms';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { OApp } from '../../Models/App';
import { TUser } from '../../Models/User/@types';
import { TReduxStore } from '../../RootReducer';
import { userLogin } from './redux-config';
interface IStateProps {
    appUser?: TUser
}

const Landingpage: FC = () => {




    const dispatch = useDispatch<ThunkDispatch<TReduxStore, {}, TAction>>();

    const { appUser } = useSelector<TReduxStore, IStateProps>(({ User }) => ({ appUser: User.appUser }));

    const handleSubmit = useAsyncTask(async (value: { email: '', password: '' }) => {
        dispatch(userLogin(value)).catch(error => {
            dispatch(OApp.showToast({ message: 'Invalid Credentials', variant: 'error' }))
            throw error;
        })
    })

    const classes = useStyles();
    const config: IReactFormProps['config'] = [
        {
            valueKey: 'email',
            type: 'text',
            fieldProps: {
                fullWidth: true,
                label: 'Email'
            }
        },
        {
            valueKey: 'password',
            type: 'password',
            fieldProps: {
                fullWidth: true,
                label: 'Password'
            }
        }
    ]
    const actionConfig: IReactFormProps['actionConfig'] = {
        loaderProps: {
            color: 'primary',
        },
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            color: 'primary',
            variant: 'contained'
        },
        submitButtonText: 'Login'
    }
    if (appUser) {
        return <Redirect to={'/dashboard'} />
    }
    return (
        <div>
            <div className={classes.landingPageContainer}>
                <div className={classes.formContainer}>
                    <ReactForm
                        formId='login-form'
                        config={config}
                        actionConfig={actionConfig}
                        intialValues={{}}
                        formSettings={{
                            verticalSpacing: 24
                        }}
                        validationSchema={Yup.object({
                            email: Yup.string().trim().required('Required').email('Invalid email'),
                            password: Yup.string().trim().required('Required')
                        })}
                        isInProgress={handleSubmit.status === 'PROCESSING'}
                        onSubmit={handleSubmit.run}
                    />
                </div>
            </div>
        </div>
    )
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    landingPageContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center'
    },
    logoContainer: {
        marginBottom: 30,
        '& > img': {
            maxWidth: '100%'
        }
    },
    formContainer: {
        width: 300,
        margin: '0 auto'
    },
    formControl: {
        marginBottom: 30
    }
}))

export default Landingpage;