import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, Fab, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import dimensions from '../../../../Constants/dimensions';
import FileInput from '../../../../Components/FileInput';
import { getTransformedUrl, uploadPicture } from '../../../../Resources/PictureUtils';
import utilities from '../../../../Resources/Utils';
import get from 'lodash/get';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { OCity } from '../../../../Models/City';
import { TCity, TCityState } from '../../../../Models/City/@types';
import { TReduxStore } from '../../../../RootReducer';
import { TDispatch, TPicture } from '../../../../Resources/GlobalTypes';
import Loader from '../../../../Components/Loader';
import DeleteButton from '../../../../Components/Buttons/DeleteButton';
import { OAsset } from '../../../../Models/Asset';
import ReorderableList from '../../../../Components/ReorderableList';
import Images from './Images';

export interface SellPageProps { }

const SellPage: FC<SellPageProps> = (props) => {
    const classes = useStyles({});
    const dispatch = useDispatch<TDispatch>();
    const { details = {} as TCity } = useSelector<TReduxStore, Pick<TCityState, 'details'>>(({ City }) => ({ details: City.details }));
    const [loading, setLoading] = useState(false);

    const uploadPictures = async (files: any[]) => {
        setLoading(true);
        if (files.length === 0) return;
        try {
            const res = await Promise.all(files.map((file) => uploadPicture(file, 'destinations')));
            const data = res.map((res) => get(res, 'data'));
            await dispatch(OCity.patchItem(details.id, { sellImages: [...(details?.sellImages || []), ...data] }));
        } catch (error) {
            // TODO: handle error
        }
        setLoading(false);
    };
    const uploadVideos = async (files: any[]) => {
        setLoading(true);
        if (files.length === 0) return;
        try {
            const formData = new FormData();
            formData.append(files[0].name, files[0].file);
            const assets = await OAsset.upload(formData);
            if (!assets.length) return;
            await dispatch(OCity.patchItem(details.id, { sellVideo: assets[0] }));
        } catch (error) {
            // TODO: handle error
        }
        setLoading(false);
    };

    const removeImage = (key: keyof TCity, item?: TPicture) => async () => {
        setLoading(true);
        switch (key) {
            case 'sellImages':
                if (!item) return;
                await dispatch(OCity.patchItem(details.id, { [key]: details[key]?.filter?.((i) => !!i && i.id !== item.id) }));
                break;
            case 'sellVideo':
                await dispatch(OCity.patchItem(details.id, { [key]: null }));
                break;
            default:
                break;
        }
        setLoading(false);
    };


    const onReorder = async (nexList: TPicture[], movedItem: TPicture, oldIndex: number, newIndex: number) => {
        if (!details?.id) return;
        const odlList = details.sellImages || [];
        try {
            // setList(nexList);
            // await dispatch(reorderDestinationEntity(details.id, 'eateries', movedItem.id, newIndex, { ...details, eateries: [...nexList] }))
        } catch (error) {
            // setList(odlList);
        }
    }


    return (
        <Box className={classes.container}>
            {loading && <Loader overlayed />}
            <Box mb={4}>
                <Images />
            </Box>
            <Box>
                <Typography gutterBottom>Sneak peak video</Typography>
                <Card className={clsx(classes.videoContainer, classes.addBtn)}>
                    {details.sellVideo ? (
                        <>
                            <video autoPlay muted width="100%">
                                <source src={get(details, 'sellVideo.url')} type="video/mp4" />
                            </video>
                            <Box className={classes.deleteContainer}>
                                <div className={classes.imageDeleteButton}>
                                    <DeleteButton
                                        alertMessage="Are you sure you want to delete the video?"
                                        backgroundColor="transparent"
                                        handleDelete={removeImage('sellVideo')}
                                    />
                                </div>
                            </Box>
                        </>
                    ) : (
                        <>
                            <i className="material-icons">add</i>
                            <FileInput accept={'video/*'} multiple={false} onDone={uploadVideos} />
                        </>
                    )}
                </Card>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    container: {
        width: dimensions.screenMaxWidth,
    },
    imageContainer: {
        width: '100%',
        aspectRatio: '1',
        position: 'relative',
        '& img': {
            width: '100%',
            aspectRatio: '1',
        },
    },
    deleteContainer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
    videoContainer: {
        position: 'relative',
        width: 400,
        aspectRatio: '2/1',
    },
    addBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, fr))',
    }
}));

export default SellPage;
