import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import { THomeSection } from '../../../Models/HomeSection/@types';
import { Cancel, Check, Edit } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { OHomeSection } from '../../../Models/HomeSection';
import { TDispatch } from '../../../Resources/GlobalTypes';

export interface TitleFormFieldProps {
    section: THomeSection
}

const TitleFormField: FC<TitleFormFieldProps> = (props) => {
    const { section } = props;
    const classes = useStyles({});
    const [editing, setEditing] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(section.name);
    }, [section])

    const handleSave = useCallback(async () => {
        await dispatch(OHomeSection.patchItem(section.id, { name: title }));
        setEditing(false);
    }, [title, section, dispatch])

    const handleCancel = useCallback(() => {
        setTitle(section.name);
        setEditing(false);
    }, [section])

    const ActionButton = useMemo(() => {
        if (editing) {
            return (
                <Box display='flex'>
                    <IconButton size='small' onClick={handleSave}><Check color='action' fontSize='small' /></IconButton>
                    <IconButton size='small' style={{ marginLeft: 16 }} onClick={handleCancel}><Cancel color='error' fontSize='small' /></IconButton>
                </Box>
            )
        }
        return (<IconButton size='small' onClick={() => setEditing(true)}><Edit fontSize='small' /></IconButton>)
    }, [editing, handleCancel, handleSave])

    return (
        <Box mt={5} width='100%' >
            <TextField
                fullWidth
                label="Title"
                className={classes.textField}
                value={title}
                onChange={e => setTitle(e.target.value)}
                disabled={!editing}
                InputProps={{
                    endAdornment: ActionButton
                }}
            />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    textField: {
        '& input:disabled': {
            color: '#000',
        },
    }
}));

export default TitleFormField;