import React, { FC } from 'react'
import { createStyles, Theme } from '@material-ui/core'
import DashboardBody from './DashboardBody';
import { makeStyles } from '@material-ui/styles';

const DashboardLaout: FC = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <DashboardBody />
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
        backgroundColor: theme.palette.grey['500'],
        overflow: 'hidden'
    },
    button: {
        color: '#fff'
    },
    flex: {
        flex: 1
    },
    userDropdown: {
        zIndex: theme.zIndex.appBar + 1
    },
    listItemIcon: {
        marginRight: 0
    }
}))

export default DashboardLaout;