import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, Grid } from '@material-ui/core';
import { getSubscribeUserList } from '../../../../Models/User/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../../RootReducer';
import { TCityState } from '../../../../Models/City/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TUser, TUserState } from '../../../../Models/User/@types';
import UserCard from '../../../../Components/Cards/UserCard';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import UserForm from '../../../../Components/Forms/UserForm';
import { ODestinationTransaction } from '../../../../Models/DestinationTransactions';
import Pagination from '../../../../Components/Pagination';
import { DESTINATION_PACK_CITITES_LIMIT } from '../../../../Constants/FilterConstants';
import moment from 'moment';

interface UProps {
    pageNumber?: number;
}

export type IStateProps = Pick<TCityState, 'details' | 'subscriptionList'>;

export default function User({ }: UProps) {
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>();
    const [showForm, setShowForm] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const { details, subscriptionList } = useSelector<TReduxStore, IStateProps>(({ City: { details, subscriptionList } }) => ({
        details,
        subscriptionList,
    }));

    const subscribedUserList = (subscriptionList.data?.map?.((item) => item.user) || []).filter(u => !!u) as TUser[];

    const getData = async () => {
        if (details?.id) {
            await dispatch(getSubscribeUserList(details?.id, pageNumber));
        }
    };
    useEffect(() => {
        getData();
    }, [details, pageNumber]);

    const handlePageChange = (pageNumber: number) => {
        setPageNumber(pageNumber);
    };
    const handleSubscribedUser = () => {
        setShowForm(true);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    const onFormSubmit = async (userList) => {
        if (!details) return;
        const newUsers = filterExistingUsersFromList(userList);
        await dispatch(
            ODestinationTransaction.multiUserMultiDestinationTransaction(
                [details.id],
                newUsers.map((u) => u.id)
            )
        );
        closeForm();
        getData();
    };

    const filterExistingUsersFromList = (userList: TUser[]) => {
        const existingUsers = subscribedUserList?.map((user) => user.id);
        return userList.filter((user) => !existingUsers?.includes(user.id)) || [];
    };

    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleSubscribedUser,
            helpText: 'Add Users',
        },
    ];

    return (
        <div>
            <div className={classes.operationBtns}>
                <HeaderActionButtons items={headerItems} />
                <UserForm open={showForm} closeForm={closeForm} users={subscribedUserList?.filter(s => !!s)} onFormSubmit={onFormSubmit} heading={'Add Users'} />
            </div>

            <div className={classes.wrapper}>
                <Grid container spacing={3}>
                    {subscriptionList.data.map((item, index) => {
                        const subTitle = `Expires on ${moment(`${item.endDate}`).format('MM/DD/YY')}`;
                        return item.user ? (
                            <Grid item key={index} md={4}>
                                <UserCard user={item.user} subTitle={subTitle} />
                            </Grid>
                        ) : null;
                    })}
                </Grid>
                <div className={classes.paginationContainer}>
                    <Pagination
                        currentPage={pageNumber}
                        onPageChange={handlePageChange}
                        itemsCountPerPage={DESTINATION_PACK_CITITES_LIMIT}
                        totalItemsCount={subscriptionList?.count}
                    />
                </div>
            </div>
        </div>
    );
}
const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        wrapper: {
            width: 924,
            [theme.breakpoints.down('md')]: {
                width: 640,
            },
            [theme.breakpoints.down('sm')]: {
                width: 324,
            },
        },
        operationBtns: {
            display: 'flex',
            justifyContent: 'center',
            color: 'white',
            marginBottom: 25,
        },
        paginationContainer: {
            width: 380,
            margin: '30px auto',
        },
    })
);
