import React, { FC, useMemo, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router';
import WhatsNew from './Tabs/WhatsNew';
import HeaderNavLinks from '../../Components/Buttons/HeaderNavLinks';
import EditorsPick from './Tabs/EditorsPick';
import Eatinerary from './Tabs/Eatinerary';
import Dish from './Tabs/Dish';
import TravelWorthy from './Tabs/TravelWorthy';
import Destinations from './Tabs/Destinations';
import ExploreCity from './Tabs/ExploreCity';
import { useDispatch, useSelector } from 'react-redux';
import { OHomeSection } from '../../Models/HomeSection';
import { TReduxStore } from '../../RootReducer';
import { THomeSectionState } from '../../Models/HomeSection/@types';
import Loader from '../../Components/Loader';
import { OCity } from '../../Models/City';
import Videos from './Tabs/Videos';

export interface HomeProps {

}

const baseUrl = '/dashboard/home'
const Home: FC<HomeProps> = (props) => {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const { loading } = useSelector<TReduxStore, Pick<THomeSectionState, 'loading'>>(({ Home: { loading } }) => ({ loading }))

    const TABS = useMemo(() => ([
        {
            name: "Videos",
            path: "videos",
            component: Videos
        },
        {
            name: "What's New",
            path: "whats-new",
            component: WhatsNew
        },
        {
            name: "Editor’s Picks",
            path: "editors-pick",
            component: EditorsPick
        },
        {
            name: "Eatinerary",
            path: "eatinerary",
            component: Eatinerary
        },
        {
            name: "Dish",
            path: "dish",
            component: Dish
        },
        {
            name: "Travel Worthy",
            path: "travel-worthy",
            component: TravelWorthy
        },
        {
            name: "Destinations",
            path: "destinations",
            component: Destinations
        },
        {
            name: "Explore City",
            path: "explore-city",
            component: ExploreCity
        },
    ]), [])




    useEffect(() => {
        dispatch(OHomeSection.getItemsList())
        dispatch(OCity.getItemsList({
            filter: {
                order: 'name ASC',
                fields: ['name', 'id', 'isPublished']
            },
        }))
    }, [])



    return (
        <Box>
            {loading ? <Loader overlayed /> : null}
            <HeaderNavLinks baseUrl={baseUrl} links={TABS.map(t => ({ name: t.name, url: t.path }))} />
            <Box className={classes.content}>
                <Switch>
                    <Route path={baseUrl} exact render={() => <Redirect to={`${baseUrl}/videos`} />} />
                    {TABS.map(t => (
                        <Route key={t.path} component={t.component} path={`${baseUrl}/${t.path}`} exact />
                    ))}
                </Switch>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    content: {
    }
}));

export default Home;