import { Box, Button, createStyles, Drawer, Fab, FormControlLabel, IconButton, Radio, RadioGroup, Theme, Tooltip } from '@material-ui/core';
import { FabProps } from '@material-ui/core/Fab';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles } from '@material-ui/styles';
import React, { Component, ReactElement, ReactNode, useEffect } from 'react';
import { Route, RouteComponentProps, RouteProps, Switch, useLocation, withRouter } from 'react-router-dom';
import utilities from '../../Resources/Utils';
import HeaderNavLinks from '../Buttons/HeaderNavLinks';
import MainList, { MainListProps } from './MainList';

export interface ListDashboardProps extends WithStyles<typeof STYLES>, RouteComponentProps {
    baseUrl: string
    csvConfig?: { modelName: string }
    itemList: Array<any>
    renderListItem: (item: any) => ReactElement
    onFilterSubmit?: () => void
    onPageChange?: (pageNumber: number) => void
    contentConfig?: Array<{ url: string, component: RouteProps['component'], name: string }>
    onItemAddRequest?: () => void
    addButtonComponent?: ReactNode
    listProps: Omit<MainListProps<any>, 'classes' | 'list' | 'itemsCountPerPage'>
    onItemSelected?: (itemId: any) => void
    onItemUnselected?: () => void
    filterConfig?: Array<{ name: string, isActive: boolean, count?: number }>
    itemIdIndex?: number // It is the index of the id of the item in the url. By default 3
    filterComponent?: ReactNode
    totalItemsCount?: number
    itemsCountPerPage?: number
    onFilterChange?: (item: { name: string, isActive: boolean }) => void
    onFilterReset?: () => void
    itemId?: string
    currentPageNumber?: number;
    customFilterComponent?: ReactNode
    listActionButtonConfig?: { icon: string, fabProps?: Omit<FabProps, 'onClick'>; id: string; onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void, label: string }[];
}

const SubDashboard = (props: ListDashboardProps) => {
    const { baseUrl, contentConfig = [], itemId = '', classes } = props;
    const { pathname } = useLocation();
    useEffect(() => {
        const elem = document.getElementById('dashboard-content');
        elem?.scrollTo(0, 0)
    }, [pathname])
    if (contentConfig.length < 1)
        return <div />;
    return (
        <Box display='flex' justifyContent='center'>
            {/* <div className={classes.headlinks}> */}
            <Box className={classes.header}>
                <HeaderNavLinks baseUrl={`${baseUrl}/${itemId}`} links={contentConfig} />
            </Box>
            {/* </div> */}
            {/* <div className={classes.detailContent}> */}
            <Box className={classes.dashboard} id='dashboard-content'>
                <Switch>
                    {
                        contentConfig.map(link => {
                            return (
                                <Route key={link.url} path={`${baseUrl}/:id/` + link.url} component={link.component}></Route>
                            )
                        })
                    }
                </Switch>
            </Box>
            {/* </div> */}
        </Box>
    )
}

interface IState {
    selectedItemId: undefined | string,
    filterElementAnchor: (EventTarget & Element) | null
}

class ListDashboard extends Component<ListDashboardProps, IState> {
    constructor(props: ListDashboardProps) {
        super(props);
        this.state = {
            selectedItemId: undefined,
            filterElementAnchor: null,
        };
    }

    handleFilterClick = (event: React.MouseEvent) => this.setState({ filterElementAnchor: this.state.filterElementAnchor ? null : event.currentTarget });

    handleFilterMenuClose = () => {
        console.log('this is fired')
        this.setState({ filterElementAnchor: null });
    }

    handleFilterApllied = () => {
        console.log('this.props.filterConfig', this.props.filterConfig);
        if (typeof this.props.onFilterSubmit === 'function') {
            this.props.onFilterSubmit();
            this.setState({ filterElementAnchor: null })
        }
    }

    selectItem = (props: ListDashboardProps) => {
        const { match, location, itemIdIndex = 3, onItemSelected, onItemUnselected } = props;
        const isExactUrl = match.isExact;
        if (isExactUrl) {
            this.setState({
                selectedItemId: undefined
            });
            typeof onItemUnselected === 'function' && onItemUnselected();
            return; //Call action to remove item from reducer

        }
        const urlLocation = location.pathname;
        const itemId = urlLocation.split('/')[itemIdIndex];
        if (itemId) {
            this.setState({
                selectedItemId: itemId
            });
            typeof onItemSelected === 'function' && onItemSelected(itemId);
        }
    }

    componentDidMount = () => this.selectItem(this.props);

    componentWillReceiveProps = (nextProps: ListDashboardProps) => this.selectItem(nextProps);

    handlePageChange = (pageNumber: number) => typeof this.props.onPageChange === 'function' && this.props.onPageChange(pageNumber);

    // handleCsvDownload = () => typeof this.pro

    render() {
        const { classes, customFilterComponent, itemList, listProps, addButtonComponent, onItemAddRequest, filterComponent, itemsCountPerPage = 20, csvConfig } = this.props;
        const { selectedItemId, filterElementAnchor } = this.state;
        const list = itemList.map(this.props.renderListItem);
        return (
            <div>
                <MainList
                    list={list}
                    float={(selectedItemId) ? 'left' : 'center'}
                    search={true}
                    currentPageNumber={this.props.currentPageNumber || 1}
                    onPageChange={this.handlePageChange}
                    totalItemsCount={this.props.totalItemsCount}
                    itemsCountPerPage={itemsCountPerPage}
                    {...listProps}
                >
                    {
                        this.props.listActionButtonConfig?.map((item, i) => {
                            return (
                                <Box key={item.id} mb={1} >
                                    <Tooltip title={item.label} >
                                        <Fab className={classes.filterButton} onClick={item.onClick} {...item?.fabProps} >
                                            <i className="material-icons" color={"primary"}>{item.icon}</i>
                                        </Fab>
                                    </Tooltip>
                                </Box>
                            )
                        })
                    }
                    {
                        (this.props.onFilterSubmit && this.props.filterConfig) &&
                        (
                            <div className={classes.filterButtonContainer} >
                                <Tooltip title="Filter results">
                                    <Fab className={classes.filterButton} onClick={this.handleFilterClick} >
                                        <i className="material-icons" color={"primary"} >filter_list</i>
                                    </Fab>
                                </Tooltip>

                                <Drawer
                                    anchor='right'
                                    open={Boolean(filterElementAnchor)}
                                    onClose={() => this.handleFilterMenuClose()}
                                >
                                    <Box pt={4} position='relative' height='100%' display='flex' flexDirection='column' overflow='auto'>
                                        <Box className={classes.paper} px={1} overflow='auto' flex={1} >
                                            {customFilterComponent || null}
                                            {
                                                (filterComponent) ? (filterComponent) :
                                                    <RadioGroup
                                                        className={classes.formGroup}
                                                        onChange={(e) => typeof this.props.onFilterChange === 'function' ? this.props.onFilterChange((this.props.filterConfig || []).find(i => i.name === e.target.value)!) : undefined}
                                                    >
                                                        {
                                                            (this.props.filterConfig || []).map((item, i) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        key={i}
                                                                        className={classes.checkbox}
                                                                        value={item.name}
                                                                        control={
                                                                            <Radio
                                                                                checked={item.isActive}
                                                                                // onChange={() => typeof this.props.onFilterChange === 'function' ? this.props.onFilterChange(item) : undefined}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={item.name + (item.count ? +`(${item.count})` : '')}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </RadioGroup>
                                            }

                                        </Box>
                                        <div className={classes.buttonsContainer} >
                                            <Button className={classes.btn} color="primary" variant="contained" onClick={this.handleFilterApllied} >Apply</Button>
                                            <Button
                                                className={classes.btn}
                                                color="primary"
                                                variant="contained"
                                                onClick={
                                                    () => {
                                                        typeof this.props.onFilterReset === 'function' && this.props.onFilterReset();
                                                        //this.setState({ filterElementAnchor: null });
                                                    }
                                                }
                                            >Reset</Button>
                                        </div>
                                        <IconButton className={classes.crossButton} onClick={this.handleFilterMenuClose} >
                                            <i className="material-icons" >close</i>
                                        </IconButton>
                                    </Box>
                                </Drawer>
                            </div>
                        )
                    }
                    {
                        (addButtonComponent) ? { addButtonComponent } : (
                            <div className={classes.filterButtonContainer} >
                                <Tooltip title="Add">
                                    <Fab color="primary" onClick={onItemAddRequest}>
                                        <i className="material-icons">add</i>
                                    </Fab>
                                </Tooltip>
                            </div>
                        )
                    }
                    {
                        csvConfig && csvConfig.modelName &&
                        (

                            <Tooltip title="Csv Download">
                                <Fab href={utilities.getCsvDownloadLink(csvConfig.modelName)} target='_blank' className={classes.fabBase} color="primary">
                                    <i className="material-icons">file_download</i>
                                </Fab>
                            </Tooltip>
                        )
                    }

                </MainList>
                {
                    (selectedItemId) &&
                    (
                        <div className={classes.content}>
                            <SubDashboard itemId={selectedItemId} {...this.props} />
                        </div>
                    )

                }
                {this.props.children || <div />}
            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    content: {
        paddingLeft: 250,
        paddingBottom: 50,
        // height: '100vh',
        overflow: 'auto',

    },
    filterButtonContainer: {
        marginBottom: 5
    },
    fabBase: {
        marginBottom: 5
    },
    filterButton: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white
    },
    paper: {
        width: 400,
        position: 'relative',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    btn: {
        flex: 1,
        borderRadius: 0
    },
    formGroup: {
        paddingLeft: 15,
    },
    checkbox: {
        height: 30,
    },
    crossButton: {
        position: 'absolute',
        right: 0, top: 0
    },
    header: {
        borderBottom: `1px solid`,
        position: 'absolute',
        background: 'white',
        width: `calc(100vw - 402px)`,
        zIndex: 10,
        height: 50
    },
    dashboard: {
        marginTop: 100,
    }
});

export default withStyles(STYLES)(withRouter(ListDashboard));
