import { Reducer } from "redux";
import { TTransactionsState } from './@types';
import { TAction } from 'loop-front';
import { OTransactions } from '.';


const initialState: TTransactionsState = {
    list: []
}


export const RTransactions: Reducer<TTransactionsState, TAction> = (state = initialState, action): TTransactionsState => {
    const {
        LIST_RECEIVED
    } = OTransactions.Actions;

    switch (action.type) {
        case LIST_RECEIVED: return { ...state, list: action.data }


        default: return state;
    }
}