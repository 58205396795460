import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import CuisinesCardList from '../../../../Components/Cards/CardLists/CuisinesCardList'
import CuisinesForm from '../../../../Components/Forms/CuisinesForm'
import HeaderActionButtons from '../../../../Components/HeaderActionButtons'
import { ITEM_PER_PAGE_COUNT } from '../../../../Constants/FilterConstants'
import { OApp } from '../../../../Models/App'
import { OCity } from '../../../../Models/City'
import { TCityState } from '../../../../Models/City/@types'
import { addEntityToCity, deleteEntityfromCity } from '../../../../Models/City/actions'
import { TCuisine } from '../../../../Models/Cuisines/@types'
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'

interface IProps extends RouteComponentProps<{ id: string }> {

}

const Cuisines: React.FC<IProps> = (props) => {
    const { match } = props;
    const cityId = match.params['id'];
    const classes = useStyles(props)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    const [pageNumber, setPageNumber] = useState(1);
    const { details, cuisines = [] } = useSelector<TReduxStore, Pick<TCityState, 'details' | 'cuisines'>>(({ City }) => ({ details: City.details, cuisines: City.cuisines }))
    const textUrl = `${DOMAIN_URL}/cities/${details?.slug || cityId}/cuisines`;
    const [showForm, setShowForm] = useState(false)
    const [displayList, setDisplayList] = useState<TCuisine[]>([]); const getData = async () => {
        setLoading(true);
        await dispatch(OCity.getEntityByItem(
            cityId,
            OCity.Entities.CUISINES,
            {
                filter: {
                    order: 'name ASC',
                    fields: ['name', 'isPublished', 'id']
                }
            }));
        setLoading(false)
    }

    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId])
    useEffect(() => {
        if (cityId) {
            const low = ITEM_PER_PAGE_COUNT * (pageNumber - 1);
            const high = ITEM_PER_PAGE_COUNT * (pageNumber)
            const tList: TCuisine[] = (cuisines || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, cuisines.toString()])

    const handleAddCuisine = () => {
        setShowForm(true)

    }
    const deleteItem = async (item: TCuisine) => {
        try {
            await dispatch(deleteEntityfromCity(cityId, OCity.Entities.CUISINES, cuisines, item.id));
        } catch (err) {
            console.log(err)

        }
    }
    const onFormSubmit = async (newList: TCuisine[]) => {
        try {
            await dispatch(addEntityToCity(cityId, newList, 'Cuisine'));
            setPageNumber(1);
            dispatch(OApp.showToast({ message: 'Cuisines Added Successfully', variant: 'success' }))
        } catch (err) {
            console.log('Error', err)
            dispatch(OApp.showToast({ message: 'Cuisine Add Unsuccessful', variant: 'error' }))
        }
        setShowForm(false)
    }
    const closeForm = () => { setShowForm(false) }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddCuisine,
            helpText: 'Add cuisine available in this city',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]
    const handlePageChange = (newPage: number) => { setPageNumber(newPage) }

    return (
        <div className={classes.destinationCuisine}>
            <HeaderActionButtons items={headerItems} />
            {showForm && <CuisinesForm open={showForm} closeForm={closeForm} onFormSubmit={onFormSubmit} cuisines={cuisines} />}
            <div className={classes.wrapper}>
                {
                    loading ? <CircularProgress />
                        : <>
                            <CuisinesCardList
                                list={displayList}
                                deleteItem={deleteItem}
                                handlePageChange={handlePageChange}
                                pageNumber={pageNumber}
                                totalCount={details ? details.counts.cuisines || 0 : 0}
                            />
                        </>
                }
            </div>
        </div >
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    destinationCuisine: {
        position: 'relative',
        margin: '0px 40px',
    },
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        },
        margin: '40px auto'
    }
}))

export default Cuisines


