import { Box, Button, Checkbox, CircularProgress, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import Pagination, { PaginationComponentProps } from '../../../Components/Pagination';
import { TPlace } from '../../../Models/Place/@types';
import utilities from '../../../Resources/Utils';

export interface FilteredListProps {
    list: TPlace[]
    loading?: boolean
    addPlaces: (list: TPlace[]) => void
    paginationProps: Omit<PaginationComponentProps, 'classes'>
}

const FilteredList: FC<FilteredListProps> = (props) => {
    const classes = useStyles();
    const { list, loading, addPlaces, paginationProps } = props;
    const [checked, setChecked] = useState<string[]>([])
    const handleClick = (id: string) => {
        const newList = utilities.toggleItemFromList(checked, id, 'a', (a, b) => a === b) as string[];
        setChecked([...newList]);
    }
    const handleSubmit = () => {
        if (checked.length === 0)
            return
        addPlaces([...list.filter(i => checked.includes(i.id))]);
        setChecked([])
    }
    return (

        <Paper className={classes.paper}>
            <Box flex={1} overflow='auto'>

                <List >
                    <ListSubheader className={classes.header} >
                        <Typography variant='body2'>{`${list.length} places qualify`}</Typography>
                    </ListSubheader>
                    {
                        list.map((it, index) => {
                            return (
                                <ListItem dense key={index} button onClick={() => handleClick(it.id)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(it.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={it.name} secondary={it.city + '\t' + it.status} />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Box>
            <Pagination {...paginationProps} />
            <Button onClick={handleSubmit} className={classes.button} fullWidth size='large' color='primary' variant='contained'>
                {loading ? <CircularProgress color='inherit' /> : `Send to list`}
            </Button>
        </Paper>

    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: 400,
            overflowY: 'scroll',
            position: 'relative',
            // paddingBottom: 42,
            borderRadius: 0,
            marginRight: 8,
            display: 'flex',
            flexDirection: 'column'
        },
        header: {
            backgroundColor: '#fff',
            padding: 12

        },
        item: {
            alignItems: 'flex-start'
        },
        button: {
            width: 400,
            borderRadius: 0
        }
    }))
})

export default FilteredList