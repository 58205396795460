import { Chip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { TCity } from '../../Models/City/@types';

export interface CityChipListProps {
    list: TCity[]
    selectedList: string[]
    onClick: (id: string) => void
}

const CityChipList: FC<CityChipListProps> = ({ list, onClick, selectedList }) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.title} >City</Typography>
            {
                (list) ?
                    list.sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                    }).map((item, index) => {
                        return (
                            <Chip
                                label={item.name}
                                key={index}
                                size='small'
                                className={classes.chip}
                                onClick={() => { onClick(item.id) }}
                                color={selectedList.findIndex(i => i === item.id) > -1 ? 'primary' : 'default'}
                            />
                        )
                    }) : null
            }
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        title: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: 'black',
            marginLeft: 4,
            marginBottom: 10
        },
        chip: {
            margin: '0px 4px 4px 0px',
            cursor: 'pointer',
        }
    }))
})

export default CityChipList