import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import _, { cloneDeep, omit } from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { OApp } from '../../../Models/App';
import { OEatinerary } from '../../../Models/Eatinerraies';
import { TEatineraryState, TEatinerraies, TEatinerraiesSection } from '../../../Models/Eatinerraies/@types';
import { OQuote } from '../../../Models/Quotes';
import { TExplore, TExploreState } from '../../../Models/Explore/@types';
import ExploreModel from '../../../Models/Explore/actions';
import { TPlace } from '../../../Models/Place/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';
import BasicInfo from './BasicInfo';
import { TQuote, TQuoteState } from '../../../Models/Quotes/@types';
export interface QuoteFormProps { }

export interface TExploreFilter {
    destinationId?: any
    range?: any
    types?: any
    status?: any
    cuisineIds?: any
    tagIds?: any
}
const QuoteForm: FC<QuoteFormProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>()
    const history = useHistory();
    const { editedQuote } = useSelector<TReduxStore, Pick<TQuoteState, 'editedQuote'>>(({ Quote: { editedQuote } }) => ({ editedQuote }))



    const handleSubmit = async (values: any) => {
        console.log(values,"values");
        try {

            if (values.id) {
                await dispatch(OQuote.patchItem(values.id, { ...values }, {}, undefined, { ...values}))
                dispatch(OApp.showToast({ message: 'Item edited successfully' }))
            } else {
                let res: { data: TQuote  } = await OQuote.requestPostItem({ ...values })
                dispatch({ type: OQuote.Actions.POST_ITEM_SUCCESS, data: res.data })
                history.push('/dashboard/quotes/' + res.data.id + '/details')
                // dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'List' } })
                dispatch(OApp.showToast({ message: 'Item added successfully' }))
            }
            closeForm();
        } catch (err) {
            console.log(err,"error");
        }
    }

    const closeForm = () => {
        dispatch({ type: OEatinerary.Actions.HIDE_EATINERARY_FORM })
    }

    return (
        <Formik<Partial<TEatinerraies>>
            initialValues={editedQuote ? editedQuote : {}}
            validationSchema={Yup.object({
                title: Yup.string().required('This is required'),
            })}
            onSubmit={handleSubmit}
        >
            {
                formikProps =>
                    <FormDialog
                        onClose={closeForm}
                        open={true}
                        heading={ editedQuote ? "Edit Quote" : 'Add Quote'}
                        onSubmit={() => formikProps.handleSubmit()}
                        containerClass={classes.container}
                    >
                        <Box className={classes.root}>
                            <Box flex={1} display='flex' flexDirection='column' alignItems='center' overflow='auto'>
                                <BasicInfo formikProps={formikProps} />
                            </Box>
                        </Box>
                    </FormDialog>
            }
        </Formik>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        container: {
            width: '100%',
            maxWidth: '100%',
            margin: `0 auto`
        },
        root: {
            display: 'flex',
            width: '100%',
            height: `calc(100vh - 64px)`,
        }
    }))
})

export default QuoteForm