import LoopFront from 'loop-front';
import { OApp } from '../App';

export const CustomEntities = {};
export const CustomActivity = {
};
export const CustomActions = {
    DESTINATION_TRANSACTION_MULTI_USER_TRANSACTION_SUCCESS: 'DESTINATION_TRANSACTION_MULTI_USER_TRANSACTION_SUCCESS',
};
class DestinationTransaction extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('DestinationTransactions', CustomActions, CustomEntities, CustomActivity);
    }

    multiUserMultiDestinationTransaction = (destinationIds: string[], userIds: string[]) => async (dispatch) => {
        try {
            const { data } = await LoopFront.request({
                url: this.ModelName + `/multi-user-multi-destination-purchase`,
                method: 'POST',
                data: {
                    destinationIds,
                    userIds,
                }
            })
            dispatch({
                type: this.Actions.DESTINATION_TRANSACTION_MULTI_USER_TRANSACTION_SUCCESS,
                data,
            })
            dispatch(OApp.showToast({ message: 'Success', variant: 'success' }));
        } catch (err) {
            console.error(err);
        }
    }

}

export const ODestinationTransaction = new DestinationTransaction();
