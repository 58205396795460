import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import PlacesCardList from '../../../../Components/Cards/CardLists/PlacesCardList'
import PlacesForm from '../../../../Components/Forms/PlacesForm'
import HeaderActionButtons from '../../../../Components/HeaderActionButtons'
import { OApp } from '../../../../Models/App'
import { OPlace } from '../../../../Models/Place'
import { TPlace, TPlaceState } from '../../../../Models/Place/@types'
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'

interface IProps { }

const Similarplaces: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
    const { match } = props
    const placeId = match.params['id'];
    const classes = useStyles(props)
    const [showForm, setShowForm] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    const { details } = useSelector<TReduxStore, Pick<TPlaceState, 'details'>>(({ Place }) => ({ details: Place.details }))
    const textUrl = `${DOMAIN_URL}/places/${details?.slug || placeId}/Similar`;
    let similarEstablishment: TPlace[] = (details && details.similarEstablishments) ? details.similarEstablishments : [];
    similarEstablishment = similarEstablishment?.filter(e => ((details?.similarEstablishmentIds || []).indexOf(e.id) > -1));
    const handleAddPlace = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowForm(true);

    }

    const closeForm = () => {
        setShowForm(false);
    }

    const onFormSubmit = async (newList: TPlace[]) => {

        if (details) {
            const idList: string[] = newList.map(i => i.id)
            try {
                await dispatch(OPlace.patchItem(details.id, { similarEstablishmentIds: idList }, {}, undefined,
                    {
                        similarEstablishmentIds: newList.map(i => i.id),
                        similarEstablishments: [...newList],
                        resolvedSimilarEstablishments: [...newList],
                    }));
                dispatch(OApp.showToast({ message: 'Similar places updated', variant: 'success' }));
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Similar Place Update Unsuccessful', variant: 'error' }));
                console.log('Similar place update: ', err)
            }
        }
        closeForm();
    }
    const deleteItem = async (item: TPlace) => {
        if (details) {
            try {
                const newList: TPlace[] = similarEstablishment.filter(i => i.id !== item.id);
                await dispatch(OPlace.patchItem(
                    details.id,
                    { similarEstablishmentIds: newList.map(i => i.id) },
                    {}, undefined,
                    {
                        similarEstablishmentIds: newList.map(i => i.id),
                        resolvedSimilarEstablishments: [...newList],
                        similarEstablishments: [...newList],
                    }
                ))
            } catch (err) {
                console.log(err)
            }
        }
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddPlace,
            helpText: 'Add a place similar to this',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]

    return (
        <div className={classes.establishmentSimilarPlaces}>
            <>
                <div className={classes.operationBtns}>
                    <HeaderActionButtons items={headerItems} />
                    {showForm && <PlacesForm
                        heading={'Add similar places'}
                        closeForm={closeForm}
                        open={showForm}
                        onFormSubmit={onFormSubmit}
                        places={similarEstablishment}
                    />}

                </div>
                <div className={classes.wrapper}>


                    <PlacesCardList
                        deleteItem={deleteItem}
                        list={similarEstablishment || []}
                    />

                </div>
            </>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    establishmentSimilarPlaces: {
        position: 'relative',
        margin: '0px 40px',
    },
    paper: {
        width: '300px',
        height: '140px',
        borderRadius: '15px',
        padding: '20px',
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    addToMamakooButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: 20
    },
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        '& > button': {
            margin: '0 10px'
        },
        '& > div': {
            margin: '0 10px'
        }
    },
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        },
        margin: '40px auto'
    },
    mainTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    address: {
        fontSize: '14px',
    },
    lockIcon: {
        // color: theme.palette.red1
    },
    bottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    fab: {
        background: 'transparent'
    }
}))

export default Similarplaces


