import { Reducer } from "redux"

import { TTagCategoriesState } from "./@types"

import { TAction } from "loop-front"

import { OTagCategories } from "."
import utilities from "../../Resources/Utils"

export const RTagCategories: Reducer<TTagCategoriesState, TAction> = (state = { showTagCategoryForm: false, showtagForm: false }, action) => {
    const {
        LIST_RECEIVED,
        SHOW_TAG_CATEGORY_FORM,
        HIDE_TAG_CATEGORY_FORM,
        POST_ITEM_SUCCESS,
        ITEM_PATCH_SUCCESS,
        REORDER_TAGS_LIST,
        SHOW_TAG_FORM,
        HIDE_TAG_FORM,
        TAG_CATEGORY_DELETE_SUCCESS,
        MULTIPLE_TAG_DELETE_SUCCESS
    } = OTagCategories.Actions
    switch (action.type) {
        case LIST_RECEIVED: return { ...state, list: action.data };
        case SHOW_TAG_CATEGORY_FORM: return { ...state, showTagCategoryForm: true, editedCategory: action.data }
        case HIDE_TAG_CATEGORY_FORM: return { ...state, showTagCategoryForm: false, editedCategory: undefined }
        case POST_ITEM_SUCCESS: return { ...state, list: (state.list) && [...state.list, ...action.data] }
        case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE') }
        case REORDER_TAGS_LIST: return { ...state, list: action.data }
        case SHOW_TAG_FORM: return { ...state, showtagForm: true, editedtag: action.data, category: action.additionalDispatchData }
        case HIDE_TAG_FORM: return { ...state, showtagForm: false, editedtag: undefined }
        case TAG_CATEGORY_DELETE_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'DELETE') }
        case MULTIPLE_TAG_DELETE_SUCCESS: return { ...state, list: action.data }
        default: return state
    }
}