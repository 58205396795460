import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../../RootReducer';
import { TDestinationPackState } from '../../../../Models/DestinationPack/@types';
import { TUser, TUserState } from '../../../../Models/User/@types';
import CitiesCardList from '../../../../Components/Cards/CardLists/CitiesCardList';
import {
    createStyles,
    makeStyles,
    Theme,
    LinearProgress,
    Typography,
} from '@material-ui/core';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import CitiesForm from '../../../../Components/Forms/CitiesForm';
import { TCity } from '../../../../Models/City/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { ODestinationTransaction } from '../../../../Models/DestinationTransactions';
import { OUser } from '../../../../Models/User';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../Constants/vars';

// export type TStateProps = Pick<TDestinationPackState, 'details'>

function Cities(props) {
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>();
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [showForm, setShowForm] = useState(false);

    // const { details } = useSelector<TReduxStore, TStateProps>(({ DestinationPack: details }) => (details));
    const { userListCities, user } = useSelector<
        TReduxStore,
        Pick<TUserState, 'userListCities' | 'user'>
    >(({ User }) => ({
        user: User.user,
        userListCities: (User.userListCities || []).filter(
            (u) => !!u.destination
        ),
    }));

    const getData = async () => {
        if (!user) return;
        setLoading(true);
        await dispatch(OUser.getSubscribedDestinations(user.id));
        setLoading(false);
    };

    const handleAddCities = () => {
        setShowForm(true);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    const onFormSubmit = async (newList: TCity[]) => {
        if (!user) return;
        const cities = filterExistingCitiesFromList(newList);
        console.log(newList, cities, 'newList onFormSubmit');
        await dispatch(
            ODestinationTransaction.multiUserMultiDestinationTransaction(
                cities.map((c) => c.id),
                [user?.id]
            )
        );
        closeForm();
    };

    const filterExistingCitiesFromList = (cities: TCity[]) => {
        const existingCityIds = userListCities?.map((c) => c.id);
        return (
            cities.filter((user) => !existingCityIds?.includes(user.id)) || []
        );
    };

    useEffect(() => {
        getData();
    }, [user]);

    const handlePageChange = () => {
        setPageNumber(pageNumber);
    };

    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddCities,
            helpText: 'Add Cities',
        },
    ];

    const renderExpiryDate = (city: TCity) => {
        const _subscription = userListCities?.find(
            (c) => c.destinationId === city.id
        );
        if (!_subscription) return null;
        return (
            <Typography variant="body2" color="textSecondary" component="span">
                Expires on: {moment(_subscription.endDate).format(DATE_FORMAT)}
            </Typography>
        );
    };

    return (
        <>
            <div className={classes.operationBtns}>
                <HeaderActionButtons items={headerItems} />
                <CitiesForm
                    cities={userListCities.map((u) => u.destination)}
                    heading={'Cities list'}
                    closeForm={closeForm}
                    open={showForm}
                    onFormSubmit={onFormSubmit}
                />
            </div>
            <div className={classes.wrapper}>
                {loading ? (
                    <LinearProgress />
                ) : (
                    <CitiesCardList
                        cities={userListCities.map((u) => u.destination)}
                        renderSubtitle={renderExpiryDate}
                        totalCount={
                            userListCities ? userListCities?.length || 0 : 0
                        }
                        handlePageChange={handlePageChange}
                        pageNumber={pageNumber}
                    />
                )}
            </div>
        </>
    );
}

const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        wrapper: {
            width: 924,
            [theme.breakpoints.down('md')]: {
                width: 640,
            },
            [theme.breakpoints.down('sm')]: {
                width: 324,
            },
        },
        operationBtns: {
            marginBottom: 25,
        },
    })
);
export default Cities;
