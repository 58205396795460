import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Paper } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TSettings } from '../../Models/Settings/@types';
import KeywordInput from '../../Components/Inputs/KeywordInput';
import { TConstant } from '../../Models/App/@types';
import { useDispatch } from 'react-redux';
import { TDispatch } from '../../Resources/GlobalTypes';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { OSettings } from '../../Models/Settings';
import { OApp } from '../../Models/App';

export interface PlaceTypeInputProps {
    placeTypeSetting?: TSettings;
}

const PlaceTypeInput: FC<PlaceTypeInputProps> = ({ placeTypeSetting }) => {
    const classes = useStyles();
    const [values, setValues] = useState<TConstant[]>([]);
    const settingsValue = placeTypeSetting?.value  as TConstant[];
    useEffect(() => {
        if (placeTypeSetting?.id) {
            setValues([...(settingsValue || [])]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsValue?.length]);
    const dispatch = useDispatch<TDispatch>();
    const onChange = (values: TConstant[]) => setValues(values);

    const onSave = useAsyncTask(async () => {
        if (placeTypeSetting?.id) {
            try {
                await dispatch(OSettings.patchItem(placeTypeSetting?.id, { value: values }));
                dispatch(OApp.showToast({ message: 'Place types updated successfully.' }));
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Place types update failed.', variant: 'error' }));
            }
        }
    });
    if (!placeTypeSetting) return <div />;
    return (
        <Paper className={classes.paper}>
            <KeywordInput
                containerClass={classes.container}
                onKeywordsUpdate={onChange}
                initialKeywords={settingsValue || []}
                textFieldProps={{
                    label: 'Place types',
                    placeholder: 'Type in your place type.',
                }}
            />
            <Box alignSelf="flex-end" mr={1.2}>
                <Button disabled={onSave.status === 'PROCESSING'} color="primary" size="small" variant="contained" onClick={onSave.run}>
                    Save
                </Button>
            </Box>
        </Paper>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            width: 500,
            margin: 'auto',
            marginTop: 100,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
        },
        container: {
            width: '100%',
        },
    });
});

export default PlaceTypeInput;
