import React, { FC, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Route, Switch, useParams } from 'react-router';
import SavedLists from '.';
import List from './List';
import { useDispatch } from 'react-redux';
import { getUserListDetail } from '../../../../Models/User/actions';
import dimensions from '../../../../Constants/dimensions';
import { OUser } from '../../../../Models/User';

export interface SavedListRouterProps {

}


export const USER_SAVE_LIST_BASE_URL = '/dashboard/users/:userId/saved-lists';

const SavedListRouter: FC<SavedListRouterProps> = (props) => {
    const classes = useStyles({});

    return (
        <div className={classes.root}>
            <Switch>
                <Route path={USER_SAVE_LIST_BASE_URL} exact component={SavedLists} />
                <Route path={`${USER_SAVE_LIST_BASE_URL}/:userListId`} component={SavedListItemRouter} />
            </Switch>
        </div>
    )
}

const SavedListItemRouter: FC = (props) => {
    const dispatch = useDispatch();
    const { userListId } = useParams<{ userListId: string }>()
    const { userId } = useParams<{ userId: string }>()

    useEffect(() => {
        dispatch(getUserListDetail(userId, userListId));
        return () => {
            dispatch({ type: OUser.Actions.CLEAR_USER_SAVED_LIST_DETAIL });
        }
    }, [])



    return (
        <>

            <Switch>
                <Route path={`${USER_SAVE_LIST_BASE_URL}/:userListId`} exact component={List} />
            </Switch>
        </>
    )
}
const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        width: dimensions.screenMaxWidth,
    }
}));

export default SavedListRouter;