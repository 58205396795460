import { Avatar, createStyles, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Lock, VerifiedUser } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import ListDashboard from '../../Components/Dashboard/ListDashboard';
import UserFilterMenu from '../../Components/Filters/UserFilter';
import { DEFAULT_USER_FILTER_OPTIONS } from '../../Constants/FilterConstants';
import UsersListSortMenu from '../../Features/Users/UsersListSortMenu';
import { TFilterConfig } from '../../Models/App/@types';
import { OCity } from '../../Models/City';
import { TCity } from '../../Models/City/@types';
import { OUser } from '../../Models/User';
import { TUser, TUserFilterConfig, TUserState } from '../../Models/User/@types';
import { UserModel } from '../../Models/User/actions';
import MuiTheme from '../../MuiTheme';
import { TDispatch } from '../../Resources/GlobalTypes';
import { TReduxStore } from '../../RootReducer';
import UserForm from './Forms/UserForm';
import Alist from './Tabs/A-List';
import Cities from './Tabs/Cities';
import { Details } from './Tabs/Details';
import Preferences from './Tabs/Preferences';
import Reviews from './Tabs/Reviews';
import Saved from './Tabs/Saved';
import SavedLists from './Tabs/SavedLists';
import SavedListRouter from './Tabs/SavedLists/SavedListRouter';
import Suggested from './Tabs/Suggested';

const BASE_URL = '/dashboard/users'

interface IStateProps extends Pick<TUserState, 'list' | 'totalCount' | 'user' | 'showMainForm'> {
    citiesList: TCity[]
}

interface IProps extends RouteComponentProps { }
const Users: FC<IProps> = () => {
    const [sortMenuEl, setSortMenuEl] = useState<HTMLElement>();
    const [sortFilter, setSortFilter] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<TUser[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const url = window.location.href;
    const urlArray = url.split('/');
    const userId = urlArray.length >= 6 ? urlArray[5] : '';
    const dispatch = useDispatch<TDispatch>();
    // const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>(

    // )
    const { list = [], totalCount = 0, user, showMainForm, citiesList } = useSelector<TReduxStore, IStateProps>(({ User, App }) => ({ list: User.list, totalCount: User.totalCount, user: User.user, showMainForm: User.showMainForm, citiesList: App.cityList }));
    const defaultCityList = citiesList.map(c => ({ name: c.name, value: c.id, isActive: false }))
    let filterInitialState: TUserFilterConfig = { ...DEFAULT_USER_FILTER_OPTIONS, cities: defaultCityList };

    const [filterConfig, setFilterConfig] = useState<TUserFilterConfig>(JSON.parse(JSON.stringify(filterInitialState)));


    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string; name: string; component: RouteProps['component'] }> = [
        {
            name: 'Details',
            url: 'details',
            component: Details,
        },
        {
            url: 'preferences',
            name: 'Preferences - ' + (user ? user.tagIds.length || 0 : 0),
            component: Preferences,
        },
        {
            name: 'Reviews - ' + (user ? user.counts.reviews || 0 : 0),
            url: 'reviews',
            component: Reviews,
        },
        // {
        //     name: 'A-List - ' + (user ? user.counts.favourites || 0 : 0),
        //     url: 'alist',
        //     component: Alist
        // },
        {
            name: 'Saved Lists',
            url: 'saved-lists',
            component: SavedListRouter,
        },
        {
            name: 'Suggested - ' + (user ? user.counts.suggested || 0 : 0),
            url: 'suggested',
            component: Suggested
        },
        {
            name: 'Cities - ' + (user?.subscribedDestinationIds?.length || 0),
            url: 'cities',
            component: Cities
        }
    ]


    const getData = (filterConfig: TUserFilterConfig = JSON.parse(JSON.stringify(filterInitialState)), _sortFilter?: any) => {
        setLoading(true);
        // const filter = OApp.parseBaseFilter(filterConfig, 'isRecommender')
        const filter = UserModel.parseUserFilters(filterConfig);
        console.log(filterConfig, filter);
        dispatch(
            OUser.getItemsList({ filter: { limit: 20, skip: 20 * (pageNumber - 1), order: 'name ASC', ...filter, ...(_sortFilter || sortFilter) } })
        );
        pageNumber === 1 && dispatch(OUser.getActivity(OUser.Activities.COUNT, { ...filter, ...sortFilter }));
        setLoading(false);
    };

    const getDetails = async () => {
        try {
            await dispatch(OUser.getActivity(OUser.Activities.PROFILE, { id: userId }));
        } catch (err) { }
    };


    useEffect(() => {
        if (userId)
            getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const handleSearchResult = (result: Array<TUser>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    };

    useEffect(() => {
        getData(filterConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    const classes = useStyles();

    const renderListItem = useCallback((user: TUser): ReactElement => {
        const isActive = (match: any, location: any) => {
            const regex = new RegExp(`${BASE_URL}/${user.id}/`, 'ig');
            return regex.test(location.pathname);
        }

        return (
            <span key={user.id}>
                <NavLink className={classes.listItem} isActive={isActive} to={`${BASE_URL}/${user.id}/details`} activeClassName={"selected"} >
                    <ListItem button >
                        <ListItemAvatar>
                            <Avatar alt={user.name} src={user._profile_image?.url} />
                        </ListItemAvatar>
                        <ListItemText primary={`${user.name || ''}`} secondary={user.email} />
                        {user.emailVerified ?
                            <ListItemSecondaryAction ><VerifiedUser fontSize='small' className={classes.secondaryAction} /></ListItemSecondaryAction>
                            : null
                        }
                    </ListItem>
                    <Divider className={classes.divider} />
                </NavLink>
            </span>
        )
    }, [classes]);

    const handleSortClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSortMenuEl(e.currentTarget);
    };

    const handleSortingChange = (filter: any) => {
        // dispatch(OUser.getItemsList({ filter: { limit: 20, skip: 20 * (pageNumber - 1), ...filter } }));
        setSortFilter(filter);
        if (pageNumber === 1) getData(filterConfig, filter);
        else setPageNumber(1);
    };
    const handleFilterChange = (item: { name: string; isActive: boolean }) => {
        console.log('filter is changed');
        const updatedFilter = filterConfig.baseConfig.map((i) => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        });
        // setFilterConfig([...updatedFilter])
    };
    const handleFilterSubmit = () => {
        if (pageNumber === 1) getData(filterConfig);
        else setPageNumber(1);
    };
    const handleFilterReset = () => {
        setFilterConfig(JSON.parse(JSON.stringify({ ...DEFAULT_USER_FILTER_OPTIONS, cities: defaultCityList })));
        if (pageNumber === 1) getData();
        else setPageNumber(1);
    };


    const handleBaseConfigChange = (item: TFilterConfig) => {
        const filter = filterConfig.baseConfig.map((i) => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        });
        setFilterConfig({ ...filterConfig, baseConfig: filter });
    };

    return (
        <div>
            <ListDashboard
                baseUrl={BASE_URL}
                csvConfig={{
                    modelName: 'user',
                }}
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                filterConfig={[]}
                filterComponent={
                    <UserFilterMenu
                        filterConfig={filterConfig}
                        handleFilterChange={setFilterConfig}
                    />
                }
                onFilterChange={handleFilterChange}
                onFilterReset={handleFilterReset}
                onFilterSubmit={handleFilterSubmit}
                itemsCountPerPage={20}
                onPageChange={setPageNumber}
                currentPageNumber={pageNumber}
                listProps={{
                    searchProps: {
                        type: 'User',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}
                listActionButtonConfig={[
                    {
                        icon: 'sort',
                        id: 'sort',
                        label: 'Sort',
                        onClick: handleSortClick,
                    },
                ]}
            />
            {showMainForm ? <UserForm /> : null}
            <UsersListSortMenu anchorEl={sortMenuEl} onSortingChange={handleSortingChange} handleCloseSortMenu={() => setSortMenuEl(undefined)} />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            backgroundColor: theme.palette.grey['500'],
        },
        verifiedIcon: {
            color: MuiTheme.palette.success.main,
        },
        listItem: {
            color: theme.palette.text.primary,
            '&.selected': {
                background: fade(theme.palette.primary.main, 0.4),
                display: 'block',
            },
        },
        subDashboardContainer: {
            marginLeft: 250,
        },
        headLinks: {
            marginBottom: 50,
        },
        secondaryAction: {
            color: theme.palette.success.main,
        }
    })
);

export default withRouter(Users);
