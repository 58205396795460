import { Avatar, Box, createStyles, Dialog, Fab, Icon, MobileStepper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useState } from 'react';
import { TPicture } from '../Resources/GlobalTypes';
export interface ImageCarouselProps {
    open: boolean
    images: TPicture[]
    currentIndex?: number
    closeDialog: () => void
}

const ImageCarousel: FC<ImageCarouselProps> = (props) => {
    const classes = useStyles();
    const { open = false, images = [], currentIndex = 0 } = props
    const [current, setCurrent] = useState(currentIndex);
    const onNext = () => {
        if (current < images.length - 1)
            setCurrent(current => current + 1)
        else
            setCurrent(0)
    }
    const onPrev = () => {
        if (current > 0)
            setCurrent(current => current - 1)
        else
            setCurrent(images.length - 1)
    }
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={props.closeDialog}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    position: 'fixed'
                },
            }}

        >
            <>
                <Fab
                    style={{ color: 'white', boxShadow: 'none', top: 20, right: 20, position: 'absolute' }}
                    size={'small'}
                    onClick={props.closeDialog}>

                    <i className="material-icons">close</i>
                </Fab>
                <Box width='70vw' height='60vh' margin='auto' display="flex" flexDirection='column' alignItems='center' justifyContent='space-between'>
                    <Box width='70vw' height='90%' margin={'20px'} display='flex' alignItems='center' justifyContent='space-between'>
                        <Box><Icon className={classes.icon} fontSize='default' onClick={onPrev}>
                            navigate_before
                </Icon></Box>

                        {images.map((img, ind) => {
                            if (ind !== current) return <></>
                            return (
                                <Box key={ind} height='100%' maxWidth='90%' minWidth='90%'>
                                    <Avatar
                                        className={classes.avatar}
                                        src={img.url}
                                        alt={''}
                                    >

                                    </Avatar>
                                </Box>)
                        })}
                        <Box>
                            <Icon className={classes.icon} fontSize='default' onClick={onNext}>
                                navigate_next
                </Icon>
                        </Box>
                    </Box>
                    <MobileStepper
                        variant="dots"
                        steps={images.length}
                        position="static"
                        activeStep={current}
                        className={classes.root}
                        nextButton={<></>}
                        backButton={<></>}
                    />
                </Box>
            </>
        </Dialog>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        avatar: {
            height: '100%',
            width: '100%',
            borderRadius: 0
        },
        icon: {
            color: 'white',
            cursor: 'pointer'
        },
        root: {
            maxWidth: 400,
            flexGrow: 1,
            background: 'white'
        },
    }))
})

export default ImageCarousel