import { Paper, Theme, Typography, Divider, Chip, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import TypographyWMention from '../../../../Components/TypographyWMention';
import dimensions from '../../../../Constants/dimensions';
import { renderHtmlStr } from '../../../../Features/Mention';
import { OApp } from '../../../../Models/App';
import { ODish } from '../../../../Models/Dish';
import { TDish, TDishState } from '../../../../Models/Dish/@types';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

const Details: FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {

    const dishId = match.params['id'];

    const dispatch = useDispatch<TDispatch>();

    const { details } = useSelector<TReduxStore, Pick<TDishState, 'details'>>(({ Dish }) => ({ details: Dish.details }));

    const selectedFoodDrink = details;

    const classes = useStyles();

    const textUrl = `${DOMAIN_URL}/dishes/${details?.slug || dishId}`;

    const setField = async (field: keyof TDish, value: any) => {
        try {
            if (selectedFoodDrink)
                await dispatch(ODish.patchItem(selectedFoodDrink.id, { [field]: value }, undefined, undefined, { [field]: value }))
        } catch (error) {

        }
    }


    const handleEdit = () => {
        dispatch({ type: ODish.Actions.SHOW_DISH_DETAIL_FORM, data: details })
    }
    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(ODish.deleteItem(details.id, {}, undefined, details))
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'FoodDrink' } })
            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }))
            }
            if (history) {
                history.push('/dashboard/dishes')
            }
        }
    }
    const handlePublish = async () => {
        if (details) {
            try {
                const flag = !details.isPublished;
                await dispatch(ODish.postItemActivity(details.id, ODish.Activities.PUBLISH, {}, { publish: !details.isPublished }))
                dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
            }
            catch (err) {
                dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))
                console.log('Dish publish', err)
            }
        }
    }


    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this dish',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this dish?',
            handleDelete: handleDelete,
            helpText: 'Delete this dish',
        },
        {
            type: 'icon',
            icon: (details && details.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (details && details.isPublished) ? 'secondary' : 'primary',
            helpText: (details && details.isPublished) ? 'Unpublish this dish' : 'Publish this dish',
        },
        {
            type: 'copy_button',
            textUrl: textUrl,
        }
    ]
    const [notes, setNotes] = useState<string>('')
    useEffect(() => {
        if (details) {
            setNotes(details.notes || '')
        }
    }, [details])
    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setNotes(e.target.value)
    }
    const saveNotes = async () => {
        try {
            await dispatch(ODish.patchItem(selectedFoodDrink!.id, { notes }, {}, undefined, { ...selectedFoodDrink, notes }))
            dispatch(OApp.showToast({ message: 'Note edit Successful', variant: 'success' }))
        } catch (er) {
            dispatch(OApp.showToast({ message: 'Note edit failed', variant: 'error' }))

        }

    }
    return (
        <div className={classes.foodDrinkDetail}>
            {
                selectedFoodDrink &&
                <>

                    <HeaderActionButtons items={headerItems} />
                    <Paper className={classes.paper} style={{ padding: 0 }}>
                        <textarea
                            placeholder={"Add a note"}
                            value={notes}
                            onChange={handleNoteChange}
                            className={classes.noteField}

                        />
                        {((selectedFoodDrink.notes || '') !== notes) && <Chip className={classes.addNoteButton} color={'primary'} label={"SAVE"} onClick={saveNotes}></Chip>}
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box display='flex' flexDirection='column' mb={2} ml={0.5}>
                            <FormControlLabel
                                onClick={e => setField('newlyAdded', !selectedFoodDrink?.newlyAdded)}
                                label='Newly added'
                                control={<Checkbox color='primary' checked={!!selectedFoodDrink?.newlyAdded} />}
                            />
                            <FormControlLabel
                                onClick={e => setField('recentlyUpdated', !selectedFoodDrink?.recentlyUpdated)}
                                label='Recently updated'
                                control={<Checkbox color='primary' checked={!!selectedFoodDrink?.recentlyUpdated} />}
                            />
                        </Box>
                        <div>

                            <Typography variant="h4" >{selectedFoodDrink.name}</Typography>

                            {
                                selectedFoodDrink.shortDescription ?
                                    <>
                                        <Divider style={{ margin: '16px 0' }} />
                                        <Typography variant='subtitle1' >Short Description</Typography>
                                        <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>
                                            {(selectedFoodDrink.shortDescription || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                        </TypographyWMention>
                                    </> : null
                            }

                            {selectedFoodDrink.description ? <> <Divider style={{ margin: '16px 0' }} />
                                <Typography variant='subtitle1' >Long Description</Typography>
                                <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr} >
                                    {(selectedFoodDrink.description || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                </TypographyWMention>
                            </>
                                : null}
                        </div>
                    </Paper>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    foodDrinkDetail: {
        position: 'relative',
        margin: '0px 40px',
    },
    paper: {
        width: dimensions.screenMaxWidth,
        margin: 'auto',
        padding: '30px',
        marginTop: 20,
        position: 'relative'
    },
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            margin: '0 10px'
        }
    },
    noteField: {
        width: '100%',
        background: 'transparent',
        minHeight: '100px',

        fontFamily: 'lato',
        fontSize: 14,
        border: '0px',
        outline: 'none',
        fontWeight: 'bold',
        maxWidth: '100%',
        padding: 20
    },
    addNoteButton: {
        position: 'absolute',
        right: '16px',
        bottom: '5px',
    },

}));

export default Details