import LoopFront from "loop-front";
import { TPicture } from "../../Resources/GlobalTypes";

export const changePictureCaption = async (model: string, modelId: string, pictureId: string, caption: string) => {
    try {
        const res: { data: TPicture } = await LoopFront.request({
            url: `${model}/${modelId}/pictures/${pictureId}`,
            method: 'PUT',
            data: {
                caption: caption
            }
        })
        return res.data
    } catch (err) {
        return undefined
    }
}
export const deletePictureFromItem = async (model: string, modelId: string, pictureId: string, type: 'portrait' | 'landscape' = 'portrait') => {
    // try {
    return await LoopFront.request({
        url: `${model}/${modelId}/${type === 'portrait' ? 'pictures' : 'landscapePictures'}/${pictureId}`,
        method: 'DELETE',

    })

    // } catch (err) {
    //     throw err
    // }
}