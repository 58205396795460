import { CircularProgress, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import CuisinesCardList from '../../../../Components/Cards/CardLists/CuisinesCardList';
import CuisinesForm from '../../../../Components/Forms/CuisinesForm';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import { ITEM_PER_PAGE_COUNT } from '../../../../Constants/FilterConstants';
import { OApp } from '../../../../Models/App';
import { TCuisine } from '../../../../Models/Cuisines/@types';
import { ODish } from '../../../../Models/Dish';
import { TDishState } from '../../../../Models/Dish/@types';
import { addEntityToDish, deleteEntityfromDish } from '../../../../Models/Dish/actions';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

export interface cuisineProps extends RouteComponentProps<{ id: string }> { }

const Cuisines: FC<cuisineProps> = (props) => {
    const classes = useStyles();
    const { match } = props;
    const dishId = match.params['id']
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>()
    const { details, cuisines = [] } = useSelector<TReduxStore, Pick<TDishState, 'details' | 'cuisines'>>(({ Dish }) => ({ details: Dish.details, cuisines: Dish.cuisines }))
    const textUrl = `${DOMAIN_URL}/dishes/${details?.slug || dishId}/cuisines`;
    const [pageNumber, setPageNumber] = useState(1);
    const [displayList, setDisplayList] = useState<TCuisine[]>([]);
    const handlePageChange = (pageNumber: number) => { setPageNumber(pageNumber) }
    const getData = async () => {
        setLoading(true);
        try {
            await dispatch(ODish.getEntityByItem(dishId, ODish.Entities.CUISINES, {
                filter: {
                    order: 'name ASC',
                    fields: ['name', 'isPublished', 'id']
                }
            }))
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }
    const deleteItem = async (item: TCuisine) => {
        try {
            await dispatch(deleteEntityfromDish(dishId, ODish.Entities.CUISINES, cuisines, item.id));
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (dishId) {
            getData();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dishId])
    useEffect(() => {
        if (dishId) {
            const low = ITEM_PER_PAGE_COUNT * (pageNumber - 1);
            const high = ITEM_PER_PAGE_COUNT * (pageNumber)
            const tList: TCuisine[] = (cuisines || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, cuisines.toString()])
    const handleAddCuisine = () => {
        setShowForm(true);
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddCuisine,
            helpText: 'Add cuisine'
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]
    const onFormSubmit = async (newList: TCuisine[]) => {
        try {
            await dispatch(addEntityToDish(dishId, newList, 'Cuisine'))

            setPageNumber(1);
            dispatch(OApp.showToast({ message: 'Cuisines Updated Successfully', variant: 'success' }))
        } catch (err) {
            dispatch(OApp.showToast({ message: 'Cuisines Update Unsuccessful', variant: 'error' }))
            console.log(err);
        }
        setShowForm(false);
    }
    const closeForm = () => {
        setShowForm(false)
    }
    return (
        <div className={classes.dishCuisine}>
            <HeaderActionButtons items={headerItems} />
            {showForm && <CuisinesForm cuisines={cuisines} onFormSubmit={onFormSubmit} open={showForm} closeForm={closeForm} />}
            <div className={classes.wrapper}>
                {
                    loading ? <CircularProgress /> :
                        <CuisinesCardList
                            list={displayList}
                            deleteItem={deleteItem}
                            handlePageChange={handlePageChange}
                            totalCount={details ? details.counts.cuisines || 0 : 0}
                            pageNumber={pageNumber}
                        />
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        dishCuisine: {
            position: 'relative',
            margin: '0px 40px',
        },
        wrapper: {
            width: 924,
            [theme.breakpoints.down('md')]: {
                width: 640,
            },
            [theme.breakpoints.down('sm')]: {
                width: 324,
            },
            margin: '40px auto'
        }
    }))
})

export default withRouter(Cuisines)