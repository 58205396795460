import LoopFront, { TAction } from 'loop-front';
import { ThunkAction } from 'redux-thunk';
import { TReduxStore } from '../../RootReducer';
import utilities from '../../Resources/Utils';
import { TUser } from './@types';
import { createBrowserHistory } from 'history';
import { TDispatch } from '../../Resources/GlobalTypes';
export const HISTORY = createBrowserHistory();
const CustomActions = {
    APP_USER_RECEIVED: 'APP_USER_RECEIVED',
    SINGLE_REVIEW_PUBLSIHED_OF_PLACE: 'SINGLE_REVIEW_PUBLISHED_OF_PLACE',
    CUSTOM_ENTITY_OF_USER_RECIEVED: 'CUSTOM_ENTITY_OF_USER_RECIEVED',
    SHOW_USER_FORM: 'SHOW_USER_FORM',
    HIDE_USER_FORM: 'HIDE_USER_FORM',
    SET_EMAIL_VERIFIED: 'SET_EMAIL_VERIFIED',
    USER_SAVED_LIST_ITEMS_FETCHED: 'USER_SAVED_LIST_ITEMS_FETCHED',
    CLEAR_USER_SAVED_LIST_ITEMS: 'CLEAR_USER_SAVED_LIST_ITEMS',
    USER_SAVED_LIST_DETAIL_FETCHED: 'USER_SAVED_LIST_DETAIL_FETCHED',
    CLEAR_USER_SAVED_LIST_DETAIL: 'CLEAR_USER_SAVED_LIST_DETAIL',
    CITY_SUBSCRIBE_USER_FETCH: 'CITY_SUBSCRIBE_USER_FETCH',
    GET_USERS_CITIES_LIST: 'GET_USERS_CITIES_LIST',
    GET_USER_SUBSCRIBED_CITIES: 'GET_USER_SUBSCRIBED_CITIES',
};

const CustomEntities = {
    REVIEWS: 'reviews',
    FAVOURITEDBY: 'favourited-by',
    WISHEDBY: 'wished-by',
    SUGGESTED: 'suggested',
    SAVED_LISTS: 'userLists',
};

const CustomActivities = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    ME: 'me',
    DETAILS: 'details',
    PROFILE: 'profile',
};

class User extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
    constructor() {
        super('users', CustomActions, CustomEntities, CustomActivities);
    }

    getSubscribedDestinations = (userId: string) => async (dispatch: TDispatch) => {
        const { data } = await LoopFront.request({
            url: `${this.ModelName}/${userId}/subscribed-destinations`,
            params: {
                userId,
                filter: {
                    include: {
                        relation: 'destination',
                        scope: {
                            fields: ['id', 'name']
                        }
                    }
                }
            }
        })
        dispatch({
            type: this.Actions.GET_USER_SUBSCRIBED_CITIES,
            data
        })
    }

    logout = (): ThunkAction<void, TReduxStore, {}, TAction> => async (dispatch, getState) => {
        HISTORY.push('/');
        this.requestPostActivity(this.Activities.LOGOUT);
        dispatch({ type: 'LOGOUT' });
        utilities.clearCookies();
        LoopFront.setAuthHeader('');
    };

    getUserPicture = (user: TUser): string =>
        (user._profile_image || {}).thumbnail ||
        'https://cdn.accounts.autodesk.com/content/identity/28.0.341.9220642/z/Content/images/icons/user_X176.png';

    adminVerifyEmail = (user: TUser, verify: boolean) => async (dispatch: TDispatch) => {
        const { data } = await LoopFront.request({
            url: `/users/admin-verify-email`,
            method: 'PATCH',
            data: {
                email: user.email,
                verify,
            },
        });
        if (data?.count) dispatch({ type: this.Actions.SET_EMAIL_VERIFIED, data: { ...user, emailVerified: verify } });
        return data;
    };
}

export const OUser = new User();
