import { Box, createStyles, Drawer, MenuItem, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, RouteProps, withRouter } from 'react-router';
import { NavLink, Switch } from 'react-router-dom';
import Dashboard from '.';
import { OApp } from '../../Models/App';
import { OUser } from '../../Models/User';
import { TDispatch } from '../../Resources/GlobalTypes';
import Cities from '../Cities';
import Coupons from '../Coupons';
import Cuisines from '../Cuisine';
import Dishes from '../Dishes';
import Explore from '../Explore';
import Landmarks from '../Landmarks';
import Places from '../Places';
import Tags from '../Tags';
import Users from '../Users';
import logo from '../../assets/logo.png'
import { TAppState, TSummary } from '../../Models/App/@types';
import { TReduxStore } from '../../RootReducer';
import Settings from '../Settings';
import { OSettings } from '../../Models/Settings';
import Home from '../Home';
import UserReview from '../UserReview';
import Eatineraries from '../Eatineraries';
import Quotes from '../Quotes';
import DestinationPack from '../DestinationPacks';

// eslint-disable-next-line
interface IProps extends RouteComponentProps { }

const PRIMARY_DRAWER_WIDTH = 150;

const MENU_ITEM_NAV_LINKS: Array<{ url: string, summaryKey?: keyof TSummary, name: string, component: RouteProps['component'], exact?: RouteProps['exact'] }> = [
    {
        name: 'Dashboard',
        url: '',
        component: Dashboard,
        exact: true
    },
    {
        name: 'Home',
        url: 'home',
        component: Home,
    },
    {
        name: 'Cities',
        url: 'cities',
        component: Cities,
        summaryKey: 'Destination'
    },
    {
        name: 'Places',
        url: 'places',
        component: Places,
        summaryKey: 'Establishment'

    },
    {
        name: 'Dishes',
        url: 'dishes',
        component: Dishes,
        summaryKey: 'FoodDrink'

    },
    {
        name: 'Cuisines',
        url: 'cuisines',
        component: Cuisines,
        summaryKey: 'Cuisine'

    },
    {
        name: 'Landmarks',
        url: 'landmarks',
        component: Landmarks,
        summaryKey: 'Landmark'
    },
    {
        name: 'Explore',
        url: 'explore',
        component: Explore,
        summaryKey: 'List'

    },
    {
        name: 'Eatineraries',
        url: 'eatineraries',
        component: Eatineraries,
        summaryKey: 'Eateries'

    },
    {
        name: 'Destination Packs',
        url: 'destination-packs',
        component: DestinationPack,

    },
    {
        name: 'Quotes',
        url: 'quotes',
        component: Quotes,
        summaryKey: 'Quote'
    },
    {
        name: 'Tags',
        url: 'tags',
        component: Tags,
        summaryKey: 'Tag',

    },
    {
        name: 'Coupons',
        url: 'coupons',
        summaryKey: 'Coupon',
        component: Coupons
    },
    {
        name: 'User',
        url: 'users',
        summaryKey: 'User',
        component: Users
    },
    {
        name: 'Reviews',
        url: 'reviews',
        component: UserReview
    },
    {
        name: 'Settings',
        url: 'settings',
        component: Settings
    }
]

const DashboardBody: FC<IProps> = ({ match, location, history }) => {
    const { summary } = useSelector<TReduxStore, Pick<TAppState, 'summary'>>(({ App }) => ({ summary: App.summary }))
    const classes = useStyles();

    const dispatch = useDispatch<TDispatch>();

    const logout = () => {
        history.push('/');
        dispatch(OUser.logout());
    }

    const getData = async () => {
        await dispatch(OApp.getActivity(OApp.Activities.SUMMARY));
        await dispatch(OApp.getActivity(OApp.Activities.CONSTANTS));
        await dispatch(OSettings.getItemsList());
    }


    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const matchUrl = match.url;
    return (
        <Box overflow={'auto'}   >
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.primaryPanelPaper,
                }}
                open={true}
            ><NavLink exact to={matchUrl}>
                    <div className={classes.logoContainer} >
                        <img alt='mamakoo-logo' className={classes.logo} src={logo} />
                    </div>
                </NavLink>
                <div className={classes.primaryPanelUpperSection} >
                    {
                        MENU_ITEM_NAV_LINKS.map(menu => {
                            let selectedClass = menu.url === ((location.pathname.split('/')[2])) ? true : null;
                            return (
                                <NavLink key={menu.url} exact to={`${matchUrl}` + (menu.url ? `/${menu.url}` : '')}>
                                    <MenuItem className={selectedClass ? classes.selectedClass : ''} >
                                        {menu.name + (menu.summaryKey ? ` - ${summary ? summary[menu.summaryKey].total : 0}` : '')}
                                    </MenuItem>
                                </NavLink>
                            );
                        })
                    }
                </div>
                {
                    <div className={classes.primaryPanelLowerSection} >

                        <MenuItem onClick={logout}>
                            Sign Out
                        </MenuItem>
                    </div>
                }
            </Drawer>
            <div className={classes.dashboardContent}>
                <Switch>
                    {
                        MENU_ITEM_NAV_LINKS.map(nav => {
                            return <Route key={nav.url} exact={nav.exact} path={`${matchUrl}/${nav.url}`} component={nav.component} />
                        })
                    }
                </Switch>
            </div>
        </Box >
    )
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    primaryPanelPaper: {
        backgroundColor: theme.palette.grey['100'],
        width: PRIMARY_DRAWER_WIDTH,
        '& > div li': {
            color: 'white',
            fontSize: '14px',
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: '32px',
        }
    },
    primaryPanelUpperSection: {
        // minHeight: 400
    },
    primaryPanelLowerSection: {
        marginTop: 60
    },
    dashboardContent: {
        marginLeft: '150px',


    },
    MenuItem: {
        padding: 0,
        height: 30,
        paddingLeft: 16,
    },
    selectedClass: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    },
    logoContainer: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        minHeight: 50,
        marginBottom: 30,
        transition: '500ms',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    },
    logo: {
        width: '80%',
        margin: 'auto'
    },
}))

export default withRouter(DashboardBody);