import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography, ListSubheader } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CloseSharp } from '@material-ui/icons';
import React, { FC } from 'react';
import { TPlace } from '../../../Models/Place/@types';

export interface SelectedListProps {
    removePlace: (id: string) => void
    selectedList: TPlace[]
}

const SelectedList: FC<SelectedListProps> = (props) => {
    const classes = useStyles();
    const { removePlace, selectedList } = props;
    const list = selectedList
    const onRemove = (id: string) => {
        removePlace(id);
    }
    return (
        <Paper className={classes.paper}>
            <List dense >
                <ListSubheader className={classes.header} >
                    <Typography variant='body2'>{`${list.length} places selected`}</Typography>
                </ListSubheader>
                {
                    list.map((it, index) => {
                        return (
                            <ListItem dense key={index}>
                                <ListItemText primary={it.name} secondary={it.city + '\t' + it.status} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" size='small' onClick={() => onRemove(it.id)}>
                                        <CloseSharp fontSize='small' />
                                    </IconButton>
                                </ListItemSecondaryAction>

                            </ListItem>
                        )
                    })
                }
            </List>
        </Paper>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: 400,
            borderRadius: 0,
            overflowY: 'scroll',
        },
        header: {
            backgroundColor: '#fff',
            padding: 12
        },
    }))
})

export default SelectedList