import React, { FC, useEffect, useState } from 'react'
import { Box, Chip, Paper, Typography } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';
import { TTag, TTagCategoriesState } from '../../../Models/Tags/@types';
import { OTagCategories } from '../../../Models/Tags';
import utilities from '../../../Resources/Utils';
import { TExploreFilter } from '.';

export interface TagFiltersProps {
    onChange: (name: keyof TExploreFilter, value: string[]) => void
}

const TagFilters: FC<TagFiltersProps> = (props) => {
    const classes = useStyles();
    const { onChange } = props;
    const dispatch = useDispatch<TDispatch>();
    const [selectedList, setSelectedList] = useState<string[]>([])
    const { list = [], } = useSelector<TReduxStore, Pick<TTagCategoriesState, 'list'>>(({ TagCategories }) => ({ list: TagCategories.list, }));

    const getData = async () => {
        await dispatch(OTagCategories.getItemsList({ filter: { order: 'order ASC', include: 'tags' } }))
    }
    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onClick = (id: string) => {
        let v = utilities.toggleItemFromList(selectedList, id, '', (a, b) => a === b);
        onChange('tagIds', v);
        setSelectedList([...v])
    }
    return (
        <Paper className={classes.paper}>

            {list.map((item, index) => (
                <TagCategoryContainer
                    tags={item.tags || []}
                    key={index}
                    title={item.name}
                    selectedList={selectedList}
                    onClick={onClick}
                />
            ))}
        </Paper>
    )
}

export interface IProps {
    title: string
    tags: TTag[]
    onClick: (id: string) => void
    selectedList: string[]
}
const TagCategoryContainer: FC<IProps> = (props) => {
    const classes = useStyles();
    const { title, tags, selectedList, onClick } = props
    if (!tags.length) {
        return <div />
    }
    return (
        <Box className={classes.tagPaper}>
            <Box width='100%' display='flex' justifyContent='space-between' >
                <Typography variant='body1' align='left'>{title}</Typography>
            </Box>
            <div className={classes.tagContainer}>
                {
                    (tags).map((item, index) => {
                        return <Chip
                            className={classes.tagChip}
                            label={item.name}
                            key={index}
                            color={selectedList.findIndex(i => i === item.id) > -1 ? 'primary' : 'default'}
                            onClick={() => { onClick(item.id) }}
                        />
                    })
                }
            </div>
        </Box>
    )
}
const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: 450,
            padding: theme.spacing(2, 1.5),
            margin: '40px auto',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between'
        },
        tagPaper: {
            width: '100%',
            padding: '20px 18px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
        },
        tagContainer: {
            display: 'flex',
            marginTop: 20,
            flexWrap: 'wrap',
            width: '100%',
            height: 'auto'
        },
        tagChip: {

            margin: '5px'
        }
    }))
})

export default TagFilters