import LoopFront from "loop-front";

export const CustomEntities = {
    CUISINES: 'cuisines',
    FOODDRINKS: 'foodDrinks',
    REVIEWS: 'get-reviews',
    TAGS: 'tags',
    FAVOURITERS: 'favouriters',
    WISHERS: 'wishers'
}
export const CustomActivity = {
    FAVOURITED: 'favourited-by',
    WISHED: 'wished-by',
    PUBLISH: 'publish',
    DETAILS: 'details'
}

export const CustomActions = {
    SHOW_PLACE_DETAIL_FORM: 'SHOW_PLACE_DETAIL_FORM',
    HIDE_PLACE_DETAIL_FORM: 'HIDE_PLACE_DETAIL_FORM',
    PUT_DISHES_TO_PLACE_SUCCESS: 'PUT_FOODDRINK_TO_PLACE_SUCCESS',
    PUT_CUISINES_TO_PLACE_SUCCESS: 'PUT_CUISINE_TO_PLACE_SUCCESS',
    DELETE_CUISINE_FROM_PLACE_SUCCESS: 'DELETE_CUISINES_FROM_PLACE_SUCCESS',
    DELETE_DISH_FROM_PLACE_SUCCESS: 'DELETE_FOODDRINKS_FROM_PLACE_SUCCESS',
    DELETE_TAG_FROM_PLACE_SUCCESS: 'DELETE_TAGS_FROM_PLACE_SUCCESS',
    SINGLE_PLACE_DELETED: 'SINGLE_PLACE_DELETED',
    GET_REVIEWS_TO_PLACE_SUCCESS: 'GET-REVIEWS_TO_PLACE_SUCCESS',
    SINGLE_REVIEW_PUBLSIHED_OF_PLACE: 'SINGLE_REVIEW_PUBLISHED_OF_PLACE',
    SINGLE_TAG_REMOVED_FROM_PLACE: 'SINGLE_TAG_REMOVED-FROM_PLACE',
    PLACE_SUGGESTIONS_UPDATED: 'PLACE_SUGGESTIONS_UPDATED'
}

class Place extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity>{
    constructor() {
        super('establishments', CustomActions, CustomEntities, CustomActivity);
    }
}

export const OPlace = new Place();