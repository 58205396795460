import React, { useEffect, useState } from 'react'
import { Box, Card, CardActionArea, createStyles, Fab, Grid, Icon, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core'
import FileInput from '../../../../Components/FileInput'
import { OAsset } from '../../../../Models/Asset'
import { useDispatch, useSelector } from 'react-redux'
import { OHomeSection } from '../../../../Models/HomeSection'
import { TReduxStore } from '../../../../RootReducer'
import { THomeSectionState } from '../../../../Models/HomeSection/@types'
import ReorderableList from '../../../../Components/ReorderableList'
import { TAsset } from '../../../../Models/Asset/@types'
import Loader from '../../../../Components/Loader'
import DeleteButton from '../../../../Components/Buttons/DeleteButton'

interface IVideoProps { }
export const VIDEO_SLUG = 'hero_videos';

const Videos: React.FC<IVideoProps> = (props) => {

    const classes = useStyles(props)
    const dispatch = useDispatch();
    const [list, setList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const { sections, initialized, videoList } = useSelector<TReduxStore, Pick<THomeSectionState, 'sections' | 'initialized' | 'videoList'>>((
        { Home: { sections, initialized, videoList } }
    ) => ({ sections, initialized, videoList }))
    const section = sections.find(s => s.slug === VIDEO_SLUG);
    
    useEffect(() => {
        if (initialized) {
            if (!section) return;
            dispatch(OHomeSection.getEntityByItem(section.id, OHomeSection.Entities.sectionEntity, { filter: { include: ['subject'] } }, undefined, { slug: section.slug }))
        }
    }, [initialized])

    useEffect(() => {
        setList(videoList.map(l => ({ doc_type: l.subjectType, sectionId: l.id,  ...l.subject })))
    }, [videoList])

    console.log('list', list)
    
    const uploadFiles = async (files: any[]) => {
        if (!section) return;
        setLoading(true);
        try {
            const formData = new FormData();
            files.forEach(file => {
                formData.append(file.name, file.file);
            })
            const assets = await OAsset.upload(formData)
            dispatch(OHomeSection.updateSections(section.id, list, [...list, ...assets.map(a => ({ doc_type: 'Asset', ...a }))]))
        } catch (error) {
            // TODO: handle error
        }
        setLoading(false);
    }

    const handleReorder = (updatedList: any[], movedItem: any) => {
        if (!section) return;
        const newOrder = updatedList.findIndex(x => x.id === movedItem.id);
        if (newOrder === -1) return;
        dispatch(OHomeSection.reorderItem(section.id, movedItem.sectionId, newOrder + 1))
        setList(updatedList);
    }

    const handleRemove = (entitySectionId: string) => {
        if (!section) return;
        dispatch(OHomeSection.addRemove(section.id, [], [entitySectionId]))
    }


    const renderList = (item: any, draghandleProps: any) => {
        return (
            <Card >
                <Box display="flex" justifyContent="center" alignItems="center" height={180}>
                    <video width="100%" height="100%" controls>
                        <source src={item?.url} type="video/mp4" />
                    </video>
                </Box>
                <Box display='flex' >
                    {draghandleProps ? (
                        <div
                            id={`drag-${item.id}`}
                            style={{ top:  5, right: '-40px', position: 'absolute' }}
                            className={'dragHandle'} {...draghandleProps}>
                            <Fab color='inherit' size={"small"} className={classes.dragIcon}>
                                <Icon fontSize='small' color='primary' className={'material-icons'} >unfold_more</Icon>
                            </Fab>
                        </div>
                    ) : null}
                    <DeleteButton backgroundColor='#fff' iconColor='#ff5400'  alertMessage='Are you sure you want to delete this video?' handleDelete={() => handleRemove(item.sectionId)} />
                </Box>
            </Card>
        )
    }


    return (
        <div className={classes.root}>
            {loading && <Loader overlayed/>}
            <Box position="sticky" top={0} display='flex' justifyContent='center'>
                <Tooltip title="Add videos">
                    <Fab size="small" color={'inherit'}>
                        <Icon color={'action'} className={'material-icons'}>add</Icon>
                        <FileInput
                            accept={"video/*"}
                            multiple={true}
                            onDone={uploadFiles}
                        />
                    </Fab>
                </Tooltip>
            </Box>
            <Box my={5}>
                <ReorderableList<any>
                    handleUpdateListOrder={handleReorder}
                    list={list}
                    renderItem={renderList}
                />
            </Box>
        </div >
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        maxWidth: 420,
        margin: '0 auto',
        paddingBottom: 100
    },
    dragIcon: {
        height: 35,
        width: 35
    }
}))

export default Videos
