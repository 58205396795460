import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    Icon,
    IconButton,
    makeStyles,
    Slide,
    Theme,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { SlideProps } from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import clsx from 'clsx';
import React, { FC } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide {...(props as SlideProps)} direction="up" ref={ref} />;
});

export interface FormDialogProps extends DialogProps {
    onSubmit: () => void;
    heading: string;
    buttonText?: string;
    loading?: boolean;
    containerClass?: string;
    onClose?: () => void;
}

const FormDialog: FC<FormDialogProps> = (props) => {
    const classes = useStyles({});

    const { loading, onSubmit, buttonText, heading, children, containerClass, ...dialogProps } = props;

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            {...dialogProps}
            PaperProps={{
                className: classes.paper,
            }}
        >
            <ValidatorForm onSubmit={onSubmit}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton color={'inherit'} onClick={props.onClose}>
                            <Icon>close</Icon>
                        </IconButton>
                        <Typography variant="h6" className={classes.formTitle}>
                            {heading}
                        </Typography>
                        <Button color={'inherit'} type={'submit'} disabled={loading}>
                            {loading ? <CircularProgress color={'inherit'} size={32} thickness={5} /> : buttonText || 'SAVE'}
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box className={clsx(classes.container, containerClass)}>{children}</Box>
            </ValidatorForm>
        </Dialog>
    );
};

const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        formTitle: {
            flex: 1,
            color: 'white',
            marginLeft: 20,
            padding: '0 8px',
        },
        saveBtn: {
            color: 'white',
            fontWeight: theme.typography.fontWeightBold,
        },
        container: {
            maxWidth: 960,
            margin: '32px auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        paper: {
            backgroundColor: theme.palette.grey['500'],
        },
    })
);

export default FormDialog;
