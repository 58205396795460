import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import DishesCardList from '../../../../Components/Cards/CardLists/DishesCardList'
import DishesForm from '../../../../Components/Forms/DishesForm'
import HeaderActionButtons from '../../../../Components/HeaderActionButtons'
import { ITEM_PER_PAGE_COUNT } from '../../../../Constants/FilterConstants'
import { OApp } from '../../../../Models/App'
import { OCity } from '../../../../Models/City'
import { TCityState } from '../../../../Models/City/@types'
import { addEntityToCity, deleteEntityfromCity } from '../../../../Models/City/actions'
import { TDish } from '../../../../Models/Dish/@types'
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'


interface IProps extends RouteComponentProps<{ id: string }> {

}
const Dishes: React.FC<IProps> = ({ match, history }, props) => {
    const cityId = match.params['id'];
    const { details, dishes = [] } = useSelector<TReduxStore, Pick<TCityState, 'details' | 'dishes'>>(({ City }) => ({ details: City.details, dishes: City.dishes }))
    const classes = useStyles(props)
    const textUrl = `${DOMAIN_URL}/cities/${details?.slug || cityId}/dishes`;
    const dispatch = useDispatch<TDispatch>();
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [displayList, setDisplayList] = useState<TDish[]>([]);
    const getDishes = async () => {
        setLoading(true)
        await dispatch(OCity.getEntityByItem(cityId, OCity.Entities.FOODDRINKS, {
            filter: {
                order: 'name ASC',
                fields: ['name', 'isPublished', 'id']
            }
        }))
        setLoading(false)
    }


    useEffect(() => {
        if (cityId)
            getDishes();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId])
    useEffect(() => {
        if (cityId) {
            const low = ITEM_PER_PAGE_COUNT * (pageNumber - 1);
            const high = ITEM_PER_PAGE_COUNT * (pageNumber)
            const tList: TDish[] = (dishes || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, dishes.toString()])
    const handlePageChange = (newPage: number) => { setPageNumber(newPage) }
    const deleteItem = async (item: TDish) => {
        try {
            await dispatch(deleteEntityfromCity(cityId, OCity.Entities.FOODDRINKS, dishes, item.id));
        } catch (err) {
            console.log(err);

        }
    }
    const handleAddDishes = () => {
        console.log(dishes);
        setShowForm(true)
    }
    const onFormSubmit = async (newList: TDish[]) => {
        try {
            await dispatch(addEntityToCity(cityId, newList, 'FoodDrink'));
            setPageNumber(1);
            dispatch(OApp.showToast({ message: 'Dishes Added Successfully', variant: 'success' }))
        } catch (err) {
            console.log('Error', err)
            dispatch(OApp.showToast({ message: 'Dishes Add Unsuccessful', variant: 'error' }))
        }
        setShowForm(false)
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddDishes,
            helpText: 'Add Dishes available in this city',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]
    const closeForm = () => { setShowForm(false) }

    const updateIconicFoodDrinkIds = async (iconicFoodDrinkIds: Array<string>) => {
        try {
            let actionTaken = "";
            if ((details?.iconicFoodDrinkIds?.length || 0) > iconicFoodDrinkIds.length) {
                actionTaken = "removed";
            } else {
                actionTaken = "added";
            }
            await dispatch(OCity.patchItem(cityId, { iconicFoodDrinkIds: iconicFoodDrinkIds }))
            if (actionTaken === "added") {
                dispatch(OApp.showToast({ message: "Dish added into the iconic dishes list", variant: "success" }))
            } else if (actionTaken === "removed") {
                dispatch(OApp.showToast({ message: "Dish removed from the iconic dishes list", variant: "success" }))
            }
        } catch (err) {
            console.error(err)
            dispatch(OApp.showToast({ message: "Failed to toggle dish status", variant: 'error' }))
        }
    }

    return (
        <div className={classes.destinationDishes}>
            <div className={classes.operationBtns}>
                <HeaderActionButtons items={headerItems} />
                {showForm && <DishesForm open={showForm} closeForm={closeForm} dishes={dishes} onFormSubmit={onFormSubmit} />}
            </div>
            <div className={classes.wrapper}>
                {loading ? <CircularProgress />
                    : <>
                        <DishesCardList
                            list={displayList}
                            deleteItem={deleteItem}
                            pageNumber={pageNumber}
                            totalCount={(details ? (details.counts.foodDrinks || 0) : 0)}
                            handlePageChange={handlePageChange}
                            showCheckBoxToMakeIconic={true}
                            iconicFoodDrinkIds={details && details.iconicFoodDrinkIds ? details.iconicFoodDrinkIds : []}
                            handleCheckboxToggle={(item) => updateIconicFoodDrinkIds(item)}
                        />
                    </>
                }
            </div>
        </div >
    )
}


const useStyles = makeStyles<Theme>((theme) => createStyles({
    destinationDishes: {
        position: 'relative',
        margin: '0px 40px',
    },

    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        '& > button': {
            margin: '0 10px'
        },
        '& > div': {
            margin: '0 10px'
        }
    },
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        },
        margin: '40px auto'
    },


}))

export default Dishes


