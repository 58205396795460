import LoopFront from "loop-front";
import { TDispatch } from "../../Resources/GlobalTypes";
import { TAsset } from "./@types";


const CustomActions = {
    ITEMS_UPLOAD_SUCCESS: 'ITEMS_UPLOAD_SUCCESS'
}

const CustomEntities = {

}
const CustomActivities = {
    COUNT: 'count',
    PUBLISH: 'publish',
}



class Asset extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
    constructor() {
        super('assets', CustomActions, CustomEntities, CustomActivities);
    }
    
    async upload(formData: FormData) {
            const { data } = await LoopFront.request({
                url: this.ModelName + '/upload',
                method: 'post',
                data: formData
            });
            return data as TAsset[];
        }

}

export const OAsset = new Asset();