import * as _ from 'lodash';
import LoopFront from 'loop-front';
import { OUser } from '.';
import { DESTINATION_PACK_CITITES_LIMIT } from '../../Constants/FilterConstants';
import { TDispatch } from '../../Resources/GlobalTypes';
import { OApp } from '../App';
import { TFilterConfig } from '../App/@types';
import { OCity } from '../City';
import { TSubscription } from '../Subscription/@types';
import { TUser, TUserFilterConfig } from './@types';

const requestUserEntity = (
    userID: string,
    entity: string,
    pageNumber: number
) =>
    LoopFront.request({
        url: `establishments/${entity}/${userID}`,
        method: 'GET',
        params: { filter: { limit: 20, skip: 20 * (pageNumber - 1) } },
    });
const requestAddSubscription = (
    userId: string,
    subscription: Partial<TSubscription>
) =>
    LoopFront.request({
        url: `/Subscriptions/${userId}/add-subscription`,
        method: 'PATCH',
        data: {
            ...subscription,
        },
    });
const requestRemoveSubscription = (
    userId: string,
    subscriptionId: string,
    terminateImmediately: boolean = false
) =>
    LoopFront.request({
        url: `/Subscriptions/${subscriptionId}/users/${userId}/remove-subscription`,
        method: 'PATCH',
        data: {
            terminateImmediately,
        },
    });
// const requestUserList = (filter: object) => (
//     LoopFront.request({
//         url: 'users/admin-autocomplete',
//         params: {
//             filter,
//             term: ''
//         }
//     })
// )
export const UserModel = {
    updateUser: (id: string, user: Partial<TUser>) =>
        LoopFront.request({
            url: `users/${id}/update-user`,
            method: 'PATCH',
            data: {
                ...user,
            },
        }),
    parseUserFilters: (config: TUserFilterConfig) => {
        let where: Record<string, any> = {};

        let sec1Filter = _.mapValues(_.keyBy(config.type, 'name'), 'isActive');
        let cities = config.cities
            .filter((s) => s.isActive)
            .map((s) => s.value);

        if (sec1Filter.All != true) {
            Object.keys(sec1Filter)
                .filter((k) => sec1Filter[k] == true)
                .forEach((key) => {
                    switch (key) {
                        case 'Recommender':
                            where['isRecommender'] = true;
                            break;
                        case 'Non recommender':
                            where['isRecommender'] = false;
                            break;
                        default:
                            break;
                    }
                });
        }

        // if (sec2Filter.All != true) {
        //     Object.keys(sec2Filter)
        //         .filter((k) => sec2Filter[k] == true)
        //         .forEach((key) => {
        //             switch (key) {
        //                 case 'Basic':
        //                     where['subscription.isActive'] = false;
        //                     break;
        //                 case 'Insider':
        //                     where['subscription.packValue'] = 'world';
        //                     break;
        //                 case 'Trial':
        //                     where['subscription.isTrial'] = true;
        //                     break;
        //                 case 'Used to be insider':
        //                     where['subscription.isUsedToBe.isUsedToBeworld'] = true;
        //                     break;
        //                 default:
        //                     break;
        //             }
        //         });
        // }

        if (cities.length > 0) {
            where.subscribedDestinationIds = { inq: cities };
        }

        return { where };
    },
    getUserList: (filter: object) => {
        return async (dispatch: TDispatch) => {};
    },
    addUserSubscription: (
        user: TUser,
        subscription: Partial<TSubscription>
    ) => {
        return async (dispatch: TDispatch) => {
            let newSubscription = user.subscriptions;
            try {
                const { data } = await requestAddSubscription(
                    user.id,
                    subscription
                );
                if (newSubscription) {
                    if (newSubscription.currentSubscription.id) {
                        newSubscription = {
                            ...newSubscription,
                            upcomingSubscriptions: [
                                ...(newSubscription.upcomingSubscriptions ||
                                    []),
                                data,
                            ],
                        };
                    } else {
                        newSubscription = { currentSubscription: data };
                    }
                } else {
                    newSubscription = {
                        currentSubscription: { ...data, isActive: true },
                    };
                }
                dispatch({
                    type: OUser.Actions.ITEM_PATCH_SUCCESS,
                    data: { ...user, subscriptions: newSubscription },
                    additionalDispatchData: {
                        ...user,
                        subscriptions: newSubscription,
                    },
                });
                dispatch(
                    OApp.showToast({
                        message: 'Subscription added to user successfully',
                        variant: 'success',
                    })
                );
            } catch (err) {
                console.log(err);
                dispatch(
                    OApp.showToast({
                        message: 'Subscription addition failed',
                        variant: 'error',
                    })
                );
            }
        };
    },
    removeUserSubscription: (
        user: TUser,
        subscription: TSubscription,
        terminateImmediately: boolean
    ) => {
        return async (dispatch: TDispatch) => {
            try {
                await requestRemoveSubscription(
                    user.id,
                    subscription.id,
                    terminateImmediately
                );
                let newSubscription: TUser['subscriptions'] =
                    subscription.isActive
                        ? {
                              currentSubscription: terminateImmediately
                                  ? {}
                                  : subscription,
                              upcomingSubscriptions: [],
                          }
                        : {
                              currentSubscription:
                                  user.subscriptions?.currentSubscription || {},
                              upcomingSubscriptions:
                                  user.subscriptions?.upcomingSubscriptions?.filter(
                                      (i) => i.id !== subscription.id
                                  ),
                          };
                dispatch({
                    type: OUser.Actions.ITEM_PATCH_SUCCESS,
                    data: {
                        ...user,
                        subscriptions: newSubscription,
                    },
                    additionalDispatchData: {
                        ...user,
                        subscriptions: newSubscription,
                    },
                });
                dispatch(
                    OApp.showToast({
                        message: 'Subscription removed from users successfully',
                        variant: 'success',
                    })
                );
            } catch (err) {
                dispatch(
                    OApp.showToast({
                        message: 'Subscription removal failed',
                        variant: 'error',
                    })
                );
            }
        };
    },
    getUserEntity: (userId: string, entity: string, pageNumber: number) => {
        return async (dispatch: TDispatch) => {
            try {
                const res =
                    entity === 'suggested'
                        ? await requestSuggestedEntity(userId, pageNumber)
                        : await requestUserEntity(userId, entity, pageNumber);
                dispatch({
                    type: OUser.Actions.CUSTOM_ENTITY_OF_USER_RECIEVED,
                    entity: entity,
                    data: res.data,
                });
            } catch (err) {
                console.log('Request User entity error : ', err);
            }
        };
    },
};
const requestSuggestedEntity = (userId: string, pageNumber: number) =>
    LoopFront.request({
        url: `users/${userId}/suggestions`,
        method: 'GET',
        params: { filter: { limit: 20, skip: 20 * (pageNumber - 1) } },
    });

export const getUserListDetail = (userId: string, userListId: string) => {
    return async (dispatch: TDispatch) => {
        try {
            const res = await LoopFront.request({
                url: `users/${userId}/userLists/${userListId}`,
            });
            dispatch({
                type: OUser.Actions.USER_SAVED_LIST_DETAIL_FETCHED,
                data: res.data,
            });
        } catch (err) {
            console.log('Request User list error : ', err);
        }
    };
};

export const getUserListItems = (
    userListId: string,
    filter?: Record<string, any>
) => {
    return async (dispatch: TDispatch) => {
        try {
            const res = await LoopFront.request({
                url: `/UserLists/${userListId}/userListItems`,
                params: {
                    filter,
                },
            });
            dispatch({
                type: OUser.Actions.USER_SAVED_LIST_ITEMS_FETCHED,
                data: res.data,
            });
        } catch (err) {
            console.log('Request User list error : ', err);
        }
    };
};

export function getUserEntity(
    userId: string,
    entity: string,
    pageNumber: number
) {
    return async (dispatch: TDispatch) => {
        try {
            const res =
                entity === 'suggested'
                    ? await requestSuggestedEntity(userId, pageNumber)
                    : await requestUserEntity(userId, entity, pageNumber);
            dispatch({
                type: OUser.Actions.CUSTOM_ENTITY_OF_USER_RECIEVED,
                entity: entity,
                data: res.data,
            });
        } catch (err) {
            console.log('Request User entity error : ', err);
        }
    };
}
export const getSubscribeUserList = (
    destinationId: string,
    pageNumber: number
) => {
    return async (dispatch: TDispatch) => {
        try {
            const res = await LoopFront.request({
                url: `/DestinationSubscriptions/subscriptions-aggs`,
                method: 'GET',
                params: {
                    filter: {
                        limit: DESTINATION_PACK_CITITES_LIMIT,
                        skip: DESTINATION_PACK_CITITES_LIMIT * (pageNumber - 1),
                        where: {
                            $expr: {
                                $eq: [
                                    '$destinationId',
                                    { $toObjectId: destinationId },
                                ],
                            },
                        },
                        include: 'user',
                    },
                },
            });
            dispatch({
                type: OUser.Actions.CITY_SUBSCRIBE_USER_FETCH,
                data: res.data,
            });
        } catch (err) {
            console.log('Request User list error : ', err);
        }
    };
};

// export const getUserCities = (userId: string) => async (dispatch) => {
//     try {
//         // const { data } = await OCity.requestGetItemsList({
//         //     filter: {
//         //         where: {
//         //             id: {
//         //                 inq: destinationIds,
//         //             }
//         //         }
//         //     }
//         // });
//         const {data} = await OUser.getSubscribedDestinations(userId);

//         dispatch({
//             type: OUser.Actions.GET_USERS_CITIES_LIST,
//             data,
//         });
//     } catch (err) {
//         console.error(err);
//     }
// };
