import React from "react";
import { TPlace } from "../../../../Models/Place/@types";

export const initialState: Partial<TPlace> = {
    placeId: '',
    name: '',
    phone: '',
    website: '',
    reservationLink: '',
    destinationId: '',
    description: '',
    shortDescription: '',
    range: undefined,
    types: [],
    addressLine1: '',
    externalRatings: [],
    _openingDays: { periods: [] },
    address: '',
    city: '',
    locality: '',
    zipcode: '',
    isPublished: false,
    status: 'WIP',
    deleted: false,
    inMamakoo: true,
    takeoutAndDelivery: '',
    localeAddress: '',
    mamakooRating: 0,
    location: { lat: 28.644800, lng: 77.216721 },
    socialUrls: [],
    instagramLink: '',
    place_email: ''
}

//<T extends {type : string , name : string , data : any}>
//function useReducer<R extends Reducer<any, any>>
export type T = { type: string, name: string, data: any };
export const EDITED_PLACE_UPDATE = 'EDITED_PLACE_UPDATE';
export const STATE_UPDATE = 'STATE_UPDATE';
export const UPDATE = 'UPDATE';
export const EMPTY_STATE = 'EMPTY_STATE';
export const reducer: React.Reducer<Partial<TPlace>, T> = (state = {}, action) => {
    switch (action.type) {
        case STATE_UPDATE: return { ...state, ...action.data };
        case UPDATE: {
            return { ...state, [action.name]: action.data }
        }
        case EDITED_PLACE_UPDATE: return {
            ...state,
            address: action.data.full_address || '',
            addressLine1: action.data.address1 || '',
            city: action.data.city || '',
            zipcode: action.data.zipcode || '',
            locality: action.data.locality || '',

        }
        case EMPTY_STATE: return {}
        default: return state;
    }
}

