import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import DishesCardList from '../../../../Components/Cards/CardLists/DishesCardList'
import DishesForm from '../../../../Components/Forms/DishesForm'
import HeaderActionButtons from '../../../../Components/HeaderActionButtons'
import { ITEM_PER_PAGE_COUNT } from '../../../../Constants/FilterConstants'
import { OApp } from '../../../../Models/App'
import { TDish } from '../../../../Models/Dish/@types'
import { OPlace } from '../../../../Models/Place'
import { TPlaceState } from '../../../../Models/Place/@types'
import { addEntityToPlace, deleteEntityfromPlace } from '../../../../Models/Place/actions'
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'

interface IProps extends RouteComponentProps<{ id: string }> { }

const Dishes: React.FC<IProps> = (props) => {

    const { match } = props
    const placeId = match.params['id']
    const classes = useStyles(props)
    const [loading, setLoading] = useState(false);
    const { details, dishes = [] } = useSelector<TReduxStore, Pick<TPlaceState, 'details' | 'dishes'>>(({ Place }) => ({ details: Place.details, dishes: Place.dishes }))
    const textUrl = `${DOMAIN_URL}/places/${details?.slug || placeId}/dishes`;
    const [showForm, setShowForm] = useState(false)
    const dispatch = useDispatch<TDispatch>();
    const [displayList, setDisplayList] = useState<TDish[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const handlePageChange = (pageNumber: number) => { setPageNumber(pageNumber) }
    const handleAddDishes = () => {
        setShowForm(true)
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddDishes,
            helpText: 'Add a dish available here',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]
    const getData = async () => {
        setLoading(true);
        await dispatch(OPlace.getEntityByItem(placeId, OPlace.Entities.FOODDRINKS, {
            filter: {
                order: 'name ASC',
                fields: ['name', 'isPublished', 'id']
            }
        }))
        setLoading(false);
    }
    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeId])
    useEffect(() => {
        if (placeId) {
            const low = ITEM_PER_PAGE_COUNT * (pageNumber - 1);
            const high = ITEM_PER_PAGE_COUNT * (pageNumber)
            const tList: TDish[] = (dishes || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, dishes.toString()])
    const onFormSubmit = async (newList: TDish[]) => {
        try {
            await dispatch(addEntityToPlace(placeId, newList, 'FoodDrink'));

            setPageNumber(1);
            dispatch(OApp.showToast({ message: 'Dishes Added Successfully', variant: 'success' }))
        } catch (err) {
            console.log('Error', err)
            dispatch(OApp.showToast({ message: 'Dishes Add unsuccessful', variant: 'error' }))
        }
        setShowForm(false)
    }
    const deleteItem = async (item: TDish) => {
        try {
            await dispatch(deleteEntityfromPlace(placeId, OPlace.Entities.FOODDRINKS, dishes, item.id));
        } catch (err) {
            console.log(err)

        }
    }
    const closeForm = () => { setShowForm(false) }
    return (
        <div>
            <div className={classes.placesDish}>
                <HeaderActionButtons items={headerItems} />
                {showForm && <DishesForm onFormSubmit={onFormSubmit} closeForm={closeForm} open={showForm} dishes={dishes} />}
                <div className={classes.wrapper}>
                    {
                        loading ? <CircularProgress /> :
                            <DishesCardList
                                deleteItem={deleteItem}
                                list={displayList}
                                totalCount={details ? details.counts.foodDrinks || 0 : 0}
                                handlePageChange={handlePageChange}
                                pageNumber={pageNumber}
                            />
                    }
                </div>
            </div >
        </div >
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    placesDish: {
        position: 'relative',
        margin: '0px 40px',
    },
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        },
        margin: '40px auto'
    }
}))

export default withRouter(Dishes)


