import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EntityImages from '../../../../Components/EntityImages'
import ImageCarousel from '../../../../Components/ImageCarousel'
import ImageContainer from '../../../../Components/ImageContainer'
import ImageLoader from '../../../../Components/ImageLoader'
import { OApp } from '../../../../Models/App'
import { changePictureCaption, deletePictureFromItem } from '../../../../Models/App/actions'
import { ODish } from '../../../../Models/Dish'
import { TDish, TDishState } from '../../../../Models/Dish/@types'
import { TDispatch, TPicture } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'

interface IProps { }

const Images: React.FC<IProps> = (props) => {

    const classes = useStyles(props)
    const { details = {} as TDish } = useSelector<TReduxStore, Pick<TDishState, 'details'>>(({ Dish }) => ({ details: Dish.details }))
    const dispatch = useDispatch<TDispatch>()
    const [reorder, setReorder] = useState(false);
    const [loadingCount, setLoadingCount] = useState(0);
    const [pictureList, setPictureList] = useState<TPicture[]>([])
    const [uploadedPicture, setUploadedPicture] = useState<TPicture>();
    const [showCorousel, setShowCorousel] = useState(false);
    const [current, setCurrent] = useState<number>(0);
    const toggleCorousel = (id: string = '') => {
        if (!showCorousel) {
            const index = pictureList.findIndex(i => i.id === id)
            setCurrent(index)
        }
        setShowCorousel(!showCorousel);
    }
    const onImagesSelected = (files: any[]) => {
        setLoadingCount(files.length);
    }
    const renderImageItem = (image: TPicture) => {
        return (<ImageContainer
            image={image}
            onCaptionChange={onCaptionChange}
            onImageDelete={onImageDelete}
            doReOrder={true}
            showCourousel={toggleCorousel}
        />)
    }
    const onReOrder = (oldIndex: number, newIndex: number) => {
        const tImages = [...pictureList]
        var data = tImages[oldIndex];
        tImages.splice(oldIndex, 1);
        tImages.splice(newIndex, 0, data);
        setReorder(true);
        setPictureList([...tImages])
    }
    const saveOrder = async () => {
        if (details) {
            try {
                await dispatch(ODish.patchItem(details.id, { _pictures: pictureList }, {}, undefined, { _pictures: pictureList }))
                dispatch(OApp.showToast({ message: 'Order updated succesfully' }))
            } catch (err) {
                console.log("Place image order update error", err)
                dispatch(OApp.showToast({ message: 'Order update Unsuccesfull', variant: 'error' }))
            }
        }
        setReorder(false)
    }
    const onImageUploadComplete = (current: any, response: TPicture) => {
        setUploadedPicture(response);
    }
    useEffect(() => {
        if (details && uploadedPicture) {
            dispatch(OApp.showToast({ message: "Picture uploaded", variant: "success" }))
            const newImages: TPicture[] = [uploadedPicture, ...details._pictures]
            dispatch({ type: ODish.Actions.ITEM_PATCH_SUCCESS, data: { _pictures: newImages }, additionalDispatchData: { _pictures: newImages } })
            setLoadingCount(loadingCount => loadingCount - 1)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedPicture])
    const renderLoadingItem = () => {
        return (
            <Box width="250px">
                <ImageLoader />
            </Box >
        )
    }
    const onImageDelete = async (pictureId: string) => {
        if (details && details._pictures) {
            const pictures = [...details._pictures]
            const pIndex = pictures.findIndex(i => i.id === pictureId);
            try {
                await deletePictureFromItem('fooddrinks', details.id, pictureId);
                if (pIndex > -1)
                    pictures.splice(pIndex, 1);
                dispatch({ type: ODish.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _pictures: pictures }, additionalDispatchData: { _pictures: pictures } })
            } catch (err) {

            }

        }
    }
    const onVideoLinkSubmit = async (newImage:TPicture) => {
        const newImages: TPicture[] = [newImage, ...details._pictures]
        await dispatch(ODish.patchItem(details.id, { _pictures: newImages }, {}, undefined, { _pictures: newImages }))
    }
    const onCaptionChange = async (pictureId: string, caption: string) => {
        if (details && details._pictures) {
            const res = await changePictureCaption('fooddrinks', details.id, pictureId, caption)
            if (res) {
                const pictures = [...details._pictures]
                const data = pictures.find(i => i.id === pictureId);
                if (data) {
                    const index = pictures.findIndex(i => i.id === pictureId);
                    pictures.splice(index, 1, { ...data, caption: caption })
                    dispatch({ type: ODish.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _pictures: pictures }, additionalDispatchData: { _pictures: pictures } })
                }
            }
        }
    }
    useEffect(() => {
        if (details.id && details._pictures) {
            setPictureList([...details._pictures])
        }
    }, [details])
    return (
        <Box className={classes.dishImages}>
            {reorder && <Button variant='contained' color='primary' className={classes.button} onClick={saveOrder}>Save new order</Button>}
            <Box width={850} mx="auto" my="20px">
                <EntityImages<TPicture>

                    folderName={'fooddrinks'}
                    images={pictureList}
                    url={`fooddrinks/${details.id}/pictures`}
                    renderImageItem={renderImageItem}
                    renderLoadingItem={renderLoadingItem}
                    hasAddButton={true}
                    loadingCount={loadingCount}
                    onImageSelected={onImagesSelected}
                    onImageUploadComplete={onImageUploadComplete}
                    onReOrder={onReOrder}
                    onVideoLinkSubmit={onVideoLinkSubmit}
                    hasUrlUpload
                    hasVideoUrlUpload
                ></EntityImages>
            </Box>
            {showCorousel && <ImageCarousel open={showCorousel} images={pictureList} closeDialog={toggleCorousel} currentIndex={current} />}
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    dishImages: {
        position: 'relative',
        margin: '0px 40px',
    },
    paper: {
        width: 700,
        margin: 'auto',
        padding: '30px',
        marginTop: 20
    },
    imageCard: {

        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        borderRadius: 8,
        paddingBottom: '5px',
        width: '100%',
        height: 'auto',
    },
    imageItem: {
        borderRadius: 0,
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        width: '100%',
        height: 'auto',
        cursor: 'pointer'
    },
    formLabel: {
        marginLeft: 0,
        '& span:last-child': {
            marginLeft: 2,
            fontSize: 12,
        }
    },
    button: {
        '&:hover': {
            background: '#d5d5d5',
            color: 'black'
        }
    }
}))

export default Images


