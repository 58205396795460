import { TAction } from "loop-front";
import { Reducer } from "redux";
import { OPack } from ".";
import { TPackReducerState } from "./@types";

export const RPack: Reducer<TPackReducerState, TAction> = (state = { packs: [] }, action) => {

    const {
        FETCH_ALL_PACKS_SUCCESS
    } = OPack.Actions;

    switch (action.type) {
        case FETCH_ALL_PACKS_SUCCESS: {
            return { ...state, packs: action.data }
        }
        default: return state;
    }
}