import { CircularProgress, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PlacesCardList from '../../../../Components/Cards/CardLists/PlacesCardList';
import { OUser } from '../../../../Models/User';
import { TUserState } from '../../../../Models/User/@types';
import { UserModel } from '../../../../Models/User/actions';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

export interface indexProps extends RouteComponentProps<{ id: string }> { }

const Suggested: FC<indexProps> = (props) => {
    const { match } = props;
    const classes = useStyles();
    const [pageNumber, setPageNumber] = useState(1)
    const userId = match.params['id'];
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch<TDispatch>();
    const { suggested = [], user } = useSelector<TReduxStore, Pick<TUserState, 'suggested' | 'user'>>(({ User }) => ({ suggested: User.suggested, user: User.user }))
    const getData = async () => {
        setLoading(true);
        await dispatch(UserModel.getUserEntity(userId, OUser.Entities.SUGGESTED, pageNumber))
        setLoading(false);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);
    const handlePageChange = (pageNumber: number) => { setPageNumber(pageNumber) }
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                {
                    loading ? <CircularProgress />
                        :
                        <PlacesCardList
                            list={suggested}
                            pageNumber={pageNumber}
                            totalCount={user ? (user.counts.suggested || 0) : 0}
                            handlePageChange={handlePageChange}
                        />
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            position: 'relative',
            margin: '0px 40px',
        },
        wrapper: {
            width: 924,
            [theme.breakpoints.down('md')]: {
                width: 640,
            },
            [theme.breakpoints.down('sm')]: {
                width: 324,
            },
            margin: '40px auto'
        }
    }))
})

export default Suggested