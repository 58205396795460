import { TDispatch } from '../../Resources/GlobalTypes';
import { OApp } from '../App';
import LoopFront from 'loop-front';
import { TTag } from '../Tags/@types';
import { OPlace } from '.';
import { TPlaceFilterConfig, TSuggestions, TPlace } from './@types';
import { get } from 'lodash'

export function addEntityToPlace<T extends { id: string }>(placeId: string, newIds: T[], entity: string) {
    return (async (dispatch: TDispatch) => {
        var data = {
            firstModel: 'Establishment',
            secondModel: entity,
            id: placeId,
            newIds: newIds.map((item) => item.id)
        }
        try {

            await OApp.requestPutActivity(OApp.Activities.RELATIONS, data);
            const action: string = 'PUT_' + entity.toUpperCase() + '_TO_PLACE_SUCCESS'
            dispatch({ type: action, data: newIds.length, additionalDispatchData: newIds })
        } catch (err) {
            console.log('Error : ', err)
        }

    }
    )
}
const requestRemoveEstablishmentTag = (establishmentId: string, tagId: string) => (
    LoopFront.request({
        url: `establishments/${establishmentId}/tags/rel/${tagId}`,
        method: 'DELETE'
    })
)
export function deleteTagFromPlace(establishmentId: string, tagId: string, tags: TTag[]) {
    return (async (dispatch: TDispatch) => {
        try {
            await requestRemoveEstablishmentTag(establishmentId, tagId);
            dispatch({ type: OPlace.Actions.SINGLE_TAG_REMOVED_FROM_PLACE, data: tags.filter(i => i.id !== tagId), additionalDispatchData: tags.length - 1 })
            dispatch(OApp.showToast({ message: 'Tag removed successfully', variant: 'success' }))
        } catch (err) {
            dispatch(OApp.showToast({ message: 'Remove tag Unsuccessful', variant: 'error' }))
            console.log(err)
        }
    })
}
const requestDeleteSingleEntityFromPlace = (establishmentId: string, entity: string, itemId: string) => (
    LoopFront.request({
        url: `establishments/${establishmentId}/${entity}/rel/${itemId}`,
        method: 'DELETE'
    })
)



export function deleteEntityfromPlace<T extends { id: string }>(establishmentId: string, entity: string, items: T[], itemId: string) {
    return (async (dispatch: TDispatch) => {
        try {
            await requestDeleteSingleEntityFromPlace(establishmentId, entity, itemId);
            const action = `DELETE_${entity.toUpperCase()}_FROM_PLACE_SUCCESS`;
            dispatch({ type: action, additionalDispatchData: items.filter(i => i.id !== itemId), data: items.length - 1 })
            dispatch(OApp.showToast({ message: entity.toUpperCase() + ' delete Successful', variant: 'success' }))
        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: entity.toUpperCase() + ' delete Unsuccessful', variant: 'error' }))
        }
    })
}

const requestUpdateSuggestions = (placeId: string, suggestions: Partial<TSuggestions>[]) => {
    return LoopFront.request({
        url: `establishments/${placeId}/update-suggestions`,
        method: 'PUT',
        data: { suggestions: suggestions }

    })

}
export function updatePlaceSuggestions(placeId: string, suggestions: Partial<TSuggestions>[]) {
    return (async (dispatch: TDispatch) => {
        try {
            const response: { data: TPlace } = await requestUpdateSuggestions(placeId, suggestions.filter(i => (i.deleted === false)).map(i => ({ userId: i.userId || '', isPrimary: i.isPrimary || false, }))) as { data: TPlace };
            const newSuggestions: TSuggestions[] = [];
            (response.data._suggestions || []).forEach(x => {
                const item = suggestions.find(i => i.userId === x.userId);
                if (item && item.user)
                    newSuggestions.push({ ...x, user: item.user })
            })
            dispatch({
                type: OPlace.Actions.PLACE_SUGGESTIONS_UPDATED,
                data: {
                    _suggestions: response.data._suggestions,
                    suggestions: newSuggestions
                }
            })
            dispatch(OApp.showToast({ message: 'Suggestors added successfully' }));
        } catch (err) {
            dispatch(OApp.showToast({ message: get(err, 'response.data.error.message') || 'Suggestors added Unsuccessfully', variant: 'error' }));
            throw err
        }
    })
}
export function getPlaceList(pageNumber: number, filterConfig: TPlaceFilterConfig) {
    return (async (dispatch: TDispatch) => {
        const filter: {
            order: string;
            where: {
                isPublished?: boolean
                inMamakoo?: boolean
                newlyAdded?: boolean
                recentlyUpdated?: boolean
                and: Array<{
                    destinationId?: { inq: string[] }
                    status?: { inq: string[] },
                    "_suggestions"?: any,
                }> | any;
                or: any[];
                mamakooRating?: object
            }
        } = { where: { and: [], or: [], }, order: filterConfig.order }
        const basicConfig = filterConfig.baseConfig;
        basicConfig.forEach((elem) => {
            if (elem.isActive) {
                switch (elem.name) {
                    case 'All': break;
                    case 'User Submitted': filter.where.and.push({
                        "_suggestions": {
                            "exists": true,
                        },
                    }, {
                        "_suggestions.deleted": { "ne": true },
                    },
                        {
                            "_suggestions": { "ne": [] }
                        }
                    ); break;
                    case 'Published': filter.where.isPublished = true; break;
                    case 'Unpublished': filter.where.isPublished = false; break;
                    case 'Rated by Mamakoo': filter.where.mamakooRating = { "exists": true }; break;
                    case 'Not Rated by Mamakoo': filter.where.mamakooRating = { "exists": false }; break;
                    case 'Newly Added': filter.where.newlyAdded = true; break;
                    case 'Recently Updated': filter.where.recentlyUpdated = true; break;
                    default: break;
                }
            }
        })
        if (!basicConfig[0].isActive) {
            if (filterConfig.cityIds.length > 0) {
                const destinationId: { inq: string[] } = { inq: filterConfig.cityIds }
                filter.where.and.push({
                    destinationId: destinationId
                })
            }
            if (filterConfig.status.length > 0) {
                const status: { inq: string[] } = { inq: filterConfig.status }
                filter.where.and.push({
                    status: status
                })
            }

        } else {
            if (filterConfig.cityIds.length > 0) {
                const destinationId: { inq: string[] } = { inq: filterConfig.cityIds }
                filter.where.and.push({
                    destinationId: destinationId
                })
            } else {
                filter.where.and.push({})
            }
            if (filterConfig.status.length > 0) {
                const status: { inq: string[] } = { inq: filterConfig.status }
                filter.where.and.push({
                    status: status
                })
            }
            if (filterConfig.ratings.length > 0) {
                const mamakooRating: { inq: number[] } = { inq: filterConfig.ratings }
                filter.where.and.push({
                    mamakooRating
                })
            }
        }

        if (filter.where.and.length === 0) delete filter.where.and;
        if (filter.where.or.length === 0) delete filter.where.or;

        dispatch(OPlace.getItemsList({
            filter: {
                include: [{ "relation": "destination", "scope": { "fields": ["name"] } }, "suggestions"],
                limit: 20,
                skip: 20 * (pageNumber - 1),
                ...filter
            }
        }))
        if (pageNumber === 1)
            dispatch(OPlace.getActivity(OPlace.Activities.COUNT, { ...filter }))
    })
};
