import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, ButtonProps, Fab, Icon, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

export interface EntityCardProps {
    imgUrl?: string
    title: string
    disableImagePlaceholder?: boolean
    id?: string
    subtitle: string
    draghandleProps?: any
    url?: string
    actionComponent?: JSX.Element | null
    classes?: {
        root: string
    }
    menuProps?: {
        id: string
        items: {
            onClick: () => void
            label: string
        }[]
    }
}

const EntityCard: FC<EntityCardProps> = (props) => {

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const history = useHistory();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { imgUrl, url, menuProps, title, actionComponent, disableImagePlaceholder = false, subtitle, id, classes: propClass, draghandleProps } = props;
    const classes = useStyles({});


    return (
        <div onClick={() => url ? history.push(url) : '#'} style={{ cursor: url ? 'pointer' : 'default' }}>
            <Box className={propClass?.root} position="relative" display="flex" p={1} bgcolor="#fff">
                {imgUrl || !disableImagePlaceholder ?
                    <img src={imgUrl} className={classes.img} />
                    : null}
                <Box flex={1} display="flex" flexDirection="column">
                    <Typography className={classes.title} variant="body2">{title}</Typography>
                    <Typography color="textSecondary" variant="caption">{subtitle}</Typography>
                </Box>
                {actionComponent ? <Box mx={2} display='flex' justifyContent='center' alignItems='center'>{actionComponent}</Box> : null}
                {menuProps ? (
                    <IconButton onClick={handleClick} size="small">
                        <MoreVert fontSize="small" />
                    </IconButton>
                ) : null}
                {draghandleProps ? (
                    <div
                        id={id ?? `drag-${title}-${subtitle}`}
                        style={{ top: imgUrl ? 11 : 5, right: '-40px', position: 'absolute' }}
                        className={'dragHandle'} {...draghandleProps}>
                        <Fab color='inherit' size={"small"} className={classes.dragIcon}>
                            <Icon fontSize='small' color='primary' className={'material-icons'} >unfold_more</Icon>
                        </Fab>
                    </div>
                ) : null}
                {menuProps ? (
                    <Menu
                        id={menuProps.id}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {menuProps.items.map(item => (
                            <MenuItem key={item.label} onClick={item.onClick}>{item.label}</MenuItem>
                        ))}
                    </Menu>
                ) : null}
            </Box>
        </div>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    img: {
        width: 36,
        height: 36,
        background: '#faa',
        marginRight: 8
    },
    title: {
        fontWeight: theme.typography.fontWeightBold
    },
    dragIcon: {
        height: 35,
        width: 35
    }
}));

export default EntityCard;