import { Chip, createStyles, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormDialog from '../../../../Components/Dialogs/FormDialog';
import { OTagCategories } from '../../../../Models/Tags';
import { TTag, TTagCategoriesState } from '../../../../Models/Tags/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
export interface TagsFormProps {
    tags: TTag[]
    open: boolean
    closeForm: () => void
    handleSubmit: (newTags: TTag[]) => Promise<void>
}

const TagsForm: FC<TagsFormProps> = (props) => {
    const classes = useStyles();
    const { tags = [], open = false, closeForm } = props
    const { list = [] } = useSelector<TReduxStore, Pick<TTagCategoriesState, 'list'>>(({ TagCategories }) => ({ list: TagCategories.list }))
    const [newTagList, setNewTagList] = useState<TTag[]>(tags);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>()
    const getData = async () => {
        setLoading(true);
        await dispatch(OTagCategories.getItemsList({ filter: { "include": "tags", "order": "name ASC" } }))
        setLoading(false);
    }
    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleSubmit = async () => {
        await props.handleSubmit(newTagList);
        // closeForm();
    }
    const handleClick = (item: TTag) => {
        const tempList: TTag[] = [...newTagList];
        const currentIndex = tempList.findIndex(i => i.id === item.id)
        currentIndex > -1 ? tempList.splice(currentIndex, 1) : tempList.push(item)
        setNewTagList([...tempList]);
    }
    return (
        <FormDialog
            loading={loading}
            open={open}
            heading={'Add / Remove Tags'}
            onSubmit={handleSubmit}
            onClose={closeForm}
        >

            <div className={classes.wrapper}>

                <Grid container spacing={3} style={{ width: '100%' }}>
                    {
                        list.map((item, index) => {
                            return <Grid item key={index} className={classes.gridItem}>
                                <Paper className={classes.paper}>
                                    <div >
                                        <Typography variant='h6' className={classes.title}>{item.name}</Typography>
                                    </div>
                                    <div className={classes.tagContainer}>
                                        {
                                            item.tags ? item.tags.map((tag, i) => {
                                                return (
                                                    <Chip
                                                        className={classes.tagChip}
                                                        label={tag.name}
                                                        key={i}
                                                        onClick={() => { handleClick(tag) }}
                                                        color={(newTagList.findIndex(i => i.id === tag.id) > -1) ? 'primary' : 'default'}
                                                    />
                                                )
                                            }) : null

                                        }
                                    </div>
                                </Paper>
                            </Grid>
                        })
                    }
                </Grid>
            </div>

        </FormDialog>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: '100%',
            padding: '30px 18px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',

        },
        placesTags: {
            position: 'relative',
            margin: '0px 40px',
        },
        wrapper: {
            width: '700px',
            margin: '40px auto'
        },
        gridItem: {
            width: '100%',

        },
        title: {
            fontFamily: 'lato',
            fontWeight: 400
        },
        tagContainer: {
            display: 'flex',
            marginTop: 20,
            flexWrap: 'wrap',
            width: '100%',
            height: 'auto'
        },
        tagChip: {

            margin: '5px'
        }
    }))
})

export default TagsForm