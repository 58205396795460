import React, { PureComponent } from 'react'
import { createStyles, WithStyles, withStyles, Theme, CircularProgress, fade } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router';
import clsx from 'clsx';


// eslint-disable-next-line
interface IProps extends WithStyles<typeof STYLES>, RouteComponentProps<any> {
    fullScreen?: boolean
    overlayed?: boolean
}

class Loader extends PureComponent<IProps> {

    render() {
        const { classes, fullScreen = false, overlayed = false } = this.props
        return (
            <div className={clsx(classes.root, {
                [classes.fullScreen]: fullScreen,
                [classes.overlayed]: overlayed,
            })} >
                <CircularProgress />
            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullScreen: {
        height: `100vh`,
        display: 'flex',
        alignItems: 'center',
    },
    overlayed: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.appBar + 1000,
        height: `100vh`,
        width: `100vw`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: fade('#fff', 0.4)
    }
})

export default (withStyles(STYLES)(withRouter(Loader)));