import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ODish } from '../../Models/Dish';
import { TDish, TDishState } from '../../Models/Dish/@types';
import { TDispatch } from '../../Resources/GlobalTypes';
import utilities from "../../Resources/Utils";
import { TReduxStore } from '../../RootReducer';
import MainList from '../Dashboard/MainList';
import FormDialog from '../Dialogs/FormDialog';
interface IProps {
    onFormSubmit: (newList: TDish[]) => Promise<void>
    dishes: TDish[]
    closeForm: () => void
    open: boolean
}

const DishesForm: React.FC<IProps> = (props) => {
    const { onFormSubmit, dishes = [], open, closeForm } = props;
    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const { list = [], totalCount = 0 } = useSelector<TReduxStore, Pick<TDishState, 'list' | 'totalCount'>>(({ Dish }) => ({ list: Dish.list, totalCount: Dish.totalCount }))

    const dispatch = useDispatch<TDispatch>();
    const [newDishList, setNewDishList] = useState<TDish[]>(dishes);
    const [searchList, setSearchList] = useState<TDish[]>([]);
    const [query, setQuery] = useState('');
    const getData = async () => {
        setLoading(true);
        try {
            dispatch(ODish.getItemsList({ filter: { order: 'name ASC', limit: 20, skip: 20 * (currentPage - 1) } }))
            currentPage === 1 && dispatch(ODish.getActivity(ODish.Activities.COUNT));
        } catch (err) {
            console.log('Error ', err)
        }
        setLoading(false)
    }

    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    const onSubmit = async () => {
        onFormSubmit(newDishList);

    }


    const handleSearchResult = (searchResult: TDish[], query: string) => {
        setQuery(query);
        setSearchList(searchResult)
    }

    const handlePageChange = (pagenumber: number) => {
        setCurrentPage(pagenumber)
    }

    const handleClick = (item: TDish) => {
        const templist = utilities.toggleItemFromList(newDishList, item);
        console.log(templist)
        setNewDishList([...templist])
    }


    const listItem = () => {
        return (query ? searchList : list).map((item, index) => {
            return (<ListItem button key={index} onClick={() => handleClick(item)}>
                <ListItemText primary={item.name}  >
                </ListItemText>
                <ListItemSecondaryAction>
                    <Checkbox
                        edge="end"
                        color='primary'
                        onClick={() => handleClick(item)}
                        checked={(newDishList.findIndex(i => i['id'] === item['id']) === -1) ? false : true}
                    />
                </ListItemSecondaryAction>
            </ListItem>)
        })
    }

    return (
        <div>
            <FormDialog
                heading={'Add Dishes'}
                open={open}
                onSubmit={onSubmit}
                onClose={closeForm}
                loading={loading}
            >
                <MainList
                    itemsCountPerPage={20}
                    search
                    totalItemsCount={totalCount}
                    onPageChange={handlePageChange}
                    searchProps={{
                        type: 'foodDrink',
                        onResults: handleSearchResult
                    }}
                    list={listItem()}
                    isform={true}
                />

            </FormDialog>
        </div>
    )
}



export default DishesForm