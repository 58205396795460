import { Box, Button, Checkbox, Divider, FormControlLabel, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../Components/HeaderActionButtons';
import NotesInput from '../../../Components/Inputs/NotesInput';
import TypographyWMention from '../../../Components/TypographyWMention';
import dimensions from '../../../Constants/dimensions';
import { DATE_FORMAT } from '../../../Constants/vars';
import { renderHtmlStr } from '../../../Features/Mention';
import { OApp } from '../../../Models/App';
import { OLandmarks } from '../../../Models/Landmarks';
import { TLandmarks, TLandmarksState } from '../../../Models/Landmarks/@types';
import { DOMAIN_URL } from '../../../Resources/GlobalConstants';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';
export interface DetailsProps { }

const Details: FC<RouteComponentProps<{ id: string }>> = (props) => {
    const classes = useStyles();
    const landmarkId = props.match.params['id'];
    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();

    const { details } = useSelector<TReduxStore, Pick<TLandmarksState, 'details'>>(({ Landmarks }) => ({ details: Landmarks.details }));
    const textUrl = `${DOMAIN_URL}/landmarks/${details?.slug || landmarkId}`;
    const handleEdit = () => {
        dispatch({ type: OLandmarks.Actions.SHOW_LANDMARK_FORM, data: details })
    }
    const setField = async (field: keyof TLandmarks, value: any) => {
        try {
            if (details)
                await dispatch(OLandmarks.patchItem(details.id, { [field]: value }, undefined, undefined, { [field]: value }))
        } catch (error) {

        }
    }

    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(OLandmarks.deleteItem(details.id, {}, undefined, details))
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'Landmark' } })
            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }))
            }
            if (history) {
                history.push('/dashboard/landmarks')
            }
        }
    }
    const handlePublish = async () => {
        if (details) {
            try {
                const flag = !details.isPublished;
                await dispatch(OLandmarks.postItemActivity(details.id, OLandmarks.Activities.PUBLISH, {}, { publish: !details.isPublished }))
                dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
            }
            catch (err) {
                dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))
                console.log('Landmark publish', err)
            }
        }
    }
    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this landmark.',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this Landmark?',
            handleDelete: handleDelete,
            helpText: 'Delete this landmark.',
        },
        {
            type: 'icon',
            icon: (details && details.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (details && details.isPublished) ? 'secondary' : 'primary',
            helpText: (details && details.isPublished) ? 'Unpublish this landmark.' : 'Publish this landmark.',
        },
        {
            type: 'copy_button',
            textUrl: textUrl,
        }
    ]
    const handleNoteSave = async (notes: string) => {
        try {
            if (details)
                await dispatch(OLandmarks.patchItem(details.id, { notes }, {}, undefined, { notes }));
            dispatch(OApp.showToast({ message: `Notes updated.`, variant: 'success' }))

        } catch (err) {
            dispatch(OApp.showToast({ message: `Notes update failed.`, variant: 'success' }))
            console.log('Error in add to mamakoo', err);
        }
    }
    return (
        <div className={classes.foodDrinkDetail}>
            {
                details &&
                <>

                    <HeaderActionButtons items={headerItems} />
                    <Box mt={3} m='auto'>
                        <NotesInput
                            onSave={handleNoteSave}
                            text={details.notes || ''}
                        />
                    </Box>
                    <Paper className={classes.paper}>

                        <Box display='flex'>
                            <Typography variant="overline">{`Created - ` + moment(details.created).format(DATE_FORMAT) + `\tModified - ` + moment(details.updated).format(DATE_FORMAT)}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='column' my={2} ml={0.5}>
                            <FormControlLabel
                                onClick={e => setField('newlyAdded', !details?.newlyAdded)}
                                label='Newly added'
                                control={<Checkbox color='primary' checked={!!details?.newlyAdded} />}
                            />
                            <FormControlLabel
                                onClick={e => setField('recentlyUpdated', !details?.recentlyUpdated)}
                                label='Recently updated'
                                control={<Checkbox color='primary' checked={!!details?.recentlyUpdated} />}
                            />
                        </Box>
                        <Divider style={{ margin: 0, marginBottom: 16 }} />
                        <div>
                            <Typography variant="h4" >{details.name}</Typography>
                            {details.address ?
                                <Box display='flex' width='100%' alignItems='center' justifyContent='space-between'>
                                    <Typography variant='subtitle1' >{details.destination?.name}</Typography>
                                    <Button variant='text' size='small' color='primary' href={details.address.url || ''} target='_blank' >Google maps URL</Button>
                                </Box>
                                : null
                            }
                            {details.shortDescription ?
                                <> <Divider style={{ margin: '16px 0' }} />
                                    <Typography variant={'subtitle1'} >Short Description</Typography>
                                    <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>{details.shortDescription || ''}</TypographyWMention>
                                </>
                                : null}
                            {details.description ?
                                <> <Divider style={{ margin: '16px 0' }} />
                                    <Typography variant={'subtitle1'} >Long Description</Typography>
                                    <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>
                                        {(details.description || '').replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                    </TypographyWMention>
                                </>
                                : null}


                        </div>
                    </Paper>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        foodDrinkDetail: {
            position: 'relative',
            margin: '0px 40px',
        },
        paper: {
            width: dimensions.screenMaxWidth,
            margin: 'auto',
            padding: '30px',
            marginTop: 20
        },
        operationBtns: {
            display: 'flex',
            justifyContent: 'center',
            '& > div': {
                margin: '0 10px'
            }
        },
    }))
})

export default Details