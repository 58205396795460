import LoopFront from 'loop-front';

export const CustomEntities = {};
export const CustomActivity = {
    COUNT: 'count',
};
export const CustomActions = {
    SHOW_DESTINATION_PACK_DETAIL_FORM: 'SHOW_DESTINATION_PACK_DETAIL_FORM',
    HIDE_DESTINATION_PACK_DETAIL_FORM: 'HIDE_DESTINATION_PACK_DETAIL_FORM',
    DESTINATION_PACK_CITITES_RECIVED: 'DESTINATION_PACK_CITITES_RECIVED',
};
class DestinationPack extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('DestinationPacks', CustomActions, CustomEntities, CustomActivity);
    }
}

export const ODestinationPack = new DestinationPack();
