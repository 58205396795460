import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import PlaceTypeInput from '../../../Features/Settings/PlaceTypeInput';
import { useSelector } from 'react-redux';
import { TSettingsState } from '../../../Models/Settings/@types';
import { TReduxStore } from '../../../RootReducer';

export interface PlaceTypeProps {

}

const PlaceType: FC<PlaceTypeProps> = (props) => {
    const classes = useStyles({});
    const { list = [] } = useSelector<TReduxStore, Pick<TSettingsState, 'list'>>(({ Settings: { list } }) => ({ list }))

    return (
        <Box>
            <PlaceTypeInput
                placeTypeSetting={list.filter(i => i.key === 'establishmentPlaceType')[0]}
            />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default PlaceType;