import React, { PureComponent } from 'react';
import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core";
import { NavLink } from 'react-router-dom';

export interface HeaderNavLinksProps extends WithStyles<typeof STYLES> {
    links: Array<Record<'name' | 'url', string>>
    baseUrl: string
}

class HeaderNavLinks extends PureComponent<HeaderNavLinksProps> {
    render() {
        const { classes, links = [], baseUrl } = this.props;
        return (
            <ul className={classes.headerNavLinks}>
                {links.map((link, i) => {
                    return (
                        <li key={i}>
                            <NavLink to={baseUrl + `/${link.url}`} activeClassName={"selectedClass"} >
                                {link.name}
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        )
    }
};

const STYLES = (theme: Theme) => createStyles({
    activeClassName: {
        color: theme.palette.primary.main,
        borderBottom: `4px Solid ${theme.palette.primary.main}`
    },
    headerNavLinks: {
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 43,
        marginTop: 0,
        fontFamily: theme.typography.fontFamily,
        '& li': {
            listStyle: 'none',
            padding: '0 10px',
            height: '50px',
            lineHeight: '50px',
            cursor: 'pointer',
            '& a': {
                height: '50px',
                textDecoration: 'none',
                display: 'block',
                fontSize: '14px',
                color: theme.palette.grey['100'],
                '&.selectedClass': {
                    color: theme.palette.primary.main,
                    borderBottom: `4px Solid ${theme.palette.primary.main}`
                }
            },
        }
    }
})

export default withStyles(STYLES)(HeaderNavLinks);