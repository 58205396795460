import { Box, createStyles, Drawer, Icon, IconButton, Paper, Theme, Typography, Divider } from '@material-ui/core';
import { StarBorderSharp, StarSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dot from '../../../../Components/Buttons/Dot';
import { OApp } from '../../../../Models/App';
import { OPlace } from '../../../../Models/Place';
import { TSuggestions } from '../../../../Models/Place/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
export interface UserDrawerProps {
    primarySuggester?: TSuggestions
    suggestions: TSuggestions[]
    closeDrawer: () => void
    id: string
}
export type DrawerSide = 'top' | 'left' | 'bottom' | 'right';
const UserDrawer: FC<UserDrawerProps> = (props) => {
    const classes = useStyles();
    const { primarySuggester = {} as TSuggestions, suggestions = [], closeDrawer, id } = props

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    useEffect(() => {
        setOpen(true);
    }, [])
    const toggleDrawer = (side: DrawerSide, open: boolean) => async (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open)
        closeDrawer();

    };
    const handleChange = async (item: TSuggestions) => {
        if (item.isPrimary) return
        try {
            let list = [...suggestions];
            let index = list.findIndex(i => i.id === primarySuggester.id);
            list[index].isPrimary = false;
            index = list.findIndex(i => item.id === i.id);
            list[index].isPrimary = true;
            await dispatch(OPlace.patchItem(id, {
                id: id,
                _suggestions: [...list],
            }, {}, undefined, {
                _suggestions: [...list],
                suggestions: [...list],
            }))
        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ message: 'Primary suggester update failed', variant: 'error' }))
        }

    }

    return (
        <Drawer anchor='right'
            open={open}
            onClose={toggleDrawer('right', false)}
        >
            <Paper style={{ width: 450, height: '100%', padding: 20, paddingRight: '8px' }}>

                <section className={classes.drawerHeader}>
                    <Typography component={'h3'} >Suggested Users</Typography>
                    <IconButton onClick={toggleDrawer('right', false)}>
                        <Icon >close</Icon>
                    </IconButton>
                </section>

                <section className={classes.suggesterList}>
                    <Box width='100%' display='flex' flexDirection='column'  >
                        {
                            suggestions.map((item, index) => {
                                return (<><Box key={index} my={2} display='flex' width='100%' justifyContent='space-between' alignItems='flex-start' pr='10px' onClick={() => handleChange(item)} style={{ cursor: 'pointer' }}>
                                    <Box display='flex' flexDirection='column'>
                                        <Typography variant='body1'>{item.user.name}</Typography>
                                        <Box display='flex' flexWrap='wrap' width='100%' alignItems='center'>
                                            {
                                                (item.tags || []).map((tag, ind) => {
                                                    let length = (item.tags || []).length;
                                                    return (<>
                                                        <Typography variant='body2'>{tag.name}</Typography>
                                                        {
                                                            (ind < length - 1) && <Dot size={7} mx={2} />
                                                        }
                                                    </>)
                                                })
                                            }
                                        </Box>
                                        {
                                            item.tips && <Box display='flex' flexDirection='column'>
                                                <Typography variant='body2'>{item.tips}</Typography>
                                            </Box>
                                        }
                                        <Box>

                                        </Box>

                                    </Box>
                                    <Box> {(item.isPrimary === true) ?
                                        <StarSharp color={'primary'} />
                                        : <StarBorderSharp color={'primary'} />
                                    }</Box>

                                </Box>
                                    {
                                        (index < suggestions.length - 1) && <Box my={1}><Divider orientation='horizontal' /></Box>
                                    }
                                </>)
                            })
                        }
                    </Box>
                    {/* <List>
                        {
                            suggestions.map((item, index) => {
                                let tagString = (item.tags && item.tags.length > 0) ? `Tags :\n${item.tags.map(i => i.name).join(' , ')}\n` : '';
                                let comment = (item.tips) ? `Comment : ${item.tips}` : '';
                                return (
                                    <ListItem button key={index} style={{ padding: 0 }} onClick={() => handleChange(item)}>
                                        <ListItemText
                                            primary={item.user.name}
                                            secondaryTypographyProps={{
                                                style: {
                                                    whiteSpace: 'pre-wrap'
                                                }
                                            }}
                                            secondary=
                                            {
                                                tagString + comment
                                            }
                                        >
                                        </ListItemText>
                                        <ListItemIcon
                                            style={{ minWidth: '36px' }}
                                        >
                                            {(item.isPrimary === true) ?
                                                <StarSharp color={'primary'} />
                                                : <StarBorderSharp color={'primary'} />
                                            }
                                        </ListItemIcon>

                                        <Divider orientation={'horizontal'} />
                                    </ListItem>
                                )
                            })

                        }
                    </List> */}
                </section>
            </Paper>
        </Drawer>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        drawerHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& h3': { fontWeight: 'bold', fontSize: 14 }
        },
        suggesterList: {

        },
        suggesterItem: {
            width: '100%'
        }
    }))
})

export default UserDrawer