import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { TPlace } from '../../../Models/Place/@types'
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog'
import ItemCard from '../ItemCard'
interface IProps extends RouteComponentProps<{ id: string }> {
    item: TPlace
    deleteItem?: (item: TPlace) => Promise<void>
    sortable?: boolean
}

const PlacesCard: React.FC<IProps> = (props) => {
    const { item, history, deleteItem, sortable = false } = props
    const [dialog, toggleDialog] = useState(false)


    const handleView = () => {

        history.push('/dashboard/places/' + item.id + '/details')
    }
    const handleDelete = () => {

        toggleDialog(!dialog);
    }
    const handleDialogDelete = () => {
        if (deleteItem)
            deleteItem(item);
        toggleDialog(!dialog)
    }
    const menuOption = [{ text: 'View Details', onClick: handleView },
    ]
    deleteItem && menuOption.push({ text: 'Delete ', onClick: handleDelete })
    return (
        <div>
            <ItemCard
                title={item.name}
                subtitle={item.addressLine1}
                status={item.status}
                options={menuOption}
                published={item.isPublished}
                sortable={sortable}
            />
            <ConfirmationDialog
                open={dialog}
                onAgree={handleDialogDelete}
                onDisagree={handleDelete}
                message={"Are you sure you want to delete?"}
                onClose={handleDelete}
            />
        </div>
    )
}



export default withRouter(PlacesCard)