import {
    CircularProgress,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
    Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { pick } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import GoogleLocationSuggest from '../../../Components/Inputs/GoogleLocationSuggest';
import TextFieldWMention, { TextFieldWMentionProps } from '../../../Components/Inputs/TextFieldWMention';
import { textFieldWMentionHelpers } from '../../../Components/Inputs/TextFieldWMention/helpers';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { OApp } from '../../../Models/App';
import { OCity } from '../../../Models/City';
import { TCityState } from '../../../Models/City/@types';
import { OLandmarks } from '../../../Models/Landmarks';
import { TLandmarks, TLandmarksState } from '../../../Models/Landmarks/@types';
import { OPlace } from '../../../Models/Place';
import { TPlace } from '../../../Models/Place/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import GoogleUtils from '../../../Resources/google';
import { TReduxStore } from '../../../RootReducer';

export interface LandmarkFormProps { }
const toMiles = (e: number) => (e * 0.621371).toFixed(1);
const LandmarkForm: FC<LandmarkFormProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { showMainForm = false, editedLandmark } = useSelector<TReduxStore, Pick<TLandmarksState, 'showMainForm' | 'editedLandmark'>>(
        ({ Landmarks }) => ({ showMainForm: Landmarks.showMainForm, editedLandmark: Landmarks.editedLandmark })
    );
    const dispatch = useDispatch<TDispatch>();
    const { list: cityList = [] } = useSelector<TReduxStore, Pick<TCityState, 'list'>>(({ City: { list } }) => ({ list }));
    const [formData, setFormData] = useState<Partial<TLandmarks>>({});
    const [placeList, setPlaceList] = useState<TPlace[]>([]);
    const onSubmit = useAsyncTask(async () => {
        if (formData.destinationId)
            try {
                if (editedLandmark?.id) {
                    await dispatch(OLandmarks.patchItem(editedLandmark?.id, { ...formData }, null, undefined, { ...formData }));
                    dispatch(OApp.showToast({ message: 'Landmark edit Successful', variant: 'success' }));
                } else {
                    const res = await OCity.requestPostEntityByItem(formData.destinationId, OCity.Entities.LANDMARKS, { ...formData });
                    dispatch({ type: OLandmarks.Actions.POST_ITEM_SUCCESS, data: res.data });
                    // dispatch({ type: OLandmarks.Actions.POST_ITEM_SUCCESS, data: res.data });
                    dispatch(OApp.showToast({ message: 'Landmark add Successful', variant: 'success' }));
                    dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'Landmark' } });
                    history.push('/dashboard/landmarks/' + res.data.id + '/details');
                }
                onClose();
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Landmark add / updated failed', variant: 'success' }));
            }
    });
    useEffect(() => {
        if (editedLandmark?.id) {
            setFormData({
                ...editedLandmark,
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedLandmark?.id]);
    const getPlaces = useAsyncTask(async (cityId: string) => {
        const city = cityList.find((i) => i.id === cityId);
        const locFilter = city?.location
            ? {
                location: {
                    near: {
                        ...city?.location,
                    },
                },
            }
            : {};
        const res = await OPlace.requestGetItemsList({
            filter: {
                where: {
                    destinationId: cityId,
                    ...locFilter,
                },
            },
        });
        if (formData.location) {
            let value = formData.location;
            setPlaceList([
                ...(res.data as TPlace[]).sort((a, b) => {
                    if (!a.location || !b.location || !value) return 0;
                    let a1 = GoogleUtils.getDistance(a.location, value);
                    let b1 = GoogleUtils.getDistance(b.location, value);
                    return a1 - b1;
                }),
            ]);
        } else {
            setPlaceList((l) => [...res.data]);
        }
    });
    useEffect(() => {
        if (formData.destinationId) {
            getPlaces.run(formData.destinationId);
        } else {
            setPlaceList([]);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.destinationId]);
    const handleChange = (e: React.ChangeEvent<{ name: unknown; value: unknown }>) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name as string]: e.target.value as string });
    };
    const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setFormData({ ...formData, destinationId: e.target.value as string });
    };
    const onClose = () => {
        dispatch({ type: OLandmarks.Actions.HIDE_LANDMARK_FORM });
    };

    const handleDescriptionChange: (name: 'shortDescription' | 'description') => TextFieldWMentionProps['handleChange'] = (name) => (e, newValue, newValueText) => {
        setFormData({ ...formData, [name as string]: e.target.value as string })
    }


    const handleSearchResult = async <T extends { place_id: string }>(item: T) => {
        const res = await GoogleUtils.placeDetails(item.place_id);

        let value: Partial<TLandmarks> = { name: res.name, address: pick(res, 'url') };
        if (res.geometry) {
            var geo: google.maps.places.PlaceGeometry = res.geometry;
            var location = geo.location;
            var lat = location.lat();
            var lng = location.lng();
            value = { ...value, location: { lat, lng } };
        }
        setFormData({
            ...formData,
            ...value,
        });
        if (value.location && placeList.length) {
            setPlaceList((pl) => [
                ...pl.sort((a, b) => {
                    if (!a.location || !b.location || !value.location) return 0;
                    let a1 = GoogleUtils.getDistance(a.location, value.location);
                    let b1 = GoogleUtils.getDistance(b.location, value.location);
                    return a1 - b1;
                }),
            ]);
        }
    };
    return (
        <FormDialog
            open={showMainForm}
            onSubmit={() => onSubmit.run({})}
            onClose={onClose}
            loading={onSubmit.status === 'PROCESSING'}
            heading={'Add Landmark'}
        >
            <>
                <Paper className={classes.paper}>
                    <div className={classes.wrapper}>
                        <Grid container spacing={3}>
                            <Grid item md={12} lg={12}>
                                <TextValidator
                                    value={formData.destinationId || ''}
                                    name="destinationId"
                                    select
                                    onChange={handleSelectChange}
                                    color="primary"
                                    fullWidth
                                    autoComplete="none"
                                    className={classes.textField}
                                    label={'City'}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                >
                                    {cityList
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item) => {
                                            return (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                </TextValidator>
                            </Grid>
                            <Grid item md={12} lg={12}>
                                <InputLabel>{'Search landmark'}</InputLabel>
                                <GoogleLocationSuggest
                                    variant={'standard'}
                                    fullWidth
                                    // placeholder='Search landmark on google'
                                    onResultClick={handleSearchResult}
                                //  suggestionsTypes={['establishment']}
                                />
                            </Grid>
                            {[
                                {
                                    name: 'name',
                                    label: 'Name',
                                    isRequired: true,
                                    multiline: false,
                                },
                                // {
                                //     name: 'shortDescription',
                                //     label: 'Short description',
                                //     multiline: true,
                                // },
                                // {
                                //     name: 'description',
                                //     label: 'Description',
                                //     multiline: true,
                                // },
                            ].map((it, index) => (
                                <Grid item md={12} lg={12} key={index}>
                                    <InputLabel>{it.label}</InputLabel>
                                    <TextValidator
                                        value={formData[it.name as keyof TLandmarks] || ''}
                                        name={it.name}
                                        onChange={handleChange}
                                        color="primary"
                                        fullWidth
                                        autoComplete="off"
                                        className={classes.textField}
                                        // multiLine={it.multiline}
                                        multiline={it.multiline}
                                        validators={it.isRequired ? ['required'] : []}
                                        errorMessages={it.isRequired ? ['this field is required'] : []}
                                    />
                                </Grid>
                            ))}
                            <Grid item md={12} lg={12}>
                                <TextFieldWMention
                                    BoxProps={{ className: classes.textField }}
                                    label="Short Description"
                                    fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                                    value={formData.shortDescription || ''}
                                    handleChange={handleDescriptionChange('shortDescription')}
                                />
                            </Grid>
                            <Grid item md={12} lg={12}>
                                <TextFieldWMention
                                    BoxProps={{ className: classes.textField }}
                                    label="Description"
                                    fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                                    value={formData.description || ''}
                                    handleChange={handleDescriptionChange('description')}
                                />
                            </Grid>
                        </Grid>

                    </div>
                </Paper>
                {formData.destinationId && (
                    <Paper className={classes.listWrapper}>
                        {getPlaces.status === 'PROCESSING' ? (
                            <CircularProgress color="primary" />
                        ) : (
                            <List dense>
                                {placeList.map((it, index) => {
                                    return (
                                        <ListItem dense key={index}>
                                            <ListItemText primary={`${index + 1}. ${it.name}`} secondary={it.city + '\t' + it.status} />
                                            <ListItemSecondaryAction style={{ right: '5px' }}>
                                                {formData.location && it.location && (
                                                    <Typography>
                                                        {toMiles(GoogleUtils.getDistance(it.location, formData.location))}&nbsp;miles
                                                    </Typography>
                                                )}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </Paper>
                )}
            </>
        </FormDialog>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            marginTop: '120px auto',
            width: '480px',
            padding: '40px',
            height: 'auto',
        },
        divider: {
            backgroundColor: theme.palette.grey['500'],
        },
        wrapper: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        listWrapper: {
            width: 400,
            position: 'fixed',
            right: 0,
            bottom: 0,
            top: 64,
            overflowY: 'scroll',
        },
        textField: {
            // marginTop: '15px'
        },
    });
});

export default LandmarkForm;
