import { Fab, makeStyles, Snackbar, SnackbarContent, Theme } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OApp } from '../Models/App';
import { TAppState, TToast } from '../Models/App/@types';
import { TReduxStore } from '../RootReducer';
interface IProps { }


export interface SnackBarProps{
    message : string 
    onClose : () => void
    variant : TToast
}
const useStyles1 = makeStyles((theme: Theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    closeBtn: {
        background: 'transparent',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            background: 'transparent'
        }
    }
}));

function SnackbarContainer (props: SnackBarProps) {
    const {message , onClose ,variant} =    props;
    const classes = useStyles1();

    return (
        <SnackbarContent 
            message={<span className={classes.message}>
                            {message}
                     </span>
            }
            className={clsx(classes[variant])}
            action = {
                <Fab className={classes.closeBtn} onClick={onClose}>
                    <i className = {clsx(classes.icon, 'material-icons') }>close</i>
                </Fab>
            }
        />
    )
        
}



const Toast : React.FC<IProps> = (props) => {
    const {toast} = useSelector<TReduxStore , Pick<TAppState , 'toast'>>(({App}) => ({toast : App.toast}))    
    
    const dispatch = useDispatch();
    const handleClose = (event?: React.SyntheticEvent) => {
            dispatch(OApp.closeToast());
        }
    return (
        <div>
            <Snackbar  
            open={(toast && toast.open) ? toast.open : false}  
            autoHideDuration={3000}
            onClose={handleClose} 
            anchorOrigin={{vertical:'top' , horizontal:'right'}}>
                <SnackbarContainer
                message = {toast && toast.message ? toast.message : ''}
                variant = {toast && toast.variant ? toast.variant : 'success'}
                onClose = {handleClose}
                />
            </Snackbar>
        </div>
    )
}



export default Toast