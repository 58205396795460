import { Box, Button, Chip, createStyles, Fab, Icon, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { TTagCategories, TTag } from '../../Models/Tags/@types';

export interface TagCategoryContainerProps {
    tagCategory: TTagCategories
    dragHandleProps?: any
    ref?: any
    selectedList: TTag[]
    onClick: (item: TTag) => void
    handleEdit: (item: TTagCategories) => void
    handleDelete: (item: TTagCategories) => void
    handleAddTag: (item: TTagCategories) => void
}

const TagCategoryContainer: FC<TagCategoryContainerProps> = (props) => {
    const classes = useStyles();
    const { tagCategory, } = props
    return (
        <Paper className={classes.paper}>
            <Box width='100%' display='flex' justifyContent='space-between' >
                <Typography variant='body1' align='left'>{tagCategory.name}</Typography>
                <Box display='flex' justifyContent='space-between' >
                    <Fab color={'inherit'} size={"small"} onClick={() => { props.handleEdit(tagCategory) }}>
                        <Icon color='action' className={'material-icons'}>edit</Icon>
                    </Fab>
                    <Fab color={'inherit'} size={"small"} onClick={() => { props.handleDelete(tagCategory) }} >
                        <Icon color='action' className={'material-icons'}>delete</Icon>
                    </Fab>
                    <Button variant='text' color='primary' onClick={() => { props.handleAddTag(tagCategory) }}>
                        Add Tag
                    </Button>

                </Box>
            </Box>
            <div className={classes.tagContainer}>
                {tagCategory.tags ?
                    (tagCategory.tags).map((item, index) => {
                        const isSelected = props.selectedList.findIndex(i => i.id === item.id) > -1
                        return <Chip
                            className={classes.tagChip}
                            label={item.name}
                            key={index}
                            deleteIcon={<Icon fontSize='small' color={isSelected ? 'inherit' : 'primary'} className={'material-icons'}>{item.isPublished ? 'lock_open' : 'lock'}</Icon>}
                            onDelete={() => { }}
                            color={isSelected ? 'primary' : 'default'}
                            onClick={() => { props.onClick(item) }}
                        />
                    }) : null
                }
            </div>
            <div
                style={{ top: '50px', right: '-25px', position: 'absolute' }}
                className={'dragHandle'} {...props.dragHandleProps}>
                <Fab color='inherit' size={"small"}>
                    <Icon color='primary' className={'material-icons'} >unfold_more</Icon>
                </Fab>
            </div>
        </Paper>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: '100%',
            padding: '20px 18px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',

        },
        tagContainer: {
            display: 'flex',
            marginTop: 20,
            flexWrap: 'wrap',
            width: '100%',
            height: 'auto'
        },
        tagChip: {

            margin: '5px'
        },
    }))
})

export default TagCategoryContainer