
let STAGING_API_URL = 'https://mamakoo-api.mithyalabs.com/api/';
let PRODUCTTION_API_URL = 'https://admin.mamakoo.com/api/';
let LOCAL_API_URL = 'http://localhost:3004/api/';

let API_BASE_URL: string = process.env.REACT_APP_NODE_ENV || STAGING_API_URL;

switch (process.env.REACT_APP_NODE_ENV) {
    case 'local':
        API_BASE_URL = LOCAL_API_URL;
        break;
    case 'staging':
        API_BASE_URL = STAGING_API_URL;
        break;
    case 'production':
        API_BASE_URL = PRODUCTTION_API_URL
        break;
    default: ;
}

export default {
    API_BASE_URL
}