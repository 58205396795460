import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../../RootReducer';
import { TCity, TCityState } from '../../../../Models/City/@types';
import clsx from 'clsx';
import { getTransformedUrl, IMAGE_UPLOAD_URL, uploadPicture } from '../../../../Resources/PictureUtils';
import DeleteButton from '../../../../Components/Buttons/DeleteButton';
import { TDispatch, TPicture } from '../../../../Resources/GlobalTypes';
import FileInput from '../../../../Components/FileInput';
import EntityImages from '../../../../Components/EntityImages';
import ImageContainer from '../../../../Components/ImageContainer';
import ImageLoader from '../../../../Components/ImageLoader';
import { OApp } from '../../../../Models/App';
import { OCity } from '../../../../Models/City';
import { get } from 'lodash';
import Loader from '../../../../Components/Loader';

export interface ImagesProps {

}

const Images: FC<ImagesProps> = (props) => {
    const classes = useStyles({});
    const { details = {} as TCity } = useSelector<TReduxStore, Pick<TCityState, 'details'>>(({ City }) => ({ details: City.details }));
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>()
    const [pictureList, setPictureList] = useState<TPicture[]>([])
    const [list, setList] = useState<string[]>([]);

    useEffect(() => {
        if (details.id && details.sellImages) {
            setPictureList((details.sellImages || []).filter(i => !!i))
        }
    }, [details])

    // useEffect(() => {
    //     if (details && uploadedPicture) {
    //         dispatch(OApp.showToast({ message: "Picture uploaded", variant: "success" }))
    //         const newImages: TPicture[] = [...(details.sellImages || []).filter(i => !!i), uploadedPicture,]
    //         dispatch({ type: OCity.Actions.ITEM_PATCH_SUCCESS, data: { ...details, sellImages: newImages }, additionalDispatchData: { sellImages: newImages } })
    //         setLoadingCount(loadingCount => loadingCount - 1)
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [uploadedPicture])
    const removeImage = async (item: TPicture) => {
        const key = 'sellImages'
        setLoading(true);
        if (!item) return;
        await dispatch(OCity.patchItem(details.id, { [key]: details[key]?.filter?.((i) => !!i && i.id !== item.id) }));
        setLoading(false);
    };

    const uploadPictures = async (files: any[]) => {
        setLoading(true);
        if (files.length === 0) return;
        try {
            const res = await Promise.all(files.map((file) => uploadPicture(file, 'destinations')));
            const data = res.map((res) => get(res, 'data'));
            await dispatch(OCity.patchItem(details.id, { sellImages: [...(details?.sellImages || []), ...data] }));
        } catch (error) {
            // TODO: handle error
        }
        setLoading(false);
    };

    // const onImagesSelected = (files: any[]) => {
    //     setLoadingCount(files.length);
    // }
    // const onImageUploadComplete = (current: any, response: TPicture) => {
    //     setUploadedPicture(response);
    // }
    const onReOrder = async (oldIndex: number, newIndex: number) => {
        const tImages = [...pictureList]
        var data = tImages[oldIndex];
        tImages.splice(oldIndex, 1);
        tImages.splice(newIndex, 0, data);
        setPictureList([...tImages])
        await dispatch(OCity.patchItem(details.id, { sellImages: [...tImages] }));
    }


    const renderImageItem = (image: TPicture) => {
        return (<ImageContainer
            image={image}
            onImageDelete={async () => await removeImage(image)}
            doReOrder={true}
        />)
    }


    return (
        <Box>
            {loading && <Loader overlayed />}
            <Typography gutterBottom>Images</Typography>
            <EntityImages<TPicture>
                folderName={'establishments'}
                images={pictureList}
                url={IMAGE_UPLOAD_URL}
                handleUpload={uploadPictures}
                renderImageItem={renderImageItem}
                renderLoadingItem={() => <ImageLoader />}
                hasAddButton={true}
                loadingCount={0}
                onReOrder={onReOrder}
            ></EntityImages>
            {/* <Grid container spacing={2}>
                {(details.sellImages || [])
                    .filter((s) => !!s)
                    .map((item) => (
                        <Grid key={item.id} item md={3}>
                            <Card className={clsx(classes.imageContainer)}>
                                <img src={getTransformedUrl(item, 300)} alt={item.name} />
                                <Box className={classes.deleteContainer}>
                                    <div className={classes.imageDeleteButton}>
                                        <DeleteButton
                                            alertMessage="Are you sure you want to delete this image?"
                                            backgroundColor="transparent"
                                            handleDelete={() => removeImage(item)}
                                        />
                                    </div>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                <Grid item md={3}>
                    <Card className={clsx(classes.imageContainer, classes.addBtn)}>
                        <i className="material-icons">add</i>
                        <FileInput accept={'image/*'} multiple={true} onDone={uploadPictures} />
                    </Card>
                </Grid>
            </Grid> */}
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    imageContainer: {
        width: '100%',
        aspectRatio: '1',
        position: 'relative',
        '& img': {
            width: '100%',
            aspectRatio: '1',
        },
    },
    deleteContainer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
    addBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default Images;

function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
