import { Box, Button, Chip, Grid, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldArray, FormikProps } from 'formik';
import React, { FC, useState, useEffect } from 'react';
import { IReactFormProps, MLFormContent } from 'react-forms';
import { textFieldWMentionHelpers } from '../../../Components/Inputs/TextFieldWMention/helpers';
import { RFTextFieldWMentionProps } from '../../../Components/Inputs/TextFieldWMention/RFTextFieldWMention';
import NewEntitySuggest from '../../../Components/NewEntitySuggest';
import ReorderableList from '../../../Components/ReorderableList';
import dimensions from '../../../Constants/dimensions';
import { TEatinerraies, TEatinerraiesSection } from '../../../Models/Eatinerraies/@types';
import PlaceListItem from './PlaceListContainer';

export interface EatinerraiesProps {
    formikProps: FormikProps<Partial<TEatinerraies>>
}

export interface EatineryFormContentProps {
    index: number;
    formikProps: FormikProps<Partial<TEatinerraies>>;
    selectedOptions: any;
    remove: <T>(index: number) => T | undefined
}

export type TSearchType = { name?: string; id: string };
const Eatinerraies: FC<EatinerraiesProps> = (props) => {
    const { formikProps } = props;

    const addNewSection = (push: any) => {
        push({
            title: "",
            description: "",
            placeIds: []
        })
    };

    return (
        <FieldArray name="_sections" render={({ push, remove }) => (
            <div>
                {
                    formikProps?.values?._sections && formikProps?.values?._sections.map((e: TEatinerraiesSection, i: number) => <EatineryFormContent key={i} selectedOptions={e.placeIds} remove={remove} formikProps={formikProps} index={i} />)
                }
                <Button variant="text" color="primary" onClick={() => addNewSection(push)} > Add Section</Button>
            </div>
        )} />
    );

};

export const EatineryFormContent: FC<EatineryFormContentProps> = (props) => {
    const { formikProps, index, remove } = props;
    const classes = useStyles();
    const config: IReactFormProps['config'] = [
        {
            valueKey: `_sections.${index}.title`,
            type: 'text',
            fieldProps: {
                label: 'Section title',
                fullWidth: true,
            },

        },
        {
            valueKey: `_sections.${index}.description`,
            type: 'mention',
            fieldProps: {
                fetchSuggestions: textFieldWMentionHelpers.fetchAutocomplete,
                label: 'Description',
            } as RFTextFieldWMentionProps['fieldProps'],

        }
        // {
        //     valueKey: `_sections.${index}.description`,
        //     type: 'text',
        //     fieldProps: {
        //         label: 'Section description',
        //         fullWidth: true,
        //         multiline: true,
        //     } as TextFieldProps,

        // }
    ];

    const [options, setOptions] = useState<TSearchType[]>([]);

    useEffect(() => {
        let intialOptions = formikProps?.values?._sections && formikProps?.values?._sections[index]?.placeIds ? (formikProps.values?._sections[index]?.placeIds as Array<TSearchType>) : []
        setOptions(intialOptions)
    }, [formikProps, formikProps.values, index]);

    const renderItem = (item: TSearchType, dragHandleProps: any, dragged: boolean) => {
        return (<><PlaceListItem place={item} OnDeletePlace={OnDeletePlace} dragHandleProps={dragHandleProps}></PlaceListItem></>);
    }

    const onSelect = (item: TSearchType) => {
        if (options.findIndex((i) => i.id === item.id) === -1) {
            const toptions = [...options, item];
            setOptions(toptions);
            formikProps.setFieldValue(`_sections.${index}.placeIds`, toptions);
        }
    };

    const onReOrder = (nexList: TSearchType[], movedItem: TSearchType, oldIndex: number, newIndex: number) => {
        const toptions = [...options]
        var data = toptions[oldIndex];
        toptions.splice(oldIndex, 1);
        toptions.splice(newIndex, 0, data);
        setOptions([...toptions])
        formikProps.setFieldValue(`_sections.${index}.placeIds`, toptions);

    }

    const OnDeletePlace = (id: string) => {
        const toptions = [...options.filter((i) => i.id !== id)];
        setOptions(toptions);
        formikProps.setFieldValue(`_sections.${index}.placeIds`, toptions);
    };

    const destinationId = formikProps?.values?.destinationId;

    return (
        <Paper className={classes.paper}>
            <MLFormContent
                formId='explore-form'
                schema={config}
                formikProps={formikProps}
            />
            <NewEntitySuggest
                placeholder="Search and add places"
                type="Establishment"
                clearOnSelection={true}
                onSelection={(i) => onSelect(i as TSearchType)}
                filters={destinationId ? {
                    where: { destinationId }
                } : undefined}
            />
            {options.length > 0 && <Box className={classes.reorderableListContainer} m={2} display="flex" flexWrap="wrap">
                <ReorderableList<TSearchType>
                    list={options}
                    renderItem={renderItem}
                    handleUpdateListOrder={onReOrder}
                />
            </Box>}
            {index !== 0 && <Grid className={classes.deleteSection} container> <Button variant="text" color="primary" onClick={() => remove(index)}> Delete Section</Button></Grid>}
        </Paper>
    );

}

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            width: dimensions.formPaperMaxWidth,
            padding: theme.spacing(2, 1.5),
            marginBottom: 15,
            display: 'flex',
            flexDirection: 'column',
        },
        labelColor: {
            color: theme.palette.primary.main,
            cursor: "pointer",
            marginTop: 10

        },
        placeList: {
            maxWidth: "100%"
        },
        deleteSection: {
            marginTop: 10
        },
        reorderableListContainer: {
            '& > div': {
                width: '90%'
            }
        }
    });
});


export default Eatinerraies;
