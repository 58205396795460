  import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { TRequestConfig } from 'loop-front';
import Cookies from 'universal-cookie';
import Config from './Config';
axios.defaults.headers.post['Content-Type'] = 'application/json';
// const IMAGE_KIT_URL = 'https://mithya.imagekit.io/stayuncle/';
const cookies = new Cookies();

let utilities = {
    delay: (ms: number = 1000) => new Promise(resolve => setTimeout(resolve, ms)),
    pipe: (...fns) => (arg) => fns.reduce((acc, fn) => fn(acc), arg),
    updateItemList: (list: Array<any>, item: any, action: 'ADD' | 'DELETE' | 'UPDATE' | 'PUT'): typeof list => {
        list = list || [];
        let newList = list.slice();
        let itemIndex;
        if (action === 'UPDATE') {
            itemIndex = newList.findIndex(listItem => item.id === listItem.id);
            if (itemIndex !== -1)
                newList.splice(itemIndex, 1, { ...list[itemIndex], ...item });
            return newList;
        } else if (action === 'ADD') {
            newList.unshift(item);
            return newList;
        } else if (action === 'DELETE') {
            return newList.filter(listItem => item.id !== listItem.id);
        }
        else if (action === 'PUT') {
            itemIndex = newList.findIndex(listItem => item.id === listItem.id);
            if (itemIndex !== -1)
                newList.splice(itemIndex, 1, item);
            else {
                newList.push(item);
            }
            return newList;
        }
        return newList;
    },
    toggleItemFromList: (list: any[] = [], item: any, key: string = 'id', comaprisonFunction?: (currentItem: any, item: any) => boolean) => {
        let updatedList: any[] = [...list];
        let index = list.findIndex(i => comaprisonFunction ? comaprisonFunction(i, item) : i[key] === item[key]);
        index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
        return updatedList;
    },
    saveUser: (accessToken: string = '', userId: string = '') => {
        cookies.set('access_token', accessToken, { path: '/' });
        cookies.set('userId', userId, { path: '/' });
    },
    clearCookies: () => {
        cookies.remove('access_token', { path: '/' });
        cookies.remove('userId', { path: '/' });
    },

    isAuthenticated: () => {
        return Boolean(cookies.get('access_token'));
    },

    getUserId: () => {
        return cookies.get('userId');
    },
    getAccessToken: () => {
        return cookies.get('access_token');
    },
    getCsvDownloadLink(modelName: string) {
        return `${Config.API_BASE_URL}AppModels/generate-csv?modelName=${modelName}&access_token=${this.getAccessToken()}`
    },
    request: (config: AxiosRequestConfig & TRequestConfig) => {
        console.log(`${Config.API_BASE_URL}${config.url}`, config)
        console.log('API Request', `${axios.defaults.baseURL}${config.url}`);
        console.log('Config', config);
        return axios.request({
            ...config,
            url: `${Config.API_BASE_URL}${config.url}`,
            headers: {
                Authorization: utilities.getAccessToken()
            }
        });
    },
    jsonParser: (data: any, keys: Array<string>) => {
        let updatedObject: any
        if (Array.isArray(data)) {
            updatedObject = [...data];
            updatedObject.forEach((objItem: any) => {
                for (let i = 0; i < keys.length; i++) {
                    try {
                        if (typeof objItem[keys[i]] === 'string') objItem[keys[i]] = JSON.parse(objItem[keys[i]])
                        else continue;
                    } catch (e) { objItem[keys[i]] = {} }
                }
            });
        }
        else {
            updatedObject = { ...data };
            for (let i = 0; i < keys.length; i++) {
                try {
                    if (typeof data[keys[i]] === 'string') updatedObject[keys[i]] = JSON.parse(data[keys[i]]);
                    else continue;
                } catch (e) { updatedObject[keys[i]] = {} }
            }
        }
        return updatedObject;
    },
    sequentialPromises: (items: any[], asyncFunc: any, onEachItem: any) => {
        if (!_.isArray(items) || !_.isFunction(asyncFunc))
            return Promise.reject('No item or method provided');
        return items.reduce((previous, current) => (
            previous.then((accumulator: any) => (
                asyncFunc(current).then((result: any) => {
                    if (_.isFunction(onEachItem))
                        onEachItem(current, result);
                    return accumulator.concat(result);
                })
            ))
        ), Promise.resolve([]));
    },
    validateEmail: (email: string) => {
        // eslint-disable-next-line
        let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email)
            return false;

        if (email.length > 254)
            return false;

        var valid = tester.test(email);
        if (!valid)
            return false;

        // Further checking of some things regex can't handle
        var parts = email.split("@");
        if (parts[0].length > 64)
            return false;

        var domainParts = parts[1].split(".");
        if (domainParts.some(function (part) { return part.length > 63; }))
            return false;

        return true
    },

    validateUrl: (uri: string): boolean => {
        // eslint-disable-next-line
        let tester = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        if (!uri) return false;
        return tester.test(uri);
    }
}



export default utilities;