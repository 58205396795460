import React from 'react'
import { createStyles, makeStyles, Theme, Box, LinearProgress, useTheme } from '@material-ui/core'
import { Paper } from '@material-ui/core';

interface IProps { }

const ImageLoader: React.FC<IProps> = (props) => {
    const theme = useTheme<Theme>()
    const classes = useStyles(theme)

    return (
        <Paper>
            <Box minHeight="200px" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Box width="80%" className={classes.container}>
                    <LinearProgress color="primary" />
                </Box>
            </Box>
        </Paper>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        '& > *': {
            width: '100%'
        }
    }
}))

export default ImageLoader