import { Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import ReviewCard from '../../../../Components/Cards/ReviewCard';
import { OUser } from '../../../../Models/User';
import { TUserState } from '../../../../Models/User/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import { TReview } from '../../../../Models/Review/@types';
import { OReview } from '../../../../Models/Review';
import { OApp } from '../../../../Models/App';

const Reviews: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {

    const userId = match.params['id'];

    const dispatch = useDispatch<TDispatch>();

    const { selectedUserReviews = [] } = useSelector<TReduxStore, Pick<TUserState, 'selectedUserReviews'>>(({ User }) => ({ selectedUserReviews: User.selectedUserReviews }));

    useEffect(() => {
        dispatch(OUser.getEntityByItem(userId, OUser.Entities.REVIEWS, { filter: { include: ['user', 'subject'] } }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handlePublish = async (item: TReview) => {

        try {
            const currentIndex = selectedUserReviews.findIndex(i => item.id === i.id)
            selectedUserReviews.splice(currentIndex, 1, { ...item, isPublished: !item.isPublished })
            await dispatch(OReview.postItemActivity(item.id, OReview.Activities.PUBLISH, {}, { publish: !item.isPublished }))
            dispatch(OApp.showToast({ variant: 'success', message: item.isPublished ? 'Unpublished!' : "Published!" }))
            dispatch({ type: OUser.Actions.SINGLE_REVIEW_PUBLSIHED_OF_PLACE, data: [...selectedUserReviews] })

        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ variant: 'error', message: 'Item Publish/Unpublish fail' }))
        }
    }
    return (
        <Box width={700} margin={'auto'} >
            {
                selectedUserReviews.map((item, i) => {
                    return <Box mb={5} key={i} >
                        <ReviewCard
                            review={item}
                            handlePublish={handlePublish}
                        /></Box>
                })
            }
        </Box>
    )
}

export default withRouter(Reviews);