import { TCity } from '../City/@types';
import { TDestinationPack } from '../DestinationPack/@types';

interface SummaryEntityData {
    total: number;
    published: number;
}

export interface TToastConfig {
    variant?: TToast;
    message?: string;
    open?: boolean;
}

export type TConstant = NameValuePair;
export interface TTypeMap {
    [key: string]: string[];
}
export interface TAppConstants {
    Continent: TConstant[];
    Establishment: {
        status: TConstant[];
        types: TConstant[];
        typeMapping: TTypeMap;
    };
    FoodDrink: {
        types: string[];
    };
    KnowsAbout: {
        how: TConstant[];
    };
    user: {
        drink: TConstant[];
        foodPreference: TConstant[];
    };
    Coupon: {
        types: TConstant[];
    };
}

export interface TAppState {
    toast?: TToastConfig;
    summary?: TSummary;
    constants?: TAppConstants;
    cityList: TCity[];
    destinationPacks?: TDestinationPack[];
}

export interface TSummary {
    Destination: SummaryEntityData;
    Establishment: SummaryEntityData;
    FoodDrink: SummaryEntityData;
    Cuisine: SummaryEntityData;
    Coupon: SummaryEntityData;
    Landmark: SummaryEntityData;
    List: SummaryEntityData;
    Tag: SummaryEntityData;
    Eateries: SummaryEntityData;
    Quote: SummaryEntityData;
    User: { total: number };
}
export interface TFilterConfig {
    name: string;
    isActive: boolean;
    value?: string;
}
export interface TCount {
    tags?: number;
    reviews?: number;
    foodDrinks?: number;
    images?: number;
    cuisines?: number;
    ingredients?: number;
    establishments?: number;
    favourites?: number;
    wishes?: number;
    suggested?: number;
    landmarks?: number;
    lists?: number;
}

export type NameValuePair<T = string> = {
    name: string;
    value: T;
};
export const pictureOrientations: Array<string> = [
    'Portrait',
    'Landscape',
    'Animated',
    'Website',
    'Marketing',
];
export type TToast = 'error' | 'success' | 'warning' | 'info';
