import { Box, Grid } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';
import _ from 'lodash';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Pagination, { PaginationComponentProps } from '../Pagination';

interface IProps<T = unknown> {
    data: T[] | undefined
    cols?: 1 | 2 | 3 | 4
    renderItem: (item: T) => React.ReactChild
    renderLottieItem?: (item: T, index: number) => React.ReactChild
    loadingCount?: number
    spacing?: GridProps['spacing']
    renderLoading?: () => JSX.Element
    paginationProps?: Omit<PaginationComponentProps, 'classes'>
    onReOrder?: (oldIndex: number, newIndex: number) => void
    className?: string
}

function Gallery<T extends any>(props: IProps<T>) {

    const { data = [], cols = 3, loadingCount = 0, renderLoading = () => null, spacing = 2 , className} = props;

    const breakpoint = () => {
        return (12 / cols as 1 | 2 | 3 | 4 | 6 | 12);
    }
    const SortableItem = SortableElement(({ value }: { value: any }) => { return <>{value}</> })
    const SortableList = SortableContainer(({ items }: { items: any[] }) => {
        return (
            <Grid container spacing={spacing} alignItems='flex-end'>{
                items.map((value, index) => {
                    return <SortableItem key={index} index={index} value={value} />
                })
            }</Grid>
        )
    })
    const gridList = data.map((item, i) => (
        <Grid item key={item.id || i} md={breakpoint()} xs={12}>
            {props.renderLottieItem ? props.renderLottieItem(item, i) : props.renderItem(item)}
        </Grid>
    ))

    if (!data)
        return <div />



    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        if (props.onReOrder)
            props.onReOrder(oldIndex, newIndex);
    };
    return (
        <Box style={{maxWidth:"100%"}}>
            {

                loadingCount > 0 && <Grid item md={breakpoint()} xs={12}>
                    {renderLoading()}
                </Grid>

            }
            {
                <SortableList shouldCancelStart={e => _.get(e.target, 'id') !== 'drag'} items={gridList} axis={'xy'} onSortEnd={onSortEnd} />

            }

            <Box width="500px" m="0 auto">
                {
                    props.paginationProps ?
                        <Pagination
                            {...props.paginationProps}
                        /> : null
                }
            </Box>
        </Box>
    )
}



export default Gallery