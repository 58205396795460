import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import EntityForm from '../../../../Components/Forms/EntityForm';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import ReorderableList from '../../../../Components/ReorderableList';
import EntityCard from '../../../../Features/Home/EntityCard';
import { TReduxStore } from '../../../../RootReducer';
import { THomeSectionState } from '../../../../Models/HomeSection/@types';
import { OHomeSection } from '../../../../Models/HomeSection';
import TitleFormField from '../../Forms/TitleFormField';
import LockIcon from '../../../../Components/LockIcon';

export interface ExploreCityProps {

}

export const EXPLORE_CITY_SLUG = 'explore_city';

const ExploreCity: FC<ExploreCityProps> = (props) => {
    const classes = useStyles({});
    const [showForm, setShowForm] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const { sections, initialized, exploreCityList } = useSelector<TReduxStore, Pick<THomeSectionState, 'sections' | 'initialized' | 'exploreCityList'>>((
        { Home: { sections, initialized, exploreCityList } }
    ) => ({ sections, initialized, exploreCityList }))

    const section = sections.find(s => s.slug === EXPLORE_CITY_SLUG);
    const dispatch = useDispatch();

    useEffect(() => {
        if (initialized) {
            if (!section) return;
            dispatch(OHomeSection.getEntityByItem(section.id, OHomeSection.Entities.sectionEntity, { filter: { include: 'subject' } }, undefined, { slug: section.slug }))
        }
    }, [initialized])

    useEffect(() => {
        setList(exploreCityList.map(l => ({ doc_type: l.subjectType, sectionId: l.id, ...l.subject })))
    }, [exploreCityList])



    const handleAddEntity = () => {
        setShowForm(true);
    }

    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddEntity,
            helpText: 'Add City',
        }
    ]


    const handleSave = async (data: any[]) => {
        if (!section) return;
        dispatch(OHomeSection.updateSections(section.id, list, data))
        setList(data);
        setShowForm(false);
    }

    const handleReorder = (updatedList: any[], movedItem: any) => {
        if (!section) return;
        const newOrder = updatedList.findIndex(x => x.id === movedItem.id);
        if (newOrder === -1) return;
        dispatch(OHomeSection.reorderItem(section.id, movedItem.sectionId, newOrder + 1))
        setList(updatedList);
    }

    const handleRemove = (entitySectionId: string) => {
        if (!section) return;
        dispatch(OHomeSection.addRemove(section.id, [], [entitySectionId]))
    }

    const renderList = (item: any, draghandleProps: any) => {
        return (
            <EntityCard
                imgUrl={item._pictures?.[0]?.url}
                subtitle={item.isPublished ? '' : 'Not Published'}
                title={item.name}
                key={item.id}
                draghandleProps={draghandleProps}
                actionComponent={item.isPublished ? null : <LockIcon />}
                menuProps={{
                    id: item.id,
                    items: [
                        { label: 'remove', onClick: () => handleRemove(item.sectionId) }
                    ]
                }}
            />
        )
    }

    return (
        <Box className={classes.root}>
            <Box position="sticky" top={0}>
                <HeaderActionButtons items={headerItems} />
            </Box>
            {section ? (
                <TitleFormField section={section} />
            ) : null}
            <Box my={5}>
                <ReorderableList<any>
                    handleUpdateListOrder={handleReorder}
                    list={list}
                    renderItem={renderList}
                />
            </Box>
            <EntityForm
                title="Add City"
                entities={["Destination"]}
                closeForm={() => setShowForm(false)}
                items={list}
                onFormSubmit={handleSave}
                open={showForm}
            />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.sm,
        margin: '0 auto',
        paddingBottom: 100
    },
}));

export default ExploreCity;