import LoopFront from "loop-front"
import { OCoupons } from "."
import { TDispatch } from "../../Resources/GlobalTypes"
import { OApp } from "../App"
import { TFilterConfig } from "../App/@types"
import { TCoupons } from "./@types"

const CouponsModel = {
    getCouponsList: (pageNumber: number, filterConfig: TFilterConfig[]) => {
        return (async (dispatch: TDispatch) => {
            const filter = OApp.parseBaseFilter(filterConfig)
            await dispatch(OCoupons.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    includeCount: true,
                    ...filter
                }
            }))
            if (pageNumber === 1)
                await dispatch(OCoupons.getActivity(OCoupons.Activities.COUNT, { ...filter }))
        })
    },
    requestDelete: (coupon: TCoupons) => (
        LoopFront.request({
            url: '/Coupons/trash',
            data: {
                ids: [coupon.id]
            },
            method: 'DELETE'
        })
    ),
    deleteCoupons: (coupon: TCoupons) => {
        return (async (dispatch: TDispatch) => {
            try {
                await CouponsModel.requestDelete(coupon)
                dispatch({ type: OCoupons.Actions.ITEM_DELETED, additionalDispatchData: coupon })
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }));
                throw err

            }
        })
    }
}
export default CouponsModel
