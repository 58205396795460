import { TEatineraryFilterConfig } from '../Components/Filters/EatineraryFilterMenu';
import { TUserFilterConfig } from '../Models/User/@types';

export const ITEM_PER_PAGE_COUNT = 30;

export const CITY_ITEM_PAGE_COUNT = 21;

export const DESTINATION_PACK_CITITES_LIMIT = 21;

export const ALL_ENTITIES_DOC_TYPES = [
    'Cuisine',
    'Establishment',
    'Destination',
    'FoodDrink',
    'List',
    'Eateries',
    'Landmark',
];

export const DEFAULT_USER_FILTER_OPTIONS: TUserFilterConfig = {
    type: [
        { name: `All`, isActive: false, value: `All` },
        { name: `Recommender`, isActive: false, value: `Recommender` },
        { name: `Non recommender`, isActive: false, value: `Non recommender` },
    ],
    cities: [
        { name: `All`, isActive: false, value: `All` },
        { name: `Insider`, isActive: false, value: `Insider` },
        { name: `Trial`, isActive: false, value: `Trial` },
        { name: `Basic`, isActive: false, value: `Basic` },
        {
            name: `Used to be insider`,
            isActive: false,
            value: `Used to be insider`,
        },
    ],
    // baseConfig: [
    //     { name: `All`, isActive: true, value: `All` },
    //     { name: `Recommender`, isActive: false, value: `Recommender` },
    //     { name: `Non recommender`, isActive: false, value: `Non recommender` },
    //     { name: `World member`, isActive: false, value: `World member` },
    //     { name: 'Basic member', isActive: false, value: 'Basic member' },
    // ],
    // sec3: [],
    // order: 'name ASC',
};

export const DEFAULT_EATINERARY_FILTER_OPTIONS: TEatineraryFilterConfig = {
    type: [
        { name: 'All', isActive: true },
        { name: 'Published', isActive: false },
        { name: 'Unpublished', isActive: false },
        { name: 'Newly Added', isActive: false },
        { name: 'Recently Updated', isActive: false },
    ],
    cities: []
    // baseConfig: [
    //     { name: `All`, isActive: true, value: `All` },
    //     { name: `Recommender`, isActive: false, value: `Recommender` },
    //     { name: `Non recommender`, isActive: false, value: `Non recommender` },
    //     { name: `World member`, isActive: false, value: `World member` },
    //     { name: 'Basic member', isActive: false, value: 'Basic member' },
    // ],
    // sec3: [],
    // order: 'name ASC',
};
