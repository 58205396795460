import {
    Box,
    Button,
    createStyles,
    Fab,
    FormControlLabel,
    Icon,
    IconButton,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Theme,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../Components/Dialogs/ConfirmationDialog';
import NewEntitySuggest from '../../Components/NewEntitySuggest';
import ReorderableList from '../../Components/ReorderableList';
import { TFilterConfig } from '../../Models/App/@types';
import { OTagCategories } from '../../Models/Tags';
import { TTag, TTagCategories, TTagCategoriesState } from '../../Models/Tags/@types';
import { deleteMultipleTags, deleteTagCategory, getTagsList, publishTag, reordeTagList } from '../../Models/Tags/actions';
import { TDispatch } from '../../Resources/GlobalTypes';
import { TReduxStore } from '../../RootReducer';
import TagCategoryForm from './Forms/TagCategoryForm';
import TagForm from './Forms/TagForm';
import TagCategoryContainer from './TagCategoryContainer';
export interface indexProps {}

export interface TDragList {
    item: TTagCategories;
    itemSelected: number;
    dragHandleProps: object;
}
export type TSelectedTagList = { tagCategoryId: string; tags: TTag[] };
const Tags: FC<indexProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [popperOpen, setPopperOpen] = React.useState(false);
    const {
        list = [],
        showTagCategoryForm = false,
        showtagForm = false,
    } = useSelector<TReduxStore, Pick<TTagCategoriesState, 'list' | 'showTagCategoryForm' | 'showtagForm'>>(({ TagCategories }) => ({
        list: TagCategories.list,
        showTagCategoryForm: TagCategories.showTagCategoryForm,
        showtagForm: TagCategories.showtagForm,
    }));
    const [query, setQuery] = useState('');
    const [searchList, setSearchList] = useState<TTagCategories[]>([]);
    // const [selectedTags, setSelectedtags] = useState<TTag[]>([]);
    const [selectedTagList, setSelectedTagList] = useState<TSelectedTagList[]>([]);
    const [showTagDialog, setShowTagDialog] = useState<boolean>(false);
    const [showCategoryDialog, setShowCategoryDialog] = useState<boolean>(false);
    const [categoryDelete, setCategoryDelete] = useState<TTagCategories>();
    const [itemList, setItemList] = useState<TTagCategories[]>([]);
    const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>([
        { name: `All`, isActive: true },
        { name: `Show To Users`, isActive: false },
        { name: `Appears on filters`, isActive: false },
    ]);
    useEffect(() => {
        setItemList([...list]);
    }, [list]);
    const getData = async () => {
        try {
            //await dispatch(OTagCategories.getItemsList({ filter: { include: "tags", order: "name ASC" } }))
            await dispatch(getTagsList(filterConfig));
        } catch (err) {
            console.log('Tag fetch Error ', err);
        }
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setPopperOpen(!popperOpen);
    };
    const handleFilterChange = useCallback((item: TFilterConfig) => {
        const updatedFilter = filterConfig.map((i) => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        });
        setFilterConfig([...updatedFilter]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onFilterSubmit = useCallback(() => {
        getData();
        setAnchorEl(null);
        setPopperOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onFilterReset = useCallback(() => {
        const tempFilter = [...filterConfig];
        tempFilter[0].isActive = true;
        tempFilter[1].isActive = false;
        tempFilter[2].isActive = false;
        setFilterConfig([...tempFilter]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSearch = (result: TTagCategories[], query: string) => {
        setQuery(query);
        setSearchList([...result]);
    };
    const handleItemRedorder = async (newList: TTagCategories[], item: TTagCategories, newIndex: number, oldIndex: number) => {
        let queue = [...itemList];
        try {
            setItemList([...newList]);
            if (newIndex === 1) await dispatch(reordeTagList(item.id, 1, newList));
            else {
                if (newList[newIndex - 1]) await dispatch(reordeTagList(item.id, (newList[newIndex - 1].order || 0) + 1, newList));
            }
        } catch (err) {
            console.log(err);
            setItemList([...queue]);
        }
    };
    const searchProps = {
        type: 'TagCategory',
        filters: { include: 'tags', fields: ['id', 'name', 'tags'] },
    };

    const handleTagClick = (item: TTag, category: TTagCategories) => {
        let list = [...selectedTagList];
        let index = list.findIndex((i) => i.tagCategoryId === category.id);
        if (index > -1) {
            let tIndex = list[index].tags.findIndex((i) => i.id === item.id);
            if (tIndex === -1) {
                list[index].tags.push(item);
            } else {
                list[index].tags.splice(tIndex, 1);
                if (list[index].tags.length === 0) {
                    list.splice(index, 1);
                }
            }
        } else {
            list.push({ tagCategoryId: category.id, tags: [item] });
        }
        setSelectedTagList([...list]);
        // const tempList = utilities.toggleItemFromList(selectedTags, item);
        // setSelectedtags([...tempList])
    };
    const deleteCategory = async (item: TTagCategories) => {
        setCategoryDelete(item);
        setShowCategoryDialog(true);
    };
    const deleteTags = async () => {
        setShowTagDialog(true);
    };
    const confirmDelete = () => {
        if (showCategoryDialog) {
            categoryDelete && dispatch(deleteTagCategory(categoryDelete));

            setShowCategoryDialog(false);
        } else {
            dispatch(deleteMultipleTags(selectedTagList, itemList));
            setSelectedTagList([]);
            setShowTagDialog(false);
        }
    };
    const addCategory = () => {
        dispatch({ type: OTagCategories.Actions.SHOW_TAG_CATEGORY_FORM });
    };
    const editCategory = (item: TTagCategories) => {
        dispatch({ type: OTagCategories.Actions.SHOW_TAG_CATEGORY_FORM, data: item });
    };
    const addSingleTag = (item: TTagCategories) => {
        dispatch({ type: OTagCategories.Actions.SHOW_TAG_FORM, additionalDispatchData: item });
    };
    const editSingleTag = () => {
        dispatch({ type: OTagCategories.Actions.SHOW_TAG_FORM, data: selectedTagList[0].tags[0] });
    };
    const handlePublish = async () => {
        if (selectedTagList[0]?.tags[0]) {
            await dispatch(publishTag([selectedTagList[0].tags[0]], list));
            setSelectedTagList([]);
        }
    };
    const renderTagCategory = (item: TTagCategories, dragHandleProps: any, dragged: boolean) => {
        return (
            <TagCategoryContainer
                tagCategory={item}
                dragHandleProps={dragHandleProps}
                selectedList={selectedTagList.find((i) => i.tagCategoryId === item.id)?.tags || []}
                onClick={(t) => handleTagClick(t, item)}
                handleEdit={editCategory}
                handleDelete={deleteCategory}
                handleAddTag={addSingleTag}
            />
        );
    };

    return (
        <div className={classes.root}>
            <Box width="700px" margin="0 auto" position="relative">
                <Box width="700px" position="relative" top="0" zIndex="100">
                    <Paper square>
                        <NewEntitySuggest placeholder="Search tags" onResults={handleSearch} {...searchProps} />
                    </Paper>
                    <Box mt="45px">
                        <ReorderableList<TTagCategories>
                            list={query ? searchList : itemList}
                            renderItem={renderTagCategory}
                            handleUpdateListOrder={handleItemRedorder}
                        />
                    </Box>
                </Box>
            </Box>
            <Popper open={popperOpen} anchorEl={anchorEl} placement={'top'} style={{ zIndex: 1500 }}>
                <Paper className={classNames(classes.popperOutline, classes.popperPaper)}>
                    <IconButton
                        color="inherit"
                        className={classes.crossButton}
                        onClick={() => {
                            setAnchorEl(null);
                            setPopperOpen(false);
                        }}
                    >
                        <i color="action" className="material-icons">
                            close
                        </i>
                    </IconButton>
                    <RadioGroup
                        className={classes.formGroup}
                        onChange={(e) => handleFilterChange(filterConfig.find((i) => i.name === e.target.value)!)}
                    >
                        {filterConfig.map((item, i) => {
                            return (
                                <FormControlLabel
                                    key={i}
                                    className={classes.checkbox}
                                    value={item.name}
                                    control={<Radio checked={item.isActive} color="primary" />}
                                    label={item.name}
                                />
                            );
                        })}
                    </RadioGroup>
                    <div className={classes.buttonsContainer}>
                        <Button className={classes.btn} color="primary" variant="contained" onClick={onFilterSubmit}>
                            Apply
                        </Button>
                        <Button className={classes.btn} color="primary" variant="contained" onClick={onFilterReset}>
                            Reset
                        </Button>
                    </div>
                </Paper>
            </Popper>
            <div className={classes.sideButtonDiv} style={{ right: window.innerWidth / 2 - 540 }}>
                {selectedTagList.length === 1 && selectedTagList[0].tags.length === 1 ? (
                    <Tooltip title={selectedTagList[0]?.tags[0]?.isPublished ? 'Unpublish selected tags' : 'Publish selected tags'}>
                        <Fab color="inherit" size="medium" className={classes.actionButton} onClick={handlePublish}>
                            <Icon color={selectedTagList[0]?.tags[0]?.isPublished ? 'secondary' : 'primary'} className={'material-icons'}>
                                {selectedTagList[0]?.tags[0]?.isPublished ? 'lock_open' : 'lock'}
                            </Icon>
                        </Fab>
                    </Tooltip>
                ) : null}
                {selectedTagList.length === 1 && selectedTagList[0].tags.length === 1 && (
                    <Fab color="inherit" size="medium" className={classes.actionButton} onClick={editSingleTag}>
                        <Icon color="action" className={'material-icons'}>
                            edit
                        </Icon>
                    </Fab>
                )}
                {selectedTagList.length > 0 && (
                    <Fab color="inherit" size="medium" className={classes.actionButton} onClick={deleteTags}>
                        <Icon color="action" className={'material-icons'}>
                            delete
                        </Icon>
                    </Fab>
                )}
                <Fab
                    color="primary"
                    size="medium"
                    className={classes.actionButton}
                    onClick={() => {
                        addCategory();
                    }}
                >
                    <Icon color="inherit" className={'material-icons'}>
                        add
                    </Icon>
                </Fab>
                <Fab color="inherit" size="medium" className={classes.actionButton} onClick={handleFilterClick}>
                    <Icon color="primary" className={'material-icons'}>
                        filter_list
                    </Icon>
                </Fab>
            </div>
            {showTagCategoryForm && <TagCategoryForm getData={getData} />}
            {showtagForm && <TagForm getData={getData} />}
            {(showCategoryDialog || showTagDialog) && (
                <ConfirmationDialog
                    open={showCategoryDialog || showTagDialog}
                    message={'Are you sure you want to delete?'}
                    onAgree={confirmDelete}
                    onDisagree={() => {
                        setShowCategoryDialog(false);
                        setShowTagDialog(false);
                    }}
                    onClose={() => {}}
                    title="Delete"
                />
            )}
        </div>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            margin: '0 auto',
        },
        listWrapper: {
            position: 'fixed',
            top: 0,
            bottom: 0,
            padding: '0px 20px',
            overflow: 'auto',
        },
        mainList: {
            width: 700,
        },
        paper: {
            width: '100%',
            padding: '30px 18px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
        },
        sideButtonDiv: {
            bottom: 20,
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-end',
            position: 'fixed',
        },
        actionButton: {
            marginBottom: 15,
        },
        popperOutline: {
            outline: 0,
        },
        popperPaper: {
            width: '200px',
            borderRadius: 6,
            overflow: 'hidden',
            position: 'relative',
            paddingTop: 35,
            zIndex: 100,
        },
        formGroup: {
            paddingLeft: 15,
        },
        checkbox: {
            height: 30,
        },
        crossButton: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: 15,
        },
        btn: {
            flex: 1,
            borderRadius: 0,
        },
    });
});

export default Tags;
