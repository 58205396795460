import { Box, createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import SummaryCard, {
    SummaryCardProps,
} from '../../Components/Cards/SummaryCard';
import Dash from '../../Components/Dash';
import Loader from '../../Components/Loader';
import { TAppState } from '../../Models/App/@types';
import { TReduxStore } from '../../RootReducer';

export interface DashboardProps extends RouteComponentProps {}

const Dashboard: FC<DashboardProps> = ({ history }) => {
    const classes = useStyles();

    const { summary } = useSelector<TReduxStore, Pick<TAppState, 'summary'>>(
        ({ App }) => ({ summary: App.summary })
    );

    const getUnpublishedCount = (
        entity:
            | 'List'
            | 'Landmark'
            | 'Coupon'
            | 'Destination'
            | 'Establishment'
            | 'FoodDrink'
            | 'Cuisine'
    ) => (summary ? summary[entity].total - summary[entity].published : 0);

    const Summary_Data_Link: {
        url: string;
        subtitles?: SummaryCardProps['subtitles'];
        title: string;
    }[] = summary
        ? [
              {
                  title: `CITIES - ${summary.Destination.total}`,
                  subtitles: [
                      `Published - ${summary.Destination.published}`,
                      `Unpublished - ${getUnpublishedCount('Destination')}`,
                  ],
                  url: '/dashboard/cities',
              },
              {
                  title: `PLACES - ${summary.Establishment.total}`,
                  subtitles: [
                      `Published - ${summary.Establishment.published}`,
                      `Unpublished - ${getUnpublishedCount('Establishment')}`,
                  ],
                  url: '/dashboard/places',
              },
              {
                  title: `DISHES - ${summary.FoodDrink.total}`,
                  subtitles: [
                      `Published - ${summary.FoodDrink.published}`,
                      `Unpublished - ${getUnpublishedCount('FoodDrink')}`,
                  ],
                  url: '/dashboard/dishes',
              },
              {
                  title: `CUISINE - ${summary.Cuisine.total}`,
                  subtitles: [
                      `Published - ${summary.Cuisine.published}`,
                      `Unpublished - ${getUnpublishedCount('Cuisine')}`,
                  ],
                  url: '/dashboard/cuisines',
              },
              {
                  title: `LANDMARKS - ${summary.Landmark.total}`,
                  subtitles: [
                      `Published - ${summary.Landmark.published}`,
                      `Unpublished - ${getUnpublishedCount('Landmark')}`,
                  ],
                  url: '/dashboard/landmarks',
              },
              {
                  title: `EXPLORE - ${summary.List.total}`,
                  subtitles: [
                      `Published - ${summary.List.published}`,
                      `Unpublished - ${getUnpublishedCount('List')}`,
                  ],
                  url: '/dashboard/explore',
              },
              {
                  title: `COUPONS - ${summary.Coupon.total}`,
                  subtitles: [
                      `Published - ${summary.Coupon.published}`,
                      `Unpublished - ${getUnpublishedCount('Coupon')}`,
                  ],
                  url: '/dashboard/coupons',
              },
              {
                  title: `USERS - ${summary.User.total || 0}`,
                  // subtitles:
                  //     [
                  //         `Published - ${summary.Cuisine.published}`,
                  //         `Unpublished - ${getUnpublishedCount('Cuisine')}`
                  //     ],
                  url: '/dashboard/users',
              },
          ]
        : [];
    return (
        <div className={classes.root}>
            {summary ? (
                <div className={classes.container}>
                    {Summary_Data_Link.map((item, index) => {
                        return (
                            <Box key={index} my={1} display="flex">
                                <SummaryCard
                                    onClick={() => history.push(item.url)}
                                    title={item.title}
                                    subtitles={item.subtitles || []}
                                />
                                {index < 7 && (
                                    <div className={classes.dashContainer}>
                                        <Dash />
                                    </div>
                                )}
                            </Box>
                        );
                    })}
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
        },
        container: {
            // width: '90%',
            margin: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        dashContainer: {
            height: 60,
            alignSelf: 'center',
            margin: 20,
        },
    })
);

export default Dashboard;
