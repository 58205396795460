import _ from 'lodash';
import { TPicture } from './GlobalTypes';
import utilities from './Utils';
export const IMAGE_UPLOAD_URL = 'pictures/upload';
const IMAGEKIT_BASE_URL = 'https://ik.imagekit.io/mithya/';

export const uploadPicture = (file: any, imagesFolder: string) => {
    if (!file.base64)
        return Promise.reject('Could not find base64 encoding of file');
    imagesFolder = imagesFolder || 'images';
    if (!(/\/$/.test(imagesFolder)))
        imagesFolder += '/';
    return utilities.request({
        url: IMAGE_UPLOAD_URL,
        method: 'POST',
        data: {
            base64img: file.base64,
            filename: file.name,
            folder: imagesFolder
        }
    });
}
export const getTransformedUrl = (image?: TPicture, width: number = 800) => {
    if (!image) return;
    return `${IMAGEKIT_BASE_URL}tr:w-${width}${image.filePath}`;
}
export const addImageToEntity = (picture: any, entityRelationUrl: string) => {
    if (_.isEmpty(picture) || !entityRelationUrl) {
        return Promise.reject('Picture object is empty or entity relation url not found');
    }
    return new Promise((resolve, reject) => {
        utilities.request({
            url: entityRelationUrl,
            method: 'POST',
            data: picture
        }).then(
            res => resolve(res.data),
            err => reject(err)
        );
    });
};

export const uploadEntityPicture = (file: any, entityRelationUrl: string, imagesFolder: string) => {
    return uploadPicture(file, imagesFolder).then(
        response => addImageToEntity(response.data, entityRelationUrl)
    )
}

export const uploadEntityPictures = (files: any, entityRelationUrl: string, entityImagesFolder: string, onEachUpload: any) => {
    if (_.isUndefined(entityRelationUrl))
        return Promise.reject('Entity relation url is required.');
    return utilities.sequentialPromises(files,
        (fileItem: any) => uploadEntityPicture(fileItem, entityRelationUrl, entityImagesFolder),
        onEachUpload
    );

}

export const removeEntityImage = (pictureId: string, entityRelationUrl: string) => {
    if (!pictureId || !entityRelationUrl)
        return Promise.reject('Picture id or url not provided');
    return utilities.request({
        url: `${entityRelationUrl}/${pictureId}`,
        method: 'DELETE'
    })
}

export const rotateBase64Image = (base64Image: any, orientation: any) => {
    var offScreenCanvas = document.createElement('canvas');
    var ctx = offScreenCanvas.getContext('2d');
    if (!ctx) return;

    // create Image
    var img = new Image();
    img.src = base64Image;

    // set its dimension to rotated size
    offScreenCanvas.height = img.width;
    offScreenCanvas.width = img.height;

    const height = img.height;
    const width = img.width;

    switch (orientation) {
        case 2:
            // horizontal flip
            ctx.translate(width, 0)
            ctx.scale(-1, 1)
            break
        case 3:
            // 180° rotate left
            ctx.translate(width, height)
            ctx.rotate(Math.PI)
            break
        case 4:
            // vertical flip
            ctx.translate(0, height)
            ctx.scale(1, -1)
            break
        case 5:
            // vertical flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI)
            ctx.scale(1, -1)
            break
        case 6:
            // 90° rotate right
            ctx.rotate(0.5 * Math.PI)
            ctx.translate(0, -height)
            break
        case 7:
            // horizontal flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI)
            ctx.translate(width, -height)
            ctx.scale(-1, 1)
            break
        case 8:
            // 90° rotate left
            ctx.rotate(-0.5 * Math.PI)
            ctx.translate(-width, 0)
            break
    }

    ctx.drawImage(img, 0, 0);

    // encode image to data-uri with base64
    return offScreenCanvas.toDataURL("image/jpeg", 100);
};

export const uploadImageByUrl = async (url: string, entityRelationUrl: string, fileName: string, imagesFolder: string, onUploadComplete: Function) => {
    imagesFolder = imagesFolder || 'images';
    if (!(/\/$/.test(imagesFolder)))
        imagesFolder += '/';
    const res = await utilities.request({
        url: "pictures/upload-by-url",
        method: 'POST',
        data: {
            url: url,
            filename: fileName,
            folder: imagesFolder
        }
    })
    addImageToEntity(res.data, entityRelationUrl)
    onUploadComplete("", res.data)
}

export const getImageFromUrl = async (url: string) => await utilities.request({ url: 'pictures/fetch', params: { url } });