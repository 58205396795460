import React, { PureComponent } from 'react';
import { createStyles, WithStyles, withStyles, Theme, Paper, Avatar, Typography } from '@material-ui/core';
import { TUser } from '../../Models/User/@types';
import { OUser } from '../../Models/User';

interface IProps extends WithStyles<typeof STYLES> {
    user: TUser;
    subTitle?: string;
}

class UserCard extends PureComponent<IProps> {
    render() {
        const { classes, user, subTitle } = this.props;
        return (
            <>
                <Paper className={classes.paper}>
                    <div className={classes.avatarContainer}>
                        <Avatar alt={user.name} src={OUser.getUserPicture(user)} className={classes.avatar} />
                    </div>
                    <div>
                        {user.name ? (
                            <Typography variant="subtitle1"> {user.name}</Typography>
                        ) : (
                            <Typography variant="subtitle1">{`${user.firstName || ''} ${user.lastName || ''}`}</Typography>
                        )}
                        <Typography variant="body2">{user.email}</Typography>
                        <Typography variant="caption">{subTitle}</Typography>
                    </div>
                </Paper>
            </>
        );
    }
}

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
        },
        paper: {
            display: 'flex',
            padding: '20px 0',
        },
        avatar: {
            width: 50,
            height: 50,
        },
        avatarContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 10px',
        },
    });

export default withStyles(STYLES)(UserCard);
