import { TPicture } from "../../Resources/GlobalTypes";


export interface TQuote {
    title: string;
    quoteImageType: QuoteImageType;
    id: string;
    isPublished: boolean
    _pictures: TPicture[];
}

export interface QuoteImageType {
    desktop: string;
    mobile: string;
}


export interface TQuoteState {
    list: TQuote[]
    details?: TQuote
    totalCount: number
    showMainForm?: boolean
    editedQuote?: TQuote
}


export const imageTypes: Array<string> = ['Desktop', 'Mobile']



