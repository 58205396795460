import { TAction } from "loop-front";
import { Reducer } from "redux";
import { OHomeSection } from ".";
import utilities from "../../Resources/Utils";
import { EDITORS_PICK_SLUG } from "../../Screens/Home/Tabs/EditorsPick";
import { WHATS_NEW_SLUG } from "../../Screens/Home/Tabs/WhatsNew";
import { THomeSectionState } from './@types';
import orderBy from 'lodash/orderBy'
import { TRAVEL_WORTHY_SLUG } from "../../Screens/Home/Tabs/TravelWorthy";
import { EXPLORE_CITY_SLUG } from "../../Screens/Home/Tabs/ExploreCity";
import { DESTINATION_SLUG } from "../../Screens/Home/Tabs/Destinations";
import { EATINERARY_SLUG } from "../../Screens/Home/Tabs/Eatinerary";
import { DISH_SLUG } from "../../Screens/Home/Tabs/Dish";
import { VIDEO_SLUG } from "../../Screens/Home/Tabs/Videos";

const initialState: THomeSectionState = {
    sections: [],
    initialized: false,
    whatsNewList: [],
    videoList: [],
    editorsPickList: [],
    travelWorthyList: [],
    destinationList: [],
    exploreCityList: [],
    eatineraryList: [],
    dishList: [],
    loading: false,
}

const slugToSectionMapping: Record<string, keyof THomeSectionState> = {
    [WHATS_NEW_SLUG]: 'whatsNewList',
    [EDITORS_PICK_SLUG]: 'editorsPickList',
    [TRAVEL_WORTHY_SLUG]: 'travelWorthyList',
    [EXPLORE_CITY_SLUG]: 'exploreCityList',
    [DESTINATION_SLUG]: 'destinationList',
    [EATINERARY_SLUG]: 'eatineraryList',
    [DISH_SLUG]: 'dishList',
    [VIDEO_SLUG]: 'videoList',
}


export const RHomeSection: Reducer<THomeSectionState, TAction> = (state = { ...initialState }, action) => {

    const {
        LIST_RECEIVED,
        ENTITY_OF_ITEM_RECEIVED,
        HOME_SECTION_ENTITIES_UPDATED,
        HOME_SECTION_SET_LOADING,
        PATCHING_ITEM,
        ITEM_PATCH_SUCCESS,
    } = OHomeSection.Actions;

    switch (action.type) {
        case PATCHING_ITEM: return { ...state, loading: true };
        case ITEM_PATCH_SUCCESS: return { ...state, loading: false, sections: utilities.updateItemList(state.sections, action.data, 'UPDATE') }
        case HOME_SECTION_SET_LOADING: return { ...state, loading: action.data }
        case HOME_SECTION_ENTITIES_UPDATED: {
            const _section = state.sections.find(s => s.id === action?.additionalDispatchData?.homeSectionId);
            if (!_section) return { ...state };
            const key = slugToSectionMapping[_section.slug];
            return { ...state, [key]: action.data }
        }
        case LIST_RECEIVED: {
            return { ...state, initialized: true, sections: action.data }
        }

        case ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case OHomeSection.Entities.sectionEntity: {
                    const key = slugToSectionMapping[action.additionalDispatchData?.slug];
                    if (key)
                        return { ...state, [key]: orderBy(action.data, 'order') }
                }
            }
        }

        default: return state;
    }
}