import React from 'react'
import { Theme, Box, Paper, Avatar, CircularProgress, Fab, } from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ClearOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import FileInput from '../FileInput';
import { PaperProps } from '@material-ui/core/Paper';
import { AvatarProps } from '@material-ui/core/Avatar';
export interface TFile {
    name: string
    type: string
    size: number | string
    base64: string | ArrayBuffer | null
    file: any;
}
interface ImageUploadProps {
    loading?: boolean
    imageUrl?: string
    handleUpload: (files: TFile[]) => void
    onRemove?: () => void
    paperProps?: PaperProps
    avatarClassName?: AvatarProps['className']
    cropConfig?: ReactCrop.Crop
}

const ImageUpload: React.FC<ImageUploadProps> = (props) => {
    const { loading = false, imageUrl, avatarClassName, } = props;
    const classes = useStyles({});

    return (
        <Box>
            {loading === false ?
                <Paper className={classes.imagePaper} {...props.paperProps || {}}>
                    <Box display='flex' width='100%' alignItems='center' justifyContent='center' flexDirection='column'>
                        {/* {imageUrl ?
                            <> */}
                        <Avatar
                            src={imageUrl}
                            className={clsx(classes.avatar, avatarClassName)}
                        />
                        <div className={classes.actionContainer}>

                            <Fab color={'primary'} size={'small'}>
                                <PhotoCameraIcon />
                                <FileInput accept='image/*' onDone={(files) => props.handleUpload(files)} />
                            </Fab>
                            {(props.onRemove && imageUrl) &&
                                <Fab color={'primary'} className={classes.clearIcon} onClick={props.onRemove} size={'small'}>
                                    <ClearOutlined />
                                </Fab>
                            }
                        </div>
                        {/* </>
                            :
                            <Fab color={'primary'} size={'small'}>
                                <PhotoCameraIcon />
                                <FileInput accept='image/*' onDone={(files) => props.handleUpload(files)} />
                            </Fab>} */}
                    </Box>
                </Paper>
                :
                <Box width='100%'> <CircularProgress /></Box>
            }
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    imagePaper: {
        position: 'relative',
        width: 200,
        minHeight: 200,
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    avatar: {
        height: '100%',
        width: '100%'
    },
    actionContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        bottom: 0,
        right: -10,
        '& button': {
            margin: '4px 0px',
            width: 30,
            minWidth: 30,
            height: 30,
            minHeight: 30,
        },
        '& svg': {
            transform: 'scale(0.7)',
            transition: '200ms ease-in',
        }
    },
    clearIcon: {
        transform: 'scale(0.7)',
        background: theme.palette.error.main
    }
}))

export default ImageUpload