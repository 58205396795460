import React from 'react';
import DraggableList, { TemplateProps } from 'react-draggable-list';




interface IProps<T = unknown> {
    list: T[]
    itemKey?: string
    handleUpdateListOrder: (nexList: T[], movedItem: T, oldIndex: number, newIndex: number) => void
    renderItem: (item: T, dragHandleProps: any, dragged: boolean) => React.ReactElement
}

function ReorderableList<T>(props: IProps<T>) {

    const { list, itemKey = 'id' } = props;
    const RenderTemplate: React.FC<TemplateProps<T, unknown>> = React.forwardRef((categoryItemProps, ref) => {
        let { item, itemSelected, dragHandleProps } = categoryItemProps;
        const dragged = itemSelected !== 0;
        return props.renderItem(item, dragHandleProps, dragged)

    })

    return (
        <DraggableList
            list={list || []}
            itemKey={itemKey}
            template={RenderTemplate as any}
            onMoveEnd={(nexList, movedItem, oldIndex, newIndex) => props.handleUpdateListOrder([...nexList], movedItem, oldIndex, newIndex)}
        />
    )
}

export default ReorderableList