import LoopFront from "loop-front";
export const CustomEntities = {
    ESTABLISHMENTS: 'establishments',
    CUISINES: 'cuisines'
}
export const CustomActivity = {
    COUNT: 'count',
    PUBLISH: 'publish',
    DETAILS: 'details'
}
export const CustomActions = {
    SHOW_DISH_DETAIL_FORM: 'SHOW_DISH_DETAIL_FORM',
    HIDE_DISH_DETAIL_FORM: 'HIDE_DISH_DETAIL_FORM',
    PUT_CUISINE_TO_DISH_SUCCESS: 'PUT_CUISINE_TO_DISH_SUCCESS',
    PUT_ESTABLISHMENT_TO_DISH_SUCCESS: 'PUT_ESTABLISHMENT_TO_DISH_SUCCESS',
    DELETE_ESTABLISHMENT_FROM_DISH_SUCCESS: 'DELETE_ESTABLISHMENTS_FROM_DISH_SUCCESS',
    DELETE_CUISINE_FROM_DISH_SUCCESS: 'DELETE_CUISINES_FROM_DISH_SUCCESS'
}
class Dish extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('FoodDrinks', CustomActions, CustomEntities, CustomActivity);
    }
}

export const ODish = new Dish();