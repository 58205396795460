import LoopFront from "loop-front"
import { OExplore } from "."
import { TDispatch } from "../../Resources/GlobalTypes"
import { TExploreFilter } from "../../Screens/Explore/Forms"
import { OApp } from "../App"
import { TFilterConfig } from "../App/@types"
const getStaticValueFilter = (name: string, values: any[]) => {
    return values.map((k) => ({ [name]: k }))
}
const ExploreModel = {
    getExploreList: (pageNumber: number, filterConfig: TFilterConfig[], cityIds: string[], sortFilter: any = {}) => {
        let cityFilter = cityIds.length ? { destinationId: { inq: cityIds } } : {}
        return (async (dispatch: TDispatch) => {
            const filter = OApp.parseBaseFilter(filterConfig)
            await dispatch(OExplore.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    includeCount: true,
                    include: {
                        relation: 'destination',
                        scope: {
                            fields: ['name']
                        }

                    },
                    ...filter,
                    where: {
                        ...filter.where,
                        ...cityFilter
                    },
                    ...sortFilter
                }
            }))
            if (pageNumber === 1)
                await dispatch(OExplore.getActivity(OExplore.Activities.COUNT, { ...filter, ...cityFilter }))
        })
    },
    getFilteredList: async (placeFilter: TExploreFilter, page: number, selectedIds: string[]) => {
        const { destinationId, tagIds } = placeFilter;
        const res = await LoopFront.request({
            url: 'Establishments/filter',
            params: {
                filter: {
                    limit: 30,
                    skip: (page - 1) * 30,
                    where: {

                        and: [
                            {
                                isPublished: true,
                            },
                            destinationId ? {
                                destinationId
                            } : {},
                            selectedIds.length ? {
                                id: {
                                    'nin': selectedIds
                                }
                            } : {},
                            placeFilter.types?.length ? {
                                types: {
                                    "inq": placeFilter.types || []
                                }
                            } : {},
                            placeFilter.range?.length ? {
                                or: getStaticValueFilter('range', placeFilter.range || [])
                            } : {},
                            placeFilter.status?.length ? {
                                or: getStaticValueFilter('status', placeFilter.status || [])
                            } : {},
                            tagIds?.length ? {
                                tagIds: {
                                    "inq": tagIds || []
                                }
                            } : {}
                        ]
                    },
                    fields: ["id", "name", "city", "status", "range", "addressLine1", 'full_address'],
                    cuisines: placeFilter.cuisineIds?.length ? placeFilter.cuisineIds : undefined
                }
            }

        });
        return res.data;
    }
}
export default ExploreModel