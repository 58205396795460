import LoopFront, { TAction } from 'loop-front';
import { TFilterConfig, TToastConfig } from './@types';
import { ThunkAction } from 'redux-thunk';
import { TReduxStore } from '../../RootReducer';
import utilities from '../../../src/Resources/Utils';
import { OUser } from '../User';
import { ODestinationPack } from '../DestinationPack';
import { OCity } from '../City';


const CustomActions = {
    OPEN_TOAST: 'OPEN_TOAST',
    CLOSE_TOAST: 'CLOSE_TOAST',
    CITY_LIST_RECEIVED: 'CITY_LIST_RECEIVED',
    UPDATE_SUMMARY: 'update-summary',
};

const CustomActivities = {
    SUMMARY: 'summary',
    CONSTANTS: 'constants',
    RELATIONS: 'relations',
    INC_TOTAL: 'increement',
    DEC_TOTAL: 'decreement',
};

class App extends LoopFront<typeof CustomActions, {}, typeof CustomActivities> {
    constructor() {
        super('appmodels', CustomActions, {}, CustomActivities);
    }

    init = (): ThunkAction<Promise<boolean>, TReduxStore, {}, TAction> => async (dispatch, getState) => {
        const access_token = utilities.getAccessToken();
        const userId = utilities.getUserId();
        if (access_token && userId) {
            LoopFront.setAuthHeader(access_token);
            const res = await OUser.requestGetActivity(OUser.Activities.ME).catch((error) => {
                console.log('Getting Me Error', error);
                LoopFront.setAuthHeader('');
                utilities.clearCookies();
                throw error;
            });
            dispatch({ type: OUser.Actions.APP_USER_RECEIVED, data: res.data });
            await dispatch(OCity.getItemsList({
                filter: {
                    order: 'name ASC',
                },
            }))
            await dispatch(ODestinationPack.getItemsList());
            return Promise.resolve(true);
        }
        return Promise.resolve(false);
    };

    showToast =
        (toastConfig: TToastConfig): ThunkAction<void, TReduxStore, {}, TAction> =>
            (dispatch, getState) => {
                const { toast = {} } = getState().App;
                if (toast.open) return;
                dispatch({ type: this.Actions.OPEN_TOAST, data: { ...toastConfig, open: true } });
            };

    closeToast = (): ThunkAction<void, TReduxStore, {}, TAction> => (dispatch, getState) => {
        const { toast = {} } = getState().App;
        if (!toast.open) return;
        dispatch({ type: this.Actions.CLOSE_TOAST });
    };

    parseBaseFilter = (filterConfig: TFilterConfig[], key = 'isPublished') => {
        const filter: {
            where: {
                and?: Array<Record<string, any>>;
            };
        } = { where: { and: [] } };
        if (!filterConfig[0].isActive) {
            if (filterConfig[1].isActive) {
                filter.where.and?.push({ [key]: true });
            }
            if (filterConfig[2].isActive) {
                filter.where.and?.push({ [key]: false });
            }
            if (filterConfig[3].isActive) {
                filter.where.and?.push({ newlyAdded: true });
            }
            if (filterConfig[4].isActive) {
                filter.where.and?.push({ recentlyUpdated: true });
            }
        }
        if (filter.where.and?.length === 0) {
            delete filter.where.and;
        }
        return filter;
    };
}
export const OApp = new App();
