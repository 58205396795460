import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { TCuisine } from '../../../Models/Cuisines/@types'
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog'
import ItemCard from '../ItemCard'

interface IProps extends RouteComponentProps<{ id: string }> {
    item: TCuisine
    deleteItem: (item: TCuisine) => Promise<void>
}

const CuisinesCard: React.FC<IProps> = (props) => {
    const { history, item, deleteItem } = props

    const [dialog, toggleDialog] = useState(false)

    const handleView = () => {
        history.push('/dashboard/cuisines/' + item.id + '/details')
    }
    const handleDelete = () => {
        toggleDialog(!dialog);
    }
    const handleDialogDelete = () => {
        deleteItem(item);
        toggleDialog(!dialog)
    }
    const menuOption = [{ text: 'View Details', onClick: handleView },
    { text: 'Delete ', onClick: handleDelete }
    ]
    return (
        <div>
            <ItemCard
                title={item.name}
                subtitle={'secondary info'}
                options={menuOption}
            />
            <ConfirmationDialog
                open={dialog}
                onAgree={handleDialogDelete}
                onDisagree={handleDelete}
                message={"Are you sure you want to delete this cuisine?"}
                onClose={handleDelete}
            />
        </div>
    )
}


export default withRouter(CuisinesCard)