import React from 'react'
import { Box, Checkbox, createStyles, Divider, FormControlLabel, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import { OApp } from '../../../../Models/App';
import { OExplore } from '../../../../Models/Explore';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import { TExplore, TExploreState } from '../../../../Models/Explore/@types';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import TypographyWMention from '../../../../Components/TypographyWMention';
import { renderHtmlStr } from '../../../../Features/Mention';
import dimensions from '../../../../Constants/dimensions';
import { DATE_FORMAT } from '../../../../Constants/vars';

interface IProps { }

const Details: React.FC<IProps> = (props) => {

    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();
    const classes = useStyles();
    const { details } = useSelector<TReduxStore, Pick<TExploreState, 'details'>>(({ Explore }) => ({ details: Explore.details }));
    const handleEdit = () => {
        dispatch({ type: OExplore.Actions.SHOW_EXPLORE_FORM, data: details })
    }

    const setField = async (field: keyof TExplore, value: any) => {
        try {
            if (details)
                await dispatch(OExplore.patchItem(details.id, { [field]: value }, undefined, undefined, { [field]: value }))
        } catch (error) {

        }
    }


    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(OExplore.deleteItem(details.id, {}, undefined, details))
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'List' } })
            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }))
            }
            if (history) {
                history.push('/dashboard/explore')
            }
        }
    }
    const handlePublish = async () => {
        if (details) {
            try {
                const flag = !details.isPublished;
                await dispatch(OExplore.postItemActivity(details.id, OExplore.Activities.PUBLISH, {}, { publish: !details.isPublished }))
                dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
            }
            catch (err) {
                dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))
                console.log('List publish', err)
            }
        }
    }
    const textUrl = `${DOMAIN_URL}/explore/${details?.slug || details?.id}`;
    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this List.',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this List?',
            handleDelete: handleDelete,
            helpText: 'Delete this List.',
        },
        {
            type: 'icon',
            icon: (details && details.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (details && details.isPublished) ? 'secondary' : 'primary',
            helpText: (details && details.isPublished) ? 'Unpublish this List.' : 'Publish this List.',
        },
        {
            type: 'copy_button',
            textUrl: textUrl,
        }
    ]
    const handleNoteSave = async (notes: string) => {
        try {
            if (details)
                await dispatch(OExplore.patchItem(details.id, { notes }, {}, undefined, { notes }));
            dispatch(OApp.showToast({ message: `Notes updated.`, variant: 'success' }))

        } catch (err) {
            dispatch(OApp.showToast({ message: `Notes update failed.`, variant: 'success' }))
            console.log('Error in add to mamakoo', err);
        }
    }
    return (
        <div className={classes.foodDrinkDetail}>
            {
                details &&
                <>

                    <HeaderActionButtons items={headerItems} />
                    <Box mt={3} m='auto'>
                        <NotesInput
                            onSave={handleNoteSave}
                            text={details.notes || ''}
                        />
                    </Box>
                    <Paper className={classes.paper}>
                        {/* <Divider style={{ margin: '16px 0' }} /> */}
                        <Box display='flex'>
                            <Typography variant="overline">{`Created - ` + moment(details.created).format(DATE_FORMAT) + ` Modified - ` + moment(details.updated).format(DATE_FORMAT)}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='column' my={2} ml={0.5}>
                            <FormControlLabel
                                onClick={e => setField('newlyAdded', !details?.newlyAdded)}
                                label='Newly added'
                                control={<Checkbox color='primary' checked={!!details?.newlyAdded} />}
                            />
                            <FormControlLabel
                                onClick={e => setField('recentlyUpdated', !details?.recentlyUpdated)}
                                label='Recently updated'
                                control={<Checkbox color='primary' checked={!!details?.recentlyUpdated} />}
                            />
                        </Box>
                        <Divider style={{ margin: 0, marginBottom: 24 }} />
                        <div>
                            <Typography variant="h4">{details.name}</Typography>
                            <Typography variant="subtitle2">{details.destination?.name}</Typography>

                        </div>
                        {details.shortDescription ?
                            <> <Divider style={{ margin: '16px 0' }} />
                                <Typography variant={'subtitle1'} >Short Description</Typography>
                                <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>{details.shortDescription || ''}</TypographyWMention>
                            </>
                            : null}
                        {details.description ?
                            <> <Divider style={{ margin: '16px 0' }} />
                                <Typography variant={'subtitle1'} >Long Description</Typography>
                                <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>{details.description || ''}</TypographyWMention>
                            </>
                            : null}


                    </Paper>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    foodDrinkDetail: {
        position: 'relative',
        margin: '0px 40px',
    },
    paper: {
        width: dimensions.screenMaxWidth,
        margin: 'auto',
        padding: '30px',
        marginTop: 20
    },
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            margin: '0 10px'
        }
    },
}))

export default Details


