import { Reducer } from "redux";
import { TAction } from "loop-front";
import { TPlaceState } from "./@types";
import { OPlace } from ".";
import utilities from "../../Resources/Utils";

export const RPlace: Reducer<TPlaceState, TAction> = (state = { showMainForm: false }, action) => {

    const { SINGLE_ITEM_RECEIVED, ACTIVITY_RECEIVED, LIST_RECEIVED, SHOW_PLACE_DETAIL_FORM, HIDE_PLACE_DETAIL_FORM,
        FETCHING_ENTITY_OF_ITEM,
        ENTITY_OF_ITEM_RECEIVED, PUT_DISHES_TO_PLACE_SUCCESS,
        PUT_CUISINES_TO_PLACE_SUCCESS, SINGLE_ENTITY_BY_ITEM_DELETED,
        ITEM_PATCH_SUCCESS,
        ITEM_ACTIVITY_POST_SUCCESS,
        SINGLE_PLACE_DELETED,
        SINGLE_REVIEW_PUBLSIHED_OF_PLACE,
        SINGLE_TAG_REMOVED_FROM_PLACE,
        DELETE_CUISINE_FROM_PLACE_SUCCESS,
        DELETE_DISH_FROM_PLACE_SUCCESS,
        ITEM_ACTIVITY_RECEIVED,
        DELETE_TAG_FROM_PLACE_SUCCESS,
        PLACE_SUGGESTIONS_UPDATED,
    } = OPlace.Actions;

    switch (action.type) {
        case DELETE_CUISINE_FROM_PLACE_SUCCESS: return {
            ...state,
            cuisines: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, cuisines: action.data } }
        }
        case DELETE_DISH_FROM_PLACE_SUCCESS: return {
            ...state,
            dishes: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, dishes: action.data } }
        }
        case DELETE_TAG_FROM_PLACE_SUCCESS: return {
            ...state,
            tags: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, tags: action.data } }
        }
        case SINGLE_TAG_REMOVED_FROM_PLACE: return {
            ...state, details: (state.details) && { ...state.details, tags: action.data, counts: { ...state.details.counts, tags: action.additionalDispatchData } },

        }
        case LIST_RECEIVED: return { ...state, list: action.data }
        case SINGLE_REVIEW_PUBLSIHED_OF_PLACE: return { ...state, reviews: action.data }
        case SINGLE_ITEM_RECEIVED: return { ...state, details: action.data }
        case ITEM_ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OPlace.Activities.DETAILS: return { ...state, details: action.data }
                default: return state;
            }
        }
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OPlace.Activities.COUNT: return { ...state, totalCount: action.data.count }
                default: return state;
            }
        }

        case FETCHING_ENTITY_OF_ITEM: {
            return { ...state, cuisines: [], dishes: [], reviews: [], tags: [], favouriters: [], wishers: [] }
        }

        case ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case OPlace.Entities.CUISINES: return { ...state, cuisines: action.data }
                case OPlace.Entities.FOODDRINKS: return { ...state, dishes: action.data }
                case OPlace.Entities.REVIEWS: return { ...state, reviews: action.data }
                case OPlace.Entities.TAGS: return { ...state, tags: action.data }
                case OPlace.Entities.FAVOURITERS: return { ...state, favouriters: action.data }
                case OPlace.Entities.WISHERS: return { ...state, wishers: action.data }
                default: return state
            }
        }
        case PLACE_SUGGESTIONS_UPDATED: return { ...state, details: { ...state.details, ...action.data } }
        case ITEM_PATCH_SUCCESS: return { ...state, details: { ...state.details, ...action.additionalDispatchData }, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE') }
        case SHOW_PLACE_DETAIL_FORM: return { ...state, editedPlace: action.data, showMainForm: true }
        case HIDE_PLACE_DETAIL_FORM: return { ...state, editedPlace: undefined, showMainForm: false }
        case PUT_CUISINES_TO_PLACE_SUCCESS: return {
            ...state,
            cuisines: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, cuisines: action.data } }
        }
        case PUT_DISHES_TO_PLACE_SUCCESS: return {
            ...state,
            dishes: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, foodDrinks: action.data } }
        }
        case SINGLE_ENTITY_BY_ITEM_DELETED: {
            switch (action.entity) {
                case OPlace.Entities.FOODDRINKS: return { ...state, dishes: utilities.updateItemList(state.dishes || [], action.additionalDispatchData, 'DELETE') }
                case OPlace.Entities.CUISINES: return { ...state, cuisines: utilities.updateItemList(state.cuisines || [], action.additionalDispatchData, 'DELETE') }
                default: return state
            }
        }
        case ITEM_ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case OPlace.Activities.PUBLISH: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), details: { ...state.details, ...action.data } }
                default: return state;
            }
        }
        case SINGLE_PLACE_DELETED: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'DELETE') }
        default: return state;
    }
}