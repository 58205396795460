import React from 'react';
import { TCity } from '../../../Models/City/@types';
import { createStyles, makeStyles, Theme, Typography, Paper } from '@material-ui/core';


type Props = {
    city: TCity,
    renderSubtitle?: (city: TCity) => JSX.Element | null
}

const CitiesCard = (props: Props) => {
    const classes = useStyles()
    const { city } = props;
    const onPageChange = () => { }
    return (
        <>
            <Paper className={classes.paper}>
                <div className={classes.Container}>
                    <Typography variant='subtitle2' >
                        {city.name}
                    </Typography>
                    {props.renderSubtitle ? props.renderSubtitle(city) : (
                        <>
                            <Typography >
                                {city.state}
                            </Typography>
                            <Typography>
                                {city.country}
                            </Typography>
                        </>
                    )}
                </div>
            </Paper>

        </>
    )
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    paper: {
        padding: 20,
    },
}));

export default CitiesCard