import LoopFront from "loop-front";

const CustomActivity = {
    COUNT: 'count',
    PUBLISH: 'publish',
    UPDATE_ESTABLISHMENTS: 'update-establishments',
    DETAILS: 'details'
}

const CustomEntities = {
    ESTABLISHMENTS: 'establishments',
    FOODDRINKS: 'foodDrinks',
    CUISINES: 'cuisines',
    LANDMARKS: 'landmarks'
}

const CustomActions = {
    SHOW_CITY_DETAIL_FORM: 'SHOW_CITY_DETAIL_FORM',
    HIDE_CITY_DETAIL_FORM: 'HIDE_CITY_DETAIL_FORM',

    PUT_DISHES_TO_CITY_SUCCESS: 'PUT_FOODDRINK_TO_CITY_SUCCESS',
    PUT_PLACES_TO_CITY_SUCCESS: 'PUT_PLACES_TO_CITY_SUCCESS',
    PUT_CUISINES_TO_CITY_SUCCESS: 'PUT_CUISINE_TO_CITY_SUCCESS',
    SINGLE_ESTABLISHMENT_DELETED_FROM_CITY: 'SINGLE_ESTABLISHMENT_DELETED_FROM_CITY',
    DELETE_CUISINE_FROM_CITY_SUCCESS: 'DELETE_CUISINES_FROM_CITY_SUCCESS',
    DELETE_DISH_FROM_CITY_SUCCESS: 'DELETE_FOODDRINKS_FROM_CITY_SUCCESS',
}

class City extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('destinations', CustomActions, CustomEntities, CustomActivity);
    }

    requestGetDetail(id: string) {
        return LoopFront.request({
            url: this.ModelName + `/${id}/details`
        })
    }
}

export const OCity = new City();