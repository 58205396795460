import { TLoginFormData, TAuthResponse } from "../../Models/Auth/@types";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse, AxiosPromise } from "axios";
import { TReduxStore } from "../../RootReducer";
import LoopFront, { TAction } from "loop-front";
import { OUser } from "../../Models/User";
import utilities from "../../Resources/Utils";

export const userLogin = (formData: TLoginFormData): ThunkAction<AxiosPromise<TAuthResponse>, TReduxStore, {}, TAction> => async (dispatch, getState) => {
    const authRes = await dispatch(OUser.postActivity(OUser.Activities.LOGIN, formData)).catch(error => { console.log('Login Error', error); throw error }) as AxiosResponse<TAuthResponse>;
    const access_token = authRes.data.id;
    const userId = authRes.data.userId;
    utilities.saveUser(access_token, userId);
    LoopFront.setAuthHeader(authRes.data.id);
    const userRes = await OUser.requestGetItem(userId).catch(error => { console.log('App User get Error', error); throw error });
    dispatch({ type: OUser.Actions.APP_USER_RECEIVED, data: userRes.data });
    return authRes;
}

