import React, { FC, useState, useCallback, ReactElement, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Divider, fade, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import ListDashboard from '../../Components/Dashboard/ListDashboard';
import { NavLink, RouteProps } from 'react-router-dom';
import { TDestinationPack, TDestinationPackState } from '../../Models/DestinationPack/@types';
import { TDispatch } from '../../Resources/GlobalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../RootReducer';
import { TFilterConfig } from '../../Models/App/@types';
import { getDestinationPackCities, getDestinationPackList } from '../../Models/DestinationPack/actions';
import { ODestinationPack } from '../../Models/DestinationPack';
import Details from './Tabs/Details';
import BasicInfo from './Forms/BasicInfo';
import Cities from './Tabs/Cities';

export interface DestinationPackProps {}

const BASE_URL = '/dashboard/destination-packs';

const initialFilter: TFilterConfig[] = [
    { name: 'All', isActive: true },
    { name: 'Published', isActive: false },
    { name: 'Unpublished', isActive: false },
];

const DestinationPack: FC<DestinationPackProps> = (props) => {
    const classes = useStyles({});
    const url = window.location.href;
    const urlArray: string[] = url.split('/');
    const destinationPackId = urlArray.length >= 6 ? urlArray[5] : '';
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    const [searchResult, setSearchResult] = useState<TDestinationPack[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [sortFilter, setSortFilter] = useState<any>({});
    const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>(JSON.parse(JSON.stringify(initialFilter)));

    const {
        details,
        list = [],
        totalCount = 0,
        cities,
    } = useSelector<TReduxStore, Pick<TDestinationPackState, 'details' | 'list' | 'totalCount' | 'cities'>>(({ DestinationPack }) => ({
        details: DestinationPack.details,
        list: DestinationPack.list,
        totalCount: DestinationPack.totalCount,
        cities: DestinationPack.cities,
    }));

    const getData = (filterConfig: TFilterConfig[] = JSON.parse(JSON.stringify(initialFilter)), sortFilter: any = {}) => {
        setLoading(true);
        try {
            dispatch(getDestinationPackList(pageNumber, filterConfig, { ...sortFilter }));
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const { showMainForm } = useSelector<TReduxStore, Pick<TDestinationPackState, 'showMainForm'>>(({ DestinationPack: { showMainForm } }) => ({
        showMainForm,
    }));

    const getDetails = async (id: string) => {
        await dispatch(ODestinationPack.getItem(id));
    };

    useEffect(() => {
        getData(filterConfig, sortFilter);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        if (destinationPackId) {
            getDetails(destinationPackId);
        }
    }, [destinationPackId]);

    useEffect(() => {
        if (details?.id) {
            dispatch(getDestinationPackCities(details?.id));
        }
    }, [details]);
    const onAddButtonClick = () => {
        dispatch({ type: ODestinationPack.Actions.SHOW_DESTINATION_PACK_DETAIL_FORM });
    };

    const handleSearchResult = (result: Array<TDestinationPack>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    };

    const handleFilterChange = (item: { name: string; isActive: boolean }) => {
        const updatedFilter = filterConfig.map((i) => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        });
        setFilterConfig([...updatedFilter]);
    };
    const handleFilterSubmit = () => {
        if (pageNumber === 1) getData(filterConfig, sortFilter);
        else setPageNumber(1);
    };
    const handleFilterReset = () => {
        setFilterConfig(JSON.parse(JSON.stringify(initialFilter)));
        if (pageNumber === 1) getData(undefined, sortFilter);
        else setPageNumber(1);
    };

    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string; name: string; component: RouteProps['component'] }> = [
        {
            name: 'Detail',
            url: 'detail',
            component: Details,
        },
        {
            name: 'Cities - ' + (cities?.length || 0),
            url: 'cities',
            component: Cities,
        },
    ];

    const renderListItem = useCallback(
        (pack: TDestinationPack): ReactElement => {
            const isActive = (match: any, location: any) => {
                const regex = new RegExp(`${BASE_URL}/${pack.id}/`, 'ig');
                return regex.test(location.pathname);
            };

            if (!pack) return <div />;
            return (
                <div key={pack.id}>
                    <NavLink
                        className={classes.listItem}
                        isActive={isActive}
                        to={`${BASE_URL}/${pack.id}/detail`}
                        activeClassName={'selected'}
                        onClick={() => {
                            setSearchQuery('');
                        }}
                    >
                        <ListItem button>
                            <ListItemText
                                primary={`${pack.name || ''}`}
                                // secondary={place.cuisines && place.cuisines.length > 0 ? place.cuisines[0].name : ''}
                            />
                            {/* <ListItemSecondaryAction>
                                {!place.isPublished ? <i className={classNames('material-icons', classes.lockIcon)}>lock</i> : null}
                            </ListItemSecondaryAction> */}
                        </ListItem>
                        <Divider className={classes.divider} />
                    </NavLink>
                </div>
            );
        },
        [classes]
    );

    return (
        <>
            <ListDashboard
                baseUrl={BASE_URL}
                csvConfig={{
                    modelName: 'DestinationPacks',
                }}
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                itemsCountPerPage={20}
                onPageChange={setPageNumber}
                onItemAddRequest={onAddButtonClick}
                // filterConfig={filterConfig}
                // onFilterChange={handleFilterChange}
                // onFilterReset={handleFilterReset}
                // onFilterSubmit={handleFilterSubmit}
                currentPageNumber={pageNumber}
                listProps={{
                    searchProps: {
                        type: 'DestinationPacks',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}
            />
            {/* <UsersListSortMenu anchorEl={sortMenuEl} onSortingChange={handleSortingChange} handleCloseSortMenu={() => setSortMenuEl(undefined)} /> */}
            {/* <MainForm /> */}
            {showMainForm ? <BasicInfo /> : null}
        </>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    listItem: {
        color: theme.palette.text.primary,
        '&.selected': {
            background: fade(theme.palette.primary.main, 0.4),
            display: 'block',
        },
    },
}));

export default DestinationPack;
