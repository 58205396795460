import LoopFront from "loop-front";
import { TDispatch } from "../../Resources/GlobalTypes";
import { OApp } from "../App";
import { TFilterConfig } from "../App/@types";



const CustomActions = {
    SHOW_EATINERARY_FORM: 'SHOW_EATINERARY_FORM',
    HIDE_EATINERARY_FORM: 'HIDE_EATINERARY_FORM',
}

const CustomEntities = {

}
const CustomActivities = {
    COUNT: 'count',
    PUBLISH: 'publish',
}


class Quote extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
    constructor() {
        super('quotes', CustomActions, CustomEntities, CustomActivities);
    }
    togglePublish(id: string, publish: boolean) {
        return (async (dispatch: TDispatch) => {
            const { data } = await LoopFront.request({
                url: this.ModelName + `/${id}/publish`,
                method: 'post',
                params: { publish }
            });
            dispatch({ type: this.Actions.ITEM_PATCH_SUCCESS, data })
        })
    }

    getQuoteList(pageNumber: number, filterConfig: TFilterConfig[], cityIds: string[], sortFilter: any = {}) {
        let cityFilter = cityIds.length ? { destinationId: { inq: cityIds } } : {}
        return (async (dispatch: TDispatch) => {
            const filter = OApp.parseBaseFilter(filterConfig)
            await dispatch(this.getItemsList({
                // filter: {
                //     limit: 20,
                //     skip: 20 * (pageNumber - 1),
                //     order: 'name ASC',
                //     include: {
                //         relation: 'destination',
                //         scope: {
                //             fields: ['name']
                //         }

                //     },
                //     ...filter,
                //     where: {
                //         ...filter.where,
                //         ...cityFilter
                //     },
                //     ...sortFilter
                // }
            }))
            if (pageNumber === 1)
                await dispatch(this.getActivity(this.Activities.COUNT, { ...filter, ...cityFilter }))
        })
    }

}

export const OQuote = new Quote();


