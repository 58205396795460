import { TAction } from "loop-front";
import { Reducer } from "redux";
import { OApp } from ".";
import { TAppState, TSummary } from './@types';
import { OCity } from "../City";
import utilities from "../../Resources/Utils";
import { ODestinationPack } from "../DestinationPack";

export const App: Reducer<TAppState, TAction> = (state: TAppState = { cityList: [] }, action: TAction): TAppState => {
    const { OPEN_TOAST, CLOSE_TOAST, CITY_LIST_RECEIVED, UPDATE_SUMMARY } = OApp.Actions;
    const { LIST_RECEIVED } = ODestinationPack.Actions;

    switch (action.type) {
        case OPEN_TOAST: { return { ...state, toast: { ...action.data } } }
        case CLOSE_TOAST: { return { ...state, toast: { open: false } } }
        case CITY_LIST_RECEIVED:
            return {
                ...state,
                cityList: action.data
            }
        case OApp.Actions.ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OApp.Activities.SUMMARY: return { ...state, summary: action.data }
                case OApp.Activities.CONSTANTS: return { ...state, constants: action.data }
                default: return state
            }
        }
        case UPDATE_SUMMARY: {
            switch (action.activity) {
                case OApp.Activities.INC_TOTAL: return {
                    ...state,
                    summary: state.summary ? {
                        ...state.summary,
                        [action.data.summaryKey as keyof TSummary]: {
                            ...state.summary[action.data.summaryKey as keyof TSummary],
                            total: state.summary[action.data.summaryKey as keyof TSummary].total + 1
                        }
                    } : undefined
                }
                case OApp.Activities.DEC_TOTAL: return {
                    ...state,
                    summary: state.summary ? {
                        ...state.summary,
                        [action.data.summaryKey as keyof TSummary]: {
                            ...state.summary[action.data.summaryKey as keyof TSummary],
                            total: state.summary[action.data.summaryKey as keyof TSummary].total - 1
                        }
                    } : undefined
                }
                default: return state;
            }
        }
        case OCity.Actions.ITEM_ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case OCity.Activities.PUBLISH: return {
                    ...state,
                    cityList: utilities.updateItemList(state.cityList || [], action.data, 'UPDATE')
                }
                default: return state;
            }
        }

        case OCity.Actions.POST_ITEM_SUCCESS: return { ...state, cityList: utilities.updateItemList(state.cityList || [], action.data, 'ADD') };
        case OCity.Actions.ITEM_PATCH_SUCCESS: return { ...state, cityList: utilities.updateItemList(state.cityList || [], action.data, 'UPDATE') };
        case OCity.Actions.ITEM_DELETED: return { ...state, cityList: utilities.updateItemList(state.cityList || [], action.additionalDispatchData, 'DELETE') }
        case LIST_RECEIVED: {
            return {
                ...state,
                destinationPacks: action.data
            }
        }
        case OCity.Actions.LIST_RECEIVED:
            return {
                ...state,
                cityList: action.data
            }

        default:
            return state;
    }
}
