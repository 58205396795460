import { createStyles, Divider, ListItem, ListItemText, makeStyles, Theme, ListItemSecondaryAction, Typography } from '@material-ui/core';
import Explore from './Tabs/Explore';
import Landmarks from './Tabs/Landmarks';
import { fade } from '@material-ui/core/styles';
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, RouteComponentProps, RouteProps } from 'react-router-dom';
import ListDashboard from '../../Components/Dashboard/ListDashboard';
import { TFilterConfig } from '../../Models/App/@types';
import { OCity } from '../../Models/City';
import { TCity, TCityState } from '../../Models/City/@types';
import { getCityList } from '../../Models/City/actions';
import { TDispatch } from '../../Resources/GlobalTypes';
import { TReduxStore } from '../../RootReducer';
import CityForm from './Forms/CityForm';
import Cuisines from './Tabs/Cuisines';
import Details from './Tabs/Details';
import Dishes from './Tabs/Dishes';
import Places from './Tabs/Places';
import classNames from 'classnames';
import Images from './Tabs/Images';
import useAsyncTask from '../../Hooks/useAsyncTask';
import UsersListSortMenu from '../../Features/Users/UsersListSortMenu';
import Eatineraries from './Tabs/Eatineraries';
import SellPage from './Tabs/SellPage';
import User from './Tabs/User';
import { getSubscribeUserList } from '../../Models/User/actions';

const BASE_URL = '/dashboard/cities';
const initialFilter: TFilterConfig[] = [
    { name: 'All', isActive: true, value: 'All' },
    { name: 'Voted Cities', isActive: false, value: 'Voted Cities' },
    { name: 'Published', isActive: false, value: 'Published' },
    { name: 'Unpublished', isActive: false, value: 'Unpublished' },
];

const Cities: FC<RouteComponentProps> = () => {
    const url = window.location.href;
    const urlArray: string[] = url.split('/');
    const cityId = urlArray.length >= 6 ? urlArray[5] : '';
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<TCity[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const classes = useStyles();
    const [sortMenuEl, setSortMenuEl] = useState<HTMLElement>();
    const [sortFilter, setSortFilter] = useState<any>({});
    const {
        details,
        list = [],
        totalCount = 0,
        subscriptionList
    } = useSelector<TReduxStore, Pick<TCityState, 'details' | 'list' | 'totalCount' | 'subscriptionList'>>(({ City }) => ({
        details: City.details,
        list: City.list,
        totalCount: City.totalCount,
        subscriptionList: City.subscriptionList
    }));

    const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>([
        { name: 'All', isActive: true, value: 'All' },
        { name: 'Voted Cities', isActive: false, value: 'Voted Cities' },
        { name: 'Published', isActive: false, value: 'Published' },
        { name: 'Unpublished', isActive: false, value: 'Unpublished' },
        { name: 'Newly Added', isActive: false, value: 'Newly Added' },
        { name: 'Recently Updated', isActive: false, value: 'Recently Updated' },
    ]);
    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string; name: string; component: RouteProps['component'] }> = [
        {
            url: 'details',
            name: 'Details',
            component: Details,
        },
        {
            url: 'places',
            name: 'Places - ' + (details && details.counts ? details.counts.establishments || 0 : 0),
            component: Places,
        },
        {
            url: 'dishes',
            name: 'Dishes - ' + (details && details.counts ? details.counts.foodDrinks || 0 : 0),
            component: Dishes,
        },
        {
            url: 'cuisines',
            name: 'Cuisines - ' + (details && details.counts ? details.counts.cuisines || 0 : 0),
            component: Cuisines,
        },
        {
            url: 'pictures',
            name: 'Pictures - ' + (details && details.counts ? (details._landscapePictures?.length || 0) + (details._pictures?.length || 0) : 0),
            component: Images,
        },
        {
            url: 'landmarks',
            name: 'Landmarks - ' + (details && details.counts ? details.counts.landmarks || 0 : 0),
            component: Landmarks,
        },
        {
            url: 'explore',
            name: 'Explore - ' + (details && details.counts ? details.counts.lists || 0 : 0),
            component: Explore,
        },
        {
            url: 'eatineraries',
            name: 'Eatineraries - ' + (details?.eateries?.length || 0),
            component: Eatineraries,
        },
        {
            url: 'sell-page',
            name: 'Sell page',
            component: SellPage,
        },
        {
            url: 'user',
            name: 'User - ' + (subscriptionList.count || 0),
            component: User,
        },
    ];

    const dispatch = useDispatch<TDispatch>();

    const getData = (filterConfig: TFilterConfig[] = JSON.parse(JSON.stringify(initialFilter)), sortFilter: any = {}) => {
        setLoading(true);
        try {
            dispatch(getCityList(pageNumber, filterConfig, { ...sortFilter }));
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleSearchResult = (result: Array<TCity>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    };
    const getCityDetails = useAsyncTask(async (id: string) => {
        dispatch(
            OCity.getItemActivity(
                id,
                OCity.Activities.DETAILS
                // {
                //     filters: {
                //         include: 'landmarks'
                //     }
                // }
            )
        );
    });

    useEffect(() => {
        if (details?.id) {
            dispatch(getSubscribeUserList(details?.id, pageNumber))
        }
    }, [details]);

    useEffect(() => {
        if (cityId) getCityDetails.run(cityId);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);
    useEffect(() => {
        getData(filterConfig, sortFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    const handleFilterChange = (item: { name: string; isActive: boolean }) => {
        const updatedFilter = filterConfig.map((i) => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        });
        setFilterConfig([...updatedFilter]);
    };
    const handleFilterSubmit = () => {
        if (pageNumber === 1) getData(filterConfig, sortFilter);
        else setPageNumber(1);
    };
    const handleFilterReset = () => {
        setFilterConfig(JSON.parse(JSON.stringify(initialFilter)));
        if (pageNumber === 1) getData(undefined, sortFilter);
        else setPageNumber(1);
    };
    const handleAddCity = useCallback(() => dispatch({ type: OCity.Actions.SHOW_CITY_DETAIL_FORM }), [dispatch]);
    const handleSortClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSortMenuEl(e.currentTarget);
    };

    const handleSortingChange = (filter: any) => {
        // dispatch(OUser.getItemsList({ filter: { limit: 20, skip: 20 * (pageNumber - 1), ...filter } }));
        setSortFilter(filter);
        if (pageNumber === 1) getData(filterConfig, filter);
        else setPageNumber(1);
    };

    const renderListItem = useCallback(
        (place: TCity): ReactElement => {
            const isActive = (match: any, location: any) => {
                const regex = new RegExp(`${BASE_URL}/${place.id}/`, 'ig');
                return regex.test(location.pathname);
            };
            if (!place) return <div />;
            else {
                return (
                    <span key={place.id}>
                        <NavLink className={classes.listItem} to={`${BASE_URL}/${place.id}/details`} isActive={isActive} activeClassName={'selected'}>
                            <ListItem button>
                                <ListItemText
                                    primary={`${place.name || ''}`}
                                    secondary={`${place.country || ''}`}
                                    secondaryTypographyProps={{ color: 'textSecondary' }}
                                />
                                <ListItemSecondaryAction style={{ right: '5px' }}>
                                    {place.totalVotes && place.totalVotes > 0 ? (
                                        <i className={classNames('material-icons', classes.thumbIcon)}>thumb_up</i>
                                    ) : null}
                                    {!place.isPublished ? <i className={classNames('material-icons', classes.lockIcon)}>lock</i> : null}
                                    {place.totalVotes && place.totalVotes > 0 ? (
                                        <Typography variant="overline" className={classes.voteCount}>
                                            {place.totalVotes}
                                        </Typography>
                                    ) : null}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider className={classes.divider} />
                        </NavLink>
                    </span>
                );
            }
        },
        [classes]
    );

    return (
        <>
            <ListDashboard
                onItemAddRequest={handleAddCity}
                csvConfig={{
                    modelName: 'Destination',
                }}
                baseUrl={BASE_URL}
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                itemsCountPerPage={20}
                onPageChange={setPageNumber}
                onFilterChange={handleFilterChange}
                onFilterSubmit={handleFilterSubmit}
                onFilterReset={handleFilterReset}
                filterConfig={filterConfig}
                currentPageNumber={pageNumber}
                listProps={{
                    searchProps: {
                        type: 'Destination',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}
                listActionButtonConfig={[
                    {
                        icon: 'sort',
                        id: 'sort',
                        label: 'Sort',
                        onClick: handleSortClick,
                    },
                ]}
            />
            <UsersListSortMenu anchorEl={sortMenuEl} onSortingChange={handleSortingChange} handleCloseSortMenu={() => setSortMenuEl(undefined)} />
            <CityForm />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            backgroundColor: theme.palette.grey['500'],
        },
        listItem: {
            color: theme.palette.text.primary,
            '&.selected': {
                background: fade(theme.palette.primary.main, 0.4),
                display: 'block',
            },
        },
        subDashboardContainer: {
            marginLeft: 250,
        },
        headLinks: {
            marginBottom: 50,
        },
        thumbIcon: {
            fontSize: 13,
            color: theme.palette.grey[400],
        },
        lockIcon: {
            fontSize: 13,
            color: '#B71840',
        },
        voteCount: {
            fontSize: '14px',
            textAlign: 'right',
        },
    })
);

export default Cities;
