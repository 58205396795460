import LoopFront from "loop-front";



const CustomActions = {
    SHOW_LANDMARK_FORM: 'SHOW_LANDMARK_FORM',
    HIDE_LANDMARK_FORM: 'HIDE_LANDMARK_FORM',
}

const CustomEntities = {

}
const CustomActivities = {
    COUNT: 'count',
    PUBLISH: 'publish',
}


class Landmarks extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities>{
    constructor() {
        super('landmarks', CustomActions, CustomEntities, CustomActivities);
    }

}

export const OLandmarks = new Landmarks();


