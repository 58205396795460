import { DialogContent, Fab, Icon, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import Config from '../../Resources/Config';
import { uploadEntityPicture } from '../../Resources/PictureUtils';
import ImageCropper from '../Inputs/ImageCropper';

export interface VideoLinkDialogProps {
	handleDialog: (url: string) => void,
	closeDialog: () => void,
	open: boolean,
	onVideoLinkSubmit?: (url:string) => void
}

export interface VideoLinkDialogState {
	url: string
	errorText: string;
}

type unitType = '%' | 'px'
export default class VideoLinkDialog extends Component<VideoLinkDialogProps, VideoLinkDialogState> {
	imageRef: HTMLImageElement | null;
	fileUrl: string;
	constructor(props: VideoLinkDialogProps) {
		super(props);
		this.imageRef = null;
		this.fileUrl = ''
	}
	state = {
		url: '',
		errorText: ''
	}
	closeDialog = () => this.props.closeDialog();

	submitDialog = async () => {
		this.props?.onVideoLinkSubmit !== undefined && this.props.onVideoLinkSubmit(this.state.url);
		this.closeDialog();
	}

	handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let url:string = e.currentTarget.value;

		if (url !== undefined || url !== '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
				url = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0';
                this.setState({
					url:url,
					errorText: ''
				});
            }
            else {
				this.setState({
					url:url,
					errorText: "Invalid url"
				});
            }
        }
	}


	render() {
		const isDisabled = this.state.url === '' || this.state.errorText !== '';

		return (
			<div>
				<Dialog
					style={{ width: '700px', height: '800px', margin: '0 auto' }}
					open={this.props.open}
					keepMounted
					onClose={this.closeDialog}>
					<div style={{ width: '100%', height: '100%' }}>
						<DialogTitle>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<Typography variant="h6" style={{ marginTop: '5px' }}>Video Link</Typography>
								<Fab size="small" color={'inherit'} onClick={this.closeDialog}>
									<Icon color={'action'} className={'material-icons'}>close</Icon>
								</Fab>
								{/* <Button onClick={this.closeDialog}><i className="material-icons" >close</i></Button> */}
							</div>
						</DialogTitle>
						<DialogContent>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ width: '400px', height: 'auto', marginTop: '20px' }}>
									<TextField
										style={{ width: '100%', margin: '0 auto' }}
										variant="outlined"
										inputProps={{ onChange: this.handleUrlChange }}
										value={this.state.url || ''}
										autoFocus={true}
										error ={this.state.errorText !== ''}
										label=""
									>
									</TextField>

								</div>
								<div style={{ width: '400px', height: 'auto', marginTop: '20px' }}>
									<Button
										style={{ width: '100%' }}
										variant="contained"
										color="primary"
										onClick={this.submitDialog}
										disabled={isDisabled}
									>
										Done
									</Button>
								</div>
							</div>
						</DialogContent>
					</div>
				</Dialog>
			</div>
		);
	}
}


