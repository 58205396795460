import { Box, Icon, makeStyles, createStyles, Theme,  Paper, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { TEatinerraiesSection } from '../../../Models/Eatinerraies/@types';

export interface SectionListItemProps {
    section: TEatinerraiesSection,
    dragHandleProps?: any
    OnDeleteSection: (id: string) => void
}

const SectionListItem: FC<SectionListItemProps> = (props) => {
    const { section, dragHandleProps,OnDeleteSection } = props;
    const classes = useStyles();
   
    console.log(section);

    return (
        <Paper className={classes.wrapper}>
        <Box display="flex" py={1} px={1}>
            <Box  display="flex" flex={1} flexDirection="column">
              <Typography variant='subtitle2'>{section.title}</Typography>
              <Typography variant='body2'>{section.placeIds.length + " places"}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
                {<Icon color='action'
                id='drag'
               {...dragHandleProps} className={'dragHandle ' + classes.icon} fontSize='small'>open_with</Icon>}
                {<Icon color='action' className={classes.icon} onClick={() => OnDeleteSection( section?.id !== undefined ? section.id : "")} fontSize='small' >close</Icon>}
            </Box>
        </Box>
       </Paper>
    );
}


const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        wrapper: {
            padding: theme.spacing(0.1, 0.2),
            width:"100%"
        },
        icon: {
            fontSize: 17,
            cursor: "pointer",
            marginLeft: 5
        }
    });
});

export default SectionListItem;

