import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import FormDialog from '../../../../Components/Dialogs/FormDialog';
import GoogleLocationSuggest from '../../../../Components/Inputs/GoogleLocationSuggest';
import { OApp } from '../../../../Models/App';
import { OPlace } from '../../../../Models/Place';
import { TPlace, TPlaceState } from '../../../../Models/Place/@types';
import { TSettingsState } from '../../../../Models/Settings/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import GoogleUtils from '../../../../Resources/google';
import { TReduxStore } from '../../../../RootReducer';
import AddressSection from './AddressSection';
import BasicInfoSection from './BasicInfoSection';
import MamakooRating from './MamakooRating';
import OpeningDaysSection from './OpeningDaysSection';
import RatingSection from './RatingSection';
import { EDITED_PLACE_UPDATE, EMPTY_STATE, initialState, reducer, STATE_UPDATE, UPDATE } from './Reducer';
import SocialMediaUrl from './SocialMediaUrl';
type IStateProps = Pick<TPlaceState, 'editedPlace' | 'showMainForm'>

export interface PlaceFormProps extends RouteComponentProps { }


const keys = Object.keys(initialState) as (keyof TPlace)[]
const DEFAULT_DESCRIPTION = `⚡
👍
⏰
📆
📍
🎥`

const PlaceForm: FC<PlaceFormProps> = (props) => {



    const classes = useStyles();
    const { history } = props;
    const { editedPlace = {}, showMainForm } = useSelector<TReduxStore, IStateProps>(({ Place }) => ({ editedPlace: Place.editedPlace, showMainForm: Place.showMainForm }));
    const dispatch = useDispatch<TDispatch>();
    const { list = [] } = useSelector<TReduxStore, Pick<TSettingsState, 'list'>>(({ Settings: { list } }) => ({ list }));
    let typeMap = list.filter(i => i.key === 'establishmentPlaceType')[0]?.value || []
    const [placeDetails, setPlaceDetails] = useState<google.maps.places.PlaceResult>();
    const [state, dispatchReducer] = useReducer(reducer, initialState);
    const [addByOwn, setAddByOwn] = useState(false);
    const closeForm = () => {
        dispatch({ type: OPlace.Actions.HIDE_PLACE_DETAIL_FORM })
        setAddByOwn(false);
    };


    const handleSearchResultClick = <T extends { place_id: string }>(item: T) => {
        if (!editedPlace.id) {
            dispatchReducer({ type: EMPTY_STATE, name: '', data: {} })
        }
        getDetails(item.place_id)
    }
    const getDetails = async (id: string) => {
        const res = await GoogleUtils.placeDetails(id)
        setPlaceDetails(res)
    }
    const handleSubmit = async () => {
        if (!state.destinationId) {
            return;
        }
        if (editedPlace.id) {

            try {
                await dispatch(OPlace.patchItem(editedPlace.id, state, {}, undefined, { ...editedPlace, ...state }))
                dispatch(OApp.showToast({ message: 'Place Details Updated', variant: 'success' }))
                dispatch({ type: OPlace.Actions.HIDE_PLACE_DETAIL_FORM });
                dispatchReducer({ type: STATE_UPDATE, name: 'state', data: initialState })
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Place Details Update Unsuccessful', variant: 'error' }))
                console.log(err)
            }
        } else {
            try {
                let res: { data: TPlace } = await OPlace.requestPostItem({ ...state, inMamakoo: true });
                // await dispatch(OPlace.postItem({ ...state, inMamakoo: true }));
                dispatch({ type: OPlace.Actions.POST_ITEM_SUCCESS, data: res.data })
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'Establishment' } })
                dispatch(OApp.showToast({ message: 'Place Added', variant: 'success' }))
                dispatch({ type: OPlace.Actions.HIDE_PLACE_DETAIL_FORM });
                dispatchReducer({ type: STATE_UPDATE, name: 'state', data: initialState })
                history.push('/dashboard/places/' + res.data.id + '/details')
            } catch (err) {
                let message = 'Place Add Unsuccessful';
                let error = err?.response?.data?.error;

                if (error?.details) {
                    if (error?.details?.codes?.placeId?.indexOf('uniqueness') > -1) {
                        message = 'This place is already in mamakoo';
                    }
                }
                else {
                    message = error?.message;
                }
                dispatch(OApp.showToast({ message, variant: 'error' }))
                console.log('Place add edit error', { err })
            }
        }


    }
    const parseEditInfo = () => {
        let newState: Partial<TPlace> = {};
        keys.forEach((key) => {
            newState = { ...newState, [key]: editedPlace[key] }
        })
        dispatchReducer({ type: STATE_UPDATE, name: 'state', data: { ...newState } });
    }
    const parsePlaceDetails = () => {

        if (placeDetails) {
            var addressinfo = GoogleUtils.transformAddress(placeDetails);
            var _openingDays = GoogleUtils.formatOpeningHours(placeDetails.opening_hours)



            if (addressinfo) {


                if (editedPlace.id) {
                    console.log('edited place id => ', editedPlace.id)
                    dispatchReducer({ type: EDITED_PLACE_UPDATE, name: '', data: addressinfo })

                    dispatchReducer({ type: UPDATE, name: 'placeId', data: placeDetails.id })
                    if (placeDetails.geometry) {
                        var geo: google.maps.places.PlaceGeometry = placeDetails.geometry
                        var location = geo.location;
                        var lat = location.lat();
                        var lng = location.lng();
                        dispatchReducer({ type: UPDATE, name: 'location', data: { lat: lat, lng: lng } })

                    }
                }
                else {
                    var newState: Partial<TPlace> = {
                        placeId: placeDetails.place_id,
                        isPublished: false,
                        name: placeDetails.name,
                        phone: placeDetails.formatted_phone_number,
                        website: placeDetails.website,
                        range: placeDetails.price_level || 0,
                        address: addressinfo.full_address || '',
                        addressLine1: addressinfo.address1 || '',
                        city: addressinfo.city || '',
                        zipcode: addressinfo.zipcode || '',
                        locality: addressinfo.locality || '',
                        _openingDays: _openingDays,
                        description: DEFAULT_DESCRIPTION,
                        externalRatings: [{ name: 'Google', value: placeDetails.rating || null }]
                    }
                    if (placeDetails.geometry) {
                        var Geo: google.maps.places.PlaceGeometry = placeDetails.geometry
                        var Location = Geo.location;
                        var Lat = Location.lat();
                        var Lng = Location.lng();
                        newState.location = { lat: Lat, lng: Lng }

                    }
                    if (placeDetails.types) {
                        let types = typeMap.filter(i => placeDetails.types?.includes(i.value)).map(i => i.name);
                        // let types = GoogleUtils.placeTypesParser(placeDetails.types, typeMap)
                        newState.types = types
                    }
                    dispatchReducer({ type: STATE_UPDATE, name: 'state', data: { ...newState } })
                }
            }
        }

    }

    useEffect(() => {
        if (placeDetails) {
            parsePlaceDetails();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeDetails])

    useEffect(() => {
        if (Object.keys(editedPlace).length > 0) {
            parseEditInfo();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedPlace])
    const isEditForm = editedPlace.id ? true : false;
    return (
        <FormDialog
            open={showMainForm}
            heading={editedPlace.id ? `Update ${editedPlace.name}` : 'Create New Place'}
            onSubmit={handleSubmit}

            onClose={closeForm}
        >
            {

                <Box width={450} display='flex' flexDirection='column' >

                    <GoogleLocationSuggest
                        variant={'outlined'}
                        fullWidth
                        onResultClick={handleSearchResultClick}
                    // suggestionsTypes={['establishment']}
                    />
                    {isEditForm ? null :
                        <div className={classes.captionDiv}>
                            <Typography variant="body2" display="block" >
                                Couldn't see what you're looking for?
                        </Typography>
                            <Button color="primary" onClick={() => { setAddByOwn(true) }}>CREATE NEW</Button>
                        </div>
                    }
                </Box>

            }
            <Box width={800} mt={5} >
                {

                    (editedPlace.id || placeDetails || addByOwn)
                        ? <>
                            <BasicInfoSection dispatchReducer={dispatchReducer} state={state} />
                            <AddressSection dispatchReducer={dispatchReducer} state={state} />
                            <MamakooRating dispatchReducer={dispatchReducer} state={state} />
                            {state.externalRatings && <RatingSection dispatchReducer={dispatchReducer} externalRatings={state.externalRatings || []} />}
                            <SocialMediaUrl dispatchReducer={dispatchReducer} state={state} />
                            <OpeningDaysSection dispatchReducer={dispatchReducer} openingHours={state._openingDays} />
                        </> : null
                }
            </Box>

        </FormDialog>
    )
}
const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        captionDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '30px'
        },


    }))
})

export default withRouter(PlaceForm)

// placeid : 0b6b6a91d1a0d1f5e837c3746a60968e3aa9526c
// palceId : ChIJA5PeFXekfDURovSZ_S5XMuI

// placeId: "0b6b6a91d1a0d1f5e837c3746a60968e3aa9526c" ​
// placeid: "ChIJA5PeFXekfDURovSZ_S5XMuI"