import { CircularProgress, createStyles, Divider, List, Paper, Theme, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { Component, ReactElement } from 'react';
import NewEntitySuggest, { NewEntitySuggestProps } from '../NewEntitySuggest';
import PaginationComponent from '../Pagination';

export interface MainListProps<T> extends WithStyles<typeof STYLES> {
    list: Array<T>;
    float?: 'left' | 'center';
    loading?: boolean;
    addButton?: ReactElement;
    onPageChange?: (pageNumber: number) => void;
    totalItemsCount?: number;
    search?: boolean;
    chips?: boolean;
    searchProps: Omit<NewEntitySuggestProps<any>, 'placeholder' | 'classes'>;
    itemsCountPerPage: number;
    isform?: boolean;
    currentPageNumber?: number;
}

interface IState {
    currentPage: number;
}

class MainList<T = any> extends Component<MainListProps<T>, IState> {
    constructor(props: MainListProps<T>) {
        super(props);
        this.state = {
            currentPage: this.props.currentPageNumber ? this.props.currentPageNumber : 1,
        };
    }
    clicked = false;

    listComponent: HTMLUListElement | null = null;

    onResults = (results: Array<any>) => {
        console.log('Results', results);
    };
    componentDidUpdate<T = any>(previousprops: MainListProps<T>) {
        if (previousprops.currentPageNumber !== this.props.currentPageNumber) this.setState({ currentPage: this.props.currentPageNumber || 1 });
    }
    handlePageChange = (pageNumber: number) => {
        this.setState({ currentPage: pageNumber });
        if (typeof this.props.onPageChange === 'function') {
            this.props.onPageChange(pageNumber);
            if (this.listComponent) {
                this.listComponent.scrollTo(0, 0);
            }
        }
    };

    render() {
        const { classes } = this.props;
        const { list = [], loading, float = 'center', search = false, chips = false, searchProps, itemsCountPerPage, isform = false } = this.props;

        return (
            <div className={classes.mainListContainerWrapper}>
                <div
                    className={clsx(
                        isform ? classes.mainListFormContainer : classes.mainListContainer,
                        float === 'left' ? classes.listLeft : classes.listCenter
                    )}
                >
                    <Paper className={classes.paper}>
                        {loading && <CircularProgress className={classes.listLoader} color="primary" thickness={5} />}
                        {!loading && search && (
                            <div>
                                <NewEntitySuggest
                                    placeholder="Search"
                                    onResults={this.onResults}
                                    textFieldProps={{
                                        classes: {
                                            root: classes.searchInput,
                                        },
                                    }}
                                    {...searchProps}
                                />
                                {/* <EntitySuggest
                                    placeholder="Search"
                                    onResults={this.onResults}
                                    classes={{
                                        input: classes.searchInput
                                    }}
                                    {...searchProps}
                                /> */}
                            </div>
                        )}
                        {!loading && list.length > 0 && (
                            <span>
                                {chips ? (
                                    <div className={classes.chipsContainer}>{list}</div>
                                ) : (
                                    <span>
                                        <List ref={(ref) => (this.listComponent = ref)} className={classes.nav}>
                                            {list}
                                        </List>
                                        <Divider />
                                        <PaginationComponent
                                            itemsCountPerPage={itemsCountPerPage}
                                            currentPage={this.state.currentPage}
                                            totalItemsCount={this.props.totalItemsCount || 0}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </span>
                                )}
                            </span>
                        )}
                    </Paper>
                    <div className={classes.addBtn}>{this.props.children || ''}</div>
                </div>
            </div>
        );
    }
}

const STYLES = (theme: Theme) =>
    createStyles({
        nav: {
            padding: 0,
            height: 'calc(100% - 120px)',
            overflow: 'auto',
        },
        mainListContainerWrapper: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
        },
        mainListContainer: {
            position: 'fixed',
            top: 0,
            bottom: 0,
        },
        mainListFormContainer: {
            position: 'fixed',
            top: '70px',
            bottom: 0,
        },
        listCenter: {
            width: 400,
        },
        paper: {
            height: '100%',
        },
        listLeft: {
            width: 250,
            left: 150,
        },
        listLoader: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
        addBtn: {
            display: 'flex',
            'flex-direction': 'column',
            position: 'absolute',
            bottom: '55px',
            right: '-30px',
        },
        searchInput: {
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.grey['100']}`,
            borderRadius: 0,
        },
        chipsContainer: {
            paddingTop: 20,
            paddingRight: 50,
        },
    });

export default withStyles(STYLES)(MainList);
