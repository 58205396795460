import { createStyles, ListItemText, TextField, Theme } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import GoogleUtils, { TGooglePlaceSuggestCategories } from '../../Resources/google';
import Clear from '@material-ui/icons/Clear';

export type GoogleLocationSuggestProps = TextFieldProps & {
    onResultClick: (result: any) => void
    suggestionsTypes?: TGooglePlaceSuggestCategories[]
}

const GoogleLocationSuggest: FC<GoogleLocationSuggestProps> = (props) => {
    const classes = useStyles();
    const [input, setInput] = useState('');
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [result, setResult] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!open)
            setOpen(true);
        setInput(e.target.value);
        // anchorEl || setAnchorEl(e.currentTarget);
    };

    const clearInput = () => {
        if (open)
            setOpen(false);
        setInput('');
        setResult([]);
    }
    const getSuggestions = async () => {
        const res = await GoogleUtils.placeSuggest(input, props.suggestionsTypes ?? []) as any[];
        if (res) setResult(res);
    }

    useEffect(() => {
        if (input) {
            getSuggestions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    const { onResultClick, suggestionsTypes, ...textFieldProps } = props;

    const handleResultClick = (item: any) => {
        setInput(item.description)
        typeof props.onResultClick === 'function' && props.onResultClick(item);
        setOpen(false);
    }
    return (
        <>
            <Autocomplete
                getOptionLabel={option => option.description}
                classes={{
                    popper: classes.popper,
                }}
                filterOptions={x => x}
                options={result}
                includeInputInList
                autoComplete
                className={classes.root}
                open={open && (input.length > 0)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        // onChange={handleInputChange} 
                        // value={input || ''}

                        inputProps={{
                            ...params.inputProps,
                            onChange: handleInputChange, value: input || '',
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (<React.Fragment>
                                <Clear fontSize='small' onClick={clearInput} className={classes.endIcon} />
                            </React.Fragment>)
                        }}
                        placeholder='Search on google'
                        variant='standard'

                        {...textFieldProps}
                    />

                }

                renderOption={(item) => (<ListItemText onClick={() => handleResultClick(item)}>{item.description}</ListItemText>)
                }
            />
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        popper: {
            zIndex: theme.zIndex.modal + 1
        },
        endIcon: {
            cursor: 'pointer'
        },
        root: {
            '& .MuiAutocomplete-popup': {
                zIndex: theme.zIndex.modal + 1
            }
        }
    }))
})

export default GoogleLocationSuggest