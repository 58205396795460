import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FormikProps } from 'formik';
import React, { FC, memo, useEffect } from 'react';
import { IReactFormProps, MLFormContent } from 'react-forms';
import { useSelector } from 'react-redux';
import { TExploreFilter } from '.';
import { TCity } from '../../../Models/City/@types';
import { TExplore } from '../../../Models/Explore/@types';
import { TReduxStore } from '../../../RootReducer';

export interface BasicInfoProps {
    formikProps: FormikProps<Partial<TExplore>>,
}
interface IProps {
    cityList: TCity[]
}
const BasicInfo: FC<BasicInfoProps> = (props) => {
    const classes = useStyles();
    const { formikProps } = props;
    const { cityList } = useSelector<TReduxStore, IProps>(({ City: { list: cityList = [] } }) => ({ cityList }))

    const destinationId = formikProps.values.destinationId

    useEffect(() => {
        // if (destinationId) {
        //     onChange('destinationId', destinationId)
        // }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationId])

    const config: IReactFormProps['config'] = [
        {
            valueKey: 'title',
            type: 'text',
            fieldProps: {
                label: 'Title',
                fullWidth: true,
            },

        }      
    ]
    return (
        <Paper className={classes.paper} key='form'>
            <MLFormContent
                formId='explore-form'
                schema={config}
                formikProps={formikProps}
            />
        </Paper>

    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: 450,
            padding: theme.spacing(2, 1.5),
            margin: '40px auto',
        }
    }))
})

export default memo(BasicInfo)