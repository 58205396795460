import { createStyles, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import { OApp } from '../../../Models/App';
import { OCuisine } from '../../../Models/Cuisines';
import { TCuisineState, TCuisine } from '../../../Models/Cuisines/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';
import { RouteComponentProps, withRouter } from 'react-router';
import TextFieldWMention, { TextFieldWMentionProps } from '../../../Components/Inputs/TextFieldWMention';
import { textFieldWMentionHelpers } from '../../../Components/Inputs/TextFieldWMention/helpers';
import dimensions from '../../../Constants/dimensions';

export interface MainFormProps extends RouteComponentProps { }

const MainForm: FC<MainFormProps> = (props) => {
    const classes = useStyles();
    const { editedCuisine, showMainForm = false } = useSelector<TReduxStore, Pick<TCuisineState, 'showMainForm' | 'editedCuisine'>>(({ Cuisine }) => ({ editedCuisine: Cuisine.editedCuisine, showMainForm: Cuisine.showMainForm }))
    const [cuisineState, setCuisineState] = useState({ name: '', description: '', shortDescription: '' });
    const dispatch = useDispatch<TDispatch>();
    const onSubmit = async () => {
        if (editedCuisine && editedCuisine.id) {
            try {
                await dispatch(OCuisine.patchItem(editedCuisine.id, cuisineState, {}, undefined, { ...editedCuisine, ...cuisineState }))
                dispatch(OApp.showToast({ message: "Cuisine update Successful", variant: 'success' }))
                closeForm();

            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: "Cuisine update unSuccessful", variant: 'error' }))
            }
        } else {
            try {
                //await dispatch(OCuisine.postItem(cuisineState));
                let res: { data: TCuisine } = await OCuisine.requestPostItem(cuisineState);
                dispatch({ type: OCuisine.Actions.POST_ITEM_SUCCESS, data: res.data })
                props.history.push('/dashboard/cuisines/' + res.data.id + '/details')
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'Cuisine' } })
                dispatch(OApp.showToast({ message: 'Cuisine Add Successful', variant: 'success' }))
                closeForm();

            } catch (err) {
                console.log(err);
                dispatch(OApp.showToast({ message: 'Cuisine Add Unsuccessful', variant: 'error' }))
            }
        }

    }
    const closeForm = () => {
        setCuisineState({ name: '', description: '', shortDescription: '' })
        dispatch({ type: OCuisine.Actions.HIDE_CUISINE_DETAIL_FORM })
    }
    const handleChange = (e: React.ChangeEvent<{ name: unknown, value: unknown }>) => {
        e.preventDefault();
        setCuisineState({ ...cuisineState, [e.target.name as string]: e.target.value as string })
    }
    const handleDescriptionChange: (name: 'shortDescription' | 'description') => TextFieldWMentionProps['handleChange'] = (name) => (e, newValue, newValueText) => {
        setCuisineState({ ...cuisineState, [name as string]: e.target.value as string })
    }

    useEffect(() => {
        if (editedCuisine && editedCuisine.id) {
            setCuisineState({ ...setCuisineState, name: editedCuisine.name, description: editedCuisine.description || '', shortDescription: editedCuisine.shortDescription || '' })
        }
    }, [editedCuisine])
    return (
        <FormDialog
            open={showMainForm}
            heading={'Add/Edit Cuisine'}
            onSubmit={onSubmit}
            onClose={closeForm}
        >
            <Paper className={classes.paper}>
                <div className={classes.wrapper}>
                    <TextValidator
                        value={cuisineState.name}
                        name='name'
                        onChange={handleChange}
                        color='primary'
                        fullWidth
                        className={classes.textField}
                        label={'Name of Cuisine'}
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                    <TextFieldWMention
                        BoxProps={{ className: classes.textField }}
                        label="Short Description"
                        fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                        value={cuisineState.shortDescription || ''}
                        handleChange={handleDescriptionChange('shortDescription')}
                    />
                    <TextFieldWMention
                        BoxProps={{ className: classes.textField }}
                        label="Description"
                        fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                        value={cuisineState.description || ''}
                        handleChange={handleDescriptionChange('description')}
                    />
                    {/* <TextValidator
                        value={cuisineState.shortDescription}
                        name='shortDescription'
                        onChange={handleChange}
                        multiline
                        color='primary'
                        fullWidth
                        label={'Short Description'}
                        className={classes.textField}
                    />
                    <TextValidator
                        value={cuisineState.description}
                        name='description'
                        onChange={handleChange}
                        multiline
                        color='primary'
                        fullWidth
                        label={'Description'}
                        className={classes.textField}
                    /> */}
                </div>
            </Paper>
        </FormDialog>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            marginTop: '120px auto',
            width: dimensions.formPaperMaxWidth,
            padding: '40px',
            height: 'auto'
        },
        wrapper: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > div:first-child': {
                width: '100%'
            }
        },
        textField: {
            marginTop: '15px'
        }
    }))
})

export default withRouter(MainForm)