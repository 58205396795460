import React, { FC, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Breadcrumbs, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { OUser } from '../../../../Models/User';
import moment from 'moment';
import { useParams } from 'react-router';
import dimensions from '../../../../Constants/dimensions';
import { TReduxStore } from '../../../../RootReducer';
import { TUserState } from '../../../../Models/User/@types';
import { generatePath, Link } from 'react-router-dom';
import EntityCard from '../../../../Features/Home/EntityCard';
import ItemCard from '../../../../Components/Cards/ItemCard';
import { DATE_FORMAT } from '../../../../Constants/vars';
import { USER_SAVE_LIST_BASE_URL } from './SavedListRouter';

export interface SavedListsProps {

}

const SavedLists: FC<SavedListsProps> = (props) => {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const { userId } = useParams<{ userId: string }>()
    const { userSavedLists } = useSelector<TReduxStore, Pick<TUserState, 'userSavedLists'>>(({ User: { userSavedLists } }) => ({ userSavedLists }))

    useEffect(() => {
        dispatch(OUser.getEntityByItem(userId, OUser.Entities.SAVED_LISTS, { filter: { order: 'created DESC' } }))
    }, [])


    return (
        <Box className={classes.root}>

            <Grid container spacing={2}>
                {userSavedLists.map((userList, index) => (
                    <ItemCard
                        link={generatePath(USER_SAVE_LIST_BASE_URL, { userId }) + `/${userList.id}`}
                        gridProps={{ md: 4 }}
                        key={userList.id}
                        title={userList.name}
                        subtitle={moment(userList.created).format(DATE_FORMAT)}
                    />
                ))}
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        flexDirection: 'column'
    }
}));

export default SavedLists;