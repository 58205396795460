import { TAction } from "loop-front";
import { Reducer } from "redux";
import { OUserReview } from ".";
import utilities from "../../Resources/Utils";
import { TUserReviewState } from './@types';

const initialState: TUserReviewState = {
    list: [],
    showMainForm: false,
    loading: false,
    totalCount: 0
}



export const RUserReview: Reducer<TUserReviewState, TAction> = (state = { ...initialState }, action) => {

    const {
        LIST_RECEIVED,
        SHOW_USER_REVIEW_FORM,
        HIDE_USER_REVIEW_FORM,
        ITEM_DELETED,
        ITEM_PATCH_SUCCESS,
        POST_ITEM_SUCCESS,
        SINGLE_ITEM_RECEIVED
    } = OUserReview.Actions;

    switch (action.type) {
        case SINGLE_ITEM_RECEIVED: return { ...state, detail: action.data }
        case ITEM_PATCH_SUCCESS: {
            return { ...state, list: utilities.updateItemList(state.list, action.data, 'UPDATE'), detail: action?.data?.id === state.detail?.id ? { ...state.detail, ...action.data } : state.detail }
        }
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list, action.data, 'ADD') }
        case ITEM_DELETED: return { ...state, list: utilities.updateItemList(state.list, action.additionalDispatchData, 'DELETE') }
        case SHOW_USER_REVIEW_FORM: return { ...state, showMainForm: true, editedReview: action.data }
        case HIDE_USER_REVIEW_FORM: return { ...state, showMainForm: false, editedReview: undefined }
        case LIST_RECEIVED: {
            return { ...state, initialized: true, list: action.data }
        }


        default: return state;
    }
}