import { Box, Chip, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { TExploreFilter } from '.';
import NewEntitySuggest from '../../../Components/NewEntitySuggest';

export interface CuisineFilterProps {
    onChange: (name: keyof TExploreFilter, value: string[]) => void;
}
type TSearchType = { name: string; id: string };
const CuisineFilter: FC<CuisineFilterProps> = (props) => {
    const classes = useStyles();
    const { onChange } = props;
    const [options, setOptions] = useState<TSearchType[]>([]);

    const onSelect = (item: TSearchType) => {
        if (options.findIndex((i) => i.id === item.id) === -1) {
            onChange(
                'cuisineIds',
                [...options, item].map((i) => i.id)
            );
            setOptions((o) => [...o, item]);
        }
    };
    const handleDelete = (id: string) => {
        onChange(
            'cuisineIds',
            [...options].filter((i) => i.id !== id).map((i) => i.id)
        );
        setOptions((o) => [...o.filter((i) => i.id !== id)]);
    };
    return (
        <Paper className={classes.paper}>
            <NewEntitySuggest placeholder="Search cuisines" type="Cuisine" onSelection={(i) => onSelect(i as TSearchType)} />
            <Box display="flex" flexWrap="wrap">
                {options.map((i, index) => {
                    return (
                        <Chip
                            key={i.id}
                            variant="outlined"
                            label={i.name}
                            style={{
                                marginRight: 8,
                                marginTop: 8,
                            }}
                            onDelete={() => handleDelete(i.id)}
                        />
                    );
                })}
            </Box>
        </Paper>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            width: 450,
            padding: theme.spacing(2, 1.5),
            margin: '40px auto',
            display: 'flex',
            flexDirection: 'column',
        },
    });
});

export default CuisineFilter;
