import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlacesCardList from '../../../../Components/Cards/CardLists/PlacesCardList';
import PlacesForm from '../../../../Components/Forms/PlacesForm';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import { OApp } from '../../../../Models/App';
import { OExplore } from '../../../../Models/Explore';
import { TExploreState } from '../../../../Models/Explore/@types';
import { TPlace } from '../../../../Models/Place/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

interface IProps { }

const Places: React.FC<IProps> = (props) => {
    const classes = useStyles(props)
    const { details, } = useSelector<TReduxStore, Pick<TExploreState, 'details'>>(({ Explore }) => ({ details: Explore.details }));
    const dispatch = useDispatch<TDispatch>();
    const displayList = details?.establishments || [];
    const [showForm, setShowForm] = useState(false)
    const deleteItem = async (item: TPlace) => {
        try {
            await dispatch(OExplore.patchItem(details!.id, { establishmentIds: details?.establishmentIds.filter(i => i !== item.id) }, {}, undefined, { establishmentIds: details?.establishmentIds.filter(i => i !== item.id), establishments: details?.establishments?.filter(i => i.id !== item.id) }))
            dispatch(OApp.showToast({ message: 'Place Deleted', variant: 'success' }))

        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: 'Place Delete Unsuccessful', variant: 'error' }))
        }
    }
    const toggleFormFlag = () => {
        setShowForm(a => !a);
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: toggleFormFlag,
            helpText: 'Add a place where this cuisine is available',
        },
    ]
    const onFormSubmit = async (newList: TPlace[]) => {
        try {
            await dispatch(OExplore.patchItem(details!.id, { establishmentIds: newList.map(i => i.id) }, {}, undefined, { establishmentIds: newList.map(i => i.id), establishments: newList }))
            dispatch(OApp.showToast({ message: 'Place Add / remove successfull.', variant: 'success' }))
            toggleFormFlag();
        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: 'Place Add / remove failed.', variant: 'error' }))
        }
    }
    const onReorder = async (newList: TPlace[]) => {
        try {
            await dispatch(OExplore.patchItem(details!.id, { establishmentIds: newList.map(i => i.id) }, {}, undefined, { establishmentIds: newList.map(i => i.id), establishments: [...newList] }))
            dispatch(OApp.showToast({ message: 'Place Reordered successfully', variant: 'success' }))

        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: 'Place Reorder Unsuccessful', variant: 'error' }))
        }
    }
    return (
        <div>
            <div className={classes.wrapper}>
                <HeaderActionButtons items={headerItems} />
                {showForm && <PlacesForm
                    closeForm={toggleFormFlag}
                    open={showForm}
                    places={details?.establishments || []}
                    heading={'Add / Edit Places'}
                    onFormSubmit={onFormSubmit}
                />}
                <PlacesCardList
                    deleteItem={deleteItem}
                    list={displayList}
                    onReorder={onReorder}
                // pageNumber={pageNumber}
                // totalCount={(details ? (details.counts.establishments || 0) : 0)}

                // handlePageChange={handlePageChange}

                />


            </div>
        </div >
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        },
        margin: '40px auto'
    },
}))

export default Places


