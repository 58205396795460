import { CircularProgress, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import PlacesCardList from '../../../../Components/Cards/CardLists/PlacesCardList';
import PlacesForm from '../../../../Components/Forms/PlacesForm';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import { ITEM_PER_PAGE_COUNT } from '../../../../Constants/FilterConstants';
import { OApp } from '../../../../Models/App';
import { ODish } from '../../../../Models/Dish';
import { TDishState } from '../../../../Models/Dish/@types';
import { addEntityToDish, deleteEntityfromDish } from '../../../../Models/Dish/actions';
import { TPlace } from '../../../../Models/Place/@types';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

export interface indexProps extends RouteComponentProps<{ id: string }> { }

const Places: FC<indexProps> = (props) => {
    const { match } = props;
    const dishId = match.params['id'];
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch<TDispatch>()
    const [showForm, setShowForm] = useState(false)
    const { details, places = [] } = useSelector<TReduxStore, Pick<TDishState, 'details' | 'places'>>(({ Dish }) => ({ details: Dish.details, places: Dish.places }))
    const textUrl = `${DOMAIN_URL}/dishes/${details?.slug || dishId}/places`;
    const [pageNumber, setPageNumber] = useState(1);
    const [displayList, setDisplayList] = useState<TPlace[]>([]);

    const handlePageChange = (pageNumber: number) => { setPageNumber(pageNumber) }

    const getData = async () => {
        setLoading(true);
        try {
            await dispatch(ODish.getEntityByItem(dishId, ODish.Entities.ESTABLISHMENTS, {
                filter: {
                    order: 'name ASC',
                    fields: ['name', 'status', 'isPublished', 'addressLine1', 'id']
                }
            }))
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }
    const deleteItem = async (item: TPlace) => {
        try {
            await dispatch(deleteEntityfromDish(dishId, ODish.Entities.ESTABLISHMENTS, places, item.id));
        } catch (err) {
            console.log(err)

        }
    }
    useEffect(() => {
        if (dishId) {
            getData();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dishId])
    useEffect(() => {
        if (dishId) {
            const low = ITEM_PER_PAGE_COUNT * (pageNumber - 1);
            const high = ITEM_PER_PAGE_COUNT * (pageNumber)
            const tList: TPlace[] = (places || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, places.toString()])
    const handleAddPlace = () => {
        setShowForm(true);
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddPlace,
            helpText: 'Add a place',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]
    const onFormSubmit = async (newList: TPlace[]) => {
        try {
            await dispatch(addEntityToDish(dishId, newList, 'Establishment'))

            setPageNumber(1);
            dispatch(OApp.showToast({ message: 'Places Updated Successfully', variant: 'success' }))
        } catch (err) {
            dispatch(OApp.showToast({ message: 'Places Update Unsuccessful', variant: 'error' }))
            console.log(err);
        }
        setShowForm(false);
    }
    const closeForm = () => {
        setShowForm(false)
    }
    return (
        <div className={classes.dishPlaces}>
            <HeaderActionButtons items={headerItems} />
            {showForm && <PlacesForm
                closeForm={closeForm}
                open={showForm}
                places={places}
                heading={'Add / Edit Places'}
                onFormSubmit={onFormSubmit}
            />}
            <div className={classes.wrapper}>
                {
                    loading ? <CircularProgress />
                        : <PlacesCardList
                            deleteItem={deleteItem}
                            list={displayList}
                            pageNumber={pageNumber}
                            handlePageChange={handlePageChange}
                            totalCount={details ? details.counts.establishments || 0 : 0}
                        />
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        dishPlaces: {
            position: 'relative',
            margin: '0px 40px',
        },
        wrapper: {
            width: 924,
            [theme.breakpoints.down('md')]: {
                width: 640,
            },
            [theme.breakpoints.down('sm')]: {
                width: 324,
            },
            margin: '40px auto'
        }
    }))
})

export default withRouter(Places)