import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MainList from '../../../../Components/Dashboard/MainList'
import FormDialog from '../../../../Components/Dialogs/FormDialog'
import { TSuggestions } from '../../../../Models/Place/@types'
import { updatePlaceSuggestions } from '../../../../Models/Place/actions'
import { OUser } from '../../../../Models/User'
import { TUser, TUserState } from '../../../../Models/User/@types'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import utilities from '../../../../Resources/Utils'
import { TReduxStore } from '../../../../RootReducer'

interface IProps {
    suggestions: TSuggestions[]
    open: boolean
    closeForm: () => void
    placeId: string
    primarySuggester?: TSuggestions
}

const UserForm: React.FC<IProps> = (props) => {

    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    const { open = false, closeForm = () => { }, placeId = '', suggestions = [] } = props
    const { list = [], totalCount } = useSelector<TReduxStore, Pick<TUserState, 'list' | 'totalCount'>>(({ User }) => ({ list: User.list, totalCount: User.totalCount }))
    const [searchList, setSearchList] = useState<TUser[]>([]);


    const [newCreatorList, setNewCreatorList] = useState<TUser[]>(suggestions.filter(i => !i.deleted).map(i => { return i.user }))
    const dispatch = useDispatch<TDispatch>();
    const [query, setQuery] = useState('');

    const getData = async () => {
        setLoading(true);
        try {
            await dispatch(OUser.getItemsList({ filter: { limit: 20, skip: 20 * (currentPage - 1) } }))
            currentPage === 1 && dispatch(OUser.getActivity(OUser.Activities.COUNT));
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }
    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])
    const handleSubmit = async () => {
        const list: Partial<TSuggestions>[] = [];
        newCreatorList.forEach(element => {
            let item = suggestions.find(i => i.userId === element.id && !i.deleted);
            if (item)
                list.push({ ...item, deleted: false });
            else
                list.push({ userId: element.id, isPrimary: false, deleted: false, user: element });
        })
        let x = list.find(i => i.isPrimary === true);
        if (!x && list.length > 0)
            list.splice(0, 1, { ...list[0], isPrimary: true });
        suggestions.forEach(element => {
            let index = list.findIndex(i => i.userId === element.id)
            if (index === -1)
                list.push({ ...element, deleted: true });
        })
        try {
            await dispatch(updatePlaceSuggestions(placeId, list))
            closeForm();
            // dispatch(OApp.showToast({ message: 'Suggesters Add / Edit Successful', variant: 'success' }))
        } catch (err) {
            console.log(err)
            // dispatch(OApp.showToast({ message: 'Suggesters Add / Edit Unsuccessful ', variant: 'error' }))
        }

    }

    const handleSearchResult = (searchResult: TUser[], query: string) => {
        setSearchList([...searchResult]);
        setQuery(query);
    }
    const handleClick = (item: TUser) => {
        const tempList: TUser[] = utilities.toggleItemFromList(newCreatorList, item);
        setNewCreatorList([...tempList])
    }

    const handlePageChange = (pagenumber: number) => { setCurrentPage(pagenumber) }
    const listItem = () => {
        return (query ? searchList : list).map((item, index) => {
            return (<ListItem button key={index} onClick={() => handleClick(item)}>

                <ListItemText primary={item.name}>
                </ListItemText>
                <ListItemSecondaryAction>
                    <Checkbox
                        edge="end"
                        onClick={() => handleClick(item)}
                        checked={(newCreatorList.findIndex(i => i['id'] === item['id']) === -1) ? false : true}
                    />
                </ListItemSecondaryAction>
            </ListItem>)
        })
    };
    return (
        <div>
            <FormDialog
                heading={'Add / Edit Suggested Users'}
                open={open}
                onSubmit={handleSubmit}
                onClose={closeForm}
                loading={loading}
            >
                <MainList
                    search
                    itemsCountPerPage={20}
                    onPageChange={handlePageChange}
                    totalItemsCount={totalCount}
                    isform={true}
                    searchProps={{
                        type: 'user',
                        onResults: handleSearchResult
                    }}
                    list={listItem()}
                />
            </FormDialog>
        </div>
    )
}



export default UserForm