import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OPlace } from '../../Models/Place'
import { TPlace, TPlaceState } from '../../Models/Place/@types'
import { TDispatch } from '../../Resources/GlobalTypes'
import utilities from "../../Resources/Utils"
import { TReduxStore } from '../../RootReducer'
import MainList from '../Dashboard/MainList'
import FormDialog from '../Dialogs/FormDialog'
interface IProps {
    onFormSubmit: (newList: TPlace[]) => Promise<void>
    places: TPlace[]
    heading: string
    closeForm: () => void
    open: boolean
}


const PlacesForm: React.FC<IProps> = (props) => {
    const { onFormSubmit, places = [], closeForm, open, heading } = props

    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>()
    const [query, setQuery] = useState('');
    const { list = [], totalCount = 0 } = useSelector<TReduxStore, Pick<TPlaceState, 'list' | 'totalCount'>>(({ Place }) => ({ list: Place.list, totalCount: Place.totalCount }))
    const [searchList, setSearchList] = useState<TPlace[]>([]);
    const [newPlaceList, setNewPlaceList] = useState<TPlace[]>(places)

    const onSubmit = async () => {

        onFormSubmit(newPlaceList);

    }


    const getPlaces = async () => {
        setLoading(true);
        try {

            dispatch(OPlace.getItemsList({
                filter: {
                    order: 'name ASC', limit: 20, skip: 20 * (currentPage - 1),
                    fields: ['name', 'id', "status", 'full_address', 'addressLine1']

                }
            }))
            currentPage === 1 && dispatch(OPlace.getActivity(OPlace.Activities.COUNT));
        }
        catch (err) {
            console.log('Error', err)
        }
        setLoading(false)
    }



    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    }


    useEffect(() => {
        getPlaces();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    const handleClick = (item: TPlace) => {

        const templist = utilities.toggleItemFromList(newPlaceList, item);
        setNewPlaceList(templist)
    }

    const handleSearchResult = (searchResult: TPlace[], query: string) => {
        setQuery(query);
        setSearchList(searchResult)

    }


    const listItem = () => {
        return (query ? searchList : list).map((item, index) => {
            return (<ListItem button key={index} onClick={() => handleClick(item)}>
                <ListItemText primary={item.name}  >
                </ListItemText>
                <ListItemSecondaryAction>
                    <Checkbox
                        color='primary'
                        edge="end"
                        onClick={() => handleClick(item)}
                        checked={(newPlaceList.findIndex(i => i['id'] === item['id']) === -1) ? false : true}
                    />
                </ListItemSecondaryAction>
            </ListItem>)
        })
    }

    return (

        <div>
            <FormDialog
                heading={heading}
                onSubmit={onSubmit}
                onClose={closeForm}
                loading={loading}
                open={open}
            >
                <MainList
                    itemsCountPerPage={20}
                    search
                    totalItemsCount={totalCount}
                    onPageChange={handlePageChange}

                    searchProps={{
                        type: 'Establishment',
                        onResults: handleSearchResult
                    }}
                    list={listItem()}
                    isform={true}

                />


            </FormDialog>
        </div>
    )
}



export default PlacesForm