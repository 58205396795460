import { Box, Divider, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import dimensions from '../../../../Constants/dimensions';
import { DATE_FORMAT } from '../../../../Constants/vars';
import { OApp } from '../../../../Models/App';
import { OCoupons } from '../../../../Models/Coupons';
import { TCouponsState } from '../../../../Models/Coupons/@types';
import CouponsModel from '../../../../Models/Coupons/actions';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

export interface DetailsProps { }

const Details: FC<DetailsProps> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();
    const classes = useStyles();
    const { details } = useSelector<TReduxStore, Pick<TCouponsState, 'details'>>(({ Coupons }) => ({ details: Coupons.details }));
    const handleEdit = () => {
        dispatch({ type: OCoupons.Actions.SHOW_COUPON_FORM, data: details })
    }
    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(CouponsModel.deleteCoupons(details))
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'Coupon' } })
                if (history) {
                    history.push('/dashboard/coupons')
                }
            } catch (err) {
                console.log(err)
            }

        }
    }
    const handlePublish = async () => {
        if (details) {
            try {
                const flag = !details.isPublished;
                await dispatch(OCoupons.postItemActivity(details.id, OCoupons.Activities.PUBLISH, {}, { publish: !details.isPublished }))
                dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
            }
            catch (err) {
                dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))
                console.log('Dish publish', err)
            }
        }
    }
    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this coupon.',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this Coupon?',
            handleDelete: handleDelete,
            helpText: 'Delete this coupon.',
        },
        {
            type: 'icon',
            icon: (details && details.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (details && details.isPublished) ? 'secondary' : 'primary',
            helpText: (details && details.isPublished) ? 'Unpublish this coupon.' : 'Publish this coupon.',
        },
        // {
        //     type: 'copy_button',
        //     textUrl: textUrl,
        // }
    ]
    const handleNoteSave = async (notes: string) => {
        try {
            if (details)
                await dispatch(OCoupons.patchItem(details.id, { notes }, {}, undefined, { notes }));
            dispatch(OApp.showToast({ message: `Notes updated.`, variant: 'success' }))

        } catch (err) {
            dispatch(OApp.showToast({ message: `Notes update failed.`, variant: 'success' }))
            console.log('Error in add to mamakoo', err);
        }
    }
    const timeZone = moment().tz('America/New_York').format('z( UTC Z)')
    return (
        <div className={classes.foodDrinkDetail}>
            {
                details &&
                <>

                    <HeaderActionButtons items={headerItems} />
                    <Box mt={3} width={dimensions.screenMaxWidth} m='auto'>
                        <NotesInput
                            onSave={handleNoteSave}
                            text={details.notes || ''}
                        />
                    </Box>
                    <Paper className={classes.paper}>
                        {/* <Divider style={{ margin: '16px 0' }} /> */}
                        <Box display='flex'>
                            <Typography variant="overline">{`Created - ` + moment(details.created).format(DATE_FORMAT) + ` Modified - ` + moment(details.updated).format(DATE_FORMAT)}</Typography>
                        </Box>
                        <Divider style={{ margin: 0, marginBottom: 24 }} />
                        <div>
                            <Typography variant="h4">{details.name}</Typography>
                            <Typography variant="subtitle1">{`CODE - ` + details.code}</Typography>
                            <Typography variant="subtitle1">{(details.discountType === 'percent' ? `${details.discountValue}%` : `$${details.discountValue}`) + ` OFF`}</Typography>
                            <Typography variant="body2">{`Start: ` + moment(details.startDate).tz('America/New_York').format(`${DATE_FORMAT} HH:mm`) + ` ${timeZone}`}</Typography>
                            {details.endDate ? <Typography variant="body2">{`End: ` + moment(details.endDate).tz('America/New_York').format(`${DATE_FORMAT} HH:mm`) + ` ${timeZone}`}</Typography> : null}

                        </div>
                    </Paper>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        foodDrinkDetail: {
            position: 'relative',
            margin: '0px 40px',
        },
        paper: {
            width: dimensions.screenMaxWidth,
            margin: 'auto',
            padding: '30px',
            marginTop: 20
        },
        operationBtns: {
            display: 'flex',
            justifyContent: 'center',
            '& > div': {
                margin: '0 10px'
            }
        },
    }))
})

export default Details