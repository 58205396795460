import React, { FC } from 'react';
import Appnavigation from '../Router/AppNavigation';


const RootLayout: FC = () => {
    return (
        <div>
            <Appnavigation />
        </div>
    )
}


export default RootLayout;