import { DialogContent, Fab, Icon, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import Config from '../../Resources/Config';
import { uploadEntityPicture } from '../../Resources/PictureUtils';
import ImageCropper from '../Inputs/ImageCropper';

export interface DialogImageProps {
	handleDialog: (url: string) => void,
	closeDialog: () => void,
	open: boolean,
	entityUploadUrl: string;
	onImageUploadComplete?: (current: any, response: any) => void
}

export interface DialogImageState {
	url: string
	src: string
	base64: string
	uploading: boolean;
	loadingFile: boolean;
}

type unitType = '%' | 'px'
export default class DialogImage extends Component<DialogImageProps, DialogImageState> {
	imageRef: HTMLImageElement | null;
	fileUrl: string;
	constructor(props: DialogImageProps) {
		super(props);
		this.imageRef = null;
		this.fileUrl = ''
	}
	state = {
		url: '',
		src: '',
		base64: '',
		uploading: false,
		loadingFile: false
	}
	closeDialog = () => this.props.closeDialog();

	submitDialog = async () => {
		this.setState({ loadingFile: true });
		const base64 = await this.getBase64ImageFromUrl();
		this.setState({ base64, loadingFile: false });
	}

	handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {

		if (e.currentTarget.value === '')
			this.setState({ src: '' })
		else {
			if (e.currentTarget.value) {
				// this.handleLoad(e.currentTarget.value);
				this.setState({ src: e.currentTarget.value })
			}
			else
				this.setState({ src: '' })
		}
	}

	getBase64ImageFromUrl = async (): Promise<string> => {

		if (!this.state.src) return Promise.reject('No input');

		var res = await fetch(`${Config.API_BASE_URL}pictures/fetch?url=${this.state.src}`);
		var blob = await res.blob();

		return new Promise((resolve, reject) => {
			var reader = new FileReader();
			reader.addEventListener("load", function () {
				if (reader.result)
					resolve(reader.result as string);
			}, false);

			reader.onerror = () => {
				return reject();
			};
			reader.readAsDataURL(blob);
		})
	}

	handleCropComplete = async (base64: string) => {
		if (!base64) return;
		console.log({ base64 });
		this.setState({ uploading: true })
		const res = await uploadEntityPicture({ base64, name: 'place image' }, this.props.entityUploadUrl, 'images').catch(err => {
			console.log({ err });
			this.setState({ uploading: false })
			throw err
		});
		this.setState({ uploading: false })
		if (res && this.props.onImageUploadComplete)
			this.props.onImageUploadComplete("", res);
		this.closeDialog();
	}

	render() {

		const isDisabled = !this.state.src || this.state.loadingFile || this.state.uploading;

		return (
			<div>
				<Dialog
					style={{ width: '700px', height: '800px', margin: '0 auto' }}
					open={this.props.open}
					keepMounted
					onClose={this.closeDialog}>
					<div style={{ width: '100%', height: '100%' }}>
						<DialogTitle>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<Typography variant="h6" style={{ marginTop: '5px' }}>Load Image url</Typography>
								<Fab size="small" color={'inherit'} onClick={this.closeDialog}>
									<Icon color={'action'} className={'material-icons'}>close</Icon>
								</Fab>
								{/* <Button onClick={this.closeDialog}><i className="material-icons" >close</i></Button> */}
							</div>
						</DialogTitle>
						<DialogContent>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ width: '400px', height: 'auto', marginTop: '20px' }}>
									<TextField
										style={{ width: '100%', margin: '0 auto' }}
										variant="outlined"
										inputProps={{ onChange: this.handleUrlChange }}
										value={this.state.src || ''}
										autoFocus={true}
										label=""
									>
									</TextField>

								</div>
								<div style={{ width: '400px', height: 'auto', marginTop: '20px' }}>
									<Button
										style={{ width: '100%' }}
										variant="contained"
										color="primary"
										onClick={this.submitDialog}
										disabled={isDisabled}
									>
										{
											(this.state.loadingFile) ?
												'Loading...'
												: (
													this.state.uploading ?
														'Uploading...'
														:
														'Done'
												)
										}
									</Button>
								</div>
							</div>
						</DialogContent>
					</div>
				</Dialog>
				{
					this.state.base64 ?
						<ImageCropper
							open={!!this.state.base64}
							base64={this.state.base64}
							cropConfig={{}}
							onComplete={this.handleCropComplete}
							onClose={() => this.setState({ base64: '' })}
						/>
						: null
				}
			</div>
		);
	}
}


