import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import CuisinesCardList from '../../../../Components/Cards/CardLists/CuisinesCardList';
import CuisinesForm from '../../../../Components/Forms/CuisinesForm';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import { ITEM_PER_PAGE_COUNT } from '../../../../Constants/FilterConstants';
import { OApp } from '../../../../Models/App';
import { TCuisine } from '../../../../Models/Cuisines/@types';
import { OPlace } from '../../../../Models/Place';
import { TPlaceState } from '../../../../Models/Place/@types';
import { addEntityToPlace, deleteEntityfromPlace } from '../../../../Models/Place/actions';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

interface IProps extends RouteComponentProps<{ id: string }> { }

const Cuisines: React.FC<IProps> = (props) => {
    const { match } = props
    const placeId = match.params['id']
    const classes = useStyles(props)
    const [loading, setLoading] = useState(false);
    const { details, cuisines = [] } = useSelector<TReduxStore, Pick<TPlaceState, 'details' | 'cuisines'>>(({ Place }) => ({ details: Place.details, cuisines: Place.cuisines }))
    const textUrl = `${DOMAIN_URL}/places/${details?.slug || placeId}/cuisines`;
    const [showForm, setShowForm] = useState(false)
    const dispatch = useDispatch<TDispatch>();
    const [displayList, setDisplayList] = useState<TCuisine[]>([]);
    const [pageNumber, setPageNumber] = useState(1);

    const handlePageChange = (pageNumber: number) => {
        setPageNumber(pageNumber)
    }

    const handleAddCuisine = () => {
        setShowForm(true)
    }

    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddCuisine,
            helpText: 'Add cuisine available here',
        },
        {
            type: 'copy_button',
            textUrl: textUrl
        }
    ]
    const getData = async () => {
        setLoading(true);
        await dispatch(OPlace.getEntityByItem(placeId, OPlace.Entities.CUISINES, {
            filter: {
                order: 'name ASC',
                fields: ['name', 'isPublished', 'id']
            }
        }))
        setLoading(false);
    }
    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeId])

    useEffect(() => {
        if (placeId) {
            const low = ITEM_PER_PAGE_COUNT * (pageNumber - 1);
            const high = ITEM_PER_PAGE_COUNT * (pageNumber)
            const tList: TCuisine[] = (cuisines || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, cuisines.toString()])
    const onFormSubmit = async (newList: TCuisine[]) => {
        try {
            await dispatch(addEntityToPlace(placeId, newList, 'Cuisine'));
            setPageNumber(1);
            dispatch(OApp.showToast({ message: 'Cuisines Added Successfully', variant: 'success' }))
        } catch (err) {
            console.log('Error', err)
            dispatch(OApp.showToast({ message: 'Cuisine Add Unsuccessful', variant: 'error' }))
        }
        closeForm();
    }
    const deleteItem = async (item: TCuisine) => {
        try {
            await dispatch(deleteEntityfromPlace(placeId, OPlace.Entities.CUISINES, cuisines, item.id));
        } catch (err) {
            console.log(err)
        }
    }
    const closeForm = () => { setShowForm(false) }
    return (
        <div className={classes.placesCuisine}>
            <HeaderActionButtons items={headerItems} />
            {showForm && <CuisinesForm open={showForm} closeForm={closeForm} onFormSubmit={onFormSubmit} cuisines={cuisines} />}
            <div className={classes.wrapper}>
                {
                    loading ? <CircularProgress /> :
                        <CuisinesCardList
                            deleteItem={deleteItem}
                            list={displayList}
                            totalCount={details ? details.counts.cuisines || 0 : 0}
                            handlePageChange={handlePageChange}
                            pageNumber={pageNumber}
                        />
                }
            </div>
        </div >
    )
}
const useStyles = makeStyles((theme: Theme) => createStyles({
    placesCuisine: {
        position: 'relative',
        margin: '0px 40px',
    },
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        },
        margin: '40px auto'
    }
}))

export default withRouter(Cuisines)


