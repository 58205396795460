import { MenuItem, Select, SelectProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC } from 'react';


export interface SingleSelectProps extends SelectProps {
    options: { label: string, value: any }[]
}

const SingleSelect: FC<SingleSelectProps> = (props) => {
    const classes = useStyles({});
    const { options, ...selectProps } = props;
    return (
        <Select
            {...selectProps}
            classes={{ icon: classes.icon, ...selectProps.classes }}
            inputProps={{ className: classes.menuItem, ...selectProps?.inputProps }}
            className={clsx(classes.select, selectProps.className)}
        >
            {
                (options).map((item, index) => {
                    return <MenuItem key={index} value={item.value} className={classes.menuItem}>{item.label}</MenuItem>
                })
            }
        </Select>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    select: {
        // color: theme.palette.primary.main,
        marginLeft: '24px',
        '&:first-child': {
            marginLeft: 8,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0
            }
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    icon: {
        color: theme.palette.primary.main,
    },
    menuItem: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left'
    }
}));

export default SingleSelect;