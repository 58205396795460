import { TDispatch } from '../../Resources/GlobalTypes';
import { OApp } from '../App';
import LoopFront from 'loop-front';
import { CustomActions, ODestinationPack } from '.';
import { TFilterConfig } from '../App/@types';
import { OCity } from '../City';

export function getDestinationPackList(pageNumber: number, filterConfig: TFilterConfig[], sortFilter?: any) {
    return async (dispatch: TDispatch) => {
        const filter = OApp.parseBaseFilter(filterConfig);
        await dispatch(
            ODestinationPack.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    ...filter,
                    ...sortFilter,
                },
            })
        );
        if (pageNumber === 1) await dispatch(ODestinationPack.getActivity(ODestinationPack.Activities.COUNT, { ...filter }));
    };
}

export const getDestinationPackCities = (destinationPackId: string) => async (dispatch) => {
    try {
        const { data } = await OCity.requestGetItemsList({
            filter: {
                where: {
                    destinationPackId,
                },
            },
        });
        dispatch({
            type: ODestinationPack.Actions.DESTINATION_PACK_CITITES_RECIVED,
            data,
        });
    } catch (err) {
        console.error(err);
    }
};
