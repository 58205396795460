import { Grid } from '@material-ui/core'
import React from 'react'
import { TPlace } from '../../../Models/Place/@types'
import PlacesCard from './PlacesCard'
import PaginationComponent from '../../Pagination'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { get } from 'lodash'
import { ITEM_PER_PAGE_COUNT } from '../../../Constants/FilterConstants'


interface IProps {
    list: TPlace[]
    deleteItem?: (item: TPlace) => Promise<void>
    pageNumber?: number
    handlePageChange?: (pageNumber: number) => void
    totalCount?: number
    onReorder?: (newList: TPlace[]) => void
}

const PlacesCardList: React.FC<IProps> = (props) => {

    const onPageChange = (number: number) => { }

    const { list = [], deleteItem } = props;
    const SortableItem = SortableElement(({ value }: { value: any }) => { return <>{value}</> })
    const SortableList = SortableContainer(({ items }: { items: any[] }) => {
        return (
            <Grid container spacing={3} >{
                items.map((value, index) => {
                    return <SortableItem key={index} index={index} value={value} />
                })
            }</Grid>
        )
    })
    const gridList = list.map((item, i) => (
        <Grid item key={item.id || i} lg={4} md={6} sm={12} xs={12}>
            <PlacesCard
                deleteItem={deleteItem}
                item={item}
                sortable={Boolean(props.onReorder)}
            />
        </Grid>
    ))
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        const newList = [...list]
        var data = newList[oldIndex];
        newList.splice(oldIndex, 1);
        newList.splice(newIndex, 0, data);
        props.onReorder?.(newList)
    };
    if (!list)
        return <div />
    return (
        <div>
            {
                <SortableList shouldCancelStart={e => get(e.target, 'id') !== 'drag'} items={gridList} axis={'xy'} onSortEnd={onSortEnd} />
            }
            {/* <Grid container spacing={3}>
                {

                    list.map((item, index) => {
                        return (
                            <Grid item key={index}>
                                <PlacesCard
                                    deleteItem={deleteItem}
                                    item={item}

                                />
                            </Grid>
                        )

                    })
                }
            </Grid> */}
            {
                (props.pageNumber && props.totalCount) ?
                    <PaginationComponent
                        currentPage={props.pageNumber || 1}
                        onPageChange={props.handlePageChange || onPageChange}
                        itemsCountPerPage={ITEM_PER_PAGE_COUNT}
                        totalItemsCount={props.totalCount || 0}
                    /> : null
            }
        </div>
    )
}



export default PlacesCardList