import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TDispatch } from '../../Resources/GlobalTypes';
import utilities from '../../Resources/Utils';
import { TReduxStore } from '../../RootReducer';
import MainList from '../Dashboard/MainList';
import FormDialog from '../Dialogs/FormDialog';
import { TCity } from '../../Models/City/@types';
import { OCity } from '../../Models/City';


interface IProps {
    onFormSubmit: (newList: TCity[]) => Promise<void>;
    cities: TCity[];
    heading: string;
    closeForm: () => void;
    open: boolean;
}

const CitiesForm: React.FC<IProps> = (props) => {
    const { onFormSubmit, cities = [], closeForm, open, heading } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    const [query, setQuery] = useState('');
    // const { list = [], totalCount = 0 } = useSelector<TReduxStore, Pick<TUserState, 'list' | 'totalCount'>>(({ User }) => ({
    //     list: User.list,
    //     totalCount: User.totalCount,
    // }));
    const [searchList, setSearchList] = useState<TCity[]>([]);
    const [newCitiesList, setNewCitiesList] = useState<TCity[]>(cities);

    useEffect(() => setNewCitiesList(cities), [cities]);

    const onSubmit = async () => {
        setLoading(true);
        await onFormSubmit(newCitiesList);
        setLoading(false);
    };

    const getUser = async () => {
        setLoading(true);
        try {
            const { data } = await OCity.requestGetItemsList({
                filter: {
                    order: 'name ASC',
                    limit: 20,
                    skip: 20 * (currentPage - 1),
                    fields: ['name', 'id', 'state'],
                },
            });
            setList(data);

            if (currentPage === 1) {
                const { data } = await OCity.requestGetActivity(OCity.Activities.COUNT);
                setTotal(data?.count || 0);
            }
        } catch (err) {
            console.log('Error', err);
        }
        setLoading(false);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getUser();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const handleClick = (item: TCity) => {
        const templist = utilities.toggleItemFromList(newCitiesList, item);
        setNewCitiesList(templist);
    };

    const handleSearchResult = (searchResult: TCity[], query: string) => {
        setQuery(query);
        setSearchList(searchResult);
    };


    const listItem = () => {
        return (query ? searchList : list).map((item, index) => {
            return (
                <ListItem button key={index} onClick={() => handleClick(item)}>
                    <ListItemText primary={item.name}></ListItemText>
                    <ListItemSecondaryAction>
                        <Checkbox
                            color="primary"
                            edge="end"
                            onClick={() => handleClick(item)}
                            checked={newCitiesList.findIndex((i) => i['id'] === item['id']) === -1 ? false : true}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });
    };

    return (
        <div>
            <FormDialog heading={heading} onSubmit={onSubmit} onClose={closeForm} loading={loading} open={open}>
                <MainList
                    itemsCountPerPage={20}
                    search
                    totalItemsCount={total}
                    onPageChange={handlePageChange}
                    searchProps={{
                        type: 'Destination',
                        onResults: handleSearchResult,
                    }}
                    list={listItem()}
                    isform={true}
                />
            </FormDialog>
        </div>
    );
};

export default CitiesForm;
