import React, { FC, useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { TPlace } from '../../../../Models/Place/@types';
import { UPDATE } from './Reducer';
export interface MamakooRatingProps {
    dispatchReducer: React.Dispatch<{
        type: string,
        name: string;
        data: any;
    }>
    state: Partial<TPlace>
}

const MamakooRating: FC<MamakooRatingProps> = (props) => {
    const classes = useStyles();
    const [rating, setRating] = useState(0);
    const { state, dispatchReducer } = props;
    useEffect(() => {
        if (state.mamakooRating)
            setRating(state.mamakooRating)
    }, [state.mamakooRating])
    const handleChange = (rating: number) => {
        dispatchReducer({ type: UPDATE, name: 'mamakooRating', data: rating });
    };
    return (
        <Box width={800} mt={5} >
            <Paper className={classes.root}>
                <Typography variant='h6'>Mamakoo Rating:&nbsp;&nbsp;</Typography>
                <Rating
                    value={rating}
                    max={3}
                    size='large'
                    color='primary'
                    name='mamakooRating'
                    onChange={(_event, newValue) => {
                        if (newValue)
                            handleChange(newValue);
                    }}
                    classes={{
                        root: classes.ratingRoot,
                        sizeLarge: classes.sizeLarge
                    }}
                />
            </Paper>

        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            padding: '40px 32px 40px',
            height: 'auto',
            display: 'flex',
            alignItems: 'center'
        },
        ratingRoot: {
            color: theme.palette.primary['main']
        },
        sizeLarge: {
            fontSize: 50
        }
    }))
})

export default MamakooRating