import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { createStyles, Divider, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme } from '@material-ui/core'
import { TFilterConfig } from '../../Models/App/@types'
import { TCoupons, TCouponsState } from '../../Models/Coupons/@types'
import { TDispatch } from '../../Resources/GlobalTypes'
import { useDispatch, useSelector } from 'react-redux'
import { TReduxStore } from '../../RootReducer'
import { OCoupons } from '../../Models/Coupons'
import CouponsModel from '../../Models/Coupons/actions'
import { NavLink, RouteProps } from 'react-router-dom'
import { fade } from '@material-ui/core/styles'
import ListDashboard from '../../Components/Dashboard/ListDashboard'
import classNames from 'classnames'
import CouponForm from './Forms'
import Details from './Tabs/Details'
import Claimed from './Tabs/Claimed'

interface IProps { }
const initialFilter: TFilterConfig[] = [
    { name: 'All', isActive: true },
    { name: 'Published', isActive: false },
    { name: 'Unpublished', isActive: false }
]
const BASE_URL = '/dashboard/coupons'

const Coupons: React.FC<IProps> = (props) => {

    const url = window.location.href;
    const urlArray: string[] = url.split('/');
    const couponId = urlArray.length >= 6 ? urlArray[5] : '';
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<TCoupons[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>(JSON.parse(JSON.stringify(initialFilter)))
    const classes = useStyles(props)
    const dispatch = useDispatch<TDispatch>()
    const { list = [], totalCount = 0, showMainForm, } = useSelector<TReduxStore, Pick<TCouponsState, 'list' | 'totalCount' | 'showMainForm' | 'details'>>(({ Coupons }) => ({ list: Coupons.list, totalCount: Coupons.totalCount, showMainForm: Coupons.showMainForm, details: Coupons.details }))

    useEffect(() => {
        getCoupons();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])
    useEffect(() => {
        if (couponId) {
            getDetails(couponId);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponId]);
    const getDetails = async (id: string) => {
        await dispatch(OCoupons.getItem(id))
    }
    const handleFilterChange = (item: { name: string, isActive: boolean }) => {
        const updatedFilter = filterConfig.map(i => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        })
        setFilterConfig([...updatedFilter])
    }
    const handleFilterSubmit = () => {

        if (pageNumber === 1)
            getCoupons(filterConfig);
        else
            setPageNumber(1);
    }
    const handleFilterReset = () => {
        setFilterConfig(JSON.parse(JSON.stringify(initialFilter)))
        if (pageNumber === 1)
            getCoupons();
        else
            setPageNumber(1);
    }
    const onAddButtonClick = () => {
        dispatch({ type: OCoupons.Actions.SHOW_COUPON_FORM })
    }
    const handleSearchResult = (result: Array<TCoupons>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    }
    const getCoupons = async (filterConfig: TFilterConfig[] = JSON.parse(JSON.stringify(initialFilter))) => {
        setLoading(true);
        try {
            await dispatch(CouponsModel.getCouponsList(pageNumber, filterConfig));
        } catch (err) {
            console.log(err)
        }
        setLoading(false);
    }
    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string, name: string, component: RouteProps['component'] }> = [
        {
            name: 'Details',
            url: 'details',
            component: Details
        },
        {
            name: 'Claimed ',
            url: 'claimed',
            component: Claimed
        }
    ]
    const renderListItem = useCallback((item: TCoupons): ReactElement => {
        const isActive = (match: any, location: any) => {
            const regex = new RegExp(`${BASE_URL}/${item.id}/`, 'ig');
            return regex.test(location.pathname);
        }
        return (
            <span key={item.id}>
                <NavLink className={classes.listItem} to={`${BASE_URL}/${item.id}/details`} isActive={isActive} activeClassName={"selected"} >
                    <ListItem button >
                        <ListItemText primary={`${item.name || ''}`} />
                        <ListItemSecondaryAction>
                            {!item.isPublished ? <i className={classNames('material-icons', classes.lockIcon)}>lock</i> : null}
                        </ListItemSecondaryAction>
                    </ListItem>

                    <Divider className={classes.divider} />
                </NavLink>
            </span>
        )
    }, [classes]);
    return (
        <>
            <ListDashboard
                baseUrl={BASE_URL}
                csvConfig={
                    {
                        modelName: 'Coupon'
                    }
                }
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                itemsCountPerPage={20}
                onPageChange={setPageNumber}
                onItemAddRequest={onAddButtonClick}
                filterConfig={filterConfig}
                onFilterChange={handleFilterChange}
                onFilterSubmit={handleFilterSubmit}
                onFilterReset={handleFilterReset}
                currentPageNumber={pageNumber}
                listProps={{
                    searchProps: {
                        type: 'Coupon',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}
            />
            {showMainForm ? <CouponForm /> : null}
        </ >
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    divider: {
        backgroundColor: theme.palette.grey['500']
    },
    listItem: {
        color: theme.palette.text.primary,
        '&.selected': {
            background: fade(theme.palette.primary.main, 0.4),
            display: 'block'
        }
    },
    subDashboardContainer: {
        marginLeft: 250,
    },
    headLinks: {
        marginBottom: 50
    },
    lockIcon: {
        fontSize: 18,
        color: '#B71840'
    },
}))

export default Coupons


