import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC } from 'react';

export interface LockIconProps {
    size?: 'small' | 'medium'
}

const LockIcon: FC<LockIconProps> = (props) => {
    const { size = 'small' } = props;
    const classes = useStyles();
    const sizeVariant = classes[size]
    return (
        <i className={classNames('material-icons', classes.lockIcon, sizeVariant)}>lock</i>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        lockIcon: {

            color: '#B71840'
        },
        small: {
            fontSize: 18,
        },
        medium: {
            fontSize: 20,
        }

    }))
})

export default LockIcon