import { Box, Button, Chip, CircularProgress, Collapse, createStyles, fade, LinearProgress, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SingleSelect from '../../../Components/Inputs/SingleSelect';
import { TAppConstants } from '../../../Models/App/@types';
import { OCity } from '../../../Models/City';
import { TCity } from '../../../Models/City/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';


interface IProps extends WithStyles<typeof STYLES> {
    step: number,
    handleStep: () => void
    onSubmit: (form: Record<string, any>) => Promise<any>
    hasCompleted?: boolean
}

const Stepper: React.FC<IProps> = (props) => {

    const { appConstants, cities } = useSelector<TReduxStore, { cities: TCity[], appConstants?: TAppConstants }>(({ City, App }) => ({ cities: City.list || [], appConstants: App.constants }))

    const dispatch = useDispatch<TDispatch>();
    const { classes, step, hasCompleted = false } = props;
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<Record<string, any>>({});
    const [cityName, setCityName] = useState('');
    const [suffix, setSuffix] = useState('');

    const constants = appConstants?.KnowsAbout?.how
    useEffect(() => {
        dispatch(OCity.getItemsList({
            filter: {
                order: 'name ASC'
            }
        })); //to display in the form.
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (name: string, value: any) => {
        setFormData({ ...formData, [name]: value });
        if (name === 'how' && value === "pastResident")
            setSuffix('lived here')
        if (name === 'how' && value === "currentResident")
            setSuffix('live here')
        if (name === 'how' && value === "frequentlyVisits")
            setSuffix('frequently visit here')
        props.handleStep();
    }


    const handleSubmit = async () => {
        setLoading(true)
        try {
            await props.onSubmit(formData);
            setFormData({});
        } catch (error) {

            console.log("error")
        }
        setLoading(false)
    }

    const handleCitySelect = (city: TCity) => {
        setCityName(city.name);
        handleChange('destinationId', city.id);
        props.handleStep();
        // props.onSubmit('destinationId', city.id);
    }


    const HOW = constants ? constants.map(c => ({ label: c.name, value: c.value, name: 'how' })) : [
        { label: 'You live here', value: 'currentResident', name: 'how' },
        { label: 'You lived here in the past', value: 'pastResident', name: 'how' },
        { label: 'You frequently visit the city', value: "frequentlyVisits", name: 'how' },
    ]




    const INITIAL_TITLE = hasCompleted ? 'Pick a city you know about' : 'Where do you know of good restaurants, bars or cafés?'


    const timeOption = useMemo(() => {
        const time = Array.from({ length: 30 }).map((_, t) => t + 2000).filter(t => t < (moment().get('y') + 1))
        return time.map(t => ({ value: moment(t.toString(), 'YYYY').toISOString(), label: t.toString() }))
    }, [])

    return (
        <div>
            <Collapse in={step === 0}>
                <Box mb={2}>
                    <Typography variant="h5">{INITIAL_TITLE}</Typography>
                    {
                        hasCompleted ? null :
                            <Typography color='textSecondary' variant="subtitle1">These cities will show up on your profile. You can choose two more later.</Typography>
                    }
                </Box>
                <div className={classes.container}>
                    {
                        loading ? <LinearProgress color="secondary" /> :
                            cities.filter(i => i.isPublished).map(c => (
                                <Chip onClick={e => handleCitySelect(c)} color={c.id === formData.destinationId ? 'primary' : 'default'} className={classes.chip} label={c.name} key={c.id} />
                            ))
                    }
                </div>
            </Collapse>


            <Collapse in={step === 1}>
                <Box mb={2}>
                    <Typography variant="h5">You know about {cityName} because</Typography>
                </Box>
                <div className={classes.containerColumn}>
                    {
                        HOW.map(h => (
                            <Button key={h.value} onClick={e => handleChange(h.name, h.value)} variant="text" color="primary" className={classes.buttonText}>
                                {h.label}
                            </Button>
                        ))
                    }
                </div>
            </Collapse>

            <Collapse in={step === 2}>
                <Box mb={2}>
                    <Typography variant="h5">You know about {cityName} because you {suffix}</Typography>
                </Box>
                {
                    formData.how !== 'frequentlyVisits' ?
                        <div className={clsx(classes.container)}>
                            <SingleSelect
                                startAdornment={<Box mr={1}><Typography variant='subtitle2'>From</Typography></Box>}
                                value={formData.startDate || ''}
                                onChange={e => handleChange('startDate', e.target.value)}
                                options={timeOption}
                            />

                            {
                                formData.how !== 'currentResident' ?
                                    <SingleSelect
                                        startAdornment={<Box mr={1}><Typography variant='subtitle2'>To</Typography></Box>}
                                        value={formData.endDate || ''}
                                        onChange={e => handleChange('endDate', e.target.value)}
                                        options={timeOption}
                                    />

                                    : null
                            }
                        </div> : null
                }
                <Button className={classes.submitBtn} onClick={handleSubmit} variant="contained" color="primary">
                    {
                        loading ? <CircularProgress color="secondary" /> : 'SUBMIT'
                    }
                </Button>
            </Collapse>
        </div>
    )
}

const STYLES = (theme: Theme) => createStyles({
    title: {
        // lineHeight: 1.5
    },
    subtitle: {
        marginTop: 8,
        // lineHeight: 1.5,
    },
    chip: {
        marginRight: 8,
        marginTop: 6,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.4)
        }
    },
    selectedChip: {
        marginRight: 8,
        marginTop: 6,

    },
    submitBtn: {
        marginTop: theme.spacing(2),
        float: 'right'
    },
    container: {
        paddingTop: 8,
        paddingBottom: 30,
    },
    containerColumn: {
        paddingBottom: 30,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',

    },
    buttonText: {

    },
    formItem: {
        flex: 1,
        margin: '0px 6px !important'
    }
})

export default withStyles(STYLES)(Stepper)