import logger from 'redux-logger';
import thunk from 'redux-thunk';

const Middleware = {
    getMiddlewares: () => {
        console.log('process.env.NODE_ENV', process.env.REACT_APP_NODE_ENV)
        if (process.env.REACT_APP_NODE_ENV === 'production') {
            /**
             * Put those middlewares here which are allowed for production environment.
             */
            return [thunk]
        } else {
            return [thunk, logger]
        }
    },
};
export default Middleware;