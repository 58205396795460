import { OLandmarks } from "."
import { TDispatch } from "../../Resources/GlobalTypes"
import { OApp } from "../App"
import { TFilterConfig } from "../App/@types"

const LandmarksModel = {
    getLandmarkList: (pageNumber: number, filterConfig: TFilterConfig[], cityIds: string[], sortFilter: any = {}) => {
        let cityFilter = cityIds.length ? { destinationId: { inq: cityIds } } : {}
        return (async (dispatch: TDispatch) => {
            const filter = OApp.parseBaseFilter(filterConfig)
            await dispatch(OLandmarks.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    includeCount: true,
                    include: {
                        relation: 'destination',
                        scope: {
                            fields: ['name']
                        }

                    },
                    ...filter,
                    where: {
                        ...filter.where,
                        ...cityFilter
                    },
                    ...sortFilter
                }
            }))
            if (pageNumber === 1)
                await dispatch(OLandmarks.getActivity(OLandmarks.Activities.COUNT, { ...filter, where: { ...filter.where, ...cityFilter } }))
        })
    }
}
export default LandmarksModel