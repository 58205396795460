import { Box, Icon, makeStyles, createStyles, Theme, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { TSearchType } from './Eatinerraies';

export interface PlaceListItemProps {
    place: TSearchType,
    dragHandleProps?: any
    OnDeletePlace: (id: string) => void
}

const PlaceListItem: FC<PlaceListItemProps> = (props) => {
    const { place, dragHandleProps, OnDeletePlace } = props;
    const classes = useStyles();


    return (
        <Box display="flex" className={classes.wrapper}  >
            <Box display="flex" flex={1}>
                <Typography>{place.name}</Typography>
            </Box>
            <Box>
                {<Icon color='action'
                    id='drag'
                    style={{ top: 6, right: '-25px', position: 'absolute' }}
                    {...dragHandleProps} className={'dragHandle ' + classes.icon} fontSize='small'>open_with</Icon>}
                {<Icon color='action' className={classes.icon} onClick={() => OnDeletePlace(place.id)} fontSize='small' >close</Icon>}
            </Box>
        </Box>
    );
}


const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        wrapper: {
            padding: theme.spacing(0.4),
            width: "100%",
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            fontSize: 16,
            cursor: "pointer",
            padding: theme.spacing(0, 0.1),
        }
    });
});

export default PlaceListItem;

