import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, BoxProps, FormControl, FormControlLabel, ListItem, ListItemText, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { MentionsInput, Mention, MentionProps, MentionsInputProps, SuggestionDataItem } from 'react-mentions'

export interface TextFieldWMentionProps {
    BoxProps?: BoxProps
    renderSuggestion?: MentionProps['renderSuggestion']
    fetchSuggestions: MentionProps['data']
    value: string
    handleChange: MentionsInputProps['onChange']
    label: string
}

function TextFieldWMention(props: TextFieldWMentionProps) {
    const { BoxProps, label, value, handleChange, renderSuggestion, fetchSuggestions } = props;
    const classes = useStyles({});


    const renderEntitySuggestion: MentionProps['renderSuggestion'] = (suggestion) => {
        const _meta = JSON.parse(suggestion.id as string);

        return <ListItemText className={classes.listItem} primary={suggestion.display} secondary={_meta?.doc_type} />
    }


    return (
        <Box width='100%' {...BoxProps}>
            <Typography gutterBottom variant='body2' >{label}</Typography>
            <div className={classes.inputContainer}>
                <MentionsInput className={classes.input} allowSpaceInQuery value={value} onChange={handleChange}>
                    <Mention
                        style={{ background: '#fa0' }}
                        trigger="@"
                        markup={`<mention>name:__display__;__id__</mention>`}
                        data={fetchSuggestions}
                        renderSuggestion={renderSuggestion ?? renderEntitySuggestion}
                    />
                </MentionsInput>
            </div>
        </Box >
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    listItem: {
        padding: '6px 4px',
        '&:hover': {
            backgroundColor: '#ddd'
        }
    },
    inputContainer: {
        padding: 8,
        boxShadow: theme.shadows[2],
    },
    input: {
        '& textarea': {
            border: 'none'
        }
    },
}));

export default TextFieldWMention;