import React, { FC, useState, useEffect } from 'react';
import { createStyles, Theme, Box, Paper, TextField, Typography, MenuItem, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import { useSelector, useDispatch } from 'react-redux';
import { TCityState, TCity } from '../../../Models/City/@types';
import { TReduxStore } from '../../../RootReducer';
import { OCity } from '../../../Models/City';
import GoogleLocationSuggest from '../../../Components/Inputs/GoogleLocationSuggest';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { OApp } from '../../../Models/App';
import { RouteComponentProps, withRouter } from 'react-router';
import GoogleUtils from '../../../Resources/google';
import { TAppConstants } from '../../../Models/App/@types';
import { TextValidator } from 'react-material-ui-form-validator';
import TextFieldWMention, { TextFieldWMentionProps } from '../../../Components/Inputs/TextFieldWMention';
import { textFieldWMentionHelpers } from '../../../Components/Inputs/TextFieldWMention/helpers';
import LoopFront from 'loop-front';
import debounce from 'lodash/debounce';
import { ALL_ENTITIES_DOC_TYPES } from '../../../Constants/FilterConstants';
import dimensions from '../../../Constants/dimensions';
import { TSettings } from '../../../Models/Settings/@types';
import { CONTINENT_SETTING_KEY } from '../../../Constants/vars';
export type IStateProps = Pick<TCityState, 'editedCity' | 'showMainForm'> & { appConstants?: TAppConstants; settings?: TSettings[] };

export interface CityFormProps extends RouteComponentProps { }

const CityForm: FC<CityFormProps> = (props) => {
    const classes = useStyles();
    const [city, setCity] = useState<Partial<TCity>>({});
    const [loading, setLoading] = useState(false);
    const {
        editedCity = {} as TCity,
        showMainForm,
        appConstants,
        settings,
    } = useSelector<TReduxStore, IStateProps>(({ City, App, Settings }) => ({
        editedCity: City.editedCity,
        showMainForm: City.showMainForm,
        appConstants: App.constants,
        settings: Settings.list,
    }));
    const dispatch = useDispatch<TDispatch>();

    const continentSetting = settings?.find((item) => item.key === CONTINENT_SETTING_KEY);
    const continentList = continentSetting?.value || [];

    const closeForm = () => dispatch({ type: OCity.Actions.HIDE_CITY_DETAIL_FORM });

    const handleResultClick = async (item: any) => {
        if (!item) return;
        const res: google.maps.places.PlaceResult = await GoogleUtils.placeDetails(item.place_id);
        var Geo: google.maps.places.PlaceGeometry | undefined = res.geometry;
        var Location = Geo?.location;
        var lat = Location?.lat() || 0;
        var lng = Location?.lng() || 0;
        let terms = item.terms || [];
        setCity({
            name: (terms[0] || {}).value,
            state: (terms[1] || {}).value,
            country: (terms[2] || {}).value,
            description: item.description || '',
            placeId: item.place_id || '',
            location: { lat, lng },
        });
    };

    useEffect(() => {
        editedCity.id && setCity(editedCity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedCity.id]);

    useEffect(() => {
        showMainForm || setCity({});
    }, [showMainForm]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (editedCity.id) {
                const { name, state, continent, country, description, placeId, location, shortDescription, byline, beforeYouGo, whatsInside } = city;
                await dispatch(
                    OCity.patchItem(editedCity.id, {
                        name,
                        continent,
                        state,
                        country,
                        description,
                        byline,
                        beforeYouGo,
                        placeId,
                        location,
                        shortDescription,
                        whatsInside,
                    })
                );
                dispatch(OApp.showToast({ message: 'Edit City Successful', variant: 'success' }));
                closeForm();
            } else {
                //await dispatch(OCity.postItem(city));
                let res: { data: TCity } = await OCity.requestPostItem({ ...city, inMamakoo: true });
                dispatch({ type: OCity.Actions.POST_ITEM_SUCCESS, data: res.data });
                props.history.push('/dashboard/cities/' + res.data.id + '/details');
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'Destination' } });
                dispatch(OApp.showToast({ message: 'Add City Successful', variant: 'success' }));
                closeForm();
            }
        } catch (err) {
            dispatch(OApp.showToast({ message: 'City Add / Edit Failed', variant: 'error' }));
        }
        setLoading(false);
    };

    const handleChange = (name: string, value: string) => setCity({ ...city, [name]: value });

    const handleDescriptionChange: (name: keyof TCity) => TextFieldWMentionProps['handleChange'] = (name) => (e, newValue, newValueText) => {
        handleChange(name, e.target.value);
    };

    // const _debouncedSearch = async (text: string) => {
    //     if (!text) return []
    //     const { data } = await LoopFront.request({
    //         url: `users/admin-autocomplete`,
    //         params: {
    //             term: text,
    //             filter: {
    //                 doc_type: ALL_ENTITIES_DOC_TYPES,
    //                 limit: 5,
    //             }
    //         },
    //     });
    //     return data.map(d => ({ id: `id:${d.id};slug:${d.slug};entity:${d.doc_type}`, display: d.name }));
    // }

    // const fetchSuggestions = debounce(_debouncedSearch, 300);

    // const fetchMentionSuggestions = async (query: string, callback: Function) => {
    //     const data = await fetchSuggestions(query);
    //     callback(data);
    // }

    return (
        <FormDialog
            open={showMainForm}
            heading={editedCity.id ? `Update ${city.name}` : 'Create New City'}
            onSubmit={handleSubmit}
            onClose={closeForm}
            loading={loading}
        >
            <Box width={dimensions.formPaperMaxWidth}>
                {!editedCity.id ? (
                    <GoogleLocationSuggest variant={'outlined'} suggestionsTypes={['(cities)']} fullWidth onResultClick={handleResultClick} />
                ) : null}
                <Paper className={classes.paper}>
                    <Typography variant={'h5'}>City</Typography>
                    {(
                        [
                            { name: 'name', label: 'City', value: city.name || '' },
                            { name: 'state', label: 'State', value: city.state || '' },
                            { name: 'country', label: 'Country', value: city.country || '' },
                            // { name: 'byline', label: 'Byline', multiline: true, value: city.byline || '' },
                            // { name: 'beforeYouGo', label: 'Before you go', multiline: true, value: city.beforeYouGo || '' },
                        ] as { name: keyof TCity; label: string; value: string; multiline: boolean | undefined }[]
                    ).map((item, i) => {
                        return (
                            <Box key={i} mt={2}>
                                <TextField
                                    label={item.label}
                                    name={item.name}
                                    fullWidth
                                    multiline={item.multiline}
                                    value={item.value}
                                    onChange={(e) => handleChange(item.name, e.target.value)}
                                />
                            </Box>
                        );
                    })}
                    <Box mt={2}>
                        <TextFieldWMention
                            label="What's Inside?"
                            fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                            handleChange={handleDescriptionChange('whatsInside')}
                            value={city.whatsInside || ''}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextFieldWMention
                            label="Byline"
                            fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                            handleChange={handleDescriptionChange('byline')}
                            value={city.byline || ''}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextFieldWMention
                            label="Before you go"
                            fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                            handleChange={handleDescriptionChange('beforeYouGo')}
                            value={city.beforeYouGo || ''}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextFieldWMention
                            label="Short Description"
                            fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                            handleChange={handleDescriptionChange('shortDescription')}
                            value={city.shortDescription || ''}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextFieldWMention
                            label="Long Description"
                            fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                            handleChange={handleDescriptionChange('description')}
                            value={city.description || ''}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextValidator
                            label={'Continent'}
                            name={'continent'}
                            fullWidth
                            select
                            validators={['required']}
                            errorMessages={['This field is required']}
                            value={city.continent || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange('continent', e.target.value)}
                        >
                            {continentList.map((item, index) => {
                                return (
                                    <MenuItem value={item.value} key={index}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </TextValidator>
                    </Box>
                </Paper>
            </Box>
        </FormDialog>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            marginTop: 48,
            padding: 20,
        },
    });
});

export default withRouter(CityForm);
