import { createStyles, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Theme } from 'react-autosuggest';

export interface SummaryCardProps {
    title: string
    subtitles: Array<string>
    onClick?: () => void
}

const SummaryCard: FC<SummaryCardProps> = ({ title, subtitles, onClick }) => {

    const classes = useStyles();
    return (
        <Paper className={classes.root} onClick={onClick} >
            {/* {
                icon === 'cuisines2'
                    ?
                    <img src={CuisineIcon} style={{ height: 75, width: 75 }} alt={'cuisine'} />
                    :
                    <IconComponent name={icon} size={75} />
            } */}
            <Typography className={classes.title} >{title}</Typography>
            {
                subtitles.map((subtitle, i) => {
                    return (
                        <Typography variant={'subtitle2'} key={i} >{subtitle}</Typography>
                    )
                })
            }
        </Paper>
    )
}



const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    title: {
        fontSize: 22,
        lineHeight: '24px',
        marginTop: 12
    }
}));


export default SummaryCard;