import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Paper, TextField } from '@material-ui/core';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import dimensions from '../../../Constants/dimensions';
import { NameValuePair } from '../../../Models/App/@types';
import snakeCase from 'lodash/snakeCase'

export interface ContinentFormProps {
    open: boolean
    title: string
    loading: boolean
    initialValue: NameValuePair
    closeForm: () => void
    onFormSubmit: (formData: NameValuePair) => Promise<void>
}

const ContinentForm: FC<ContinentFormProps> = (props) => {
    const classes = useStyles({});
    const { open, title, loading, initialValue, closeForm, onFormSubmit } = props;
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
        return () => {
            setValue({ name: '', value: '' });
        }
    }, [initialValue])

    const handleSubmit = () => {
        onFormSubmit(value)
    }

    return (
        <FormDialog
            heading={title}
            open={open}
            onSubmit={handleSubmit}
            onClose={closeForm}
            loading={loading}
        >


            <Paper className={classes.paper} >
                <TextField
                    label="Continent"
                    value={value.name}
                    onChange={(e) => setValue({ name: e.target.value, value: snakeCase(e.target.value) })}
                    fullWidth
                />
            </Paper>
        </FormDialog>

    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    paper: {
        width: dimensions.formPaperMaxWidth,
        marginTop: 48,
        padding: 20
    },
}));

export default ContinentForm;