import { TAction } from "loop-front";
import { Reducer } from "redux";
import { OCity } from ".";
import utilities from "../../Resources/Utils";
import { ODestinationTransaction } from "../DestinationTransactions";
import { OUser } from "../User";
import { TCityState } from './@types';

export const RCity: Reducer<TCityState, TAction> = (state = { showMainForm: false, subscriptionList: { data: [], count: 0 } }, action) => {

    const { SINGLE_ITEM_RECEIVED,
        ACTIVITY_RECEIVED,
        LIST_RECEIVED,
        SHOW_CITY_DETAIL_FORM,
        HIDE_CITY_DETAIL_FORM,
        ITEM_PATCH_SUCCESS,
        POST_ITEM_SUCCESS,
        ITEM_ACTIVITY_POST_SUCCESS,
        ITEM_DELETED,
        SINGLE_ENTITY_BY_ITEM_DELETED,
        ENTITY_OF_ITEM_RECEIVED,
        PUT_ACTIVITY_OF_SINGLE_ITEM_SUCCESS,
        PUT_DISHES_TO_CITY_SUCCESS,
        PUT_CUISINES_TO_CITY_SUCCESS,
        SINGLE_ESTABLISHMENT_DELETED_FROM_CITY,
        DELETE_CUISINE_FROM_CITY_SUCCESS,
        DELETE_DISH_FROM_CITY_SUCCESS,
        ITEM_ACTIVITY_RECEIVED,

    } = OCity.Actions;

    const {
        CITY_SUBSCRIBE_USER_FETCH
    } = OUser.Actions;

    const {
        DESTINATION_TRANSACTION_MULTI_USER_TRANSACTION_SUCCESS
    } = ODestinationTransaction.Actions;

    switch (action.type) {
        case CITY_SUBSCRIBE_USER_FETCH: {
            return { ...state, subscriptionList: action.data };
        }
        case DESTINATION_TRANSACTION_MULTI_USER_TRANSACTION_SUCCESS: {
            return {
                ...state,
            }
        }
        case DELETE_CUISINE_FROM_CITY_SUCCESS:

            return {
                ...state,
                cuisines: action.additionalDispatchData,
                details: (state.details) && { ...state.details, counts: { ...state.details.counts, cuisines: action.data } }
            }
        case DELETE_DISH_FROM_CITY_SUCCESS: return {
            ...state,
            dishes: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, foodDrinks: action.data } }


        }
        case LIST_RECEIVED: return { ...state, list: action.data }
        case SINGLE_ESTABLISHMENT_DELETED_FROM_CITY: return {
            ...state,
            places: utilities.updateItemList(state.places || [], action.data, 'DELETE'),
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, establishments: state.details.counts.establishments && state.details.counts.establishments - 1 } }
        };
        case SINGLE_ITEM_RECEIVED: return { ...state, details: action.data }

        case PUT_ACTIVITY_OF_SINGLE_ITEM_SUCCESS: {
            switch (action.activity) {
                case OCity.Activities.UPDATE_ESTABLISHMENTS: return {
                    ...state,
                    // places: action.additionalDispatchData,
                    details: (state.details) && {
                        ...state.details, counts: {
                            ...state.details.counts, establishments: action.additionalDispatchData.length,
                        },
                        establishments: action.additionalDispatchData
                    }
                }
                default: return state;
            }
        }
        case ITEM_DELETED: return { ...state, list: utilities.updateItemList(state.list || [], action.additionalDispatchData, 'DELETE') }
        case SINGLE_ENTITY_BY_ITEM_DELETED: {
            switch (action.entity) {
                case OCity.Entities.FOODDRINKS: return { ...state, dishes: utilities.updateItemList(state.dishes || [], action.additionalDispatchData, 'DELETE') }
                case OCity.Entities.CUISINES: return { ...state, cuisines: utilities.updateItemList(state.cuisines || [], action.additionalDispatchData, 'DELETE') }
                default: return state
            }
        }
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OCity.Activities.COUNT: return { ...state, totalCount: action.data.count }
                case OCity.Activities.DETAILS: { return { ...state, details: action.data } }
                default: return state;
            }
        }
        case ITEM_ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OCity.Activities.COUNT: return { ...state, totalCount: action.data.count }
                case OCity.Activities.DETAILS: return { ...state, details: action.data }
                default: return state;
            }
        }
        case ITEM_ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case OCity.Activities.PUBLISH: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), details: { ...state.details, ...action.data } }
                default: return state;
            }
        }
        case ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case OCity.Entities.ESTABLISHMENTS: return { ...state, places: action.data }
                case OCity.Entities.FOODDRINKS: return { ...state, dishes: action.data }
                case OCity.Entities.CUISINES: return { ...state, cuisines: action.data }
                default: return state;
            }
        }
        case SHOW_CITY_DETAIL_FORM: return { ...state, showMainForm: true, editedCity: action.data }

        case HIDE_CITY_DETAIL_FORM: return { ...state, showMainForm: false, editedCity: undefined }
        case ITEM_PATCH_SUCCESS: return { ...state, details: { ...state.details, ...action.data, ...action.additionalDispatchData }, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE') };
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'ADD') };
        case PUT_DISHES_TO_CITY_SUCCESS: return {
            ...state,
            dishes: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, foodDrinks: action.data } }
        }
        case PUT_CUISINES_TO_CITY_SUCCESS: return {
            ...state,
            cuisines: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, cuisines: action.data } }
        }

        default: return state;
    }
}