import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import EntityCard from '../../../../Features/Home/EntityCard';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import ReorderableList from '../../../../Components/ReorderableList';
import EntityForm from '../../../../Components/Forms/EntityForm';
import { TReduxStore } from '../../../../RootReducer';
import { THomeSectionState } from '../../../../Models/HomeSection/@types';
import { OHomeSection } from '../../../../Models/HomeSection';
import TitleFormField from '../../Forms/TitleFormField';
import LockIcon from '../../../../Components/LockIcon';

export interface EatineraryProps {

}

export const EATINERARY_SLUG = "eatinerary";


const Eatinerary: FC<EatineraryProps> = (props) => {
    const classes = useStyles({});
    const [showForm, setShowForm] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const { sections, initialized, eatineraryList } = useSelector<TReduxStore, Pick<THomeSectionState, 'sections' | 'initialized' | 'eatineraryList'>>((
        { Home: { sections, initialized, eatineraryList } }
    ) => ({ sections, initialized, eatineraryList }))
    const dispatch = useDispatch();

    const section = sections.find(s => s.slug === EATINERARY_SLUG);

    useEffect(() => {
        if (initialized) {
            if (!section) return;
            dispatch(OHomeSection.getEntityByItem(section.id, OHomeSection.Entities.sectionEntity, {
                filter: {
                    include: [
                        { relation: 'subject', scope: { include: [{ relation: 'destination', scope: { fields: ['name', 'id'] } }] } }
                    ]
                }
            }, undefined, { slug: section.slug }))
        }
    }, [initialized])

    useEffect(() => {
        setList(eatineraryList.map(l => ({ doc_type: l.subjectType, sectionId: l.id, ...l.subject })))
    }, [eatineraryList])


    const handleAddEntity = () => {
        setShowForm(true);
    }

    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddEntity,
            helpText: 'Add Eatinerary',
        }
    ]

    const handleSave = async (data: any[]) => {
        if (!section) return;
        dispatch(OHomeSection.updateSections(section.id, list, data))
        setList(data);
        setShowForm(false);
    }

    const handleReorder = (updatedList: any[], movedItem: any) => {
        if (!section) return;
        const newOrder = updatedList.findIndex(x => x.id === movedItem.id);
        if (newOrder === -1) return;
        dispatch(OHomeSection.reorderItem(section.id, movedItem.sectionId, newOrder + 1))
        setList(updatedList);
    }

    const handleRemove = (entitySectionId: string) => {
        if (!section) return;
        dispatch(OHomeSection.addRemove(section.id, [], [entitySectionId]))
    }


    const renderList = (item: any, draghandleProps: any) => {
        return (
            <EntityCard
                imgUrl={item._pictures?.[0]?.url}
                subtitle={item.destination?.name || ''}
                title={item.name}
                key={item.id}
                draghandleProps={draghandleProps}
                actionComponent={item.isPublished ? null : <LockIcon />}
                menuProps={{
                    id: item.id,
                    items: [
                        { label: 'remove', onClick: () => handleRemove(item.sectionId) }
                    ]
                }}
            />
        )
    }

    return (
        <Box className={classes.root}>
            <Box position="sticky" top={0}>
                <HeaderActionButtons items={headerItems} />
            </Box>
            {section ? (
                <TitleFormField section={section} />
            ) : null}
            <Box my={5}>
                <ReorderableList<any>
                    handleUpdateListOrder={handleReorder}
                    list={list}
                    renderItem={renderList}
                />
            </Box>
            <EntityForm
                title="Add Eatinerary"
                entities={["Eateries"]}
                closeForm={() => setShowForm(false)}
                items={list}
                onFormSubmit={handleSave}
                open={showForm}
            />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.sm,
        margin: '0 auto',
        paddingBottom: 100
    },
}));

export default Eatinerary;