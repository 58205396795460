import React, { useEffect, useState } from 'react';
import { Box, Checkbox, createStyles, Divider, FormControlLabel, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import { OApp } from '../../../../Models/App';
import { OExplore } from '../../../../Models/Explore';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import ReorderableList from '../../../../Components/ReorderableList';
import dimensions from '../../../../Constants/dimensions';
import { OQuote } from '../../../../Models/Quotes';
import { DATE_FORMAT } from '../../../../Constants/vars';
import { TDestinationPack, TDestinationPackState } from '../../../../Models/DestinationPack/@types';
import { ODestinationPack } from '../../../../Models/DestinationPack';

interface IProps { }

const Details: React.FC<IProps> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();
    const classes = useStyles();
    const { details } = useSelector<TReduxStore, Pick<TDestinationPackState, 'details'>>(({ DestinationPack: { details } }) => ({ details }));

    const handleEdit = () => {
        dispatch({ type: ODestinationPack.Actions.SHOW_DESTINATION_PACK_DETAIL_FORM, data: details });
    };

    const setField = async (field: keyof TDestinationPack, value: any) => {
        try {
            if (details) await dispatch(ODestinationPack.patchItem(details.id!, { [field]: value }, undefined, undefined, { [field]: value }));
        } catch (error) { }
    };

    const handlePublish = async () => {
        if (!details) return;
        try {
            const flag = !!!details.isPublished;
            // dispatch(ODestinationPack.togglePublish(details.id, flag));
            dispatch(OApp.showToast({ message: flag ? 'Published!' : 'Unpublished!', variant: 'success' }));
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }));
        }
    };

    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(ODestinationPack.deleteItem(details.id, {}, undefined, details));
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }));
            } catch (err) {
                console.error(err);
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }));
            }
            if (history) {
                history.push('/dashboard/destination-packs');
            }
        }
    };

    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this Pack.',
        },
        // {
        //     type: 'delete',
        //     alertMessage: 'Are you sure you want to delete this Pack?',
        //     handleDelete: handleDelete,
        //     helpText: 'Delete this Pack.',
        // },
        // {
        //     type: 'icon',
        //     icon: details && details.isPublished ? 'lock_open' : 'lock',
        //     onClick: handlePublish,
        //     color: details && details.isPublished ? 'secondary' : 'primary',
        //     helpText: details && details.isPublished ? 'Unpublish this Pack.' : 'Publish this Pack.',
        // },
    ];
    const headerItemsDefault: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this Pack.',
        },

    ];
    const showHeaderButton = details?.name === "DEFAULT" ? headerItemsDefault : headerItems

    return (
        <div className={classes.foodDrinkDetail}>
            {details && (
                <>
                    <HeaderActionButtons items={showHeaderButton} />
                    <Paper className={classes.paper}>
                        {/* <Divider style={{ margin: '16px 0' }} /> */}
                        <Box display="flex">
                            <Typography variant="overline">
                                {`Created - ` +
                                    moment(details.created).format(DATE_FORMAT) +
                                    ` Modified - ` +
                                    moment(details.updated).format(DATE_FORMAT)}
                            </Typography>
                        </Box>
                        <Divider style={{ margin: 0, marginBottom: 24 }} />
                        <div>
                            <Typography variant="h4">{details.name}</Typography>
                            <Typography>
                                {details.currencyValue} {details.currencySymbol}
                            </Typography>
                            <Typography>
                                {details.durationValue} {details.durationType}
                            </Typography>
                        </div>
                    </Paper>
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        foodDrinkDetail: {
            position: 'relative',
            margin: '0px 40px',
        },
        paper: {
            width: dimensions.screenMaxWidth,
            margin: 'auto',
            padding: '30px',
            marginTop: 20,
        },
        operationBtns: {
            display: 'flex',
            justifyContent: 'center',
            '& > div': {
                margin: '0 10px',
            },
        },
    })
);

export default Details;
