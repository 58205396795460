import LoopFront from "loop-front";
import { ALL_ENTITIES_DOC_TYPES } from "../../../Constants/FilterConstants";

export const textFieldWMentionHelpers = {
    getSearchText(text: string) {
        if (!/@/ig.test(text)) return '';
        const mentions = text.split('@');
        if (!mentions.length) return '';
        return mentions[mentions.length - 1]
    },
    getTextNode(text: string, renderMention: (name: string, meta: string) => string) {
        const _regex = /<mention>name:([^;]+?);([^<]+?)<\/mention>/ig
        const _res = text.matchAll(_regex)
        let _htmlStr = text;

        for (let r of _res) {
            _htmlStr = _htmlStr.replace(r[0], renderMention(r[1], r[2]))
        }
        return _htmlStr
    },
    fetchAutocomplete(query, callback) {
        if (!query) return
        LoopFront.request({
            url: `users/admin-autocomplete`,
            params: {
                term: query,
                filter: {
                    doc_type: ALL_ENTITIES_DOC_TYPES,
                    limit: 5,
                }
            },
        })
            .then(res => res.data)

            // Transform the data to what react-mentions expects
            .then(res =>
                res.map(d => {
                    const meta = { id: d.id, slug: d.slug, doc_type: d.doc_type };
                    return ({ display: d.name, id: JSON.stringify(meta) })
                })
            )
            .then(callback)
    }
}