import React, { useCallback, useState } from 'react';
import AddKnowsAboutDialog from '.';
import { TUser } from '../../../Models/User/@types';


function useKnowsAboutDialog() {
    const [open, setOpen] = useState(false)
    const [user, setUser] = useState<TUser>();
    const openDialog = (user: TUser) => {
        setUser(user);
        setOpen(true)
    }
    const closeDialog = useCallback(() => { setOpen(false); setUser(undefined) }, []);



    return {
        openDialog,
        closeDialog,
        DialogComponent: user ? (
            <AddKnowsAboutDialog user={user} open={open} onClose={closeDialog} />
        ) : null
    }

}


export default useKnowsAboutDialog;