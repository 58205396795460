import React, { useState, FC } from 'react';
import { Fab, useTheme, Theme, Tooltip, IconButton, ExtendButtonBase } from '@material-ui/core';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';

export interface DeleteButtonProps {
    backgroundColor?: string
    iconColor?: string
    handleDelete: () => void
    alertMessage?: string,
    helpText?: string
    type?: 'fab' | 'button'
}

const DeleteButton: FC<DeleteButtonProps> = ({type = 'fab', backgroundColor, iconColor, handleDelete, alertMessage = 'Do you really want to delete this item?', helpText = '' }) => {

    const [dialog, setDialog] = useState(false);

    const theme = useTheme<Theme>();

    const toggleDialog = () => setDialog(dialog => !dialog);
    const handleDialogDelete = () => {
        handleDelete();
        toggleDialog();
    }

    const ButtonComponent: ExtendButtonBase<any> = type === 'fab' ? Fab : IconButton;

    if (helpText)
        return (
            <div>
                <Tooltip title={helpText}>
                    <ButtonComponent style={{ backgroundColor: backgroundColor || theme.palette.primary.main, color: iconColor || 'white', boxShadow: 'none' }} size={'small'} onClick={toggleDialog}>
                        <i className="material-icons">delete</i>
                    </ButtonComponent>
                </Tooltip>

                <ConfirmationDialog
                    open={dialog}
                    onAgree={handleDialogDelete}
                    onDisagree={toggleDialog}
                    message={alertMessage}
                    onClose={toggleDialog}
                />
            </div>
        )
    return (
        <div>
            <ButtonComponent style={{ backgroundColor: backgroundColor || theme.palette.primary.main, color: iconColor || 'white', boxShadow: 'none' }} size={'small'} onClick={toggleDialog}>
                <i className="material-icons">delete</i>
            </ButtonComponent> 
            <ConfirmationDialog
                open={dialog}
                onAgree={handleDialogDelete}
                onDisagree={toggleDialog}
                message={alertMessage}
                onClose={toggleDialog}
            />
        </div>
    )
}

export default DeleteButton