import { Box, createStyles, Icon, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useState } from 'react';
import Lottie from 'react-lottie';
import ConfirmationDialog from './Dialogs/ConfirmationDialog';

export interface LottieContainerProps {
    image: any
    onImageDelete?: (index: number) => Promise<void>
    imageIndex: number
}

const LottieContainer: FC<LottieContainerProps> = (props) => {
    const classes = useStyles();
    const { image, imageIndex } = props
    const [deleteDialog, setDialogDelete] = useState<boolean>(false);
    const toggleDialog = () => { setDialogDelete(!deleteDialog) }
    const handleDelete = () => {
        if (props.onImageDelete)
            props.onImageDelete(imageIndex);
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: image,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Box className={classes.imageCard}>
            {(props.onImageDelete) ? <Box padding='5px 10px' zIndex='1' width='100%' display='flex' alignContent='flex-end' justifyContent='space-between' className={classes.topDiv}>
                {props.onImageDelete && <Icon color='action' className={classes.icon} fontSize='default' onClick={toggleDialog}>delete</Icon>}
            </Box> : null
            }
            {/* <Paper square className={classes.lPaper}> */}
            <Lottie
                options={defaultOptions}
                height={'100%'}
                width={'100%'}
            />
            <ConfirmationDialog
                open={deleteDialog}
                onAgree={handleDelete}
                onDisagree={toggleDialog}
                message={'Do you really wish to delete this Image ?'}
                onClose={toggleDialog}
            />
            {/* </Paper> */}
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        imageCard: {
            position: 'relative',
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: 8,
            paddingBottom: '5px',
            width: '100%',
            height: 'auto',
        },
        topDiv: {
            cursor: 'pointer',
            background: 'linear-gradient(#000000, rgba(0, 0, 0, 0.1))',
            color: '#ff0',
            position: 'absolute'
        },
        icon: {
            color: 'white',
            opacity: '40%',
            '&:hover': {
                // color: 'white',
                opacity: '100%',
            }
        },
        lPaper: {
            height: '100%',
            width: '100%',
            padding: '5px'
        }
    }))
})

export default LottieContainer