import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import { useSelector, useDispatch } from 'react-redux';
import { TReduxStore } from '../../../RootReducer';
import { TextValidator } from 'react-material-ui-form-validator';
import { TDestinationPack, TDestinationPackState } from '../../../Models/DestinationPack/@types';
import { ODestinationPack } from '../../../Models/DestinationPack';
import { createStyles, Theme, Paper, Box, MenuItem } from '@material-ui/core';
import dimensions from '../../../Constants/dimensions';
import { OApp } from '../../../Models/App';
import { TDispatch } from '../../../Resources/GlobalTypes';


type IDestination = Pick<TDestinationPack, 'name' | 'currencyValue' | 'durationType' | 'durationValue'>

export default function BasicInfo() {
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>();
    const [destinstion, setDestinatinon] = useState<IDestination>(
        { name: '', currencyValue: 10, durationValue: 0, durationType: 'year' }
    )
    const { showMainForm = false, editedDish } = useSelector<TReduxStore, Pick<TDestinationPackState, 'showMainForm' | 'details' | 'editedDish'>>(({ DestinationPack: { showMainForm, details, editedDish } }) => ({ showMainForm, details, editedDish }));
    const Timeduration = [
        { name: 'Year', value: 'year' },
        { name: 'Month', value: 'month' },
        { name: 'Week', value: 'week' }
    ]

    useEffect(() => {
        if (editedDish && editedDish.id) {
            setDestinatinon({
                ...destinstion,
                name: editedDish?.name || '',
                currencyValue: editedDish?.currencyValue || 0,
                durationValue: editedDish?.durationValue || 0,
                durationType: editedDish?.durationType || '',
            })
        }
    }, [editedDish])

    const handleSubmit = async () => {
        try {
            if (editedDish && editedDish.id) {
                await dispatch(ODestinationPack.patchItem(editedDish.id, destinstion, {}, undefined, { ...destinstion }));
                dispatch(OApp.showToast({ message: 'Edit Destination Successful', variant: 'success' }))
                onClose()
            } else {
                dispatch(ODestinationPack.postItem(destinstion));
                dispatch(OApp.showToast({ message: 'Add Destination Successful', variant: 'success' }))
                onClose()
            }
        } catch (err) {
            dispatch(OApp.showToast({ message: 'Add/Edit Destination Unsuccessful', variant: 'error' }))
        }
    }

    const onClose = () => {
        dispatch({ type: ODestinationPack.Actions.HIDE_DESTINATION_PACK_DETAIL_FORM })
    }

    const handleTextChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'currencyValue') {
            value = Number(value)
        }
        if (name === 'durationValue') {
            value = Number(value)
        }
        setDestinatinon({
            ...destinstion,
            [name]: value
        })
    }

    return (
        <FormDialog onSubmit={handleSubmit}
            heading={editedDish ? 'Edit Destination Pack' : 'Add Destination Pack'}
            open={showMainForm}
            onClose={onClose}>
            <Paper className={classes.paper}>
                <div className={classes.wrapper}>
                    <TextValidator
                        fullWidth
                        label='Name'
                        value={destinstion?.name || ''}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        name='name'
                        color='primary'
                        className={classes.textField}
                        onChange={handleTextChange}
                    />
                    <TextValidator
                        fullWidth
                        label='Currency'
                        value={destinstion?.currencyValue || 0}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        name='currencyValue'
                        color='primary'
                        className={classes.textField}
                        onChange={handleTextChange}
                    />
                    <TextValidator
                        fullWidth
                        label='Duration Value'
                        value={destinstion?.durationValue || 0}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        name='durationValue'
                        color='primary'
                        className={classes.textField}
                        onChange={handleTextChange}
                    />
                    <Box mt={2}>
                        <TextValidator
                            label='Duration'
                            name='durationType'
                            fullWidth
                            color='primary'
                            className={classes.textField}
                            select
                            validators={['required']}
                            errorMessages={['This field is required']}
                            value={destinstion?.durationType}
                            onChange={handleTextChange}
                        >
                            {Timeduration.map((item, index) => {
                                return (
                                    <MenuItem value={item.value} key={index}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </TextValidator>
                    </Box>
                </div>
            </Paper>
        </FormDialog>

    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            margin: '100px  auto',
            width: dimensions.formPaperMaxWidth,
            height: 'auto',
            padding: '20px 20px 40px'
        },
        wrapper: {
            width: '100%',
            display: 'flex',
            height: 'auto',
            minHeight: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& > div:first-child': {
                width: '100%'
            }
        },
        textField: {
            margin: '15px 0px'
        },
    }))
})


