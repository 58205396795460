import React, { FC, useState, useEffect } from 'react'
import { createStyles, Theme, Box, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { TPicture, animationDimention } from '../../../../Resources/GlobalTypes';
import FormDialog from '../../../../Components/Dialogs/FormDialog';
import { TextValidator } from 'react-material-ui-form-validator';
import { get } from 'lodash'
export interface AnimationFormProps {
    onClose: (flag: boolean) => void
    onSubmit: (image: TPicture) => void
    open: boolean
    image: TPicture
}
type TAnimationField = {
    heading: string
    fields: Array<{
        name: string,
        label: string,
        placeholder?: string
    }>
}
const AnimationForm: FC<AnimationFormProps> = (props) => {
    const { open = false, image } = props;
    const [formData, setFormData] = useState({ ...animationDimention })
    const classes = useStyles();
    const onClose = () => {
        props.onClose(false)
    }
    const onSubmit = () => {

        props.onSubmit({ ...image, ...formData });
    }
    const handleChange = (e: React.ChangeEvent<{ name: unknown, value: unknown }>) => {
        setFormData({ ...formData, [e.target.name as string]: e.target.value as string });
    }
    useEffect(() => {
        if (image) {
            setFormData({
                ...formData,
                imageWidth: image.imageWidth || '',
                imageWidthSm: image.imageWidthSm || '',
                imageHeight: image.imageHeight || '',
                imageHeightSm: image.imageHeightSm || '',
                startX: image.startX || '',
                startXSm: image.startXSm || '',
                startY: image.startY || '',
                startYSm: image.startYSm || '',
                endX: image.endX || '',
                endXSm: image.endXSm || '',
                endY: image.endY || '',
                endYSm: image.endYSm || '',
                duration: image.duration || '',
                durationSm: image.durationSm || '',

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const Paper1Fields: TAnimationField[] = [
        {
            heading: 'Image Dimension',
            fields: [
                {
                    name: 'imageWidth',
                    label: 'Width',
                },
                {
                    name: 'imageHeight',
                    label: 'Height'
                }
            ]
        },
        {
            heading: 'Start Position',
            fields: [
                {
                    name: 'startX',
                    label: 'X',
                },
                {
                    name: 'startY',
                    label: 'Y'
                }
            ]
        },
        {
            heading: 'End Position',
            fields: [
                {
                    name: 'endX',
                    label: 'X',
                },
                {
                    name: 'endY',
                    label: 'Y'
                }
            ]
        },
        {
            heading: 'Duration',
            fields: [
                {
                    name: 'duration',
                    label: '',
                    placeholder: '10s'
                }
            ]
        }
    ]
    return (
        <FormDialog
            open={open}
            onClose={onClose}
            onSubmit={onSubmit}
            heading='Animation Configuration'
        >
            <Box display='flex' justifyContent='center' margin='50px auto'>
                <Paper className={classes.paper}>
                    <Box display='flex' flexDirection='column' alignItems='flex-start'>
                        <Typography color='primary' variant='body1'>Large Devices</Typography>
                        {
                            Paper1Fields.map((item, index) => {
                                return (
                                    <Box width='100%' key={index} marginBottom='10px'>
                                        <Typography variant='body2'>{item.heading}</Typography>
                                        <Box width='100%' display='flex'>
                                            {
                                                item.fields.map((field, fIndex) => {
                                                    return <TextValidator
                                                        label={field.label}
                                                        placeholder={field.placeholder || '100px'}
                                                        value={get(formData, field.name)}
                                                        name={field.name}
                                                        onChange={handleChange}
                                                        key={fIndex}
                                                        color={'primary'}
                                                        style={{ width: '50%' }}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Paper>
                <Paper className={classes.paper}>
                    <Box display='flex' flexDirection='column' alignItems='flex-start'>
                        <Typography color='primary' variant='body1'>Small Devices</Typography>
                        {
                            Paper1Fields.map((item, index) => {
                                return (
                                    <Box width='100%' key={index} marginBottom='10px'>
                                        <Typography variant='body2'>{item.heading}</Typography>
                                        <Box width='100%' display='flex'>
                                            {
                                                item.fields.map((field, fIndex) => {
                                                    return <TextValidator
                                                        label={field.label}
                                                        placeholder={field.placeholder || '100px'}
                                                        value={get(formData, field.name + 'Sm')}
                                                        name={field.name + 'Sm'}
                                                        onChange={handleChange}
                                                        key={fIndex}
                                                        color={'primary'}
                                                        style={{ width: '50%' }}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Paper>
            </Box>
        </FormDialog>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            height: 'auto',
            width: '400px',
            padding: '10px 5px',
            margin: '20px',

        }
    }))
})

export default AnimationForm