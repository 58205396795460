import { Box, createStyles, Grid, Paper, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import MapsView from '../../../../Components/MapsView';
import { OApp } from '../../../../Models/App';
import { TAppState } from '../../../../Models/App/@types';
import { OCity } from '../../../../Models/City';
import { TPosition } from '../../../../Models/Location/@types';
import { TPlace } from '../../../../Models/Place/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import { STATE_UPDATE, UPDATE } from './Reducer';

export interface AddressSectionProps {

    dispatchReducer: React.Dispatch<{
        type: string,
        name: string;
        data: any;
    }>
    state: Partial<TPlace>
}
export type AddressFormType = {
    address?: string
    addressLine1?: string
    city?: string
    locality?: string
    zipcode?: string
    cityId?: string
    localeAddress?: string
}
const AddressSection: FC<AddressSectionProps> = (props) => {
    const { dispatchReducer, state } = props;
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>();
    const [location, setLocation] = useState<TPosition>({ lat: 28.644800, lng: 77.216721 })
    const { cityList = [] } = useSelector<TReduxStore, Pick<TAppState, 'cityList'>>(({ App: { cityList } }) => ({ cityList }));
    const [address, setAddress] = useState<AddressFormType>({ address: '', addressLine1: '', city: '', locality: '', zipcode: '', cityId: '', localeAddress: '' })
    const handleChange = (name: keyof AddressFormType, value: string) => {
        setAddress({ ...address, [name]: value });
    }
    const handleBlur = () => {
        dispatchReducer({ type: STATE_UPDATE, data: address, name: '' })
    }
    const handleSelectChange = (destinationId?: string) => {
        dispatchReducer({ type: UPDATE, name: 'destinationId', data: destinationId })
    }
    const getCityList = async () => {
        const res = await OCity.requestGetItemsList();
        dispatch({ type: OApp.Actions.CITY_LIST_RECEIVED, data: res.data })
    }

    useEffect(() => {
        if (state) {
            if (state.location)
                setLocation(state.location)
            setAddress({
                ...address,
                address: state.address || '',
                addressLine1: state.addressLine1 || '',
                city: state.city || '',
                zipcode: state.zipcode || '',
                locality: state.locality || '',
                localeAddress: state.localeAddress || ''
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])
    useEffect(() => {
        if (cityList.length < 1)
            getCityList();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const city = useMemo(() => {
        return cityList.find(i => i.id === state.destinationId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.destinationId, cityList.length])
    return (
        <Box width={800} mt={5} >
            <Paper className={classes.root}>
                <Grid container spacing={3}>
                    {
                        ([{ name: 'address', label: 'Address Full', value: address.address, fullWidth: true, required: true },
                        { name: 'localeAddress', label: 'Address on local Language', value: address.localeAddress, fullWidth: true },
                        { name: 'addressLine1', label: 'AddressLine1', value: address.addressLine1 },
                        { name: 'city', label: 'City', value: address.city, required: true },
                        { name: 'locality', label: 'Locality', value: address.locality },
                        { name: 'zipcode', label: 'Zipcode', value: address.zipcode, },
                        ] as { name: keyof AddressFormType, label: string, value: string, fullWidth?: boolean, required?: boolean }[]).map((item, index) => {
                            let isAddress = item.name === "address";
                            var validator: string[] = item.required ? ['required'] : [];
                            var error: string[] = item.required ? ['This Field is Required'] : []
                            return (
                                <Grid md={item.fullWidth ? 12 : 6} item key={index} >
                                    <TextValidator
                                        label={item.label}
                                        fullWidth
                                        validators={validator}
                                        errorMessages={error}
                                        name={item.name}
                                        value={item.value}
                                        color='primary'
                                        autoComplete='none'
                                        multiline={isAddress}
                                        onBlur={handleBlur}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(item.name, e.target['value'])}
                                    />
                                </Grid>
                            )
                        })
                    }
                    {cityList.length ? <Grid md={12} item>
                        {/* <InputLabel id="city-id">City</InputLabel>
                        <TextValidator
                            name="city"
                            select
                            validators={['required']}
                            value={selectedCity || ''}
                            fullWidth
                            errorMessages={['Required']}
                            onChange={handleSelectChange}>
                            {
                                cityList.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id} >
                                            {item.name}
                                        </MenuItem>

                                    )
                                })
                            }
                        </TextValidator> */}
                        <Autocomplete
                            id="country-select-demo"
                            // style={{ width: 300 }}
                            options={cityList.sort((a, b) => a.name.localeCompare(b.name))}
                            classes={{
                                option: classes.option,
                            }}
                            value={city || null}
                            fullWidth
                            autoHighlight
                            onChange={(a, c) => handleSelectChange(c?.id)}
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <React.Fragment>
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a city"
                                    variant="standard"
                                    fullWidth
                                    error={!city}
                                    helperText={city ? undefined : 'This is required'}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />

                    </Grid> : null}
                    <Grid item md={12} >
                        <MapsView
                            points={{ ...location }}
                            containerElement={<div style={{ height: 300, width: '100%', overflow: 'hidden' }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            padding: '40px 32px 40px'
        },
        container: {
            marginBottom: '40px'
        }
    }))
})

export default AddressSection