import { TAction } from "loop-front";
import { Reducer } from "redux";
import { ODish } from ".";
import utilities from "../../Resources/Utils";
import { TDishState } from "./@types";


export const RDish: Reducer<TDishState, TAction> = (state = { showMainForm: false }, action) => {
    const { SINGLE_ITEM_RECEIVED, ACTIVITY_RECEIVED, LIST_RECEIVED,
        POST_ITEM_SUCCESS,
        ITEM_PATCH_SUCCESS,
        ITEM_DELETED,
        ITEM_ACTIVITY_POST_SUCCESS,
        ENTITY_OF_ITEM_RECEIVED,
        PUT_CUISINE_TO_DISH_SUCCESS,
        SINGLE_ENTITY_BY_ITEM_DELETED,
        PUT_ESTABLISHMENT_TO_DISH_SUCCESS,
        DELETE_CUISINE_FROM_DISH_SUCCESS,
        DELETE_ESTABLISHMENT_FROM_DISH_SUCCESS,
        SHOW_DISH_DETAIL_FORM,
        HIDE_DISH_DETAIL_FORM,
        ITEM_ACTIVITY_RECEIVED
    } = ODish.Actions;

    switch (action.type) {
        case DELETE_CUISINE_FROM_DISH_SUCCESS: return {
            ...state,
            cuisines: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, cuisines: action.data } }
        }
        case DELETE_ESTABLISHMENT_FROM_DISH_SUCCESS: return {
            ...state,
            places: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, establishments: action.data } }
        }
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'ADD') }
        case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), details: { ...state.details, ...action.additionalDispatchData } }
        case LIST_RECEIVED: return { ...state, list: action.data }
        case SHOW_DISH_DETAIL_FORM: return { ...state, showMainForm: true, editedDish: action.data }
        case HIDE_DISH_DETAIL_FORM: return { ...state, editedDish: undefined, showMainForm: false }
        case SINGLE_ITEM_RECEIVED: return { ...state, details: action.data }
        case SINGLE_ENTITY_BY_ITEM_DELETED: {
            switch (action.entity) {
                case ODish.Entities.CUISINES: return { ...state, cuisines: utilities.updateItemList(state.cuisines || [], action.additionalDispatchData, 'DELETE') };
                case ODish.Entities.ESTABLISHMENTS: return { ...state, places: utilities.updateItemList(state.places || [], action.additionalDispatchData, 'DELETE') }
                default: return state;
            }
        }
        case ITEM_DELETED: return { ...state, list: utilities.updateItemList(state.list || [], action.additionalDispatchData, 'DELETE') }
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case ODish.Activities.COUNT: return { ...state, totalCount: action.data.count }
                default: return state;
            }
        }
        case ITEM_ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case ODish.Activities.DETAILS: return { ...state, details: action.data }
                default: return state;
            }
        }
        case ITEM_ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case ODish.Activities.PUBLISH: return { ...state, details: { ...state.details, ...action.data }, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE') }
                default: return state
            }
        }
        case ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case ODish.Entities.CUISINES: return { ...state, cuisines: action.data }
                case ODish.Entities.ESTABLISHMENTS: return { ...state, places: action.data }
                default: return state
            }
        }
        case PUT_CUISINE_TO_DISH_SUCCESS: return {
            ...state,
            cuisines: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, cuisines: action.data } }
        }
        case PUT_ESTABLISHMENT_TO_DISH_SUCCESS: return {
            ...state,
            places: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, establishments: action.data } }
        }
        default: return state;
    }

}