import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import CitiesCard from '../../../Components/Cards/CardLists/CitiesCard';
import _ from 'lodash';
import PaginationComponent from '../../../Components/Pagination';
import { TCity } from '../../../Models/City/@types';
import { CITY_ITEM_PAGE_COUNT } from '../../../Constants/FilterConstants';

type Props = {
    cities?: TCity[]
    pageNumber?: number
    renderSubtitle?: (city: TCity) => JSX.Element | null
    handlePageChange?: (pageNumber: number) => void
    totalCount?: number
}

function CitiesCardList(props: Props) {
    const classes = useStyles()
    const { cities } = props

    const onPageChange = () => { }
    return (
        <div className={classes.wrapper} >
            <Grid container spacing={3}>
                {
                    _.sortBy(cities, ['name'])?.map((item, index) => {
                        return (
                            <Grid item key={index} md={4}>
                                <CitiesCard city={item} renderSubtitle={props.renderSubtitle} />
                            </Grid>
                        )
                    })
                }
            </Grid>
            <div>
                {(cities && cities?.length > 0) ?
                    <div className={classes.paginationMargin}>
                        <PaginationComponent
                            currentPage={props.pageNumber || 0}
                            onPageChange={props.handlePageChange || onPageChange}
                            itemsCountPerPage={CITY_ITEM_PAGE_COUNT}
                            totalItemsCount={props.totalCount || 0}
                        />
                    </div> : null}
            </div>
        </div >
    )
}
const useStyles = makeStyles<Theme>(theme => createStyles({
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        }
    },
    paginationMargin: {
        margin: '30px auto',
        width: 380
    }
}));

export default CitiesCardList;