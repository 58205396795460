import LoopFront from 'loop-front'
import { OTagCategories } from '.'
import { TDispatch } from '../../Resources/GlobalTypes'
import { TSelectedTagList } from '../../Screens/Tags'
import { OApp } from '../App'
import { TFilterConfig } from '../App/@types'
import { TTag, TTagCategories } from './@types'

const requestReorderList = (tagID: string, order: number) => (
    LoopFront.request({
        url: `TagCategories/change-order`,
        method: 'PATCH',
        data: { id: tagID, order: order }
    })
)
export function reordeTagList(tagId: string, order: number, newList: TTagCategories[]) {
    return (async (dispatch: TDispatch) => {
        try {
            await requestReorderList(tagId, order);
            dispatch({ type: OTagCategories.Actions.REORDER_TAGS_LIST, data: newList })
            dispatch(OApp.showToast({ message: 'Reorder Successful', variant: 'success' }))
        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ message: 'Reorder unsuccessful', variant: 'error' }))
        }
    })
}
const requestPatchTag = (tagId: string, name: string, applyOnFilters: boolean, showToUsers: boolean) => (
    LoopFront.request({
        url: `tags/${tagId}`,
        method: 'PATCH',
        data: { id: tagId, name, applyOnFilters, showToUsers }
    })
)
const requestPublishTag = (ids: string[], publish: boolean) => (
    LoopFront.request({
        url: `tags/changePublishStatus`,
        method: 'PATCH',
        data: { ids, publish }
    })
)
export function publishTag(tag: TTag[], categories: TTagCategories[]) {
    return (async (dispatch: TDispatch) => {
        try {
            let flag = !tag[0].isPublished
            let tagIds = tag.map(i => i.id)
            await requestPublishTag(tagIds, flag);
            let newList: TTagCategories[] = []
            categories.forEach(i => {
                let nTags: TTag[] = (i.tags || [])?.map(i => ({ ...i, isPublished: tagIds.includes(i.id) ? flag : i.isPublished }))
                newList.push({ ...i, tags: nTags });
            })
            // let newList = [...categories]
            // let index = newList.findIndex(i => i.id === categoryId);
            // let tagIndex = newList[index].tags?.findIndex(i => i.id === tag.id) || -1;
            // newList[index].tags?.splice(tagIndex, 1, { ...tag, isPublished: !tag.isPublished })
            dispatch(OApp.showToast({ message: 'Tag publish Successful', variant: 'success' }))
            dispatch({ type: 'TAGCATEGORIES_LIST_RECEIVED', data: newList })

        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ message: 'Tag publish Unsuccessful', variant: 'error' }))
        }
    })
}
export function patchTag(tagId: string, name: string, applyOnFilters: boolean, showToUsers: boolean) {
    return (async (dispatch: TDispatch) => {
        try {
            await requestPatchTag(tagId, name, applyOnFilters, showToUsers);
            dispatch(OApp.showToast({ message: 'Tag Edit Successful', variant: 'success' }))
            //await dispatch(OTagCategories.getItemsList({ filter: { include: "tags", order: "name ASC" } }))

        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ message: 'Tag Edit Unsuccessful', variant: 'error' }))
        }
    })
}
const requestAddTags = (categoryId: string, items: any[]) => (
    LoopFront.request({
        url: `TagCategories/${categoryId}/new-tags`,
        method: 'POST',
        data: { tags: items }
    })
)
export function addTags(categoryId: string, items: any[]) {
    return (async (dispatch: TDispatch) => {
        try {
            await requestAddTags(categoryId, items);
            dispatch(OApp.showToast({ message: 'Tag Add Successful', variant: 'success' }))
            //  await dispatch(OTagCategories.getItemsList({ filter: { include: "tags", order: "name ASC" } }))
        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: 'Tag Add Unsuccessful', variant: 'error' }))
        }
    })
}

const requestDeletetagCategory = (item: TTagCategories) => (
    LoopFront.request({
        url: 'TagCategories/delete-multiple',
        method: 'DELETE',
        params: { ids: [item.id] }
    })
)

export function deleteTagCategory(item: TTagCategories) {
    return (async (dispatch: TDispatch) => {
        try {
            await requestDeletetagCategory(item);
            dispatch({ type: OTagCategories.Actions.TAG_CATEGORY_DELETE_SUCCESS, data: item });
            dispatch(OApp.showToast({ message: 'Tag Category Delete Successful', variant: 'success' }))

        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: 'Tag Category delete Unsucceeful', variant: 'error' }));
        }
    })
}
const requestDeleteTags = (items: { tagCategoryId: string, tagIds: string[] }[]) => (
    LoopFront.request({
        url: 'Tags/delete-bulk',
        method: 'DELETE',
        data: items,
    })
)

export function deleteMultipleTags(items: TSelectedTagList[], list: TTagCategories[]) {
    return (async (dispatch: TDispatch) => {
        try {
            let newData = items.map(i => ({ tagCategoryId: i.tagCategoryId, tagIds: i.tags.map(ii => ii.id) }));
            await requestDeleteTags(newData);
            const tempList: TTagCategories[] = [];

            list.forEach((value, index) => {
                let tcIndex = newData.findIndex(i => i.tagCategoryId === value.id);
                if (tcIndex > -1) {
                    let tags = (value.tags || []).filter(i => !newData[tcIndex].tagIds.includes(i.id));
                    tempList.push({ ...value, tags, tagIds: tags.map(i => i.id) })
                } else {
                    tempList.push({ ...value })
                }
                // const tags = (value.tags || []).filter(i => items.findIndex(j => j.id === i.id) === -1)
                // tempList.push({ ...value, tags: [...tags], tagIds: tags.map(i => i.id) })
            })
            dispatch({ type: OTagCategories.Actions.MULTIPLE_TAG_DELETE_SUCCESS, data: tempList });
            dispatch(OApp.showToast({ message: 'Tag Category Delete Successful', variant: 'success' }))

        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: 'Tag Category delete Unsucceeful', variant: 'error' }));
        }
    })
}
export function getTagsList(filterConfig: TFilterConfig[]) {
    return (async (dispatch: TDispatch) => {
        try {
            const filter = {
                include: {
                    relation: 'tags',
                    scope: {
                        where: {
                            applyOnFilters: false,
                            showToUsers: false
                        }
                    }
                }
            }
            if (filterConfig[0].isActive) {

                await dispatch(OTagCategories.getItemsList({ filter: { include: "tags", order: "order ASC" } }))
            } else {
                if (filterConfig[1].isActive)
                    filter.include.scope.where.showToUsers = true;
                else
                    delete filter.include.scope.where.showToUsers
                if (filterConfig[2].isActive)
                    filter.include.scope.where.applyOnFilters = true;
                else
                    delete filter.include.scope.where.applyOnFilters
                await dispatch(OTagCategories.getItemsList({ filter: { ...filter, order: 'order ASC' } }))
            }
        } catch (err) {
            console.log(err);

        }
    })

}