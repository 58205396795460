import MomentUtils from '@date-io/moment';
import { Box, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React, { FC, useEffect } from 'react';
import { FormConfig, IFieldProps, getFieldError } from 'react-forms';
import { DATE_FORMAT } from '../../../Constants/vars';
import { TCoupons } from '../../../Models/Coupons/@types';
export interface DateTimeInputProps extends IFieldProps {
    fieldProps: {
        type: 'Start' | 'End',
        label?: string
    }
}
// let momentNY = moment.tz('America/New_York')
const DateTimeInput: FC<DateTimeInputProps> = (props) => {
    const { fieldConfig = {} as FormConfig, formikProps = {} as FormikProps<Partial<TCoupons>> } = props;
    const valueKey = fieldConfig.valueKey;
    const value = get(formikProps, `values.${valueKey}`);
    const { type = 'Start', label } = props.fieldProps
    moment.tz.setDefault("America/New_York");
    useEffect(() => {
        console.log(moment.tz.guess());
        return () => {
            moment.tz.setDefault(moment.tz.guess());
        }
    }, [])
    const onChange = (date: MaterialUiPickersDate, type: 'date' | 'time') => {
        let data
        if (value && date) {
            if (type === 'date') {
                data = moment(value).set('D', date.date() || 1).set('M', date.month()).set('y', date.year())
            } else {
                data = moment(value).set('h', date.hours()).set('minute', date.minutes()).set('s', 0)
            }
        } else if (date) {
            data = moment(date)
        }
        // console.log(data?.tz('America/New_York').toISOString())
        formikProps.setFieldValue(valueKey, data?.toISOString());
    }
    const helperText = getFieldError(valueKey, formikProps);
    const error = !!helperText
    const timeZone = moment().tz('America/New_York').format('z( UTC Z)')
    return (
        <>
            <Box display='flex'>
                <Box mr={1} flex={2}>
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <DatePicker
                            fullWidth
                            helperText={helperText}
                            clearable={false}
                            label={label ?? `${type} Date`}
                            error={error}
                            value={value ? value : null}
                            onChange={(data) => onChange(data, 'date')}
                            // format='DD/MM/YYYY'
                            format={DATE_FORMAT}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
                <Box flex={1}>
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <TimePicker
                            fullWidth
                            error={error}
                            clearable={false}
                            placeholder="Select Time"
                            value={value ? value : null}
                            onChange={(data) => onChange(data, 'time')}
                            format='HH:mm'
                            label={`${type} time`}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </Box>
            <Typography variant='overline'>Timezone : {timeZone} </Typography>
        </>
    )
}



export default DateTimeInput