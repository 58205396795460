import { Reducer } from "redux";
import { TSettingsState } from './@types';
import { TAction } from 'loop-front';
import { OSettings } from '.';
import utilities from "../../Resources/Utils";


const initialState: TSettingsState = {
    list: []
}


export const RSettings: Reducer<TSettingsState, TAction> = (state = initialState, action): TSettingsState => {
    const {
        LIST_RECEIVED,
        ITEM_PATCH_SUCCESS
    } = OSettings.Actions;

    switch (action.type) {
        case ITEM_PATCH_SUCCESS: {
            return { ...state, list: utilities.updateItemList(state.list, action.data, 'UPDATE') };
        }
        case LIST_RECEIVED: return {
            ...state,
            list: [...action.data]
        }

        default: return state;
    }
}