import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DATE_FORMAT } from '../../../../Constants/vars';
import useAsyncTask from '../../../../Hooks/useAsyncTask';
import { OCoupons } from '../../../../Models/Coupons';
import { TClaimed, TCoupons } from '../../../../Models/Coupons/@types';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';

export interface ClaimedProps { }
type IProps = {
    claims?: TClaimed[]
    details?: TCoupons
}
const Claimed: FC<ClaimedProps> = (props) => {
    const classes = useStyles();
    const { details, claims = [] } = useSelector<TReduxStore, IProps>(({ Coupons: { details, claims } }) => ({ details, claims }))
    const dispatch = useDispatch<TDispatch>()
    const getTransaction = useAsyncTask(async () => {
        await dispatch(OCoupons.getEntityByItem(details?.id || '', OCoupons.Entities.CLAIM));
    })
    useEffect(() => {
        if (details?.id) {
            getTransaction.run({})
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details?.id]);
    return (
        <Box width={'450px'} mx='auto' mt={12} display='flex' flexDirection='column'>
            {
                getTransaction.status === 'PROCESSING' ? <CircularProgress /> :
                    <> {claims.map((i, index) => (
                        <Box key={index} className={classes.item}>
                            <Button variant='text' size='small' color='primary'>{i.user?.name}</Button>
                            <Typography variant='overline' color='textSecondary'>{moment(i.transactionData?.created).format(`${DATE_FORMAT}, HH:MM`)}</Typography>
                        </Box>
                    ))}
                    </>
            }
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        item: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            borderBottom: `1px solid ${lighten(theme.palette.grey['800'], 0.5)}`
        }
    }))
})

export default Claimed