import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {  FormControlLabel, IconButton, Paper, Switch, TextField, Typography } from '@material-ui/core';
import FormDialog from '../../Components/Dialogs/FormDialog';
import clsx from 'clsx';
import dimensions from '../../Constants/dimensions';

export interface PageSectionConfigItemProps {
    title: string;
    subtitle?: string;
    isVisible: boolean;
    onChange?: (title: string, subtitle: string, isVisible: boolean) => Promise<void>;
}

const PageSectionConfigItem: FC<PageSectionConfigItemProps> = (props) => {
    const {title, isVisible, onChange, subtitle} = props;
    const classes = useStyles({});
    const [formData, setFormData] = useState({
        title,
        subtitle,
        isVisible
    });
    const [formOpen, setFormOpen] = useState(false);
    const openForm = () => {
        setFormData({
            title,
            subtitle,
            isVisible
        });
        setFormOpen(true)
    };
    const closeForm = () => setFormOpen(false);
    
    const handleSubmit = async () => {
        if (onChange) {
            await onChange(formData.title, formData.subtitle || '', formData.isVisible);
        }
        closeForm();
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }


    return (
        <>
        <Paper className={clsx(classes.root, {
            [classes.visible]: isVisible,
            [classes.hidden]: !isVisible
        })}>
            <div>
                <Typography gutterBottom><b>{title}</b></Typography>
                <Typography variant='body2'>{subtitle}</Typography>
            </div>
            <div>
                <IconButton onClick={openForm}>
                    <i className="material-icons">edit</i>
                </IconButton>
            </div>
        </Paper>
        <FormDialog keepMounted={false} onClose={closeForm} heading="Update" onSubmit={handleSubmit} open={formOpen}>
            <Paper className={classes.formRoot}>
                <FormControlLabel
                    className={classes.formItem}
                    control={
                        <Switch
                            checked={formData.isVisible}
                            onChange={(_, checked) => setFormData({...formData, isVisible: checked})}
                        />
                    }
                    label="Visible"
                />
                <TextField  className={classes.formItem} onChange={handleTextChange} name="title" label="Title" value={formData.title} />
                <TextField multiline className={classes.formItem}  onChange={handleTextChange} name="subtitle" label="Subtitle" value={formData.subtitle} />
            </Paper>
        </FormDialog>
        </>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        display:'flex',
        padding: theme.spacing(2, 1),
        marginBottom: theme.spacing(2),
        justifyContent:'space-between',
        '&:last-child': {
            marginBottom: 0,
        }
    },
    visible: {
        borderLeft: `6px solid ${theme.palette.success.main}`,
    },
    hidden: {
        borderLeft: `6px solid ${theme.palette.error.main}`,
    },
    formItem: {
        marginBottom: theme.spacing(3),
        '&:last-child': {
            marginBottom: 0,
        }
    },
    formRoot: {
        width: dimensions.formPaperMaxWidth,
        padding: theme.spacing(4, 2),
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default PageSectionConfigItem;