import { Box, Dialog, fade, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowLeft } from '@material-ui/icons';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIconButton from '../../../Components/Buttons/CloseIconButton';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import useStepper from '../../../Hooks/useStepper';
import { OApp } from '../../../Models/App';
import { OUser } from '../../../Models/User';
import { TKnowsAbout, TUser } from '../../../Models/User/@types';
import { UserModel } from '../../../Models/User/actions';
import { TDispatch } from '../../../Resources/GlobalTypes';
import Stepper from './Stepper';

export interface AddKnowsAboutDialogProps {
    open: boolean
    onClose: () => void
    user: TUser
}

const AddKnowsAboutDialog: FC<AddKnowsAboutDialogProps> = ({ open, onClose, user }): JSX.Element => {
    const classes = useStyles({});
    const { currentIndex, nextStep, prevStep, resetStepper, hasNext, hasPrev } = useStepper(3);
    const [hasCompleted, setHasCompleted] = useState(false);

    const dispatch = useDispatch<TDispatch>();

    const updateUser = useAsyncTask(async (values: Partial<TUser>) => {
        const { _knowsAbouts } = values;
        if (user?.id) {
            try {
                const res = await UserModel.updateUser(user.id, { _knowsAbouts })//, null, undefined, { ...values, _knowsAbouts }))
                dispatch({ type: OUser.Actions.ITEM_PATCH_SUCCESS, data: { ...res.data }, additionalDispatchData: { ...values, ...res.data, } })
                dispatch(OApp.showToast({ message: 'User edit successfull', 'variant': 'success' }))
                onClose();
            } catch (e) {
                dispatch(OApp.showToast({ message: 'User edit failed', 'variant': 'error' }))
            }
        }
    })
    const submitForm = async (form: any) => {
        if (!user) return;


        try {
            const formData = { ...form };
            if (formData.startDate) formData.startDate = new Date(formData.startDate).toISOString()
            if (formData.endDate) formData.endDate = new Date(formData.endDate).toISOString()
            const _knowsAbouts = (user?._knowsAbouts || [])
            _knowsAbouts.push(formData as TKnowsAbout);
            await updateUser.run({ ...user, _knowsAbouts })
        } catch (error) {
            throw error
        }
    }

    const handleSubmit = async (form: Record<string, any>) => {
        if (!isFormValid(form)) return;
        try {
            await submitForm(form);
            setHasCompleted(true);
            resetStepper();
        } catch (error) {
            throw error
        }
        return
    }




    const isFormValid = (formData: Record<string, any>) => {
        if (!formData['destinationId']) return false;
        if (!formData['how']) return false;

        return true;
    }


    return (
        <Dialog disableBackdropClick open={open} onClose={onClose} PaperProps={{ className: classes.paper }} BackdropProps={{ className: classes.backdrop }} scroll="body">
            <Box display='flex' p={1} alignItems='center'>
                {
                    hasPrev ?
                        <IconButton onClick={prevStep}>
                            <KeyboardArrowLeft />
                        </IconButton> : <Box width='48px' />
                }
                <div style={{ flex: 1 }} >
                    <Typography variant='body1'>{user.name}</Typography>
                </div>
                {
                    hasNext ?
                        <CloseIconButton onClick={onClose} /> : null
                }
            </Box>
            <div className={classes.content}>
                <Stepper hasCompleted={hasCompleted} handleStep={nextStep} step={currentIndex} onSubmit={handleSubmit} />
            </div>
        </Dialog>
    )
}





const useStyles = makeStyles<Theme, any>((theme) => ({
    content: {
        padding: theme.spacing(5),
        width: 500,
    },
    paper: {
        borderRadius: 0,
        background: theme.palette.background.default,
        boxShadow: 'none',
        // width: 420,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '100%'
            // height: `calc(100vh - ${HEADER_HEIGHT_SMALL}px)`
        }
    },
    backdrop: {
        background: `transparent linear-gradient(120deg, ${fade('#FF6250', 0.5)} 0%, ${fade('#FF6250', 0.5)} 100%) 0% 0% no-repeat padding-box`,
        opacity: 0.7
    },
}));

export default AddKnowsAboutDialog;