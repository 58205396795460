import LoopFront from "loop-front";

export const CustomEntities = {

}
export const CustomActivity = {
    PUBLISH: 'publish',
}

export const CustomActions = {

}

class Review extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity>{
    constructor() {
        super('reviews', CustomActions, CustomEntities, CustomActivity);
    }


}

export const OReview = new Review();