import { Grid } from '@material-ui/core'
import React from 'react'
import { TCuisine } from '../../../Models/Cuisines/@types'
import CuisinesCard from './CuisinesCard'
import PaginationComponent from '../../Pagination'
import { ITEM_PER_PAGE_COUNT } from '../../../Constants/FilterConstants'

interface IProps {
    list: TCuisine[]
    deleteItem: (item: TCuisine) => Promise<void>
    pageNumber: number
    handlePageChange: (pageNumber: number) => void
    totalCount: number
}

const CuisinesCardList: React.FC<IProps> = (props) => {
    const { list = [], deleteItem } = props

    return (
        <div>
            <Grid container spacing={3}>
                {
                    list.map((item, index) => {
                        return (<Grid item key={index} lg={4} md={6} sm={12} xs={12}>
                            <CuisinesCard
                                deleteItem={deleteItem}
                                item={item}
                            /></Grid>)
                    })
                }
            </Grid>
            <PaginationComponent
                currentPage={props.pageNumber}
                onPageChange={props.handlePageChange}
                itemsCountPerPage={ITEM_PER_PAGE_COUNT}
                totalItemsCount={props.totalCount}
            />
        </div>
    )
}



export default CuisinesCardList