import { Box, createStyles, Divider, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, RouteProps } from 'react-router-dom';
import ListDashboard from '../../Components/Dashboard/ListDashboard';
import CityChipList from '../../Components/Filters/CityChipList';
import UsersListSortMenu from '../../Features/Users/UsersListSortMenu';
import { TFilterConfig } from '../../Models/App/@types';
import { OCity } from '../../Models/City';
import { TCity } from '../../Models/City/@types';
import { OLandmarks } from '../../Models/Landmarks';
import { TLandmarks } from '../../Models/Landmarks/@types';
import LandmarksModel from '../../Models/Landmarks/actions';
import { TDispatch } from '../../Resources/GlobalTypes';
import utilities from '../../Resources/Utils';
import { TReduxStore } from '../../RootReducer';
import LandmarkForm from './Forms/LandmarkForm';
import Details from './Tabs/Details';
import Images from './Tabs/Images';
const BASE_URL = '/dashboard/landmarks';
const MODEL_NAME = 'Landmark';
interface IProps { }
interface SelecterProps {
    totalCount: number;
    list: TLandmarks[];
    showMainForm: boolean | undefined;
    details?: TLandmarks | undefined;
    destinations: TCity[];
}
const initialFilter: TFilterConfig[] = [
    { name: 'All', isActive: true },
    { name: 'Published', isActive: false },
    { name: 'Unpublished', isActive: false },
    { name: 'Newly Added', isActive: false },
    { name: 'Recently Updated', isActive: false },
];
const Landmarks: React.FC<IProps> = (props) => {
    const url = window.location.href;
    const urlArray: string[] = url.split('/');
    const landmarkId = urlArray.length >= 6 ? urlArray[5] : '';
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<TLandmarks[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedList, setSelectedList] = useState<string[]>([]);
    const [filterConfig, setFilterConfig] = useState<TFilterConfig[]>(JSON.parse(JSON.stringify(initialFilter)));
    const classes = useStyles(props);
    const dispatch = useDispatch<TDispatch>();
    const [sortMenuEl, setSortMenuEl] = useState<HTMLElement>();
    const [sortFilter, setSortFilter] = useState<any>({});
    const {
        list = [],
        totalCount = 0,
        showMainForm,
        details,
        destinations,
    } = useSelector<TReduxStore, SelecterProps>(({ Landmarks, City }) => ({
        list: Landmarks.list,
        totalCount: Landmarks.totalCount,
        showMainForm: Landmarks.showMainForm,
        details: Landmarks.details,
        destinations: City.list || [],
    }));
    useEffect(() => {
        getLandMarks(filterConfig, sortFilter);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);
    useEffect(() => {
        if (landmarkId) {
            getDetails(landmarkId);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [landmarkId]);
    const getDetails = async (id: string) => {
        await dispatch(
            OLandmarks.getItem(id, {
                filter: {
                    include: { relation: 'destination', scope: { fields: ['name'] } },
                },
            })
        );
    };
    const handleFilterChange = (item: { name: string; isActive: boolean }) => {
        const updatedFilter = filterConfig.map((i) => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        });
        setFilterConfig([...updatedFilter]);
    };
    const handleFilterSubmit = () => {
        if (pageNumber === 1) getLandMarks(filterConfig, sortFilter);
        else setPageNumber(1);
    };
    const handleFilterReset = () => {
        setFilterConfig(JSON.parse(JSON.stringify(initialFilter)));
        setSelectedList([]);
        if (pageNumber === 1) getLandMarks(undefined, sortFilter);
        else setPageNumber(1);
    };
    const handleSortClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSortMenuEl(e.currentTarget);
    };

    const handleSortingChange = (filter: any) => {
        // dispatch(OUser.getItemsList({ filter: { limit: 20, skip: 20 * (pageNumber - 1), ...filter } }));
        setSortFilter(filter);
        if (pageNumber === 1) getLandMarks(filterConfig, filter);
        else setPageNumber(1);
    };
    const onAddButtonClick = () => {
        dispatch({ type: OLandmarks.Actions.SHOW_LANDMARK_FORM });
    };
    const handleSearchResult = (result: Array<TLandmarks>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    };
    useEffect(() => {
        dispatch(
            OCity.getItemsList({
                filter: {
                    order: 'name ASC',
                },
            })
        ); //to display in the form.
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getLandMarks = async (filterConfig: TFilterConfig[] = JSON.parse(JSON.stringify(initialFilter)), sortFilter: any = {}) => {
        setLoading(true);
        try {
            await dispatch(LandmarksModel.getLandmarkList(pageNumber, filterConfig, selectedList, sortFilter));
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };
    const toggleList = (id: string) => {
        setSelectedList((e) => utilities.toggleItemFromList(e, id, '', (a, b) => a === b));
    };
    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string; name: string; component: RouteProps['component'] }> = [
        {
            name: 'Details',
            url: 'details',
            component: Details,
        },
        {
            name: 'Images - ' + (details?._pictures.length || 0),
            url: 'images',
            component: Images,
        },
    ];
    const renderListItem = useCallback(
        (landmark: TLandmarks): ReactElement => {
            const isActive = (match: any, location: any) => {
                const regex = new RegExp(`${BASE_URL}/${landmark.id}/`, 'ig');
                return regex.test(location.pathname);
            };
            return (
                <span key={landmark.id}>
                    <NavLink className={classes.listItem} to={`${BASE_URL}/${landmark.id}/details`} isActive={isActive} activeClassName={'selected'}>
                        <ListItem button>
                            <ListItemText primary={`${landmark.name || ''}`} secondary={landmark.destination?.name || ''} />
                            <ListItemSecondaryAction>
                                {!landmark.isPublished ? <i className={classNames('material-icons', classes.lockIcon)}>lock</i> : null}
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider className={classes.divider} />
                    </NavLink>
                </span>
            );
        },
        [classes]
    );
    return (
        <>
            <ListDashboard
                baseUrl={BASE_URL}
                csvConfig={{
                    modelName: MODEL_NAME,
                }}
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                itemsCountPerPage={20}
                onPageChange={setPageNumber}
                onItemAddRequest={onAddButtonClick}
                filterConfig={filterConfig}
                onFilterChange={handleFilterChange}
                onFilterSubmit={handleFilterSubmit}
                onFilterReset={handleFilterReset}
                currentPageNumber={pageNumber}
                customFilterComponent={
                    <Box p={1.5}>
                        <CityChipList selectedList={selectedList} onClick={toggleList} list={destinations} />
                    </Box>
                }
                listProps={{
                    searchProps: {
                        type: 'Landmark',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}
                listActionButtonConfig={[
                    {
                        icon: 'sort',
                        id: 'sort',
                        label: 'Sort',
                        onClick: handleSortClick,
                    },
                ]}
            />
            <UsersListSortMenu anchorEl={sortMenuEl} onSortingChange={handleSortingChange} handleCloseSortMenu={() => setSortMenuEl(undefined)} />
            {showMainForm ? <LandmarkForm /> : null}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            backgroundColor: theme.palette.grey['500'],
        },
        listItem: {
            color: theme.palette.text.primary,
            '&.selected': {
                background: fade(theme.palette.primary.main, 0.4),
                display: 'block',
            },
        },
        subDashboardContainer: {
            marginLeft: 250,
        },
        headLinks: {
            marginBottom: 50,
        },
        lockIcon: {
            fontSize: 18,
            color: '#B71840',
        },
    })
);

export default Landmarks;
