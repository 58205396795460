import { Box } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import HeaderNavLinks from '../../Components/Buttons/HeaderNavLinks';
import PlaceTypeInput from '../../Features/Settings/PlaceTypeInput';
import { TSettingsState } from '../../Models/Settings/@types';
import { TReduxStore } from '../../RootReducer';
import AuthScreen from './Tabs/AuthScreen';
import CityPage from './Tabs/CityPage';
import Continents from './Tabs/Continents';
import PlaceType from './Tabs/PlaceType';

export interface SettingsProps { }


const baseUrl = '/dashboard/settings'



const Settings: FC<SettingsProps> = (props) => {

    const TABS = useMemo(() => ([
        {
            name: "Place Types",
            path: "place-types",
            component: PlaceType,
        },
        {
            name: "Continents",
            path: "continents",
            component: Continents,
        },
        {
            name: "City Page",
            path: "city-page",
            component: CityPage,
        },
        {
            name: "Auth Screen",
            path: "auth-screen",
            component: AuthScreen,
        },

    ]), [])


    return (
        <Box>
            <HeaderNavLinks baseUrl={baseUrl} links={TABS.map(t => ({ name: t.name, url: t.path }))} />
            <Box >
                <Switch>
                    <Route path={baseUrl} exact render={() => <Redirect to={`${baseUrl}/place-types`} />} />
                    {TABS.map(t => (
                        <Route key={t.path} component={t.component} path={`${baseUrl}/${t.path}`} exact />
                    ))}
                </Switch>
            </Box>

        </Box>
    )
}



export default Settings