import { Box, createStyles, Divider, Fab, Icon, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import EntityImages from '../../../../Components/EntityImages';
import Gallery from '../../../../Components/Gallery';
import ImageCarousel from '../../../../Components/ImageCarousel';
import ImageContainer from '../../../../Components/ImageContainer';
import ImageLoader from '../../../../Components/ImageLoader';
import LottieContainer from '../../../../Components/LottieContainer';
import { OApp } from '../../../../Models/App';
import { changePictureCaption, deletePictureFromItem } from '../../../../Models/App/actions';
import { OQuote } from '../../../../Models/Quotes';
import { TCity, TCityState } from '../../../../Models/City/@types';
import { TQuoteState, TQuote, imageTypes } from '../../../../Models/Quotes/@types';
import { animationDimention, TDispatch, TPicture } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import AnimationForm from './AnimationForm';
import DeleteButton from '../../../../Components/Buttons/DeleteButton';

export interface indexProps extends RouteComponentProps { }


const Images: FC<indexProps> = (props) => {

    const classes = useStyles();
    const { details = {} as TQuote } = useSelector<TReduxStore, Pick<TQuoteState, 'details'>>(({ Quote }) => ({ details: Quote.details }))
    const dispatch = useDispatch<TDispatch>()
    const [showForm, setShowForm] = useState(false);
    const [animatedImage, setAnimatedImage] = useState<TPicture | undefined>(undefined)
    const [loadingCount, setLoadingCount] = useState(0);
    const [pictureList, setPictureList] = useState<TPicture[]>([])
    const [showCorousel, setShowCorousel] = useState(false);
    const [uploadedPicture, setUploadedPicture] = useState<TPicture>();
    const [current, setCurrent] = useState<number>(0);
    const [isJson, setIsJson] = useState(false);
    const history = useHistory();
    const toggleJson = () => {
        setIsJson(!isJson);
    }
    const toggleCorousel = (id: string = '') => {
        if (!showCorousel) {
            const index = pictureList.findIndex(i => i.id === id)
            setCurrent(index)
        }
        setShowCorousel(!showCorousel);
    }
    const onImagesSelected = (files: any[]) => {
        setLoadingCount(files.length);
    }
    const renderImageItem = (image: TPicture) => {
        var value = 'Desktop';
        if (image.desktop === false)
            value = 'Mobile'

        console.log(value, image);

        return (<ImageContainer
            image={image}
            orientation={value}
            onOrientationChange={onOrientationChange}
            OrientationList={imageTypes}
            onImageDelete={onImageDelete}
            showCourousel={toggleCorousel}

        />)
    }
    const renderLoadingItem = () => {
        return (
            <Box width="250px"  >
                <ImageLoader />
            </Box >
        )
    }
    const onImageUploadComplete = (current: any, response: TPicture) => {
        setUploadedPicture(response);
    }
    useEffect(() => {
        if (details && uploadedPicture) {
            dispatch(OApp.showToast({ message: "Picture uploaded", variant: "success" }))
            const newImages: TPicture[] = [uploadedPicture, ...details._pictures]
            dispatch({ type: OQuote.Actions.ITEM_PATCH_SUCCESS, data: { _pictures: newImages }, additionalDispatchData: { _pictures: newImages } })
            setLoadingCount(loadingCount => loadingCount - 1)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedPicture])


    const onImageDelete = async (pictureId: string) => {
        if (details && details._pictures) {
            const pictures = [...details._pictures]
            const pIndex = pictures.findIndex(i => i.id === pictureId);
            try {
                await deletePictureFromItem('quotes', details.id, pictureId);
                if (pIndex > -1)
                    pictures.splice(pIndex, 1);
                dispatch({ type: OQuote.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _pictures: pictures }, additionalDispatchData: { _pictures: pictures } })
            } catch (er) {

            }
        }
    }


    const handleEdit = () => {
        dispatch({ type: OQuote.Actions.SHOW_EATINERARY_FORM, data: details })
    }



    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(OQuote.deleteItem(details.id, {}, undefined, details))
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
                // dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'List' } })
            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }))
            }
            if (history) {
                history.push('/dashboard/quotes')
            }
        }
    }

    const handlePublish = async () => {
        if (!details) return;
        try {
            const flag = !!!details.isPublished;
            dispatch(OQuote.togglePublish(details.id, flag));
            dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))
        }
    }



    const onOrientationChange = async (id: string, orientation: string) => {

        if (details._pictures) {
            const pictures = [...details._pictures]
            var index = pictures.findIndex(i => i.id === id);
            if (index > -1) {
                var data = pictures[index];
                if (orientation === 'Desktop')
                    pictures.splice(index, 1, { ...data, desktop: true });
                else
                    pictures.splice(index, 1, { ...data, desktop: false });
            }

            await dispatch(OQuote.patchItem(details.id, { _pictures: pictures }, {}, undefined, { ...details, _pictures: pictures }))
        }
    }
    useEffect(() => {

        if (details.id && details._pictures) {
            const list: TPicture[] = []
            details._pictures.forEach(element => {
                if (element.desktop === false)
                    list.push({ ...element, desktop: false })
                else
                    list.push({ ...element, desktop: true })
            })
            setPictureList([...list])
        }
    }, [details])

    const headerButtons: Array<JSX.Element> = [
        <Tooltip title="Edit Quote">
            <Fab onClick={handleEdit} size="small" color={'inherit'}>
                <Icon color={'action'} className={'material-icons'}>edit</Icon>
            </Fab>
        </Tooltip >,
        <DeleteButton
            alertMessage={'Are you sure you want to delete'}
            backgroundColor={'white'}
            iconColor={'black'}
            handleDelete={handleDelete}
            helpText={"Delete Quote"}
        />,
        <Tooltip title={(details && details.isPublished) ? 'Unpublish this Quote.' : 'Publish this Quote.'}>
            <Fab onClick={handlePublish} size="small" color={'inherit'}>
                <Icon color={(details && details.isPublished) ? 'action' : 'primary'} className={'material-icons'}>
                    {(details && details.isPublished) ? 'lock_open' : 'lock'}
                </Icon>
            </Fab>
        </Tooltip >,
    ];

    return (
        <Box className={classes.cityImages}>
            <Box width={850} mx="auto" my="20px">
                <EntityImages<TPicture>
                    folderName={'quotes'}
                    images={pictureList}
                    url={`quotes/${details.id}/pictures`}
                    renderImageItem={renderImageItem}
                    renderLoadingItem={renderLoadingItem}
                    hasAddButton={true}
                    loadingCount={loadingCount}
                    onImageSelected={onImagesSelected}
                    onImageUploadComplete={onImageUploadComplete}
                    isJson={isJson}
                    customButtons={headerButtons}
                    heading={details.title || ""}
                ></EntityImages>
            </Box>
            {showCorousel && <ImageCarousel open={showCorousel} images={pictureList} closeDialog={toggleCorousel} currentIndex={current} />}
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        cityImages: {
            position: 'relative',
            margin: '0px 40px',
        },
        paper: {
            width: 700,
            margin: 'auto',
            padding: '30px',
            marginTop: 20
        },
        imageCard: {
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: 8,
            paddingBottom: '5px',
            width: '100%',
            height: 'auto',
        },
        imageItem: {
            borderRadius: 0,
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            width: '100%',
            height: 'auto',
            cursor: 'pointer'
        },
        formLabel: {
            marginLeft: 0,
            '& span:last-child': {
                marginLeft: 2,
                fontSize: 12,
            }
        },
        lPaper: {
            height: '100%',
            width: '100%',
            padding: '5px'
        },
        operationBtns: {
            display: 'flex',
            justifyContent: 'center',
            color: 'white',
            '& > button': {
                margin: '0 10px'
            },
            '& > div': {
                margin: '0 10px'
            }
        },
    }))
})


export default withRouter(Images)