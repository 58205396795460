import { Box, Button, Checkbox, createStyles, Fab, FormControlLabel, Icon, Link, makeStyles, Paper, Theme, Tooltip, Typography } from '@material-ui/core';
import { ClearOutlined } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ConfirmationDialog from '../../../../Components/Dialogs/ConfirmationDialog';
import ImageUpload, { TFile } from '../../../../Components/EntityImages/ImageUpload';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import Stepper from '../../../../Components/Stepper';
import TypographyWMention from '../../../../Components/TypographyWMention';
import dimensions from '../../../../Constants/dimensions';
import { renderHtmlStr } from '../../../../Features/Mention';
import useKnowsAboutDialog from '../../../../Features/Users/AddKnowsAbout/useKnowsAboutDialog';
import UserSubscriptions from '../../../../Features/Users/UserSubscriptions';
import useAsyncTask from '../../../../Hooks/useAsyncTask';
import { OApp } from '../../../../Models/App';
import { TAppState, TConstant } from '../../../../Models/App/@types';
import { OUser } from '../../../../Models/User';
import { TKnowsAbout, TUser, TUserState } from '../../../../Models/User/@types';
import { UserModel } from '../../../../Models/User/actions';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { uploadPicture } from '../../../../Resources/PictureUtils';
import { TReduxStore } from '../../../../RootReducer';

interface KnowAboutProps {
    onRemove: () => void
    knowAbout: TKnowsAbout
}
const KnowAboutCard: React.FC<KnowAboutProps> = ({ knowAbout, onRemove }) => {
    const classes = useStyles();
    const text = knowAbout.destination?.name || '';
    const secondaryText = useMemo(() => {
        if (knowAbout.how === 'pastResident')
            return 'Local for ' + (new Date(knowAbout.startDate || '').getFullYear()) + ' - ' + (new Date(knowAbout.endDate || '').getFullYear());
        if (knowAbout.how === 'currentResident')
            return 'Local since ' + (new Date(knowAbout.startDate || '').getFullYear())
        if (knowAbout.how === 'frequentlyVisits')
            return 'Frequent Visits'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [knowAbout.how])
    return (
        <div className={classes.knowAboutDiv}>
            <Button color='primary' href=''>{text}</Button>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant={"body2"}>{secondaryText}</Typography>
                <ClearOutlined color='action' fontSize='default' style={{ cursor: 'pointer' }} onClick={onRemove} />
            </Box>
        </div>
    )

}
export const Details: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
    const classes = useStyles()
    const [showConfirm, setShowConfirm] = useState(false);
    const { constants } = useSelector<TReduxStore, Pick<TAppState, 'constants'>>(({ App }) => ({ constants: App.constants }))
    const foodPreferences: TConstant[] = (constants ? constants.user.foodPreference : [])
    const drinks: TConstant[] = (constants ? constants.user.drink : [])
    const { user } = useSelector<TReduxStore, Pick<TUserState, 'user'>>(({ User }) => ({ user: User.user }));
    const knowAbout: TKnowsAbout[] = (user && user.knowsAbouts) ? user.knowsAbouts : []
    const { openDialog, DialogComponent } = useKnowsAboutDialog();
    const dispatch = useDispatch<TDispatch>();
    const handleChange = async (flag: keyof TUser) => {
        const v = !!user?.[flag];
        try {
            await dispatch(OUser.patchItem(user!.id, { [flag]: !v }, {}, undefined, { [flag]: !v }));
            dispatch(OApp.showToast({ message: 'User updated successfully', 'variant': 'success' }));
        } catch (err) {
            dispatch(OApp.showToast({ message: 'User update failed', 'variant': 'error' }));
        }
    }

    const toggleEmailVefify = async (verify: boolean) => {
        if (!user) return;
        try {
            await dispatch(OUser.adminVerifyEmail(user, verify));
            dispatch(OApp.showToast({ message: `User email ${verify ? 'verified' : 'unverified'} successfully`, 'variant': 'success' }));
        } catch (error) {
            dispatch(OApp.showToast({ message: `User email verification failed`, 'variant': 'error' }));
        }
    }

    const handleEdit = () => {
        dispatch({ type: OUser.Actions.SHOW_USER_FORM, data: user })
    }
    const onRemove = () => {
        setShowConfirm(s => !s)
    }
    const handleRemove = async () => {
        await dispatch(OUser.patchItem(user!.id, { _profile_image: null }, {}, undefined, { _profile_image: null }));
        onRemove();
    }
    const handleUpload = useAsyncTask(async (f: TFile[]) => {
        if (f.length === 0)
            return
        try {
            const res = await uploadPicture(f[0], `user_profileImage`);
            await dispatch(OUser.patchItem(user!.id, { _profile_image: res.data }, {}, undefined, { _profile_image: res.data }));

        } catch (e) {

        }
    });
    const handleKnowsAboutEdit = () => {
        user && openDialog(user)
    }
    const removeKnowsAbout = useAsyncTask(async (destinationId: string) => {

        if (user?.id) {
            let knows = knowAbout.filter(i => i.destinationId !== destinationId)
            try {
                const res = await UserModel.updateUser(user.id, { _knowsAbouts: knows })//, null, undefined, { ...values, _knowsAbouts }))
                dispatch({ type: OUser.Actions.ITEM_PATCH_SUCCESS, data: { ...res.data }, additionalDispatchData: { ...user, ...res.data, } })
                dispatch(OApp.showToast({ message: 'User edit successfull', 'variant': 'success' }))
            } catch (e) {
                dispatch(OApp.showToast({ message: 'User edit failed', 'variant': 'error' }))
            }
        }
    })
    const handleNoteSave = async (notes: string) => {
        try {
            if (user?.id) {
                const res = await UserModel.updateUser(user.id, { notes })//, null, undefined, { ...values, _knowsAbouts }))
                dispatch({ type: OUser.Actions.ITEM_PATCH_SUCCESS, data: { ...res.data }, additionalDispatchData: { ...user, ...res.data, } })

                dispatch(OApp.showToast({ message: `Notes updated.`, variant: 'success' }))
            }

        } catch (err) {
            dispatch(OApp.showToast({ message: `Notes update failed.`, variant: 'success' }))
            console.log('Error in add to mamakoo', err);
        }
    }
    return (
        <div className={classes.root}>
            {DialogComponent}
            {
                (user && user.id) ?
                    <div className={classes.wrapper}>

                        <ImageUpload
                            handleUpload={handleUpload.run}
                            loading={handleUpload.status === 'PROCESSING'}
                            imageUrl={user?._profile_image?.url}
                            paperProps={{ className: classes.img, style: { display: 'flex', position: 'relative', marginBottom: 24 } }}
                            avatarClassName={classes.img}
                            onRemove={onRemove}
                        />
                        <Typography align='center' gutterBottom className={classes.name}>{user.name}</Typography>
                        <Typography align='center' variant='body2' gutterBottom >{user.email}</Typography>
                        <Link href={user.website} target="_blank">{user.website}</Link>
                        <Box display='flex' mt={2} width={200} justifyContent='space-between'>

                            <Button variant="outlined" color="primary" href={DOMAIN_URL + '/profile/' + user.slug}>
                                Go to profile
                            </Button>
                            <Tooltip title={'Edit user'}>
                                <Fab color={'inherit'} size={"small"} onClick={handleEdit}>
                                    <Icon color={'primary'} className={'material-icons'}>{'edit'}</Icon>
                                </Fab>
                            </Tooltip>
                        </Box>
                        <Paper className={classes.paper}>
                            <FormControlLabel
                                key='recommender'
                                control={
                                    <Checkbox
                                        checked={user.isRecommender}
                                        onChange={() => handleChange('isRecommender')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Recommender"
                            />
                            <FormControlLabel
                                key='in-search'
                                control={
                                    <Checkbox
                                        checked={user.inSearch}
                                        onChange={() => handleChange('inSearch')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="In Search"
                            />
                            <FormControlLabel
                                key='emailVerified'
                                control={
                                    <Checkbox
                                        checked={user.emailVerified}
                                        onChange={() => toggleEmailVefify(!user.emailVerified)}
                                        name="emailVerified"
                                        color="primary"
                                    />
                                }
                                label="Email verified"
                            />
                        </Paper>
                        <Box mt={3} width={dimensions.screenMaxWidth} m='auto'>
                            <NotesInput
                                onSave={handleNoteSave}
                                text={user.notes || ''}
                            />
                        </Box>
                        {/* <UserSubscriptions user={user} /> */}
                        {user.bio ? <Paper className={classes.paper}>
                            <TypographyWMention renderMentionHTMLStr={renderHtmlStr} variant='body2'>{user.bio}</TypographyWMention>
                        </Paper> : null
                        }
                        {
                            (user.foodPreference || user.drink) ?
                                <Paper className={classes.paperStep}>
                                    {
                                        user.foodPreference ?
                                            <Stepper list={foodPreferences} selectedValue={user.foodPreference} /> : null
                                    }
                                    {
                                        user.drink ?
                                            <Stepper list={drinks} selectedValue={user.drink} /> : null
                                    }
                                </Paper> : null
                        }
                        <Paper className={classes.paper}>
                            <Fab className={classes.editIcon} color={'inherit'} size={"small"} onClick={handleKnowsAboutEdit}>
                                <Icon color={'action'} fontSize={'small'} className={'material-icons'}>{'edit'}</Icon>
                            </Fab>
                            <Typography>Knows About</Typography>
                            {
                                knowAbout.map((item, index) => {
                                    return <KnowAboutCard key={index} knowAbout={item} onRemove={() => removeKnowsAbout.run(item.destinationId)} />
                                })
                            }
                        </Paper>
                    </div> : null}
            {
                showConfirm
                &&
                <ConfirmationDialog
                    message='Are you sure you want to remove this image?'
                    onAgree={handleRemove}
                    onDisagree={onRemove}
                    open={showConfirm}
                    onClose={onRemove}
                />
            }
        </div>
    )
}



const useStyles = makeStyles<Theme>((theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: dimensions.screenMaxWidth,
        flexDirection: 'column'
    },
    img: {
        height: 120,
        width: 120,
        borderRadius: 60
    },
    name: {
        fontSize: 20
    },
    paper: {
        marginTop: '10px',
        width: '100%',
        padding: '24px',
        position: 'relative'
    },
    paperStep: {
        marginTop: '10px',
        width: '100%',
        height: '200px',
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    knowAboutDiv: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    stepIcon: {
        marginTop: '5px'
    },
    activeicon: {
        marginTop: '0px'
    },
    editIcon: {
        position: 'absolute',
        top: 0,
        right: 0
    }
}))
