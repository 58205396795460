import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TDestinationPackState } from '../../../../Models/DestinationPack/@types';
import { getDestinationPackCities } from '../../../../Models/DestinationPack/actions';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import _ from 'lodash';
import CitiesCardList from '../../../../Components/Cards/CardLists/CitiesCardList';
import { TCity } from '../../../../Models/City/@types';
import { CITY_ITEM_PAGE_COUNT } from '../../../../Constants/FilterConstants';
import { TAppState } from '../../../../Models/App/@types';
import NewEntitySuggest from '../../../../Components/NewEntitySuggest';

export type TStateProps = Pick<TAppState, 'summary'> & Pick<TDestinationPackState, 'details' | 'cities'>

function Cities<Props>(props) {
    const { match } = props;
    const cityId = match.params['id'];
    const classes = useStyles();
    const [displayList, setDisplayList] = useState<TCity[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchResponse, setSearchResponse] = useState<TCity[]>([]);
    const dispatch = useDispatch<TDispatch>()
    const { details, cities, summary } = useSelector<TReduxStore, TStateProps>(({ DestinationPack: { details, cities }, App: { summary } }) => ({ details, cities, summary }));

    const handlePageChange = (pageNumber: number) => {
        setPageNumber(pageNumber)
    }

    const getData = async () => {
        if (details?.id) {
            setLoading(true)
            await dispatch(getDestinationPackCities(details?.id))
            setLoading(false)
        }
    }
    useEffect(() => {
        getData()
    }, [details])

    useEffect(() => {
        if (cityId) {
            const low = CITY_ITEM_PAGE_COUNT * (pageNumber - 1);
            const high = CITY_ITEM_PAGE_COUNT * (pageNumber)
            const tList: TCity[] = (cities || []).filter((value, index) => (index >= low && index < high))
            setDisplayList([...tList])
        }
    }, [pageNumber, cities?.toString()])


    const onResults = (results) => {
        setSearchResponse(results)
    };

    return (
        <div className={classes.wrapper} >
            {
                loading ?
                    <LinearProgress />
                    :
                    <>
                        <div className={classes.searchBarContainer}>
                            <NewEntitySuggest
                                type={'Destination'}
                                placeholder={'search'}
                                onResults={onResults}
                                textFieldProps={{
                                    classes: {
                                        root: classes.searchInput,
                                    },
                                }}
                                filters={{
                                    where: {
                                        destinationPackId: details?.id
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.citiesCardListContainer}>
                            <CitiesCardList
                                cities={searchResponse.length !== 0 ? searchResponse : displayList}
                                totalCount={summary ? summary?.Destination.total : 0}
                                handlePageChange={handlePageChange}
                                pageNumber={pageNumber}
                            />
                        </div>
                    </>
            }
        </div >
    )
}
const useStyles = makeStyles<Theme>(theme => createStyles({
    wrapper: {
        width: 924,
        [theme.breakpoints.down('md')]: {
            width: 640,
        },
        [theme.breakpoints.down('sm')]: {
            width: 324,
        }
    },
    searchBarContainer: {
        position: 'absolute',
        left: '50%',
        top: '10%',
    },
    citiesCardListContainer: {
        marginTop: 40,
        border: 'none'
    },
    searchInput: {
        width: 380,
        background: '#ffffff',
    },
}));

export default Cities;