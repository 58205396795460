import { TDispatch } from '../../Resources/GlobalTypes';
import { OApp } from '../App';
import LoopFront from 'loop-front';
import { TFilterConfig } from '../App/@types';
import { OCity } from '.';
import { TCity } from './@types';

export function addEntityToCity<T extends { id: string }>(cityId: string, newIds: T[], entity: string) {
    return async (dispatch: TDispatch) => {
        var data = {
            firstModel: 'Destination',
            secondModel: entity,
            id: cityId,
            newIds: newIds.length > 0 ? newIds.map((item) => item.id) : [],
        };
        try {
            await OApp.requestPutActivity(OApp.Activities.RELATIONS, data);
            const action: string = 'PUT_' + entity.toUpperCase() + '_TO_CITY_SUCCESS';

            dispatch({ type: action, data: newIds.length, additionalDispatchData: newIds });
        } catch (err) {
            console.log('Error : ', err);
        }
    };
}
const requestDeleteSingleEntityFromCity = (destinationId: string, entity: string, itemId: string) =>
    LoopFront.request({
        url: `destinations/${destinationId}/${entity}/rel/${itemId}`,
        method: 'DELETE',
    });
export function deleteEntityfromCity<T extends { id: string }>(destinationId: string, entity: string, items: T[], itemId: string) {
    return async (dispatch: TDispatch) => {
        try {
            await requestDeleteSingleEntityFromCity(destinationId, entity, itemId);
            const action = `DELETE_${entity.toUpperCase()}_FROM_CITY_SUCCESS`;
            dispatch({ type: action, additionalDispatchData: items.filter((i) => i.id !== itemId), data: items.length - 1 });
            dispatch(OApp.showToast({ message: entity.toUpperCase() + ' delete Successful', variant: 'success' }));
        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: entity.toUpperCase() + 'delete Unsuccessful', variant: 'error' }));
        }
    };
}

export function getCityList(pageNumber: number, filterConfig: TFilterConfig[], _sortFilter: any) {
    return async (dispatch: TDispatch) => {
        const filter: {
            where: {
                totalVotes?: {
                    gt: number;
                };
                and?: Array<Record<string, any>>;
            };
        } = { where: { and: [] } };
        const countfilter: typeof filter = { where: { and: [] } };
        if (!filterConfig[0].isActive) {
            if (filterConfig[1].isActive) {
                filter.where.totalVotes = { gt: 0 };
                countfilter.where.totalVotes = { gt: 0 };
            }
            if (filterConfig[2].isActive) {
                filter.where.and?.push({ isPublished: true });
                countfilter.where.and?.push({ isPublished: true });
            }
            if (filterConfig[3].isActive) {
                filter.where.and?.push({ isPublished: false });
                countfilter.where.and?.push({ isPublished: false });
            }
            if (filterConfig[4].isActive) {
                filter.where.and?.push({ newlyAdded: true });
                countfilter.where.and?.push({ newlyAdded: true });
            }
            if (filterConfig[5].isActive) {
                filter.where.and?.push({ recentlyUpdated: true });
                countfilter.where.and?.push({ recentlyUpdated: true });
            }
        }
        if (filter.where.and?.length === 0) {
            delete filter.where.and;
            delete countfilter.where.and;
        }
        await dispatch(
            OCity.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    includeCount: true,
                    ...filter,
                    ..._sortFilter,
                },
            })
        );
        if (pageNumber === 1) await dispatch(OCity.getActivity(OCity.Activities.COUNT, { ...filter }));
    };
}

const doReorder = (id: string, entity: string, entityId: string, newIndex: number) =>
    LoopFront.request({
        url: `destinations/${id}/${entity}/${entityId}/change-order`,
        data: {
            order: newIndex,
        },
        method: 'PATCH',
    });

export function reorderDestinationEntity(id: string, entity: string, entityId: string, newIndex: number, destination: TCity) {
    return async (dispatch: TDispatch) => {
        try {
            const res = await doReorder(id, entity, entityId, newIndex + 1);

            if (res) {
                dispatch({ type: OCity.Actions.ITEM_PATCH_SUCCESS, data: destination });
                dispatch(OApp.showToast({ message: entity.toUpperCase() + ' reorder Successful', variant: 'success' }));
            } else {
                dispatch(OApp.showToast({ message: entity.toUpperCase() + ' reorder failed', variant: 'error' }));
            }
        } catch (er) {
            throw er;
        }
    };
}
