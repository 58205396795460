import { TDispatch } from '../../Resources/GlobalTypes';
import { OApp } from '../App';
import LoopFront from 'loop-front';
import { OCuisine } from '.';
import { TFilterConfig } from '../App/@types';

export function addEntityToCuisine<T extends { id: string }>(cityId: string, newIds: T[], entity: string) {
    return async (dispatch: TDispatch) => {
        var data = {
            firstModel: 'Cuisine',
            secondModel: entity,
            id: cityId,
            newIds: newIds.map((item) => item.id),
        };
        try {
            await OApp.requestPutActivity(OApp.Activities.RELATIONS, data);
            const action: string = 'PUT_' + entity.toUpperCase() + '_TO_CUISINE_SUCCESS';
            dispatch({ type: action, data: newIds.length, additionalDispatchData: newIds });
        } catch (err) {
            console.log('Error : ', err);
        }
    };
}
const requestDeleteSingleEntityFromCuisine = (cuisineId: string, entity: string, itemId: string) =>
    LoopFront.request({
        url: `cuisines/${cuisineId}/${entity}/rel/${itemId}`,
        method: 'DELETE',
    });
export function deleteEntityfromCuisine<T extends { id: string }>(cuisineId: string, entity: string, items: T[], itemId: string) {
    return async (dispatch: TDispatch) => {
        try {
            await requestDeleteSingleEntityFromCuisine(cuisineId, entity, itemId);
            const action = `DELETE_${entity.toUpperCase()}_FROM_CUISINE_SUCCESS`;
            dispatch({ type: action, additionalDispatchData: items.filter((i) => i.id !== itemId), data: items.length - 1 });
            dispatch(OApp.showToast({ message: entity.toUpperCase() + ' delete Successful', variant: 'success' }));
        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: entity.toUpperCase() + ' delete Unsuccessful', variant: 'error' }));
        }
    };
}
export function getCuisineList(pageNumber: number, filterConfig: TFilterConfig[], _sortFilter: any) {
    return async (dispatch: TDispatch) => {
        const filter: {
            where: {
                and?: Array<Record<string, any>>;
            };
        } = { where: { and: [] } };
        const countfilter: typeof filter = { where: { and: [] } };
        if (!filterConfig[0].isActive) {
            if (filterConfig[1].isActive) {
                filter.where.and?.push({ isPublished: true });
                countfilter.where.and?.push({ isPublished: true });
            }
            if (filterConfig[2].isActive) {
                filter.where.and?.push({ isPublished: false });
                countfilter.where.and?.push({ isPublished: false });
            }
            if (filterConfig[3].isActive) {
                filter.where.and?.push({ newlyAdded: true });
                countfilter.where.and?.push({ newlyAdded: true });
            }
            if (filterConfig[4].isActive) {
                filter.where.and?.push({ recentlyUpdated: true });
                countfilter.where.and?.push({ recentlyUpdated: true });
            }
        }
        if (filter.where.and?.length === 0) {
            delete filter.where.and;
            delete countfilter.where.and;
        }
        await dispatch(
            OCuisine.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    includeCount: true,
                    ...filter,
                    ..._sortFilter,
                },
            })
        );
        if (pageNumber === 1) await dispatch(OCuisine.getActivity(OCuisine.Activities.COUNT, { ...filter }));
    };
}
