import LoopFront from "loop-front";
import { TDispatch } from "../../Resources/GlobalTypes";
import _ from 'lodash';
import { OApp } from "../App";
import { TFilterConfig } from "../App/@types";


const CustomActivity = {
    COUNT: 'count'
}

const CustomEntities = {
    sectionEntity: 'entity-sections'
}

const CustomActions = {
    HOME_SECTION_ENTITIES_UPDATED: 'HOME_SECTION_ENTITIES_UPDATED',
    HOME_SECTION_SET_LOADING: 'HOME_SECTION_SET_LOADING',
    HIDE_USER_REVIEW_FORM: 'HIDE_USER_REVIEW_FORM',
    SHOW_USER_REVIEW_FORM: 'SHOW_USER_REVIEW_FORM'
}




class UserReview extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('userReviews', CustomActions, CustomEntities, CustomActivity);
    }
    togglePublish(id: string, publish: boolean) {
        return (async (dispatch: TDispatch) => {
            const { data } = await LoopFront.request({
                url: this.ModelName + `/${id}/publish`,
                method: 'post',
                params: { publish }
            });
            dispatch({ type: this.Actions.ITEM_PATCH_SUCCESS, data })
        })
    }

    getUserReviewList(pageNumber: number, filterConfig: TFilterConfig[], sortFilter: any = {}) {
        return (async (dispatch: TDispatch) => {
            const filter = OApp.parseBaseFilter(filterConfig)
            await dispatch(this.getItemsList({
                filter: {
                    limit: 20,
                    skip: 20 * (pageNumber - 1),
                    order: 'name ASC',
                    includeCount: true,
                    include: {
                        relation: 'user',
                        scope: {
                            fields: ['name']
                        }

                    },
                    ...filter,
                    where: {
                        ...filter.where,
                    },
                    ...sortFilter
                }
            }))
            if (pageNumber === 1)
                await dispatch(this.getActivity(this.Activities.COUNT, { ...filter }))
        })
    }

}

export const OUserReview = new UserReview();