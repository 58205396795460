import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { cloneDeep, omit } from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { OApp } from '../../../Models/App';
import { OExplore } from '../../../Models/Explore';
import { TExplore, TExploreState } from '../../../Models/Explore/@types';
import ExploreModel from '../../../Models/Explore/actions';
import { TPlace } from '../../../Models/Place/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';
import BasicInfo from './BasicInfo';
import CuisineFilter from './CuisineFilter';
import FilteredList from './FilteredList';
import PlaceTypeFilter from './PlaceTypeFilter';
import SelectedList from './SelectedList';
import TagFilters from './TagFilters';
export interface ExploreFormProps { }

export interface TExploreFilter {
    destinationId?: any
    range?: any
    types?: any
    status?: any
    cuisineIds?: any
    tagIds?: any
}
const ExploreForm: FC<ExploreFormProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>()
    const { showMainForm = false, editedExplore } = useSelector<TReduxStore, Pick<TExploreState, 'editedExplore' | 'showMainForm'>>(({ Explore: { editedExplore, showMainForm } }) => ({ editedExplore, showMainForm }))
    const [filteredPlaces, setFilteredPlaces] = useState<TPlace[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [selectedList, setSelectedList] = useState<TPlace[]>([]);
    const [placeFilter, setPlaceFilter] = useState<TExploreFilter>({});
    const page = useRef<number>(1);
    const history = useHistory();
    useEffect(() => {
        if (editedExplore?.id) {
            setSelectedList(editedExplore.establishments || [])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedExplore?.id])
    const onPageChange = useCallback((newPage: number) => {
        page.current = newPage;
        getFilteredPlaces.run({ placeFilter, page: newPage, selectedList: selectedList.map(i => i.id) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeFilter, selectedList.length])
    const getFilteredPlaces = (useAsyncTask(async ({ placeFilter, page, selectedList }: { placeFilter: TExploreFilter, page: number, selectedList: string[] }) => {
        const res = await ExploreModel.getFilteredList(placeFilter, page, selectedList)
        setTotal(res.total)
        setFilteredPlaces([...res.results]);
    }))
    const onChange = (name: keyof TExploreFilter, value: any) => {
        const updatedFilter: TExploreFilter = {
            ...placeFilter,
            [name]: value
        };
        setPlaceFilter(cloneDeep(updatedFilter));
        getFilteredPlaces.run({ placeFilter: updatedFilter, page: 1, selectedList: selectedList.map(i => i.id) });
        page.current = 1;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const handleSubmit = async (values: Partial<TExplore>) => {
        const data = omit(values, ['establishments', 'destination'])
        try {
            if (values.id) {
                await dispatch(OExplore.patchItem(values.id, { ...data, establishmentIds: selectedList.map(i => i.id) }, {}, undefined, { ...data, establishmentIds: selectedList.map(i => i.id), establishments: selectedList }))
                dispatch(OApp.showToast({ message: 'Item edited successfully' }))
            } else {
                let res: { data: TExplore } = await OExplore.requestPostItem({ ...values, establishmentIds: selectedList.map(i => i.id) })
                dispatch({ type: OExplore.Actions.POST_ITEM_SUCCESS, data: res.data })
                history.push('/dashboard/explore/' + res.data.id + '/details')
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'List' } })
                dispatch(OApp.showToast({ message: 'Item added successfully' }))
            }
            closeForm();
        } catch (err) {

        }
    }

    const closeForm = () => {
        dispatch({ type: OExplore.Actions.HIDE_EXPLORE_FORM })
    }

    return (
        <Formik
            initialValues={editedExplore || {}}
            validationSchema={Yup.object({
                destinationId: Yup.string().required('This is required'),
                name: Yup.string().required('This is required'),
            })}
            onSubmit={handleSubmit}
        >
            {
                formikProps =>
                    <FormDialog
                        onClose={closeForm}
                        open={showMainForm}
                        heading={editedExplore?.name ? `Edit ${editedExplore?.name}` : 'Add List'}
                        onSubmit={() => formikProps.handleSubmit()}
                        containerClass={classes.container}
                    >
                        <Box className={classes.root}>
                            <Box flex={1} display='flex' flexDirection='column' alignItems='center' overflow='auto'>
                                <BasicInfo onChange={onChange} formikProps={formikProps} />
                                <CuisineFilter onChange={onChange} />
                                <PlaceTypeFilter onChange={onChange} />
                                <TagFilters onChange={onChange} />
                            </Box>
                            <FilteredList
                                paginationProps={{
                                    currentPage: page.current,
                                    onPageChange: onPageChange,
                                    totalItemsCount: total,
                                    itemsCountPerPage: 30,
                                }}
                                loading={getFilteredPlaces.status === 'PROCESSING'}
                                addPlaces={(l) => setSelectedList(p => [...p, ...l])}
                                list={filteredPlaces.filter(i => selectedList.findIndex(j => j.id === i.id) === -1)} />

                            <SelectedList
                                selectedList={selectedList}
                                removePlace={(id) => setSelectedList(p => [...p.filter(i => i.id !== id)])}
                            />
                        </Box>
                    </FormDialog>
            }
        </Formik>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        container: {
            width: '100%',
            maxWidth: '100%',
            margin: `0 auto`
        },
        root: {
            display: 'flex',
            width: '100%',
            height: `calc(100vh - 64px)`,
        }
    }))
})

export default ExploreForm