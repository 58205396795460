import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../../RootReducer';
import { TCityState } from '../../../../Models/City/@types';
import dimensions from '../../../../Constants/dimensions';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import EntityForm from '../../../../Components/Forms/EntityForm';
import { OEatinerary } from '../../../../Models/Eatinerraies';
import EntityCard from '../../../../Features/Home/EntityCard';
import { useHistory } from 'react-router';
import ReorderableItem from '../../../../Components/Cards/ReorderableItem';
import { TEatinerraies } from '../../../../Models/Eatinerraies/@types';
import ReorderableList from '../../../../Components/ReorderableList';
import { reorderDestinationEntity } from '../../../../Models/City/actions';
import { TDispatch } from '../../../../Resources/GlobalTypes';




export interface EatinerariesProps {

}




const Eatineraries: FC<EatinerariesProps> = (props) => {
    const classes = useStyles({});
    const { details } = useSelector<TReduxStore, Pick<TCityState, 'details'>>(({ City: { details } }) => ({ details }))
    // const [list, setList] = useState<any[]>([]);
    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();

    const [list, setList] = useState<TEatinerraies[]>([]);

    useEffect(() => {
        const list = details?.eateries || [];
        setList(list);
    }, [details]);




    const onReorder = async (nexList: TEatinerraies[], movedItem: TEatinerraies, oldIndex: number, newIndex: number) => {
        if (!details?.id) return;
        const odlList = list;
        try {
            setList(nexList);
            await dispatch(reorderDestinationEntity(details.id, 'eateries', movedItem.id, newIndex, { ...details, eateries: [...nexList] }))
        } catch (error) {
            setList(odlList);
        }
    }


    const onView = (id: string) => {
        history.push(`/dashboard/eatineraries/${id}/details`)
    }

    const renderItem = (item: TEatinerraies, dragHandleProps: any) => {
        return (
            <ReorderableItem
                // onDelete={() => onDelete(item.id)}
                onViewDetails={() => onView(item.id)}
                title={item.name}
                isPublished={item.isPublished}
                logo={item._pictures[0]}
                draghandleProps={dragHandleProps}
            />
        )
    }

    if (!details) return null;
    return (
        <Box className={classes.listContainer}>
            <ReorderableList<TEatinerraies>
                list={list}
                handleUpdateListOrder={onReorder}
                renderItem={renderItem}
            />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    listContainer: {
        position: 'relative',
        margin: '0px auto',
        width: 300,
    }
}));

export default Eatineraries;