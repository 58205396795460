import { Chip, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import HeaderActionButtons from '../../../../Components/HeaderActionButtons'
import { OApp } from '../../../../Models/App'
import { OPlace } from '../../../../Models/Place'
import { TPlaceState } from '../../../../Models/Place/@types'
import { deleteTagFromPlace } from '../../../../Models/Place/actions'
import { TTag, TTagCategories } from '../../../../Models/Tags/@types'
import { TDispatch } from '../../../../Resources/GlobalTypes'
import { TReduxStore } from '../../../../RootReducer'
import TagsForm from './TagsForm'

interface IProps extends RouteComponentProps<{ id: string }> { }
export type TagMap = {
    category: string
    tags: TTag[]
}

const Tags: React.FC<IProps> = (props) => {
    const { match } = props;
    const placeId = match.params['id']
    const classes = useStyles(props)
    const [tagContainer, setTagContainer] = useState<TagMap[]>([]);
    const dispatch = useDispatch<TDispatch>();
    const { details } = useSelector<TReduxStore, Pick<TPlaceState, 'details'>>(({ Place }) => ({ details: Place.details }))
    const [showTagForm, setShowTagForm] = useState<boolean>(false);
    const handleAddTags = () => {
        setShowTagForm(true);
    }
    const headerItems = [
        {
            type: 'icon',
            icon: 'pan_tool',
            onClick: handleAddTags,
            helpText: 'Add a tag',
        },
    ]
    const mapTag = (tags: TTag[]) => {
        try {
            const list: TagMap[] = []
            tags.forEach((item) => {
                const category: TTagCategories[] = item.categories;
                category.forEach((data) => {
                    const currentIndex = list.findIndex(i => i.category === data.name);
                    if (currentIndex === -1)
                        list.push({ category: data.name, tags: [item] })
                    else {
                        const taglist = list[currentIndex].tags
                        taglist.push(item)
                        list.splice(currentIndex, 1, { category: data.name, tags: [...taglist] })
                    }
                })
            })
            setTagContainer([...list])
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (details && details.tags)
            mapTag(details.tags);

    }, [details])

    const closeForm = () => {
        setShowTagForm(false)
    }
    const handletagDelete = async (id: string) => {
        if (details && details.tags) {
            try {
                await dispatch(deleteTagFromPlace(placeId, id, details.tags));
            } catch (error) {
                console.log(error)
            }
        }
    }
    const onSubmit = async (newList: TTag[]) => {
        try {
            const counts = details?.counts || {}
            await dispatch(OPlace.patchItem(placeId, { tagIds: newList.map(i => i.id) }, {}, undefined, { tags: newList, counts: { ...counts, tags: newList.length } }))
            dispatch(OApp.showToast({ message: 'Tags Updated Successfully', variant: 'success' }))
            closeForm();
        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ message: 'Tags Update Unsuccessful', variant: 'error' }))

        }
    }
    return (
        <div>
            {details ?
                <div className={classes.placesTags}>
                    <HeaderActionButtons items={headerItems} />
                    {
                        (details && showTagForm) && <TagsForm
                            tags={(details.tags) ? details.tags : []}
                            open={showTagForm}
                            closeForm={closeForm}

                            handleSubmit={onSubmit}
                        />
                    }
                    <div className={classes.wrapper}>

                        <Grid container spacing={3} style={{ width: '100%' }}>
                            {
                                tagContainer.map((item, index) => {
                                    return <Grid item key={index} className={classes.gridItem}>
                                        <Paper className={classes.paper}>
                                            <div >
                                                <Typography variant='h6' className={classes.title}>{item.category}</Typography>
                                            </div>
                                            <div className={classes.tagContainer}>
                                                {
                                                    item.tags.map((tag, tIndex) => {
                                                        return (
                                                            <Chip
                                                                className={classes.tagChip}
                                                                label={tag.name}
                                                                key={tIndex}
                                                                onDelete={() => { handletagDelete(tag.id) }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Paper>
                                    </Grid>
                                })
                            }
                        </Grid>

                    </div>
                </div> : null}
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        width: '100%',
        padding: '30px 18px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',

    },
    placesTags: {
        position: 'relative',
        margin: '0px 40px',
    },
    wrapper: {
        width: '700px',
        margin: '40px auto'
    },
    gridItem: {
        width: '100%',

    },
    title: {
        fontFamily: 'lato',
        fontWeight: 400
    },
    tagContainer: {
        display: 'flex',
        marginTop: 20,
        flexWrap: 'wrap',
        width: '100%',
        height: 'auto'
    },
    tagChip: {

        margin: '5px'
    }
}))

export default Tags


