import { Avatar, Button, Chip, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
// import utilities from 'resources/utils';
// import { publishReview, unpublishReview } from '../screens/Users/actions'
import { makeStyles } from '@material-ui/styles';
// import THEME from 'resources/theme'
import moment from 'moment';
import React, { FC, useState } from 'react';
import { HISTORY } from '../../App';
import { TReview } from '../../Models/Review/@types';
import { OUser } from '../../Models/User';

export interface ReviewCardProps {
    review: TReview
    handlePublish?: (item: TReview) => Promise<void>
}

const ReviewCard: FC<ReviewCardProps> = (props) => {

    const { review } = props
    const [expand, setExpand] = useState(false);
    const classes = useStyles();

    const toggleExpand = () => setExpand((expand) => !expand);

    const handlePublish = () => {
        if (props.handlePublish)
            props.handlePublish(review);
    }
    const { user } = review;
    return (
        <Paper>
            <Grid container className={classes.container}>
                <Grid container item lg={8} className={classes.details}>
                    <Grid item lg={3} className={classes.avatarContainer}>
                        <Avatar
                            alt="Adelle Charles"
                            src={OUser.getUserPicture(user)}
                            className={classes.avatar}
                        />
                    </Grid>
                    <Grid item lg={9} className={classes.detailContainer}>
                        <Chip onClick={e => { HISTORY.push(`/dashboard/users/${review.userId}/details`) }} label={user.name || ''} />
                        <div>{moment(review.created).format('MMMM DD, hh:MM a, YYYY')}</div>
                        {(review.text) ?
                            <p className={classes.review}>
                                {(expand) ? review.text : review.text.substring(0, 80)}
                            </p> : <p></p>
                        }
                        <div className={classes.btnContainer}>
                            {(review.subject) ?
                                <div className={classes.suggestedPlace}>
                                    <i className="material-icons">place</i>
                                    <Typography>{review.subject.name}</Typography>
                                </div> : null
                            }

                            <div className={classes.updatebtn}>
                                {/* <Button color='primary'>Edit</Button> */}
                                <Button color='primary' onClick={handlePublish}>{(review.isPublished) ? 'Unpublish' : 'Publish'}</Button>
                                {
                                    (review.text && review.text.length > 80) &&
                                    <Button onClick={toggleExpand}><i className="material-icons">
                                        {expand ? 'expand_less' : 'expand_more'}</i>
                                    </Button>
                                }
                            </div>
                        </div>
                    </Grid>

                </Grid>
                <Grid item lg={4} className={classes.rating}>
                    <div className={classes.overall}>
                        <Rating
                            size='large'
                            readOnly
                            value={review.rating}
                        />
                    </div>
                    <div className={classes.detailRatingContainer}>
                        {
                            [{ label: 'Taste', value: 'taste' }, { label: 'Cleanliness', value: 'cleanliness' }, { label: 'Service', value: 'service' }, { label: 'Value', value: 'value' }].map((item, i) => {
                                return (
                                    <div key={i} className={classes.detailRating}>
                                        <Typography variant={'subtitle2'} >{item.label}</Typography>
                                        <Rating
                                            size={'small'}
                                            readOnly
                                            value={review[item.value as keyof Pick<TReview, 'taste' | 'cleaneliness' | 'service' | 'value'>]}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}

// const mapStateToProps = (state) => ({
// })

// const mapDispatchToProps = dispatch => ({
//     publishReview: (id) => dispatch(publishReview(id)),
//     unpublishReview: (id) => dispatch(unpublishReview(id)),
// })

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        width: 700,
        display: 'flex'
    },
    details: {
        height: 'auto',
        position: 'relative'
    },
    rating: {
        borderLeft: `1px solid ${theme.palette.grey['500']}`,
        textAlign: 'center',
    },
    overall: {
        marginTop: 10
    },
    detailRating: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    detailRatingContainer: {
        marginTop: 20,
        width: '75%',
        margin: '0 auto'
    },
    avatarContainer: {
        padding: 18
    },
    avatar: {
        width: 80,
        height: 80
    },
    detailContainer: {
        marginTop: 20,
        // position: 'relative'
    },
    restaurant: {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: 20
    },
    review: {
    },
    updatebtn: {
        position: 'absolute',
        right: '5px',
        bottom: '5px'
        // marginTop: -5
    },
    btnContainer: {
        height: 40,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    suggestedPlace: {
        alignItems: 'center',
        display: 'flex',
        '& i': {
            color: theme.palette.primary.main
        }
    }
}))

export default ReviewCard;