import React, { FC, useState, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Paper, Select } from '@material-ui/core';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import dimensions from '../../../Constants/dimensions';
import { useSelector } from 'react-redux';
import { TReduxStore } from '../../../RootReducer';
import { TCity, TCityState } from '../../../Models/City/@types';
import { OCity } from '../../../Models/City';
import Loader from '../../../Components/Loader';
import { TDish } from '../../../Models/Dish/@types';

export interface DishFormProps {
    open: boolean
    title: string
    loading: boolean
    initialValues: Partial<HomeDishFormData>
    closeForm: () => void
    onFormSubmit: (formData: HomeDishFormData) => Promise<void>
}

export interface HomeDishFormData {
    city: TCity
    dishes: TDish[]
}

const DishForm: FC<DishFormProps> = (props) => {
    const classes = useStyles({});
    const { title, loading, onFormSubmit, closeForm, open, initialValues } = props;
    const { list: cities } = useSelector<TReduxStore, Pick<TCityState, 'list'>>(({ City: { list } }) => ({ list }))
    const [loadingCityDetail, setLoadingCityDetail] = useState(false);
    const [cityDetail, setCityDetail] = useState<TCity | undefined>();
    const [dishIds, setDishIds] = useState<string[]>([]);
    const [cityId, setCityId] = useState('');
    const iconicDishes = useMemo(() => cityDetail?.foodDrinks?.filter(f => cityDetail?.iconicFoodDrinkIds?.includes(f.id)), [cityDetail]);

    useEffect(() => {
        if (initialValues?.city)
            setCityId(initialValues.city.id)
        if (initialValues?.dishes)
            setDishIds(initialValues.dishes?.map(d => d.id))
    }, [initialValues])

    useEffect(() => {
        if (!cityId) return;
        setDishIds([]);
        setLoadingCityDetail(true);
        OCity.requestGetDetail(cityId).then(res => {
            setCityDetail(res.data);
            setLoadingCityDetail(false);
        }).catch(err => {
            setCityDetail(undefined);
            setLoadingCityDetail(false);
        })
    }, [cityId])

    const handleSubmit = () => {
        if (!cityDetail) return;
        onFormSubmit({
            city: cityDetail,
            dishes: iconicDishes?.filter(i => dishIds.includes(i.id)) || []
        })
    }
    const handleCityChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        setCityId(event.target.value as string);
    };

    const handleDishChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        const { value } = event.target;
        const dishIds = typeof value === 'string' ? value.split(',') : value;
        setDishIds(dishIds as string[]);
    };


    return (
        <FormDialog
            heading={title}
            open={open}
            onSubmit={handleSubmit}
            onClose={closeForm}
            loading={loading}
        >
            {loadingCityDetail ? <Loader overlayed /> : null}
            <Paper className={classes.paper} >
                <FormControl fullWidth>
                    <InputLabel id="city-select-label">City</InputLabel>
                    <Select
                        labelId="city-select-label"
                        id="city-select"
                        value={cityId}
                        label="Age"
                        onChange={handleCityChange}
                    >
                        {cities?.filter(c => c.isPublished).map(c => (
                            <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>
            {cityDetail ? (
                <Paper className={classes.paper} >
                    <FormControl fullWidth>
                        <InputLabel id="dish-select-label">Dishes</InputLabel>
                        <Select
                            labelId="dish-select-label"
                            id="dish-select"
                            value={dishIds}
                            multiple
                            label="Dishes"
                            onChange={handleDishChange}
                            renderValue={(selected) => (
                                <Box display='flex' flexWrap='wrap'>
                                    {(selected as string[]).map((value) => {
                                        const dish = iconicDishes?.find(d => d.id === value);
                                        if (!dish) return null;
                                        return (
                                            <Chip className={classes.dishChipItem} key={dish.id} label={dish.name} />
                                        )
                                    })}
                                </Box>
                            )}

                        >
                            {iconicDishes?.map(d => (
                                <MenuItem value={d.id} key={d.id}>
                                    <Checkbox checked={dishIds.indexOf(d.id) > -1} />
                                    <ListItemText primary={d.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Paper>
            ) : null}

        </FormDialog>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    paper: {
        width: dimensions.formPaperMaxWidth,
        marginTop: 48,
        padding: 20
    },
    dishChipItem: {
        marginRight: 8
    }
}));

export default DishForm;