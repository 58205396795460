import { Reducer } from "redux";
import { TEatineraryState, TEatinerraies } from './@types';
import { TAction } from 'loop-front';
import { OEatinerary } from '.';
import utilities from "../../Resources/Utils";
import _ from 'lodash';


const initialState: TEatineraryState = {
    list: [],
    totalCount: 0,
    showMainForm: false,
}


export const REatinerary: Reducer<TEatineraryState, TAction> = (state = initialState, action): TEatineraryState => {
    const {
        LIST_RECEIVED,
        POST_ITEM_SUCCESS,
        SHOW_EATINERARY_FORM,
        HIDE_EATINERARY_FORM,
        SINGLE_ITEM_RECEIVED,
        ITEM_PATCH_SUCCESS,
        ACTIVITY_RECEIVED
    } = OEatinerary.Actions;

    const transformEatinerary = (state: TEatineraryState, action: any) => {
        action._sections = _.map(action._sections, (sec) => {
            sec.placeIds = _.map(sec._places, (p) => ({ name: p.name, id: p.id }));
            delete sec._places;
            return sec;
        });

        return { ...state, details: action };
    }

    console.log("count", action, OEatinerary.Actions);

    switch (action.type) {
        case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), details: { ...state.details, ...action.data, ...action.additionalDispatchData } }
        case SINGLE_ITEM_RECEIVED: return transformEatinerary(state, action.data);
        case LIST_RECEIVED: return { ...state, list: action.data }
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], { ...action.data, ...action.additionalDispatchData }, 'ADD') };
        case SHOW_EATINERARY_FORM: return { ...state, showMainForm: true, editedEatinerary: action.data || undefined }
        case HIDE_EATINERARY_FORM: return { ...state, showMainForm: false }
        case ACTIVITY_RECEIVED: {
            if (action.activity === OEatinerary.Activities.COUNT) {
                return { ...state, totalCount: action.data?.count }
            }
        }
        default: return state;
    }
}