import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { IFieldProps } from 'react-forms';
import get from 'lodash/get';
import TextFieldWMention, { TextFieldWMentionProps } from '.';

export interface RFTextFieldWMentionProps extends IFieldProps {
    fieldProps?: Pick<TextFieldWMentionProps, 'BoxProps' | 'fetchSuggestions' | 'renderSuggestion' | 'label'>
}

const RFTextFieldWMention: FC<RFTextFieldWMentionProps> = (props) => {
    const { formikProps, fieldConfig, fieldProps } = props;
    const classes = useStyles({});
    const key = fieldConfig?.valueKey || '-';
    const value = get(formikProps?.values, key) || ''

    const handleChange: TextFieldWMentionProps['handleChange'] = (e) => {
        if (!fieldConfig) return;

        formikProps?.setFieldValue(key, e.target.value);
    }

    return fieldProps ? <TextFieldWMention value={value} handleChange={handleChange} {...fieldProps} /> : null
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default RFTextFieldWMention;