import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TDispatch } from '../../Resources/GlobalTypes';
import utilities from '../../Resources/Utils';
import { TReduxStore } from '../../RootReducer';
import MainList from '../Dashboard/MainList';
import FormDialog from '../Dialogs/FormDialog';
import { TUser } from '../../Models/User/@types';
import { TUserState } from '../../Models/User/@types';
import { OUser } from '../../Models/User';

interface IProps {
    onFormSubmit: (newList: TUser[]) => Promise<void>;
    users: TUser[];
    heading: string;
    closeForm: () => void;
    open: boolean;
}

const UserForm: React.FC<IProps> = (props) => {
    const { onFormSubmit, users = [], closeForm, open, heading } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<TDispatch>();
    const [query, setQuery] = useState('');
    // const { list = [], totalCount = 0 } = useSelector<TReduxStore, Pick<TUserState, 'list' | 'totalCount'>>(({ User }) => ({
    //     list: User.list,
    //     totalCount: User.totalCount,
    // }));
    const [searchList, setSearchList] = useState<TUser[]>([]);
    const [newUserList, setNewUserList] = useState<TUser[]>(users);

    useEffect(() => {
        setNewUserList(users);
    }, [users]);

    const onSubmit = async () => {
        // onFormSubmit(newUserList);
        setLoading(true);
        await onFormSubmit(newUserList);
        setLoading(false);
    };

    console.log('query', query);
    const getUser = async () => {
        setLoading(true);
        try {
            const { data } = await OUser.requestGetItemsList({
                filter: {
                    order: 'name ASC',
                    limit: 20,
                    skip: 20 * (currentPage - 1),
                    fields: ['name', 'id', 'email'],
                },
            });
            setList(data);

            if (currentPage === 1) {
                const { data } = await OUser.requestGetActivity(OUser.Activities.COUNT);
                setTotal(data?.count || 0);
            }
        } catch (err) {
            console.log('Error', err);
        }
        setLoading(false);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getUser();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const handleClick = (item: TUser) => {
        const templist = utilities.toggleItemFromList(newUserList, item);
        setNewUserList(templist);
    };

    const handleSearchResult = (searchResult: TUser[], query: string) => {
        setQuery(query);
        setSearchList(searchResult);
    };

    const listItem = () => {
        return (query ? searchList : list).map((item, index) => {
            return (
                <ListItem button key={index} onClick={() => handleClick(item)}>
                    <ListItemText primary={item.name} secondary={item.email}></ListItemText>
                    <ListItemSecondaryAction>
                        <Checkbox
                            color="primary"
                            edge="end"
                            onClick={() => handleClick(item)}
                            checked={newUserList.findIndex((i) => i['id'] === item['id']) === -1 ? false : true}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });
    };

    return (
        <FormDialog
            heading={heading}
            onSubmit={onSubmit}
            onClose={closeForm}
            loading={loading}
            open={open}
            TransitionProps={{
                addEndListener(node, done) {
                    setQuery('');
                },
            }}
        >
            <MainList
                itemsCountPerPage={20}
                search
                totalItemsCount={total}
                onPageChange={handlePageChange}
                searchProps={{
                    type: 'user',
                    onResults: handleSearchResult,
                }}
                list={listItem()}
                isform={true}
            />
        </FormDialog>
    );
};

export default UserForm;
