export const SETTINGS_CITY_PAGE = {
    id: 'cityPageConfig',
    dishes: 'dishes',
    allPlaces: 'allPlaces',
    bookWeeksAhead: 'bookWeeksAhead',
    cuisines: 'cuisines',
    explore: 'explore',
    landmarks: 'landmarks',
    eatinerary: 'eatinerary',
}
export const SETTINGS_AUTH_SCREEN = {
    id: 'authScreenConfig',
    lgPicture: "lgPicture",
    smPicture: "smPicture"
}