import { Avatar, createStyles, Divider, Drawer, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TUser } from '../../../../Models/User/@types';

export interface LikedDrawerProps extends RouteComponentProps {
    closeDrawer: () => void
    list: TUser[]
    heading: string
}
export type DrawerSide = 'top' | 'left' | 'bottom' | 'right';
const LikedDrawer: FC<LikedDrawerProps> = (props) => {
    const classes = useStyles(props);
    const [open, setOpen] = useState(false)
    const { closeDrawer, list = [] } = props;
    useEffect(() => {
        setOpen(true);
    }, [])
    const toggleDrawer = (side: DrawerSide, open: boolean) => async (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open)
        closeDrawer();

    };
    const handleClick = (item: TUser) => {
        if (props.history)
            props.history.push(`/dashboard/users/${item.id}/details`)
    }
    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={toggleDrawer('right', false)}
        >
            <Paper style={{ width: 300, height: '100%', padding: 20 }}>
                <section className={classes.drawerHeader}>
                    <Typography component={'h3'} >{props.heading}</Typography>
                    <IconButton onClick={toggleDrawer('right', false)}>
                        <Icon >close</Icon>
                    </IconButton>
                </section>
                <section className={classes.suggesterList}>
                    <List>
                        {

                            (list.length > 0) ?
                                list.map((item, index) => {
                                    return (
                                        <>
                                            <ListItem button key={index} onClick={() => handleClick(item)}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={item._profile_image ? item._profile_image.url : 'https://cdn.accounts.autodesk.com/content/identity/28.0.341.9220642/z/Content/images/icons/user_X176.png'}
                                                        alt='Adelle Charles'
                                                        style={{ width: '40px', height: '40px' }}
                                                    >
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={item.name}>

                                                </ListItemText>

                                            </ListItem>
                                            <Divider className={classes.divider} />
                                        </>
                                    )
                                })
                                : null
                        }
                    </List>
                </section>
            </Paper>
        </Drawer>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        drawerHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& h3': { fontWeight: 'bold', fontSize: 14 }
        },
        suggesterList: {

        },
        suggesterItem: {
            width: '100%'
        },
        divider: {
            backgroundColor: theme.palette.grey['500'],
            margin: '5px 0'
        }
    }))
})

export default withRouter(LikedDrawer)