import React, { FC } from "react"
import { makeStyles, createStyles } from "@material-ui/styles"
import { Theme, Typography } from "@material-ui/core"
import moment from "moment"
import { TPlace, TTimePeriod } from "../../Models/Place/@types"

const DAY_OF_WEEK = {
    0: 'SUNDAY',
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: "FRIDAY",
    6: 'SATURDAY'
}

const OpeningDays: FC<Pick<TPlace, '_openingDays'>> = ({ _openingDays }) => {

    const classes = useStyles();

    let openingHours: Array<TTimePeriod[]> = [];


    (Array.from(Array(7).keys())).forEach(day => {
        let periods = _openingDays.periods
        const _openingHours = periods.filter(period => period.day === day);
        openingHours.push(_openingHours);
    })

    return (
        <div className={classes.openingHours}>
            {
                openingHours.map((openingHour, index) => (
                    <div key={index} className={classes.opening}>
                        <Typography variant={"body2"} >{DAY_OF_WEEK[index as keyof typeof DAY_OF_WEEK]}</Typography>
                        <div>
                            {
                                (openingHour || []).length > 0 ?
                                    openingHour.reverse().map((periods, i) => {
                                        const isValidStartDate = moment(periods.open, 'HHmm').isValid();
                                        const isValidEndDate = moment(periods.close, 'HHmm').isValid();
                                        return <Typography variant={'body2'} key={i} color="primary">
                                            {
                                                `${isValidStartDate && isValidEndDate ? moment(periods.open, 'HHmm').format('hh:mm A') + ' - ' + moment(periods.close, 'HHmm').format('hh:mm A') : '- - -'}`
                                            }
                                        </Typography>
                                    }) :
                                    <Typography variant={'body2'} color={'error'} >Closed</Typography>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    openingHours: {
        display: 'flex',
        width: 240,
        flexDirection: 'column'
    },
    opening: {
        padding: '6px 0px',
        borderBottom: `1px solid grey`,
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export default OpeningDays;