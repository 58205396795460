import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OCuisine } from '../../Models/Cuisines';
import { TCuisine, TCuisineState } from '../../Models/Cuisines/@types';
import { TDispatch } from '../../Resources/GlobalTypes';
import utilities from "../../Resources/Utils";
import { TReduxStore } from '../../RootReducer';
import MainList from '../Dashboard/MainList';
import FormDialog from '../Dialogs/FormDialog';
import get from 'lodash/get'
import { ALL_ENTITIES_DOC_TYPES } from '../../Constants/FilterConstants';


interface IProps {
    open: boolean
    title?: string
    entities?: string[] | null
    searchFilter?: Record<string, any>
    closeForm: () => void
    onFormSubmit: (newList: any[]) => Promise<void>
    items: any[]
    cardFieldGetterConfig?: Record<string, {
        title: (response: any) => string
        subtitle: (response: any) => string
        url?: (response: any) => string
    }>

}

// ['Cuisine', 'Establishment', 'Destination', 'FoodDrink', 'Landmark', 'List']
export const EntityResponseConfig: IProps['cardFieldGetterConfig'] = {
    'Cuisine': {
        title: (response: any) => response['name'],
        subtitle: (response: any) => `Cuisine`,
        url: (response: any) => `/dashboard/cuisines/${response.id}/details`
    },
    'Establishment': {
        title: (response: any) => response['name'],
        subtitle: (response: any) => `Place  ${response?.destination ? (' | ' + response?.destination?.name) : ''}`,
        url: (response: any) => `/dashboard/places/${response.id}/details`
    },
    'Destination': {
        title: (response: any) => response['name'],
        subtitle: (response: any) => `City`,
        url: (response: any) => `/dashboard/cities/${response.id}/details`
    },
    'FoodDrink': {
        title: (response: any) => response['name'],
        subtitle: (response: any) => `Dish`,
        url: (response: any) => `/dashboard/dishes/${response.id}/history`
    },
    'Landmark': {
        title: (response: any) => response['name'],
        subtitle: (response: any) => `Landmark ${response?.destination ? (' | ' + response?.destination?.name) : ''}`,
        url: (response: any) => `/dashboard/landmarks/${response.id}/history`
    },
    'List': {
        title: (response: any) => response['name'],
        subtitle: (response: any) => `Explore ${response?.destination ? (' | ' + response?.destination?.name) : ''}`,
        url: (response: any) => `/dashboard/explore/${response.id}/history`
    },
    'Eateries': {
        title: (response: any) => response['name'],
        subtitle: (response: any) => `Eatinerary ${response?.destination ? (' | ' + response?.destination?.name) : ''}`,
        url: (response: any) => `/dashboard/eatineraries/${response.id}/history`
    },
}
const EntityForm: React.FC<IProps> = (props) => {

    const { onFormSubmit, items = [], open = false, closeForm } = props
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const list: any[] = [], totalCount = 0;
    const dispatch = useDispatch<TDispatch>();
    const [newList, setNewList] = useState<any[]>(items);
    const [searchList, setSearchList] = useState<any[]>([]);
    const [query, setQuery] = useState('');

    useEffect(() => {
        setNewList(items)
    }, [items])

    const onSubmit = async () => {
        onFormSubmit(newList);
    }


    const handleSearchResult = (searchResult: any[], query: string) => {
        setQuery(query);
        setSearchList(searchResult)

    }
    const handlePageChange = (pagenumber: number) => {
        setCurrentPage(pagenumber)
    }

    const handleClick = (item: any) => {

        const templist = utilities.toggleItemFromList(newList, item);
        setNewList(templist)
    }

    const listItem = () => {
        return (query ? searchList : list).map((item, index) => {
            const getter = get(EntityResponseConfig, item.doc_type);

            return (<ListItem button key={index} onClick={() => handleClick(item)}>
                <ListItemText primary={getter?.title?.(item) || item.name} secondary={getter?.subtitle?.(item) || ''} >
                </ListItemText>
                <ListItemSecondaryAction>
                    <Checkbox
                        color='primary'
                        edge="end"
                        onClick={() => handleClick(item)}
                        checked={(newList.findIndex(i => i['id'] === item['id']) === -1) ? false : true}
                    />
                </ListItemSecondaryAction>
            </ListItem>)
        })
    }


    return (
        <div>
            <FormDialog
                heading={props.title ?? 'Add Entity'}
                open={open}
                onSubmit={onSubmit}
                onClose={closeForm}
                loading={loading}
            >
                <MainList
                    itemsCountPerPage={20}
                    search
                    totalItemsCount={totalCount}
                    onPageChange={handlePageChange}
                    searchProps={{
                        filters: props.searchFilter,
                        type: props.entities === null ? null : props.entities ?? ALL_ENTITIES_DOC_TYPES,
                        onResults: handleSearchResult,
                    }}
                    list={listItem()}
                    isform={true}
                />

            </FormDialog>
        </div>
    )
}



export default EntityForm