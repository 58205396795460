import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Typography, Theme } from '@material-ui/core';

// eslint-disable-next-line
interface Props extends WithStyles<typeof STYLES> { }

class Page404 extends Component<Props> {

    render() {
        // eslint-disable-next-line
        const { classes } = this.props
        return (
            <div>
                <Typography variant="h2">404: Page Not Found</Typography>
            </div>
        )
    }
}

const STYLES = (theme: Theme) => createStyles({

})

export default withStyles(STYLES)(Page404);