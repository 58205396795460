import LoopFront from 'loop-front';

export const CustomEntities = {
    FOODDRINKS: 'foodDrinks',
    ESTABLISHMENTS: 'establishments'
}
export const CustomActivity = {
    COUNT: 'count',
    PUBLISH: 'publish',
    DETAILS: 'details'
}
export const CustomActions = {
    SHOW_CUISINE_DETAIL_FORM: 'SHOW_CUISINE_DETAIL_FORM',
    HIDE_CUISINE_DETAIL_FORM: 'HIDE_CUISINE_DETAIL_FORM',
    PUT_DISH_TO_CUISINE_SUCCESS: 'PUT_FOODDRINK_TO_CUISINE_SUCCESS',
    PUT_ESTABLISHMENT_TO_CUISINE_SUCCESS: 'PUT_ESTABLISHMENT_TO_CUISINE_SUCCESS',
    DELETE_ESTABLISHMENT_FROM_CUISINE_SUCCESS: 'DELETE_ESTABLISHMENTS_FROM_CUISINE_SUCCESS',
    DELETE_DISH_FROM_CUISINE_SUCCESS: 'DELETE_FOODDRINKS_FROM_CUISINE_SUCCESS',
}
class Cuisines extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('cuisines', CustomActions, CustomEntities, CustomActivity);
    }
}

export const OCuisine = new Cuisines();