import React, { FC } from 'react'
import { withSize } from 'react-sizeme'

interface DashProps {
    color?: string
    dashGap?: number
    dashLength?: number
    rounded?: boolean
    dashThickness?: number
    direction?: 'row' | 'column'
    size: { height: number, width: number }
}

const Dash: FC<DashProps> = (props) => {

    const { color = '#7A7A7A', dashGap = 4, dashLength = 1, dashThickness = 1, direction = 'column', rounded = false, size } = props;
    const isRow = direction === 'row';
    const length = isRow ? size.width : size.height;
    const dots = Math.ceil(length / (dashGap + dashLength));

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: (isRow ? 'row' : 'column') }} >
            {
                (Array.from(Array(dots))).map((number, i) => {
                    return (
                        <div
                            key={i}
                            style={{
                                height: dashLength,
                                width: dashThickness,
                                borderRadius: (rounded ? '100%' : 0),
                                backgroundColor: color,
                                marginRight: (isRow ? dashGap : 0),
                                marginBottom: (isRow ? 0 : (number === dots - 1 ? 0 : dashGap))
                            }}
                        />
                    )
                })
            }
        </div>
    )
}

export default (withSize({ monitorHeight: true })(Dash));