import { Radio, Chip, createStyles, FormControlLabel, RadioGroup, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TAppState, TFilterConfig } from '../../Models/App/@types';
import { TCityState } from '../../Models/City/@types';
import { TPlaceFilterConfig } from '../../Models/Place/@types';
import { TReduxStore } from '../../RootReducer';
import CityChipList from './CityChipList';
import WORTHINESS from '../../Constants/worthiness.json';
// import { TEatineraryFilterConfig } from '../../Models/User/@types';


export type TEatineraryFilterConfig = Record<string, TFilterConfig[]>

export interface EatineraryFilterMenuProps {
    handleFilterChange: (newFilter: TEatineraryFilterConfig) => void
    filterConfig: TEatineraryFilterConfig
}

const EatineraryFilterMenu: FC<EatineraryFilterMenuProps> = (props) => {
    const classes = useStyles(props);
    const { filterConfig, handleFilterChange } = props

    const handleToggle = (key: string, item: TFilterConfig) => {
        const newFilter = { ...filterConfig }
        newFilter[key] = newFilter[key].map(i => {
            if (i.value && item.value) {
                return i.value === item.value ? { ...i, isActive: !i.isActive } : i
            }
            if (i.name === item.name) {
                return { ...i, isActive: !i.isActive }
            }
            return i
        })
        handleFilterChange({ ...newFilter })
    }
    const filterKeys = Object.keys(filterConfig);

    return (
        <div className={classes.root}>
            {filterKeys.map((key, index) => (
                <div className={classes.wrapper} key={key}>
                    {
                        filterConfig[key].map((item, index) => {
                            return (
                                <Chip
                                    label={item.name}
                                    key={index}
                                    size='small'
                                    className={classes.chip}
                                    onClick={() => { handleToggle(key, item) }}
                                    color={item.isActive ? 'primary' : 'default'}
                                />
                            )
                        })
                    }
                </div>
            ))}
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            width: '100%',
            padding: '5px',
            '& > div': {
                marginTop: 10
            }
        },
        wrapper: {
            paddingBottom: '20px',
            borderBottom: '1px Dashed #7E7E7E'
        },
        title: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: 'black',
            marginLeft: 4,
            marginBottom: 10
        },
        chip: {
            margin: '0px 4px 4px 0px',
            cursor: 'pointer',
        }
    }))
})

export default EatineraryFilterMenu