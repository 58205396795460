import React from 'react'
import { Box, Checkbox, createStyles, Divider, FormControlLabel, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import { OApp } from '../../../../Models/App';
import { OExplore } from '../../../../Models/Explore';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import NotesInput from '../../../../Components/Inputs/NotesInput';
import { TEatineraryState, TEatinerraies, TEatinerraiesSection } from '../../../../Models/Eatinerraies/@types';
import { OEatinerary } from '../../../../Models/Eatinerraies';
import ReorderableList from '../../../../Components/ReorderableList';
import SectionListItem from '../../Forms/SectionListContainer';
import dimensions from '../../../../Constants/dimensions';
import { renderHtmlStr } from '../../../../Features/Mention';
import TypographyWMention from '../../../../Components/TypographyWMention';
import { DATE_FORMAT } from '../../../../Constants/vars';


interface IProps { }

const Details: React.FC<IProps> = (props) => {

    const history = useHistory();
    const dispatch = useDispatch<TDispatch>();
    const classes = useStyles();
    const { details } = useSelector<TReduxStore, Pick<TEatineraryState, 'details'>>(({ Eatinerary: { details } }) => ({ details }));
    const handleEdit = () => {
        dispatch({ type: OEatinerary.Actions.SHOW_EATINERARY_FORM, data: details })
    }

    const setField = async (field: keyof TEatinerraies, value: any) => {
        try {
            if (details)
                await dispatch(OEatinerary.patchItem(details.id!, { [field]: value }, undefined, undefined, { [field]: value }))
        } catch (error) {

        }
    }


    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(OEatinerary.deleteItem(details.id, {}, undefined, details))
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
                // dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'List' } })
            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }))
            }
            if (history) {
                history.push('/dashboard/eatineraries')
            }
        }
    }

    const handlePublish = () => {
        if (!details) return;
        try {
            const flag = !!!details.isPublished;
            dispatch(OEatinerary.togglePublish(details.id, flag))
            dispatch(OApp.showToast({ message: flag ? 'Published!' : "Unpublished!", variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Failed!', variant: 'error' }))

        }
    }

    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit this List.',
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this Eatinerary?',
            handleDelete: handleDelete,
            helpText: 'Delete this List.',
        },
        {
            type: 'icon',
            icon: (details && details.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (details && details.isPublished) ? 'secondary' : 'primary',
            helpText: (details && details.isPublished) ? 'Unpublish' : 'Publish',
        },
    ]


    const onReOrder = async (nexList: TEatinerraiesSection[], movedItem: TEatinerraiesSection, oldIndex: number, newIndex: number) => {

        const tSections = [...(details?._sections ? details?._sections : [])]
        var data = tSections[oldIndex];
        tSections.splice(oldIndex, 1);
        tSections.splice(newIndex, 0, data);
        dispatch({ type: OEatinerary.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _sections: tSections }, additionalDispatchData: { _sections: tSections } })
        await dispatch(OEatinerary.patchItem(details ? details?.id : "", { _sections: tSections }, {}, undefined, { _sections: tSections }))
    }

    const renderItem = (item: TEatinerraiesSection, dragHandleProps: any, dragged: boolean) => {
        return (<><SectionListItem section={item} OnDeleteSection={OnDeleteSection} dragHandleProps={dragHandleProps}></SectionListItem></>);
    }

    const OnDeleteSection = async (id: string) => {
        let tSections = [...(details?._sections ? details?._sections : [])]
        console.log(tSections, "Tsectionss", id);
        tSections = [...tSections.filter((i) => i.id !== id)];
        console.log(tSections, "Tsectionss", id);
        dispatch({ type: OEatinerary.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _sections: tSections }, additionalDispatchData: { _sections: tSections } })
        await dispatch(OEatinerary.patchItem(details ? details?.id : "", { _sections: tSections }, {}, undefined, { _sections: tSections }))
    };

    return (
        <div className={classes.foodDrinkDetail}>
            {
                details &&
                <>

                    <HeaderActionButtons items={headerItems} />
                    <Paper className={classes.paper}>
                        {/* <Divider style={{ margin: '16px 0' }} /> */}
                        <Box display='flex'>
                            <Typography variant="overline">{`Created - ` + moment(details.created).format(DATE_FORMAT) + ` Modified - ` + moment(details.updated).format(DATE_FORMAT)}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='column' my={2} ml={0.5}>
                            <FormControlLabel
                                onClick={e => setField('newlyAdded', !details?.newlyAdded)}
                                label='Newly added'
                                control={<Checkbox color='primary' checked={!!details?.newlyAdded} />}
                            />
                            <FormControlLabel
                                onClick={e => setField('recentlyUpdated', !details?.recentlyUpdated)}
                                label='Recently updated'
                                control={<Checkbox color='primary' checked={!!details?.recentlyUpdated} />}
                            />
                        </Box>
                        <Divider style={{ margin: 0, marginBottom: 24 }} />
                        <div>
                            <Typography variant="h4">{details.name}</Typography>
                            <Typography variant="subtitle1" style={{ paddingTop: '10px' }} >{details.destination.name}</Typography>
                        </div>
                        {details.description ?
                            <> <Divider style={{ margin: '16px 0' }} />
                                <Typography variant={'subtitle1'} >Description</Typography>
                                <TypographyWMention renderMentionHTMLStr={renderHtmlStr} variant="body2">{details.description || ''}</TypographyWMention>
                            </>
                            : null}


                    </Paper>
                    <Box mt={2}>
                        <ReorderableList<TEatinerraiesSection>
                            list={details._sections ? details._sections : []}
                            renderItem={renderItem}
                            handleUpdateListOrder={onReOrder}
                        />
                    </Box>
                </>
            }
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    foodDrinkDetail: {
        position: 'relative',
        margin: '0px 40px',
    },
    paper: {
        width: dimensions.screenMaxWidth,
        margin: 'auto',
        padding: '30px',
        marginTop: 20
    },
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            margin: '0 10px'
        }
    },
}))

export default Details


