import { Radio, Chip, createStyles, FormControlLabel, RadioGroup, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TAppState, TFilterConfig } from '../../Models/App/@types';
import { TCityState } from '../../Models/City/@types';
import { TPlaceFilterConfig } from '../../Models/Place/@types';
import { TReduxStore } from '../../RootReducer';
import CityChipList from './CityChipList';
import WORTHINESS from '../../Constants/worthiness.json';

export interface FilterListMenuProps {
    toggleRating: (rating: number) => void
    toggleStatus: (item: string) => void
    toggleCity: (item: string) => void
    toggleCheck: (item: TFilterConfig) => void
    filterConfig: TPlaceFilterConfig
}

const FilterListMenu: FC<FilterListMenuProps> = (props) => {
    const classes = useStyles(props);
    const { constants } = useSelector<TReduxStore, Pick<TAppState, 'constants'>>(({ App }) => ({ constants: App.constants }))
    const { filterConfig, toggleRating, toggleCheck, toggleStatus, toggleCity } = props
    const { list = [] } = useSelector<TReduxStore, Pick<TCityState, 'list'>>(({ City }) => ({ list: City.list }))
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Typography className={classes.title} >Status</Typography>
                {
                    (constants && constants.Establishment && constants.Establishment.status) ?
                        constants.Establishment.status.map((item, index) => {
                            return (
                                <Chip
                                    label={item.name}
                                    key={index}
                                    size='small'
                                    className={classes.chip}
                                    onClick={() => { toggleStatus(item.value) }}
                                    color={filterConfig.status.findIndex(i => i === item.value) > -1 ? 'primary' : 'default'}
                                />
                            )
                        }) : null
                }
            </div>
            <div className={classes.wrapper}>
                <Typography className={classes.title} >Mamakoo Worthiness Index</Typography>
                {
                    WORTHINESS.map((item, index) => {
                        return (
                            <Chip
                                label={item.text}
                                key={index}
                                size='small'
                                className={classes.chip}
                                onClick={() => { toggleRating(item.rating) }}
                                color={filterConfig.ratings.findIndex(i => i === item.rating) > -1 ? 'primary' : 'default'}
                            />
                        )
                    })
                }
            </div>
            <div className={classes.wrapper}>
                <CityChipList selectedList={filterConfig.cityIds} list={list} onClick={toggleCity} />
            </div>
            <div className={classes.wrapper}>
                <RadioGroup style={{ paddingLeft: 15 }} onChange={(e) => toggleCheck(filterConfig.baseConfig.find(i => i.name === e.target.value)!)} >
                    {
                        filterConfig.baseConfig.map((item, index) => {
                            return (
                                <FormControlLabel
                                    style={{ height: 30 }}
                                    key={index}
                                    value={item.name}
                                    control={
                                        <Radio
                                            checked={item.isActive}
                                            color="primary"
                                        />
                                    }

                                    label={item.name}
                                />
                                // </FormControlLabel>
                            )
                        })
                    }
                </RadioGroup>
            </div>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            width: '100%',
            padding: '5px',
            '& > div': {
                marginTop: 10
            }
        },
        wrapper: {
            paddingBottom: '20px',
            borderBottom: '1px Dashed #7E7E7E'
        },
        title: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: 'black',
            marginLeft: 4,
            marginBottom: 10
        },
        chip: {
            margin: '0px 4px 4px 0px',
            cursor: 'pointer',
        }
    }))
})

export default FilterListMenu