import { Box, Checkbox, createStyles, FormControlLabel, Grid, Paper, Radio, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { TextValidator as TextField } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import TextFieldWMention, { TextFieldWMentionProps } from '../../../../Components/Inputs/TextFieldWMention';
import { textFieldWMentionHelpers } from '../../../../Components/Inputs/TextFieldWMention/helpers';
import { TPlace } from '../../../../Models/Place/@types';
import { TSettingsState } from '../../../../Models/Settings/@types';
import { TReduxStore } from '../../../../RootReducer';
import { STATE_UPDATE, UPDATE } from './Reducer';
export interface BasicInfoSectionProps {

    dispatchReducer: React.Dispatch<{
        type: string,
        name: string;
        data: any;
    }>
    state: Partial<TPlace>
}


export type BasicInfoSectionFormType = Pick<TPlace, 'name' | 'phone' | 'website' | 'instagramLink' | 'reservationLink' | 'description' | 'range' | 'types' | 'takeoutAndDelivery' | 'shortDescription' | 'place_email'>
const BasicInfoSection: FC<BasicInfoSectionProps> = (props) => {

    const { dispatchReducer, state } = props;
    const classes = useStyles();
    const { list = [] } = useSelector<TReduxStore, Pick<TSettingsState, 'list'>>(({ Settings: { list } }) => ({ list }));
    let placeTypes = list.filter(i => i.key === 'establishmentPlaceType')[0]?.value || []
    // const { constants } = useSelector<TReduxStore, Pick<TAppState, 'constants'>>(({ App }) => ({ constants: App.constants }));
    // let placeTypes = constants ? constants.Establishment.types : [];
    const [basicInfo, setBasicInfo] = useState<BasicInfoSectionFormType>({ name: '', phone: '', website: '', reservationLink: '', description: '', instagramLink: '', range: undefined, types: [], takeoutAndDelivery: '', place_email: '' });

    const handleChange = (name: keyof BasicInfoSectionFormType, value: string | string[] | number | boolean) => {
        setBasicInfo({ ...basicInfo, [name]: value })
        dispatchReducer({ type: UPDATE, data: value, name })
    };
    const handleBlur = () => {
        dispatchReducer({ type: STATE_UPDATE, data: basicInfo, name: '' })
    }
    const handleTypeChange = (item: string) => {
        let types = basicInfo.types || [];
        let itemIndex = types.indexOf(item);
        itemIndex > -1 ? types.splice(itemIndex, 1) : types.push(item);
        handleChange('types', types);
    }
    useEffect(() => {
        if (state) {

            setBasicInfo({
                ...basicInfo,
                instagramLink: state.instagramLink || '',
                name: state.name || '',
                website: state.website || '',
                phone: state.phone || '',
                range: state.range || 0,
                shortDescription: state.shortDescription || '',
                description: state.description || '',
                reservationLink: state.reservationLink || '',
                types: state.types || [],
                takeoutAndDelivery: state.takeoutAndDelivery || '',
                place_email: state.place_email || ''
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])


    const handleDescriptionChange: (name: 'shortDescription' | 'description') => TextFieldWMentionProps['handleChange'] = (name) => (e, newValue, newValueText) => {
        handleChange(name, e.target.value);
    }

    return (
        <Box width={800} mt={5} >
            <Paper className={classes.root} >
                <Grid container className={classes.containerGrid} spacing={3} >
                    {
                        ([
                            { name: 'name', label: 'Name of the Place', value: basicInfo.name, fullWidth: true },
                            { name: 'phone', label: 'Contact Number', value: basicInfo.phone, fullWidth: false },
                            { name: 'reservationLink', label: 'Reservation Link', value: basicInfo.reservationLink, fullWidth: false },
                            { name: 'takeoutAndDelivery', label: 'Takeout & Delivery', value: basicInfo.takeoutAndDelivery, fullWidth: false },
                            { name: 'website', label: 'Website', value: basicInfo.website, fullWidth: false },
                            { name: 'place_email', label: 'Email', value: basicInfo.place_email, fullWidth: false },
                            { name: 'instagramLink', label: 'Instagram Link', value: basicInfo.instagramLink, fullWidth: false },
                            // { name: 'shortDescription', label: 'Short Description', value: basicInfo.shortDescription, fullWidth: true, multiline: true },
                            // { name: 'description', label: 'Description', value: basicInfo.description, fullWidth: true, multiline: true },
                        ] as { name: keyof BasicInfoSectionFormType, label: string, value: string, fullWidth: boolean, multiline?: boolean }[]).map((item, i) => {
                            var validator: string[] = item.name === 'name' ? ['required'] : []
                            var error: string[] = item.name === 'name' ? ['This field is required'] : []
                            return (
                                <Grid md={item.fullWidth ? 12 : 6} item key={i} >
                                    <TextField
                                        label={item.label}
                                        fullWidth
                                        autoComplete='none'
                                        validators={validator}
                                        errorMessages={error}
                                        color='primary'
                                        name={item.name}
                                        value={item.value || ''}
                                        multiline={item.multiline}
                                        onBlur={handleBlur}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(item.name, e.target['value'])}
                                    />
                                </Grid>
                            )
                        })
                    }

                    <Grid md={12} item  >
                        <TextFieldWMention
                            label="Short Description"
                            fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                            value={basicInfo.shortDescription || ''}
                            handleChange={handleDescriptionChange('shortDescription')}
                        />
                    </Grid>
                    <Grid md={12} item  >
                        <TextFieldWMention
                            label="Description"
                            fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                            value={basicInfo.description || ''}
                            handleChange={handleDescriptionChange('description')}
                        />
                    </Grid>
                    <Grid md={6} item >
                        <Box mt={2} ><Typography>Place Type</Typography></Box>
                        {
                            placeTypes.length
                                ?
                                placeTypes.map((item, i) => {
                                    return (
                                        <Box key={i} >
                                            <FormControlLabel
                                                label={item.name}

                                                control={
                                                    <Checkbox
                                                        onBlur={handleBlur}
                                                        checked={basicInfo.types.indexOf(item.name) > -1}
                                                        onChange={() => handleTypeChange(item.name)}
                                                    />
                                                }
                                            />
                                        </Box>
                                    )
                                })
                                : null
                        }
                    </Grid>
                    <Grid md={6} item >
                        <Box mt={2} ><Typography>$ Range</Typography></Box>
                        {
                            [1, 2, 3, 4].map((item, i) => {
                                return (
                                    <Box key={i} >
                                        <FormControlLabel
                                            label={"$".repeat(item)}
                                            control={
                                                <Radio
                                                    checked={basicInfo.range === item}
                                                    className={classes.checkbox}
                                                    onBlur={handleBlur}
                                                    onChange={() => handleChange('range', item)}
                                                />
                                            }
                                        />
                                    </Box>
                                )
                            })
                        }
                    </Grid>

                </Grid>
            </Paper>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            padding: '40px 32px 0px'
        },
        containerGrid: {

        }
    }))
})

export default BasicInfoSection

/*// types : info.types ?
                //             info.types.filter( i =>
                //                             { if(placeTypes.indexOf( i.charAt(0).toUpperCase()+i.slice(1)) > -1)
                //                                 {
                //                                     console.log(i.charAt(0).toUpperCase()+i.slice(1))
                //                                     return (i.charAt(0).toUpperCase()+i.slice(1))}
                //                             }) : [] })
                 */