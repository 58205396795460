import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Fab, Theme, Tooltip } from '@material-ui/core';
import { OApp } from '../../Models/App';
import { useTheme } from '@material-ui/styles';



const CopyButton: FC<{ text: string, backgroundColor?: string, iconColor?: string}> = ({ text, backgroundColor, iconColor, }) => {

    const dispatch = useDispatch();

    const copy = () => {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        console.log("ad")
        dispatch(OApp.showToast({ message: 'Link Copied!', variant: 'success' }));
    }

    const theme = useTheme<Theme>();

    return (
        <div>
            <Tooltip title="Copy link to this page">
            <Fab
                style={{
                    backgroundColor: backgroundColor || theme.palette.primary.main,
                    color: iconColor || 'black',
                    boxShadow: 'none'
                }}
                onClick={copy}
                size={'small'}
            ><i className="material-icons">link</i>
            </Fab>
            </Tooltip>
        </div>
    )
}

export default CopyButton;
