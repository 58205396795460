import { CircularProgress, ListItemText, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import Axios, { CancelTokenSource } from 'axios';
import LoopFront from 'loop-front';
import React, { useState } from 'react';
import debounce from 'lodash/debounce';

export interface NewEntitySuggestProps<T> {
    type: string | string[] | null
    filters?: any;
    onResults?: (results: Array<T>, query: string) => void;
    placeholder: string;
    clearOnSelection?: boolean;
    onSelection?: (suggestion: T) => void;
    textFieldProps?: TextFieldProps;
}

const NewEntitySuggest = <T extends Record<string, any> | { name?: string }>(props: NewEntitySuggestProps<T>) => {
    const classes = useStyles();
    const [options, setOptions] = useState<T[]>([]);
    const [query, setQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const onSearchResults = (results: Array<T>, queryTerm: string) => {
        if (typeof props.onResults === 'function') {
            props.onResults(results || [], queryTerm);
            return;
        }
        setOptions(results || []);
    };
    const { textFieldProps } = props;

    const _onChange = async (newQuery: string) => {
        setQuery(newQuery);
        if (!newQuery) {
            onSearchResults([], '');
            return;
        }
        setLoading(true);
        const { type, filters = {} } = props;
        const res = await LoopFront.request({
            url: `users/admin-autocomplete`,
            params: {
                term: newQuery,
                filter: {
                    doc_type: type ?? undefined,
                    limit: 30,
                    ...filters,
                },
            },
        }).catch((error) => {
            console.log('AutoSuggest Error', error);
            setLoading(false);
            throw error;
        });
        setLoading(false);
        onSearchResults(res.data, newQuery);
    };
    const onChange = debounce(_onChange, 500);

    const onSuggestionSelected = (suggestion: T) => {
        const { clearOnSelection = true } = props;
        if (typeof props.onSelection === 'function') {
            props.onSelection(suggestion);
        }
        if (clearOnSelection) {
            setQuery('');
        }
    };
    const getSuggestionValue = (suggestion: T) => suggestion.name;

    const renderSuggestion = (suggestion: T) => {
        return <Typography variant="body1">{getSuggestionValue(suggestion)}</Typography>;
    };

    return (
        <Autocomplete<T, false, true, true>
            options={options}
            fullWidth
            freeSolo={true}
            onInputChange={(_a, v) => onChange(v)}
            getOptionLabel={getSuggestionValue}
            renderOption={(option) => <ListItemText onClick={() => onSuggestionSelected(option)}>{renderSuggestion(option)}</ListItemText>}
            className={classes.base}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...textFieldProps}
                    inputProps={{
                        style: {
                            padding: '6px 4px',
                        },
                        ...params.inputProps,
                        ...textFieldProps?.inputProps,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        ...textFieldProps?.InputProps,
                        classes: {
                            root: classes.root,
                            notchedOutline: classes.input,
                        },
                        startAdornment: <i className="material-icons">search</i>,
                        endAdornment: loading ? (
                            <CircularProgress size={24} />
                        ) : query ? (
                            params.InputProps.endAdornment || textFieldProps?.InputProps?.endAdornment || null
                        ) : null,
                    }}
                    placeholder={props.placeholder}
                    fullWidth
                    value={query}
                    variant="outlined"
                />
            )}
        />
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        base: {
            borderBottom: `1px solid ${theme.palette.grey['100']}`,
            borderRadius: 0,
            '& > *': {
                boxShadow: 'none !important',
                border: 'none !important',
            },
        },
        root: {
            paddingRight: `9px !important`,
        },
        input: {
            boxShadow: 'none',
            border: 'none',
            borderRadius: 0,
        },
        clearIcon: {
            padding: 4,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
                backgroudColor: 'rgba(0, 0, 0, 0.04)',
            },
            height: 'auto',
            width: 'auto',
            minHeight: 0,
        },
        cross: {
            fontSize: 18,
        },
    });
});

export default NewEntitySuggest;
