import React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../../RootReducer';
import { TCityState } from '../../../../Models/City/@types';
import ReorderableList from '../../../../Components/ReorderableList';
import { TExplore } from '../../../../Models/Explore/@types';
import ReorderableItem from '../../../../Components/Cards/ReorderableItem';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { reorderDestinationEntity } from '../../../../Models/City/actions';
import { useHistory } from 'react-router';
import { OApp } from '../../../../Models/App';
import { OExplore } from '../../../../Models/Explore';
import { OCity } from '../../../../Models/City';

interface IProps { }

const Explore: React.FC<IProps> = (props) => {

    const classes = useStyles(props)
    const history = useHistory();
    const dispatch = useDispatch<TDispatch>()
    const { details } = useSelector<TReduxStore, Pick<TCityState, 'details'>>(({ City }) => ({ details: City.details }));
    const list = details?.lists || [];
    const onReorder = (nexList: TExplore[], movedItem: TExplore, oldIndex: number, newIndex: number) => {
        if (details?.id)
            dispatch(reorderDestinationEntity(details.id, 'lists', movedItem.id, newIndex, { ...details, lists: [...nexList] }))
    }
    const onDelete = async (itemId: string) => {
        if (details) {

            try {
                await OExplore.requestDeleteItem(itemId);
                dispatch({ type: OCity.Actions.ITEM_PATCH_SUCCESS, data: { ...details, lists: list.filter(i => i.id !== itemId), counts: { ...details.counts, Explore: list.length - 1 } } })
                dispatch(OApp.showToast({ message: 'Item Deleted Successfully', variant: 'success' }))
            } catch (err) {
                console.log(err)
                dispatch(OApp.showToast({ message: 'Item Delete Unsuccessful', variant: 'error' }))
            }
        }
    }
    const onView = (itemId: string) => {
        history.push(`/dashboard/explore/${itemId}/details`)
    }
    const renderItem = (item: TExplore, dragHandleProps: any) => {
        return (
            <ReorderableItem
                // onDelete={() => onDelete(item.id)}
                onViewDetails={() => onView(item.id)}
                title={item.name}
                isPublished={item.isPublished}
                logo={item._pictures[0]}
                draghandleProps={dragHandleProps}
            />
        )
    }
    return (
        <Box className={classes.listContainer}>
            <ReorderableList<TExplore>
                list={list}
                handleUpdateListOrder={onReorder}
                renderItem={renderItem}
            />
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    listContainer: {
        position: 'relative',
        margin: '0px auto',
        width: 300,
    }
}))

export default Explore


