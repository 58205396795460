import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import EntityCard from '../../../../Features/Home/EntityCard';
import HeaderActionButtons from '../../../../Components/HeaderActionButtons';
import ReorderableList from '../../../../Components/ReorderableList';
import EntityForm from '../../../../Components/Forms/EntityForm';
import DishForm, { HomeDishFormData } from '../../Forms/DishForm';
import { THomeSectionState } from '../../../../Models/HomeSection/@types';
import { TReduxStore } from '../../../../RootReducer';
import { OHomeSection } from '../../../../Models/HomeSection';
import TitleFormField from '../../Forms/TitleFormField';
import LockIcon from '../../../../Components/LockIcon';

export interface DishProps {

}

export const DISH_SLUG = 'dish';


function transformListToDishFormData(list: any[]): HomeDishFormData | {} {
    if (!list.length) return {};

    const city = list?.[0]?.destination;

    return {
        city,
        dishes: list
    }
}
const Dish: FC<DishProps> = (props) => {
    const classes = useStyles({});
    const [showForm, setShowForm] = useState(false);
    const [list, setList] = useState<any[]>([]);
    // const [editedData, setEditedData] = useState<HomeDishFormData>();
    const dispatch = useDispatch();
    const { sections, initialized, dishList } = useSelector<TReduxStore, Pick<THomeSectionState, 'sections' | 'initialized' | 'dishList'>>((
        { Home: { sections, initialized, dishList } }
    ) => ({ sections, initialized, dishList }))
    const section = sections.find(s => s.slug === DISH_SLUG);


    useEffect(() => {
        if (initialized) {
            if (!section) return;
            dispatch(OHomeSection.getEntityByItem(section.id, OHomeSection.Entities.sectionEntity, { filter: { include: ['subject', { relation: 'destination', scope: { fields: ['name', 'id'] } }] } }, undefined, { slug: section.slug }))
        }
    }, [initialized])

    useEffect(() => {
        setList(dishList.map(l => ({ doc_type: l.subjectType, sectionId: l.id, destination: l.destination, ...l.subject })))
    }, [dishList])


    const handleEdit = () => {
        setShowForm(true);
    }

    const headerItems = [
        {
            type: 'icon',
            icon: 'edit',
            onClick: handleEdit,
            helpText: 'Edit Dish',
        }
    ]


    const handleSave = async (formData: HomeDishFormData) => {
        if (!section) return;
        const data = formData.dishes.map(d => ({ destination: formData.city, doc_type: 'FoodDrink', ...d }))
        dispatch(OHomeSection.updateSections(section.id, list, data))
        setList(data);
        setShowForm(false);
    }

    const handleReorder = (updatedList: any[], movedItem: any) => {
        if (!section) return;
        const newOrder = updatedList.findIndex(x => x.id === movedItem.id);
        if (newOrder === -1) return;
        dispatch(OHomeSection.reorderItem(section.id, movedItem.sectionId, newOrder + 1))
        setList(updatedList);
    }

    const handleRemove = (entitySectionId: string) => {
        if (!section) return;
        dispatch(OHomeSection.addRemove(section.id, [], [entitySectionId]))
    }


    const renderList = (item: any, draghandleProps: any) => {
        return (
            <EntityCard
                imgUrl={item._pictures?.[0]?.url}
                subtitle={item.destination?.name ?? ''}
                title={item.name}
                key={item.id}
                actionComponent={item.isPublished ? null : <LockIcon />}
                draghandleProps={draghandleProps}
                menuProps={{
                    id: item.id,
                    items: [
                        { label: 'remove', onClick: () => handleRemove(item.sectionId) }
                    ]
                }}
            />
        )
    }

    return (
        <Box className={classes.root}>
            <Box position="sticky" top={0}>
                <HeaderActionButtons items={headerItems} />
            </Box>
            {section ? (
                <TitleFormField section={section} />
            ) : null}
            <Box my={5}>
                <ReorderableList<any>
                    handleUpdateListOrder={handleReorder}
                    list={list}
                    renderItem={renderList}
                />
            </Box>
            <DishForm
                onFormSubmit={handleSave}
                closeForm={() => setShowForm(false)}
                loading={false}
                initialValues={transformListToDishFormData(list)}
                open={showForm}
                title={'Add Dishes'}
            />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.sm,
        margin: '0 auto',
        paddingBottom: 100
    },
}));

export default Dish;