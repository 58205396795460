import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { TDish } from '../../../Models/Dish/@types'
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog'
import ItemCard from '../ItemCard'


interface IProps extends RouteComponentProps<{ id: string }> {
    item: TDish
    deleteItem: (item: TDish) => Promise<void>
    showCheckBoxToMakeIconic?: boolean
    isIconic?: boolean
    handleCheckboxToggle?: (item: any) => void
}

const DishesCard: React.FC<IProps> = (props) => {
    const { item, history, deleteItem } = props
    const [dialog, toggleDialog] = useState(false)

    const handleView = () => {

        history.push('/dashboard/dishes/' + item.id + '/history')
    }
    const handleDelete = () => {
        toggleDialog(!dialog);
    }
    const handleDialogDelete = () => {
        deleteItem(item);
        toggleDialog(!dialog)
    }
    const menuOption = [{ text: 'View Details', onClick: handleView },
    { text: 'Delete ', onClick: handleDelete }
    ]

    return (
        <div>
            {
                props.showCheckBoxToMakeIconic && props.handleCheckboxToggle ?
                    <ItemCard
                        title={item.name}
                        published={item.isPublished}
                        checkboxText="Make this dish Iconic"
                        onCheckboxChange={props.handleCheckboxToggle}
                        options={menuOption}
                        isChecked={props.isIconic}
                    />
                    :
                    <ItemCard
                        title={item.name}
                        published={item.isPublished}
                        options={menuOption}
                    />
            }
            <ConfirmationDialog
                open={dialog}
                onAgree={handleDialogDelete}
                onDisagree={handleDelete}
                message={"Are you sure you want to delete?"}
                onClose={handleDelete}
            />
        </div>
    )
}



export default withRouter(DishesCard)