import LoopFront from "loop-front";
import { TDispatch } from "../../Resources/GlobalTypes";

export const CustomEntities = {

}
export const CustomActivity = {

}

export const CustomActions = {
    FETCH_ALL_PACKS: 'FETCH_ALL_PACKS',
    FETCH_ALL_PACKS_SUCCESS: 'FETCH_ALL_PACKS_SUCCESS',

}

class Pack extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity>{
    constructor() {
        super('packs', CustomActions, CustomEntities, CustomActivity);
    }

    getPacks = () => async (dispatch: TDispatch) => {
        dispatch({ type: this.Actions.FETCH_ALL_PACKS });
        const res = await LoopFront.request({
            url: `/packs/get-packs`
        })
        dispatch({ type: this.Actions.FETCH_ALL_PACKS_SUCCESS, data: res.data });
    }
}

export const OPack = new Pack();