import LoopFront from "loop-front";

export const CustomEntities = {

}
export const CustomActivity = {

}

export const CustomActions = {
    SHOW_TAG_CATEGORY_FORM: 'SHOW_TAG_CATEGORY_FORM',
    HIDE_TAG_CATEGORY_FORM: 'HIDE_TAG_CATEGORY_FORM',
    SHOW_TAG_FORM: 'SHOW_TAG_FORM',
    HIDE_TAG_FORM: 'HIDE_TAG_FORM',
    REORDER_TAGS_LIST: 'REORDER_TAGS_LIST',
    PATCH_TAG_SUCCESS: 'PATCH_TAG_SUCCESS',
    TAG_CATEGORY_DELETE_SUCCESS: 'TAG_CATEGORY_DELETE_SUCCESS',
    MULTIPLE_TAG_DELETE_SUCCESS: 'MULTIPLE_TAG_DELETE_SUCCESS'
}
class TagCategories extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity>{
    constructor() {
        super('TagCategories', CustomActions, CustomEntities, CustomActivity);
    }
}

export const OTagCategories = new TagCategories();