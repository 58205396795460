import React, { FC, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import { generatePath, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserListItems } from '../../../../Models/User/actions';
import { Link } from 'react-router-dom';
import { USER_SAVE_LIST_BASE_URL } from './SavedListRouter';
import { TReduxStore } from '../../../../RootReducer';
import { TUserState } from '../../../../Models/User/@types';
import EntityCard from '../../../../Features/Home/EntityCard';
import { EntityResponseConfig } from '../../../../Components/Forms/EntityForm';
import { OUser } from '../../../../Models/User';

export interface ListProps {

}

const List: FC<ListProps> = (props) => {
    const classes = useStyles({});
    const { selectedUserSavedList, selectedUserSavedListItems } = useSelector<TReduxStore, Pick<TUserState, 'selectedUserSavedList' | 'selectedUserSavedListItems'>>(({ User: { selectedUserSavedList, selectedUserSavedListItems } }) => ({ selectedUserSavedList, selectedUserSavedListItems }))
    const { userListId, userId } = useParams<{ userListId: string, userId: string }>();
    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(getUserListItems(userListId, { include: 'subject' }));
        return () => {
            dispatch({ type: OUser.Actions.CLEAR_USER_SAVED_LIST_ITEMS });
        }
    }, [])


    return (
        <Box>
            <Breadcrumbs>
                <Link to={generatePath(USER_SAVE_LIST_BASE_URL, { userId })}>Saved Lists</Link>
                <Typography color='primary'>{selectedUserSavedList?.name}</Typography>
            </Breadcrumbs>
            <Box my={2}>
                {selectedUserSavedListItems.map(userListItem => (
                    <Box my={1} key={userListItem.id}>
                        <EntityCard
                            title={EntityResponseConfig ? EntityResponseConfig[userListItem.subjectType].title(userListItem.subject) : ''}
                            subtitle={EntityResponseConfig ? EntityResponseConfig[userListItem.subjectType].subtitle(userListItem.subject) : ''}
                            imgUrl={userListItem.subject?._pictures?.[0]?.url}
                            url={EntityResponseConfig ? EntityResponseConfig[userListItem.subjectType].url?.(userListItem.subject) : ''}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default List;

