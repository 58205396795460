import { Box, createStyles, Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import EntityImages from '../../../../Components/EntityImages';
import Gallery from '../../../../Components/Gallery';
import ImageCarousel from '../../../../Components/ImageCarousel';
import ImageContainer from '../../../../Components/ImageContainer';
import ImageLoader from '../../../../Components/ImageLoader';
import LottieContainer from '../../../../Components/LottieContainer';
import { OApp } from '../../../../Models/App';
import { pictureOrientations } from '../../../../Models/App/@types';
import { changePictureCaption, deletePictureFromItem } from '../../../../Models/App/actions';
import { OCity } from '../../../../Models/City';
import { TCity, TCityState } from '../../../../Models/City/@types';
import { animationDimention, TDispatch, TPicture } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import AnimationForm from './AnimationForm';

export interface indexProps extends RouteComponentProps {}

const Images: FC<indexProps> = (props) => {
    const classes = useStyles();
    const { details = {} as TCity } = useSelector<TReduxStore, Pick<TCityState, 'details'>>(({ City }) => ({ details: City.details }));
    const dispatch = useDispatch<TDispatch>();
    const [showForm, setShowForm] = useState(false);
    const [animatedImage, setAnimatedImage] = useState<TPicture | undefined>(undefined);
    const [loadingCount, setLoadingCount] = useState(0);
    const [pictureList, setPictureList] = useState<TPicture[]>([]);
    const [showCorousel, setShowCorousel] = useState(false);
    const [uploadedPicture, setUploadedPicture] = useState<TPicture>();
    const [current, setCurrent] = useState<number>(0);
    const [isJson, setIsJson] = useState(false);
    const toggleJson = () => {
        setIsJson(!isJson);
    };
    const toggleCorousel = (id: string = '') => {
        if (!showCorousel) {
            const index = pictureList.findIndex((i) => i.id === id);
            setCurrent(index);
        }
        setShowCorousel(!showCorousel);
    };
    const onImagesSelected = (files: any[]) => {
        setLoadingCount(files.length);
    };
    const renderImageItem = (image: TPicture) => {
        var value = 'Portrait';
        if (image.isAnimated === true) value = 'Animated';
        else if (image.isDetail === true) value = 'Website';
        else if (image.isPortrait === true) value = 'Portrait';
        else if (image.isMarketingImage === true) value = 'Marketing';
        else value = 'Landscape';

        console.log('values', image, value);
        return (
            <ImageContainer
                image={image}
                orientation={value}
                OrientationList={pictureOrientations}
                onOrientationChange={onOrientationChange}
                onCaptionChange={onCaptionChange}
                onImageDelete={onImageDelete}
                showAnimationForm={toggleAnimationForm}
                showCourousel={toggleCorousel}
            />
        );
    };
    const renderLoadingItem = () => {
        return (
            <Box width="250px">
                <ImageLoader />
            </Box>
        );
    };
    const onImageUploadComplete = (current: any, response: TPicture) => {
        setUploadedPicture(response);
    };
    useEffect(() => {
        if (details && uploadedPicture) {
            dispatch(OApp.showToast({ message: 'Picture uploaded', variant: 'success' }));
            const newImages: TPicture[] = [uploadedPicture, ...details._pictures];
            dispatch({ type: OCity.Actions.ITEM_PATCH_SUCCESS, data: { _pictures: newImages } });
            setLoadingCount((loadingCount) => loadingCount - 1);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedPicture]);
    const onJsonUpload = async (json: any[]) => {
        if (details) {
            if (details._lottieJson) {
                const lottie = [...details._lottieJson];
                lottie.concat([...json]);
                console.log(details._lottieJson, lottie.concat(json), json);
                await dispatch(OCity.patchItem(details.id, { _lottieJson: lottie.concat(json) }));
            } else {
                await dispatch(OCity.patchItem(details.id, { _lottieJson: json }));
            }
        }
        setLoadingCount(0);
    };
    const handleJsonImageDelete = async (index: number) => {
        console.log(index);
        if (details && details._lottieJson) {
            const lottie = [...details._lottieJson];
            lottie.splice(index, 1);
            await dispatch(OCity.patchItem(details.id, { _lottieJson: lottie }));
        }
    };
    const renderJsonImage = (item: any, index: number) => {
        return <LottieContainer image={item} imageIndex={index} onImageDelete={handleJsonImageDelete} />;
    };
    const toggleAnimationForm = (flag: boolean, id: string = '') => {
        setShowForm(flag);
        const data = pictureList.find((i) => i.id === id);
        setAnimatedImage(data);
    };

    const onAnimationSubmit = async (image: TPicture) => {
        if (details && details._pictures) {
            try {
                const pictures = [...details._pictures];
                const index = pictures.findIndex((i) => i.id === image.id);
                pictures.splice(index, 1, image);
                await dispatch(OCity.patchItem(details.id, { _pictures: pictures }));
                toggleAnimationForm(false);
            } catch (err) {
                console.log('Animation Configuration Failed ', err);
            }
        }
    };
    const onCaptionChange = async (pictureId: string, caption: string) => {
        if (details && details._pictures && details._landscapePictures) {
            const res = await changePictureCaption('destinations', details.id, pictureId, caption);
            if (res) {
                const pictures = [...details._pictures];
                const landscape = [...details._landscapePictures];
                const data = pictures.find((i) => i.id === pictureId);
                if (data) {
                    const index = pictures.findIndex((i) => i.id === pictureId);
                    pictures.splice(index, 1, { ...data, caption: caption });
                    dispatch({ type: OCity.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _pictures: pictures } });
                } else {
                    const lData = landscape.find((i) => i.id === pictureId);
                    if (lData) {
                        const lIndex = landscape.findIndex((i) => i.id === pictureId);
                        landscape.splice(lIndex, 1, { ...lData, caption: caption });
                        dispatch({ type: OCity.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _landscapePictures: landscape } });
                    }
                }
            }
        }
    };
    const onImageDelete = async (pictureId: string) => {
        if (details && details._pictures && details._landscapePictures) {
            const pictures = [...details._pictures];
            const landscape = [...details._landscapePictures];
            const pIndex = pictures.findIndex((i) => i.id === pictureId);
            const lIndex = landscape.findIndex((i) => i.id === pictureId);
            try {
                await deletePictureFromItem('destinations', details.id, pictureId, pIndex > -1 ? 'portrait' : 'landscape');
                if (pIndex > -1) pictures.splice(pIndex, 1);
                else if (lIndex > -1) landscape.splice(lIndex, 1);
                dispatch({ type: OCity.Actions.ITEM_PATCH_SUCCESS, data: { ...details, _pictures: pictures, _landscapePictures: landscape } });
            } catch (er) {}
        }
    };
    const onOrientationChange = async (id: string, orientation: string) => {
        if (details._pictures && details._landscapePictures) {
            const pictures = [...details._pictures];
            const landscape = [...details._landscapePictures];
            var index = pictures.findIndex((i) => i.id === id);
            if (index > -1) {
                var data = pictures[index];
                if (orientation === 'Portrait')
                    pictures.splice(index, 1, { ...data, isPortrait: true, isDetail: false, isAnimated: false, isMarketingImage: false });
                else if (orientation === 'Website')
                    pictures.splice(index, 1, { ...data, isPortrait: true, isDetail: true, isAnimated: false, isMarketingImage: false });
                else if (orientation === 'Animated') {
                    if (data.imageWidth)
                        pictures.splice(index, 1, { ...data, isPortrait: true, isDetail: false, isAnimated: true, isMarketingImage: false });
                    else
                        pictures.splice(index, 1, {
                            ...data,
                            isPortrait: true,
                            isDetail: false,
                            isAnimated: true,
                            isMarketingImage: false,
                            ...animationDimention,
                        });
                } else if (orientation === 'Marketing') {
                    pictures.splice(index, 1, { ...data, isPortrait: false, isDetail: false, isAnimated: false, isMarketingImage: true });
                } else {
                    pictures.splice(index, 1);
                    landscape.push({ ...data, isPortrait: false, isDetail: false, isAnimated: false });
                }
            } else {
                var lIndex = landscape.findIndex((i) => i.id === id);
                if (lIndex > -1) {
                    var lData = landscape[lIndex];
                    if (orientation === 'Landscape') {
                    } else {
                        landscape.splice(lIndex, 1);
                        if (orientation === 'Portrait')
                            pictures.push({ ...lData, isPortrait: true, isDetail: false, isAnimated: false, isMarketingImage: false });
                        else if (orientation === 'Website')
                            pictures.push({ ...lData, isPortrait: true, isDetail: true, isAnimated: false, isMarketingImage: false });
                        else if (orientation === 'Animated') {
                            if (lData.imageWidth)
                                pictures.push({ ...lData, isPortrait: true, isDetail: false, isAnimated: true, isMarketingImage: false });
                            else
                                pictures.push({
                                    ...lData,
                                    isPortrait: true,
                                    isDetail: false,
                                    isAnimated: true,
                                    isMarketingImage: false,
                                    ...animationDimention,
                                });
                        } else if (orientation === 'Marketing') {
                            pictures.push({ ...lData, isPortrait: false, isDetail: false, isAnimated: false, isMarketingImage: true });
                        }
                    }
                }
            }

            await dispatch(OCity.patchItem(details.id, { _pictures: pictures, _landscapePictures: landscape }));
        }
    };
    useEffect(() => {
        if (details.id) {
            const list: TPicture[] = [];
            details._pictures?.forEach?.((element) => {
                if (element.isDetail === true)
                    list.push({ ...element, isPortrait: false, isDetail: true, isAnimated: false, isMarketingImage: false });
                else if (element.isAnimated === true)
                    list.push({ ...element, isPortrait: false, isDetail: false, isAnimated: true, isMarketingImage: false });
                else if (element.isMarketingImage === true)
                    list.push({ ...element, isPortrait: false, isDetail: false, isAnimated: false, isMarketingImage: true });
                else list.push({ ...element, isPortrait: true, isDetail: false, isAnimated: false, isMarketingImage: false });
            });
            details._landscapePictures?.forEach?.((element) => {
                list.push({ ...element, isPortrait: false, isDetail: false, isAnimated: false });
            });
            setPictureList([...list]);
        }
    }, [details]);

    return (
        <Box className={classes.cityImages}>
            <Box width={850} mx="auto" my="20px">
                <EntityImages<TPicture>
                    folderName={'destinations'}
                    images={pictureList}
                    url={`destinations/${details.id}/pictures`}
                    renderImageItem={renderImageItem}
                    renderLoadingItem={renderLoadingItem}
                    hasAddButton={true}
                    loadingCount={loadingCount}
                    onImageSelected={onImagesSelected}
                    onImageUploadComplete={onImageUploadComplete}
                    isJson={isJson}
                    handleJsonSwitch={toggleJson}
                    onJsonUpload={onJsonUpload}
                    hasUrlUpload
                ></EntityImages>
                {details && details._lottieJson && details._lottieJson.length > 0 ? (
                    <>
                        <Divider style={{ margin: '30px 5px' }} />
                        <Gallery<any> data={details._lottieJson} renderLottieItem={renderJsonImage} renderItem={(item) => <div></div>} />
                    </>
                ) : null}
            </Box>
            {showForm && animatedImage && (
                <AnimationForm open={showForm} image={animatedImage} onClose={toggleAnimationForm} onSubmit={onAnimationSubmit} />
            )}
            {showCorousel && <ImageCarousel open={showCorousel} images={pictureList} closeDialog={toggleCorousel} currentIndex={current} />}
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        cityImages: {
            position: 'relative',
            margin: '0px 40px',
        },
        paper: {
            width: 700,
            margin: 'auto',
            padding: '30px',
            marginTop: 20,
        },
        imageCard: {
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: 8,
            paddingBottom: '5px',
            width: '100%',
            height: 'auto',
        },
        imageItem: {
            borderRadius: 0,
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            width: '100%',
            height: 'auto',
            cursor: 'pointer',
        },
        formLabel: {
            marginLeft: 0,
            '& span:last-child': {
                marginLeft: 2,
                fontSize: 12,
            },
        },
        lPaper: {
            height: '100%',
            width: '100%',
            padding: '5px',
        },
    });
});

export default withRouter(Images);
