import { Box, Button, Checkbox, Chip, CircularProgress, Fab, FormControlLabel, Icon, Link, Paper, Theme, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Dot from '../../../../Components/Buttons/Dot';
import HeaderActionButtons, { HeaderActionButtonProps } from '../../../../Components/HeaderActionButtons';
import TypographyWMention from '../../../../Components/TypographyWMention';
import dimensions from '../../../../Constants/dimensions';
import { DATE_FORMAT } from '../../../../Constants/vars';
import { renderHtmlStr } from '../../../../Features/Mention';
import { OApp } from '../../../../Models/App';
import { OPlace } from '../../../../Models/Place';
import { TPlace, TPlaceState, TSuggestions } from '../../../../Models/Place/@types';
import { DOMAIN_URL } from '../../../../Resources/GlobalConstants';
import { TDispatch } from '../../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../../RootReducer';
import OpeningDays from '../../OpeningDays';
import LikedDrawer from './LikedDrawer';
import UserDrawer from './UserDrawer';
import UserForm from './UserForm';
export type DrawerSide = 'top' | 'left' | 'bottom' | 'right';



const Details: FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {

    const classes = useStyles({});
    const { details, favouriters = [], wishers = [] } = useSelector<TReduxStore, Pick<TPlaceState, 'details' | 'favouriters' | 'wishers'>>(({ Place }) => ({ details: Place.details, favouriters: Place.favouriters, wishers: Place.wishers }));
    const selectedEstablishment = details;
    const dispatch = useDispatch<TDispatch>();
    const [notes, setNotes] = useState<string>('')
    const [showUserForm, setShowUserForm] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false);
    const [showLiked, setShowLiked] = useState(false);
    const [showSaved, setShowSaved] = useState(false);
    // const [submittingForm, setSubmittingForm] = useState(false);
    const statusTypes = [
        { name: "Undecided", value: "undecided" },
        { name: "WIP", value: "WIP" },
        { name: "WIP - Priority", value: "WIPPriority" },
        { name: "WIP - Top Priority", value: "WIPTopPriority" },
        { name: "Complete", value: "complete" },
        { name: "Archived", value: "archived" },
    ]

    const activeSuggestions = selectedEstablishment?.suggestions?.filter(i => !i.deleted && i.user) || [];

    const handleSelectChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        if (details) {
            try {
                await dispatch(OPlace.patchItem(details.id, { status: event.target.value as string }, {}, undefined, { status: event.target.value as string }))
                dispatch(OApp.showToast({ message: 'Status Updated Successfully', variant: 'success' }))
            } catch (err) {
                console.log(err);
                dispatch(OApp.showToast({ message: 'Status Update Unsuccessful', variant: 'error' }))
            }
        }
    }
    const handleEdit = () => {
        dispatch({ type: OPlace.Actions.SHOW_PLACE_DETAIL_FORM, data: details });
    }
    const handleDelete = async () => {
        if (details) {
            try {
                await dispatch(OPlace.patchItem(details.id, { deleted: true }))
                dispatch({ type: OPlace.Actions.SINGLE_PLACE_DELETED, data: details })
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.DEC_TOTAL, data: { summaryKey: 'Establishment' } })
                dispatch(OApp.showToast({ message: 'Place Deleted', variant: 'success' }))
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Place Delete Unsuccessful', variant: 'error' }))
                console.log("Place delete: ", err);
            }
            if (history) history.push("/dashboard/places");
        }

    }
    const handlePublish = async () => {

        if (details) {

            try {
                const flag = !(details.isPublished)
                console.log(flag)
                await dispatch(OPlace.postItemActivity(details.id, OPlace.Activities.PUBLISH, {}, { publish: !(details.isPublished) }))

                dispatch(OApp.showToast({ message: flag ? 'Place Published' : 'Place Unpublished', variant: 'success' }))
            } catch (err) {
                dispatch(OApp.showToast({ message: 'Place Publish Unsuccessful', variant: 'error' }))
                console.log("Place publish: ", err)
            }
        }
    }
    const handleAddToMamakooToggle = async () => {
        if (selectedEstablishment && selectedEstablishment.id) {
            const flag = selectedEstablishment.inMamakoo
            try {

                await dispatch(OPlace.patchItem(selectedEstablishment.id, { inMamakoo: !selectedEstablishment.inMamakoo }, {}, undefined, { inMamakoo: !selectedEstablishment.inMamakoo }))
                dispatch(OApp.showToast({ message: flag ? 'Removed from mamakoo' : 'Added to mamakoo', variant: 'success' }))
            } catch (err) {
                console.log('In mamakoo toggle error ', err);
                dispatch(OApp.showToast({ message: flag ? 'Removed from mamakoo failed' : 'Added to mamakoo failed', variant: 'error' }))

            }
        }
    }
    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setNotes(e.target.value)
    }
    const saveNotes = async () => {
        if (selectedEstablishment) {
            try {
                await dispatch(OPlace.patchItem(selectedEstablishment.id, { notes: notes }, {}, undefined, { notes: notes }))
                dispatch(OApp.showToast({ message: 'Note Saved', variant: 'success' }))

            } catch (err) {
                console.log('Error : ', err)
                dispatch(OApp.showToast({ message: 'Note Save Unsuccessful', variant: 'error' }))
            }
        }
    }
    useEffect(() => {
        if (details) {
            setNotes(details.notes || '')
        }
    }, [details])

    const setField = async (field: keyof TPlace, value: any) => {
        try {
            if (details)
                await dispatch(OPlace.patchItem(details.id, { [field]: value }, undefined, undefined, { [field]: value }))
        } catch (error) {

        }
    }

    const textUrl = selectedEstablishment ? `${DOMAIN_URL}/places/${selectedEstablishment.slug || selectedEstablishment.id}/details` : '';
    const headerItems: HeaderActionButtonProps['items'] = [
        {
            type: 'icon',
            icon: 'edit',
            helpText: 'Edit Place',
            onClick: handleEdit
        },
        {
            type: 'delete',
            alertMessage: 'Are you sure you want to delete this Place',
            handleDelete: handleDelete,
            helpText: 'Delete Place',
        },
        {
            type: 'icon',
            icon: (selectedEstablishment && selectedEstablishment.isPublished) ? 'lock_open' : 'lock',
            onClick: handlePublish,
            color: (selectedEstablishment && selectedEstablishment.isPublished) ? 'secondary' : 'primary',
            helpText: (selectedEstablishment && selectedEstablishment.isPublished) ? 'Unpublish this place' : 'Publish this place',
        },
        {
            type: 'copy_button',
            textUrl: textUrl,
        },
        {
            type: 'select',
            selectedValue: (details) ? details.status || '' : '',
            onSelectClick: handleSelectChange,
            selectList: statusTypes,
        }
    ]
    const closeForm = () => {
        setShowUserForm(false);
    }
    const closeDrawer = () => {
        setShowDrawer(false);
    }
    const getLikedAndSaved = async (entity: string) => {
        if (selectedEstablishment) {
            try {
                await dispatch(OPlace.getEntityByItem(selectedEstablishment.id, entity, {
                    filter: { order: 'name ASC' }
                }))
                if (entity === 'favouriters')
                    setShowLiked(true);
                else
                    setShowSaved(true)
            } catch (err) {
                console.log(err);
            }
        }

    }
    const closeLikedDrawer = () => {
        setShowLiked(false);
        setShowSaved(false);
    }
    const suggesters = () => {
        if (selectedEstablishment) {
            const { suggestions = [] } = selectedEstablishment;
            const primarySuggester = activeSuggestions.find(i => i.isPrimary) || {} as TSuggestions
            const flag = activeSuggestions.length > 0
            return <div className={classes.userDiv}>
                <Typography style={{ display: 'inline', marginTop: '5px' }}>{(primarySuggester) ? 'Suggested by' : 'Added By Admin'}</Typography>
                {
                    (flag) ?
                        <Chip
                            className={classes.userButton}
                            label={`${(primarySuggester && primarySuggester.user) ? primarySuggester.user.name : ''}`}
                            component='a'
                            style={{ cursor: 'pointer' }}
                            href={`/dashboard/users/${primarySuggester ? primarySuggester.userId : ''}/details`}
                            classes={{ label: classes.chipText }}
                        />
                        :
                        <Chip
                            className={classes.userButton}
                            classes={{ label: classes.chipText }}
                            label={'Assign Users'}
                            onClick={() => { setShowUserForm(true); }}
                        />
                }
                {
                    (flag) ?

                        <Fab className={classes.editSuggestors} onClick={() => { setShowUserForm(true); }}>
                            <i className="material-icons">edit</i>
                        </Fab>
                        : null

                }
                {
                    (activeSuggestions.length > 0) ?
                        <Chip
                            className={classes.userButton}
                            classes={{ label: classes.chipText }}
                            label={`View ${activeSuggestions.length}`}
                            onClick={() => { setShowDrawer(true) }}
                        ></Chip> : null
                }
                {showUserForm &&
                    <UserForm
                        open={showUserForm}
                        placeId={selectedEstablishment.id}
                        closeForm={closeForm}
                        suggestions={suggestions}
                        primarySuggester={primarySuggester}
                    />}
                {

                    showDrawer && <UserDrawer
                        closeDrawer={closeDrawer}
                        primarySuggester={primarySuggester}
                        suggestions={activeSuggestions}
                        id={selectedEstablishment.id}
                    />
                }
            </div>

        }
    }
    return (
        <div className={classes.establishmentDetail}>
            {
                selectedEstablishment ?
                    <>
                        <HeaderActionButtons items={headerItems} />

                        <Paper className={classes.paperNote} style={{ padding: 0 }}>
                            <textarea
                                placeholder={"Add a note"}
                                value={notes}
                                onChange={handleNoteChange}
                                className={classes.noteField}

                            />
                            {((selectedEstablishment.notes || '') !== notes) && <Chip className={classes.addNoteButton} color={'primary'} label={"SAVE"} onClick={saveNotes}></Chip>}
                        </Paper>

                        <Paper className={classes.paper}>
                            <div>
                                <section className={classes.section}>
                                    <Box display='flex' width='100%' justifyContent='space-between'>
                                        {suggesters()}
                                        <Button
                                            variant={'contained'}
                                            onClick={handleAddToMamakooToggle}
                                            color='primary'
                                            style={{
                                                borderRadius: '16px',
                                                height: '32px'
                                            }}
                                        >
                                            <Typography variant='caption'> {selectedEstablishment.inMamakoo ? 'Remove from Mamakoo' : 'Add to Mamakoo'}</Typography>
                                        </Button>
                                    </Box>
                                    <div className={classes.dateDiv}>
                                        {
                                            selectedEstablishment.created ? <Typography variant='body2' color="textPrimary">Created - {moment(selectedEstablishment.created).format(DATE_FORMAT)}</Typography> : null
                                        }
                                        {
                                            selectedEstablishment.manualUpdated ? <Typography variant='body2' color="textPrimary">&nbsp; Modified - {moment(selectedEstablishment.manualUpdated).format(DATE_FORMAT)}</Typography> : null
                                        }
                                    </div>
                                </section>
                                <Box display='flex' flexDirection='column' my={2} ml={0.5}>
                                    <FormControlLabel
                                        onClick={e => setField('newlyAdded', !details?.newlyAdded)}
                                        label='Newly added'
                                        control={<Checkbox color='primary' checked={!!details?.newlyAdded} />}
                                    />
                                    <FormControlLabel
                                        onClick={e => setField('recentlyUpdated', !details?.recentlyUpdated)}
                                        label='Recently updated'
                                        control={<Checkbox color='primary' checked={!!details?.recentlyUpdated} />}
                                    />
                                    <FormControlLabel
                                        onClick={e => setField('bookWeeksAhead', !details?.bookWeeksAhead)}
                                        label='Book weeks ahead'
                                        control={<Checkbox color='primary' checked={!!details?.bookWeeksAhead} />}
                                    />
                                    <FormControlLabel
                                        onClick={e => setField('expectALine', !details?.expectALine)}
                                        label='Expect a line'
                                        control={<Checkbox color='primary' checked={!!details?.expectALine} />}
                                    />
                                </Box>
                                <section className={classes.section}>
                                    <Typography variant={'h4'}>{selectedEstablishment.name} {selectedEstablishment.isPublished ? <span></span> : <Icon color={'error'} className={"material-icons"}>lock</Icon>} </Typography>
                                    <div className={classes.subHeading}>

                                        {
                                            (selectedEstablishment.range && selectedEstablishment.range > 0) &&
                                            <div className={classes.range} >
                                                <Typography variant='h6' >{'$'.repeat(selectedEstablishment.range)}</Typography>
                                            </div>
                                        }

                                        {
                                            (selectedEstablishment.types || []).map((type, i) => {
                                                return (
                                                    <Fragment key={i} >
                                                        <Dot mx={0} size={7} />
                                                        <div className={classes.range}><Typography variant='h6'>{type}</Typography></div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        {
                                            (selectedEstablishment.favouriteCount) ?
                                                <>
                                                    <Dot mx={0} size={7} />
                                                    <Button color='primary' onClick={() => { getLikedAndSaved(OPlace.Entities.FAVOURITERS) }}><Typography variant='h6'>{selectedEstablishment.favouriteCount} Liked</Typography></Button>
                                                    {showLiked && <LikedDrawer heading={'Liked By'} closeDrawer={closeLikedDrawer} list={favouriters} />}
                                                </> : null
                                        }
                                        {
                                            (selectedEstablishment.wishCount) ?
                                                <>
                                                    <Dot mx={0} size={7} />
                                                    <Button color='primary' onClick={() => { getLikedAndSaved(OPlace.Entities.WISHERS) }}><Typography variant='h6'>{selectedEstablishment.wishCount} Saved</Typography></Button>
                                                    {showSaved && <LikedDrawer heading={'Saved By'} closeDrawer={closeLikedDrawer} list={wishers} />}
                                                </> : null
                                        }
                                    </div>
                                    <Box display='flex' alignItems='center'>
                                        <Typography component="ul" className={classes.rating}>
                                            {(selectedEstablishment.externalRatings || []).map((rating, i) => rating.value !== 0 ? (<li key={i}>{rating.name}- <span>{rating.value}</span></li>) : <div key={i} />)}
                                        </Typography>
                                    </Box>
                                    <Box display='flex' alignItems='center'>
                                        {

                                            selectedEstablishment.mamakooRating ? <>  <Typography variant='body1'>Mamakoo Rating:&nbsp;&nbsp;</Typography><Rating max={3} readOnly value={selectedEstablishment.mamakooRating} size='medium' classes={{ root: classes.ratingRoot }} /> </> : null
                                        }
                                    </Box>

                                    <div className={classes.inMamakoo}>
                                        <Box mb={1}>
                                            {
                                                (activeSuggestions && activeSuggestions.length > 0) ?

                                                    (activeSuggestions[0].user && activeSuggestions[0].user.name) + (activeSuggestions.length > 1 ? ` and ${activeSuggestions.length - 1} others` : '')
                                                    : null
                                            }
                                        </Box>
                                    </div>

                                </section>
                                <section className={classes.section}>
                                    <Typography component="div" className={classes.details}>
                                        {
                                            (selectedEstablishment.address) &&
                                            <Typography variant={'body2'} style={{ marginBottom: '15px' }}><span>ADDRESS</span> {selectedEstablishment.address}</Typography>
                                        }
                                        {
                                            (selectedEstablishment.phone) &&
                                            <Typography variant={'body2'} style={{ marginBottom: '15px' }}><span>CONTACT</span> {selectedEstablishment.phone}</Typography>
                                        }
                                        {
                                            (selectedEstablishment.place_email) &&
                                            <Typography variant={'body2'} style={{ marginBottom: '15px' }}><span>EMAIL</span><Link color='primary' href={`mailto:${selectedEstablishment.place_email}`} target='_blank'> {selectedEstablishment.place_email}</Link></Typography>
                                        }
                                        {
                                            (selectedEstablishment.website) &&
                                            <Typography variant={'body2'} style={{ marginBottom: '15px' }}><span>WEBSITE</span> <Link color='primary' href={selectedEstablishment.website} target='_blank'>{selectedEstablishment.website}</Link></Typography>
                                        }
                                        {
                                            (selectedEstablishment.instagramLink) &&
                                            <Typography variant={'body2'} style={{ marginBottom: '15px' }}><span>INSTAGRAM</span> <Link color='primary' href={selectedEstablishment.instagramLink} target='_blank'>{selectedEstablishment.instagramLink}</Link></Typography>
                                        }
                                        {
                                            (selectedEstablishment.socialUrls) &&
                                            <Box display='flex'> <Typography variant={'body2'} style={{ marginBottom: '15px' }}><span>SOCIAL MEDIA</span></Typography>{selectedEstablishment.socialUrls.map((item, index) => (<Typography key={index} variant={'body2'} style={{ marginBottom: '15px', marginRight: 8 }}><Link color='primary' href={item.url} target='_blank'>{item.name}</Link></Typography>))}</Box>
                                        }
                                        {
                                            (selectedEstablishment.reservationLink) &&
                                            <Typography variant={'body2'} ><span>RESERVATION LINK</span> <Link color='primary' target={'_blank'} href={selectedEstablishment.reservationLink} >{selectedEstablishment.reservationLink}</Link></Typography>
                                        }

                                        {
                                            (selectedEstablishment.takeoutAndDelivery) &&
                                            <Typography variant={'body2'} ><span>TAKEOUT &amp; DELIVERY</span> <Link color='primary' target={'_blank'} href={selectedEstablishment.takeoutAndDelivery} >{selectedEstablishment.takeoutAndDelivery}</Link></Typography>
                                        }
                                    </Typography>

                                </section>
                                {
                                    (selectedEstablishment._openingDays) &&
                                    (
                                        <section className={classes.section}>
                                            <Typography component="div">
                                                <h3>
                                                    HOURS OF OPERATION
                                                </h3>
                                                <OpeningDays _openingDays={selectedEstablishment._openingDays} />
                                            </Typography>
                                        </section>
                                    )
                                }
                                {
                                    selectedEstablishment.shortDescription &&
                                    <section className={classes.section} >
                                        <Typography variant={'subtitle1'} >Short Description</Typography>
                                        <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>
                                            {(selectedEstablishment.shortDescription).replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                        </TypographyWMention>
                                    </section>
                                }

                                {
                                    selectedEstablishment.description &&
                                    <section className={classes.section} >
                                        <Typography variant={'subtitle1'} >Long Description</Typography>
                                        <TypographyWMention variant="body2" renderMentionHTMLStr={renderHtmlStr}>
                                            {(selectedEstablishment.description).replace(/(?:\r\n|\r|\n)/g, '<br />')}
                                        </TypographyWMention>
                                    </section>
                                }

                            </div>
                        </Paper>
                    </>
                    : <CircularProgress />
            }
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => createStyles({
    establishmentDetail: {
        position: 'relative',
        margin: '0px 40px',
    },
    paperNote: {
        marginTop: 20,
        width: dimensions.screenMaxWidth,
        margin: 'auto',
        position: 'relative',
        padding: 0,

    },
    paper: {
        width: dimensions.screenMaxWidth,
        margin: 'auto',
        padding: '10px 20px',
        marginTop: 20
    },
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            margin: '0 10px'
        }
    },
    userDiv: {
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'center',
        height: 'auto',
        marginBottom: '10px',
    },
    dateDiv: {
        display: 'flex',
        alignItems: 'flex-start',
        height: 'auto'
    },
    editSuggestors: {
        backgroundColor: '#F2F2F2',
        color: theme.palette.primary.main,
        boxShadow: 'none',
        height: 28,
        minHeight: 28,
        width: 28,
        borderRadius: 14,
        marginLeft: 5,
        '&  i': {
            fontSize: 16
        }
    },
    chipText: { color: theme.palette.primary.main },
    noteField: {
        width: '100%',
        background: 'transparent',
        minHeight: '100px',

        fontFamily: 'lato',
        fontSize: 14,
        border: '0px',
        outline: 'none',
        fontWeight: 'bold',
        maxWidth: '100%',
        padding: 20
    },
    userButton: {
        background: theme.palette.grey['700'],
        marginLeft: 10,

    },
    section: {
        // marginTop: '15px',
        //marginBottom: '15px',
        padding: '15px 0',
        borderBottom: `1px solid ${theme.palette.grey['500']}`
    },
    subHeading: {
        display: 'flex',
        marginTop: 10,
        position: 'relative',
        alignItems: 'center'
    },
    range: {
        marginLeft: 10,
        marginRight: 10,
    },
    details: {
        '& span': {
            fontWeight: theme.typography.fontWeightBold,
            marginRight: 10
        }
    },
    rating: {
        display: 'flex',
        listStyle: 'none',
        padding: 0,
        marginTop: 10,
        '& > li': {
            paddingLeft: 10,
            paddingRight: 10,
            borderRight: `1px solid ${'black'}`,
            '&:first-child': {
                paddingLeft: 0
            },
            '&:last-child': {
                borderRight: 'none'
            },
            '& > span': {
                fontWeight: theme.typography.fontWeightBold
            }
        }
    },
    timings: {
        listStyle: 'none',
        padding: 0
    },
    inMamakoo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    openingHours: {
        display: 'flex',
        width: 240,
        flexDirection: 'column'
    },
    opening: {
        padding: '6px 0px',
        borderBottom: `1px solid grey`,
        display: 'flex',
        justifyContent: 'space-between'
    },
    addNoteButton: {
        position: 'absolute',
        right: '16px',
        bottom: '5px',
    },
    ratingRoot: {
        color: theme.palette.primary['main']
    },
}));

export default Details