
const withLink = (href: string, body: string) => `<a href="${href}" target="_blank">${body}</a>`

export function renderHtmlStr(name: string, meta: string) {
    const _meta = JSON.parse(meta);
    if (!_meta.doc_type || !_meta.id) return withLink('#', name);
    const baseUrl = window.location.origin + '/dashboard/';
    const id = _meta.id;

    switch (_meta.doc_type) {
        case 'Cuisine': return withLink(baseUrl + `cuisines/${id}/details`, name);
        case 'Establishment': return withLink(baseUrl + `places/${id}/details`, name);
        case 'Destination': return withLink(baseUrl + `cities/${id}/details`, name);
        case 'FoodDrink': return withLink(baseUrl + `dishes/${id}/history`, name);
        case 'List': return withLink(baseUrl + `explore/${id}/details`, name);
        case 'Eateries': return withLink(baseUrl + `eatineraries/${id}/details`, name);
        case 'Landmark': return withLink(baseUrl + `landmarks/${id}/details`, name);
    }
    return `<a href="#">${name}</a>`
}