import { Grid } from '@material-ui/core'
import React from 'react'
import { TDish } from '../../../Models/Dish/@types'
import DishesCard from './DishesCard'
import PaginationComponent from '../../Pagination'
import { ITEM_PER_PAGE_COUNT } from '../../../Constants/FilterConstants'

interface IProps {
    list: TDish[]
    deleteItem: (item: TDish) => Promise<void>
    pageNumber: number
    handlePageChange: (pageNumber: number) => void
    totalCount: number
    showCheckBoxToMakeIconic?: boolean
    iconicFoodDrinkIds?: Array<any>
    handleCheckboxToggle?: (item: any) => void
}

const DishesCardList: React.FC<IProps> = (props: IProps) => {

    const { list = [], deleteItem } = props

    const updateIconicFoodDrinkIds = (event: React.SyntheticEvent, id: string) => {
        if ((event.currentTarget as HTMLInputElement).checked && props.iconicFoodDrinkIds && props.handleCheckboxToggle) {
            props.iconicFoodDrinkIds.push(id)
            props.handleCheckboxToggle(props.iconicFoodDrinkIds)
        } else if (!(event.currentTarget as HTMLInputElement).checked && props.iconicFoodDrinkIds && props.handleCheckboxToggle) {
            let iconicDishesCopy = props.iconicFoodDrinkIds.filter(foodId => id !== foodId)
            props.handleCheckboxToggle(iconicDishesCopy)
        }
    }
    return (
        <div>
            <Grid container spacing={3}>
                {
                    list.map((item, index) => {
                        return (<Grid item key={index} md={4}>
                            <DishesCard
                                deleteItem={deleteItem}
                                item={item}
                                showCheckBoxToMakeIconic={props.showCheckBoxToMakeIconic}
                                isIconic={props.iconicFoodDrinkIds && props.iconicFoodDrinkIds.includes(item.id)}
                                handleCheckboxToggle={(event) => updateIconicFoodDrinkIds(event, item.id)}
                            />
                        </Grid>)
                    })
                }
            </Grid>
            <PaginationComponent
                currentPage={props.pageNumber}
                onPageChange={props.handlePageChange}
                itemsCountPerPage={ITEM_PER_PAGE_COUNT}
                totalItemsCount={props.totalCount}
            />

        </div>
    )
}


export default DishesCardList