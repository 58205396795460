import { Box, Fab, FormControlLabel, Icon, makeStyles, Typography, Switch, Theme, Tooltip, createStyles } from '@material-ui/core';
import loadImage from 'blueimp-load-image';
import _ from 'lodash';
import React, { useState } from 'react';
import { TPicture } from '../../Resources/GlobalTypes';
import { uploadEntityPictures } from '../../Resources/PictureUtils';
import DialogImage from '../Dialogs/DialogImage';
import VideoLinkDialog from '../Dialogs/VideoLinkDialog';
import FileInput from '../FileInput';
import Gallery from '../Gallery';
var mongoid = require('mongoid-js');



interface IProps<T = unknown> {
    hasAddButton?: boolean
    onImageSelected?: (files: any[]) => void
    onImageUploadComplete?: (current: any, response: T) => void
    folderName: string
    loadingCount?: number
    url: string
    handleUpload?: (files: any) => any
    images: T[]
    renderImageItem: (item: T) => JSX.Element
    renderLoadingItem: () => JSX.Element
    onReOrder?: (oldIndex: number, newIndex: number) => void
    handleJsonSwitch?: () => void
    isJson?: boolean
    onJsonUpload?: (response: any[]) => void
    onVideoLinkSubmit?: (newVideo: TPicture) => void
    hasUrlUpload?: boolean
    hasVideoUrlUpload?: boolean
    customButtons?: Array<JSX.Element>
    heading?: string
}

function EntityImages<T extends any>(props: IProps<T>) {
    const [urlUploadDialogOpen, setUrlUploadDialogOpen] = useState(false);
    const [videoDialogOpen, setVideoDialogOpen] = useState(false);
    const { hasAddButton, hasUrlUpload, hasVideoUrlUpload, customButtons = [], heading } = props
    const { loadingCount = 0, isJson = false } = props;
    const { onReOrder = undefined } = props;
    const handleJsonSwitch = () => {
        if (props.handleJsonSwitch)
            props.handleJsonSwitch();
    }
    const classes = useStyles();

    const uploadJsonFiles = (files: any[]) => {
        if (_.isEmpty(files) || !_.isFunction(props.onImageSelected))
            return;
        props.onImageSelected(files);
        const file = files.map((item) => {
            const json = atob(_.get(_.split(_.get(item, 'base64'), ','), '[1]'));
            return JSON.parse(json)
        })
        if (props.onJsonUpload)
            props.onJsonUpload(file)
        //const json = atob(_.get(_.split(_.get(file, 'base64'), ','), '[1]'));
        // props.onJSONSelected(JSON.parse(json));

    }

    const uploadFiles = (files: any[]) => {

        if (_.isEmpty(files))
            return;
        if (props.handleUpload) {
            return props.handleUpload(files);
        }
        if (!_.isFunction(props.onImageSelected)) return;
        props.onImageSelected(files);
        const { url, folderName } = props;

        const filePromises = files.map((file, index) => {
            return new Promise((resolve, reject) => {
                loadImage(file.base64, (img: any, data: any) => {
                    console.log('Data', data);
                    //const base64 = img.getAttribute('src');
                    resolve(file);
                }, { meta: undefined });
            })
        });

        Promise.all(filePromises).then(
            (newFiles) => {
                uploadEntityPictures(newFiles, url, folderName, props.onImageUploadComplete);
            }
        )
    }

    const handleDialogSubmit = async (imgUrl: string) => {
        getBase64ImageFromUrl(imgUrl);
    }

    const getBase64ImageFromUrl = async (imageUrl: string): Promise<string> => {
        const res = await fetch(imageUrl);
        const blob = await res.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                resolve(reader.result as string);
            }, false);

            reader.onerror = () => {
                return reject();
            };
            // @ts-ignore
            reader.onload?.((event) => {
                console.log({ reader: reader.result });

            })
            reader.readAsDataURL(blob);
        })
    }

    const onVideoLinkSubmitted = (url: string) => {
        let mongoId = mongoid();
        let picture: TPicture = {
            imagePath: url,
            thumbnail: url,
            url: url,
            desktop: false,
            "isPortrait": false,
            "id": mongoId,
            "created": "2021-11-30T18:10:23.055Z",
            "fileType": "video",
            "height": 370,
            "width": 2364,
            filePath: ''
        };

        if (props && props.onVideoLinkSubmit)
            props.onVideoLinkSubmit(picture);
    }

    return (
        <div>
            {
                hasAddButton ?
                    <Box display="flex" className={classes.operationBtns} justifyContent="center">
                        <Tooltip title="Add image">
                            <Fab size="small" color={'inherit'}>
                                <Icon color={'action'} className={'material-icons'}>add</Icon>
                                <FileInput
                                    accept={isJson ? "json/*" : "image/*"}
                                    multiple={true}
                                    onDone={isJson ? uploadJsonFiles : uploadFiles}
                                />
                            </Fab>
                        </Tooltip>
                        {props.handleJsonSwitch &&
                            <FormControlLabel
                                control={<Tooltip title="Switch from/to JSON upload"><Switch
                                    checked={isJson}
                                    value={'Accept Json'}
                                    onChange={handleJsonSwitch}
                                    color={'primary'} /></Tooltip>}
                                label="Upload JSON"
                                style={{ marginLeft: '10px' }}
                            />
                        }
                        {
                            hasUrlUpload && (
                                <>
                                    <Fab style={{ marginLeft: props.handleJsonSwitch ? 0 : 10 }} size="small" color='inherit' onClick={() => setUrlUploadDialogOpen(true)}>
                                        <Icon color='action' className="material-icons">link</Icon>
                                    </Fab>
                                    {
                                        urlUploadDialogOpen ?
                                            <DialogImage
                                                onImageUploadComplete={props.onImageUploadComplete}
                                                entityUploadUrl={props.url}
                                                open={urlUploadDialogOpen}
                                                handleDialog={handleDialogSubmit}
                                                closeDialog={() => { setUrlUploadDialogOpen(false) }}
                                            />
                                            :
                                            null
                                    }
                                </>
                            )
                        }
                        {
                            hasVideoUrlUpload && (
                                <>
                                    <Fab style={{ marginLeft: props.handleJsonSwitch ? 0 : 10 }} size="small" color='inherit' onClick={() => setVideoDialogOpen(true)}>
                                        <Icon color='action' className="material-icons">video_call</Icon>
                                    </Fab>
                                    {
                                        videoDialogOpen ?
                                            <VideoLinkDialog
                                                onVideoLinkSubmit={onVideoLinkSubmitted}
                                                open={videoDialogOpen}
                                                handleDialog={handleDialogSubmit}
                                                closeDialog={() => { setVideoDialogOpen(false) }}
                                            />
                                            :
                                            null
                                    }
                                </>
                            )
                        }
                        {
                            customButtons.map(e => e)
                        }
                    </Box> : null
            }
            {heading && <Box my={3} display="flex" justifyContent="center">
                <Typography className={classes.heading} variant="h5" >
                    {heading}
                </Typography>
            </Box>}
            <Box mt="16px">
                <Gallery<T>
                    data={props.images}
                    renderItem={props.renderImageItem}
                    loadingCount={loadingCount}
                    renderLoading={props.renderLoadingItem}
                    onReOrder={onReOrder}
                />
            </Box>
        </div>
    )
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    operationBtns: {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        '& > button': {
            margin: '0 10px'
        },
        '& > div': {
            margin: '0 10px'
        }
    },
    heading: {
        fontWeight: 600
    }
}))



export default EntityImages