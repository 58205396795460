import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TSettingsState } from '../../../Models/Settings/@types';
import { TReduxStore } from '../../../RootReducer';
import ReorderableList from '../../../Components/ReorderableList';
import { NameValuePair, TConstant } from '../../../Models/App/@types';
import EntityCard from '../../../Features/Home/EntityCard';
import ContinentForm from '../Forms/ContinentForm';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { OSettings } from '../../../Models/Settings';
import HeaderActionButtons from '../../../Components/HeaderActionButtons';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';

export interface ContinentsProps {

}

const continentSettingKey = "continentName";

const Continents: FC<ContinentsProps> = (props) => {
    const classes = useStyles({});
    const { list = [] } = useSelector<TReduxStore, Pick<TSettingsState, 'list'>>(({ Settings: { list } }) => ({ list }))
    const [showForm, setShowForm] = useState(false);
    const [toDelete, setToDelete] = useState<string | null>(null);
    const [confirmationText, setConfirmationText] = useState("");
    const [editedItem, setEditedItem] = useState<NameValuePair | null>(null);
    const dispatch = useDispatch<TDispatch>();

    const [continentList, setContinentList] = useState<TConstant[]>([])
    const continentSetting = list.find(item => item.key === continentSettingKey);


    useEffect(() => {
        const _continentList = (continentSetting?.value || []) as TConstant[];
        setContinentList(_continentList);
    }, [continentSetting, list])

    const handleAddEntity = () => {
        setShowForm(true);
    }

    const headerItems = [
        {
            type: 'icon',
            icon: 'add',
            onClick: handleAddEntity,
            helpText: 'Add Continent',
        }
    ]



    const handleReorder = async (newList: TConstant[]) => {
        if (!continentSetting) return;
        const _oldList = continentList;
        setContinentList(newList);
        try {
            await dispatch(OSettings.patchItem(continentSetting.id, { value: newList }));
        } catch (error) {
            setContinentList(_oldList);
        }
    }

    const handleRemove = (item: NameValuePair) => {
        setConfirmationText(`Are you sure you want to delete ${item.name}?`);
        setToDelete(item.value);
    }

    const closeForm = () => {
        setShowForm(false);
        setEditedItem(null);
    }

    const handleEdit = (item: NameValuePair) => {
        setEditedItem(item);
        setShowForm(true);
    }

    const submitHandler = useAsyncTask(async (item: NameValuePair) => {
        if (!continentSetting) return;
        const updatedList = editedItem ? continentList.map(i => i.value === editedItem.value ? { name: item.name, value: editedItem.value } : i) : [...continentList, item];
        await dispatch(OSettings.patchItem(continentSetting.id, { value: updatedList }));
        closeForm();
    })

    const handleConfirmDelete = () => {
        if (!continentSetting) return;
        const value = toDelete;
        const newList = continentList.filter(item => item.value !== value);
        dispatch(OSettings.patchItem(continentSetting.id, { value: newList }));
        closeConfirmation()
    }

    const closeConfirmation = () => {
        setToDelete(null);
        setConfirmationText("");
    }

    const renderItem = (item: TConstant, draghandleProps: any) => {

        return (
            <EntityCard
                subtitle=''
                disableImagePlaceholder
                title={item.name}
                draghandleProps={draghandleProps}
                key={item.value || item.name}
                menuProps={{
                    id: item.value,
                    items: [
                        { label: 'edit', onClick: () => handleEdit(item) },
                        { label: 'remove', onClick: () => handleRemove(item) }
                    ]
                }}
            />
        )
    }

    if (!continentList.length) return null;
    return (
        <Box width={400} mx='auto' >
            <Box position="sticky" top={0}>
                <HeaderActionButtons items={headerItems} />
            </Box>
            <Box mt={4} position='relative'>
                <ReorderableList<TConstant>
                    list={continentList}
                    itemKey="value"
                    handleUpdateListOrder={handleReorder}
                    renderItem={renderItem}
                />
            </Box>
            <ContinentForm
                open={showForm}
                title={`${editedItem ? 'Edit' : 'Add'} Continent`}
                closeForm={closeForm}
                initialValue={editedItem ?? { name: '', value: '' }}
                loading={submitHandler.status === 'PROCESSING'}
                onFormSubmit={submitHandler.run}
            />
            <ConfirmationDialog
                open={!!confirmationText}
                onAgree={handleConfirmDelete}
                onDisagree={closeConfirmation}
                message={confirmationText}
                onClose={closeConfirmation}
            />
        </Box>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default Continents;