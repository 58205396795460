import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import React, { FC } from 'react';
import { IReactFormProps, MLFormContent } from 'react-forms';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import ImageUpload, { TFile } from '../../../Components/EntityImages/ImageUpload';
import TextFieldWMention, { TextFieldWMentionProps } from '../../../Components/Inputs/TextFieldWMention';
import { textFieldWMentionHelpers } from '../../../Components/Inputs/TextFieldWMention/helpers';
import dimensions from '../../../Constants/dimensions';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { OApp } from '../../../Models/App';
import { OUser } from '../../../Models/User';
import { TUser, TUserState } from '../../../Models/User/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { uploadPicture } from '../../../Resources/PictureUtils';
import { TReduxStore } from '../../../RootReducer';
export interface UserFormProps { }
const validationSchema = Yup.object({
    name: Yup.string().required("This is required"),
    email: Yup.string().required("This is required").email("Invalid Format")
})
const UserForm: FC<UserFormProps> = (props) => {
    const classes = useStyles({});
    const { showMainForm = false, editedUser } = useSelector<TReduxStore, Pick<TUserState, 'editedUser' | 'showMainForm'>>(({ User }) => ({ showMainForm: User.showMainForm, editedUser: User.editedUser }))
    const dispatch = useDispatch<TDispatch>();

    const onSubmit = useAsyncTask(async (values: Partial<TUser>) => {
        const { name, bio, _profile_image, email, website } = values;
        if (editedUser?.id) {
            try {
                await dispatch(OUser.patchItem(editedUser.id, { name, bio, _profile_image, email, website }, null, undefined, { ...values }))
                dispatch(OApp.showToast({ message: 'User edit successfull', 'variant': 'success' }))
                onClose();
            } catch (e) {
                dispatch(OApp.showToast({ message: 'User edit failed', 'variant': 'error' }))
            }
        }
    })
    const onClose = () => {
        dispatch({ type: OUser.Actions.HIDE_USER_FORM })
    }
    const config: IReactFormProps['config'] = [
        {
            valueKey: 'name',
            type: 'text',
            fieldProps: {
                fullWidth: true,
                label: "Name"
            }
        },
        {
            valueKey: 'email',
            type: 'text',
            fieldProps: {
                fullWidth: true,
                label: "Email"
            }
        },
        {
            valueKey: 'website',
            type: 'text',
            fieldProps: {
                fullWidth: true,
                label: "Website"
            }
        },
        // {
        //     valueKey: 'bio',
        //     type: 'text',
        //     fieldProps: {
        //         fullWidth: true,
        //         label: "Bio",
        //         multiline: true
        //     }
        // }
    ]
    const handleUpload = useAsyncTask(async ({ files, formikProps }: { files: TFile[], formikProps: FormikProps<Partial<TUser>> }) => {
        if (files.length === 0)
            return
        try {
            const res = await uploadPicture(files[0], `user_profileImage`);
            formikProps.setFieldValue('_profile_image', res.data)

        } catch (e) {

        }

    })

    const handleBioChange = (formikProps): TextFieldWMentionProps['handleChange'] => (e, newValue, newValueText) => {
        console.log("change", formikProps)
        formikProps.setFieldValue('bio', e.target.value)
        // handleChange(name, e.target.value);
    }
    const onRemove = (formikProps: FormikProps<Partial<TUser>>) => { formikProps.setFieldValue('_profile_image', null) }
    return (
        <Formik
            initialValues={editedUser || {}}
            onSubmit={onSubmit.run}
            validationSchema={validationSchema}
        >
            {
                formikProps =>
                    <FormDialog
                        heading={`Edit ${editedUser?.name}`}
                        onSubmit={() => { formikProps.handleSubmit() }}
                        open={showMainForm}
                        loading={onSubmit.status === 'PROCESSING'}
                        onClose={onClose}
                    >
                        <ImageUpload
                            handleUpload={(f) => handleUpload.run({ files: f, formikProps })}
                            loading={handleUpload.status === 'PROCESSING'}
                            imageUrl={formikProps.values['_profile_image']?.url}
                            paperProps={{ className: classes.img, style: { display: 'flex', position: 'relative' } }}
                            avatarClassName={classes.img}
                            onRemove={() => onRemove(formikProps)}
                        />
                        <Paper className={classes.paper}>
                            <MLFormContent
                                schema={config}
                                actionConfig={{
                                    displayActions: false
                                }}
                                formId='user-edit-form'
                                formikProps={formikProps}
                                settings={{
                                    verticalSpacing: 24
                                }}
                            />
                            <TextFieldWMention
                                label="Bio"
                                fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                                value={formikProps.values?.bio || ''}
                                handleChange={handleBioChange(formikProps)}
                            />
                        </Paper>
                    </FormDialog>
            }

        </Formik>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            margin: '24px auto',
            width: dimensions.formPaperMaxWidth,
            padding: '40px',
            height: 'auto',
        },
        img: {
            height: 150,
            width: 150,
            borderRadius: 75
        }
    }))
})

export default UserForm