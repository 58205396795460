import { createStyles, Divider, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, RouteComponentProps, RouteProps } from 'react-router-dom';
import ListDashboard from '../../Components/Dashboard/ListDashboard';
import FilterListMenu from '../../Components/Filters/FilterListMenu';
import UsersListSortMenu from '../../Features/Users/UsersListSortMenu';
import useAsyncTask from '../../Hooks/useAsyncTask';
import { TFilterConfig } from '../../Models/App/@types';
import { OCity } from '../../Models/City';
import { OPlace } from '../../Models/Place';
import { TPlaceFilterConfig, TPlace, TPlaceState } from '../../Models/Place/@types';
import { getPlaceList } from '../../Models/Place/actions';
import { TDispatch } from '../../Resources/GlobalTypes';
import { TReduxStore } from '../../RootReducer';
import PlaceForm from './Forms/PlaceForm/PlaceForm';
import Cuisines from './Tabs/Cuisines';
import Details from './Tabs/Details';
import Dishes from './Tabs/Dishes';
import Images from './Tabs/Images';
import Reviews from './Tabs/Reviews';
import Similarplaces from './Tabs/SimilarPlaces';
import Tags from './Tabs/Tags';


const BASE_URL = '/dashboard/places'
const filterInitialState: TPlaceFilterConfig = {
    status: [],
    ratings: [],
    cityIds: [],
    baseConfig: [
        {
            name: 'All',
            isActive: true
        },
        {
            name: 'User Submitted',
            isActive: false
        },
        {
            name: 'Published',
            isActive: false
        },
        {
            name: 'Unpublished',
            isActive: false
        },
        {
            name: 'Rated by Mamakoo',
            isActive: false
        },
        {
            name: 'Not Rated by Mamakoo',
            isActive: false
        },
        { name: 'Newly Added', isActive: false },
        { name: 'Recently Updated', isActive: false },
    ],
    order: 'name ASC'
}
const Places: FC<RouteComponentProps> = () => {
    const url = window.location.href;
    const urlArray = url.split('/')
    const placeId = urlArray.length >= 6 ? urlArray[5] : ''
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState<TPlace[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [filterConfig, setFilterConfig] = useState<TPlaceFilterConfig>(JSON.parse(JSON.stringify(filterInitialState)))
    const dispatch = useDispatch<TDispatch>();
    const { details, list = [], totalCount = 0, showMainForm = false } = useSelector<TReduxStore, Pick<TPlaceState, 'details' | 'list' | 'totalCount' | 'showMainForm'>>(({ Place }) => ({ details: Place.details, list: Place.list, totalCount: Place.totalCount, showMainForm: Place.showMainForm }))
    const [sortMenuEl, setSortMenuEl] = useState<HTMLElement>();
    const SUB_DASHBOARD_LINK_CONFIG: Array<{ url: string, name: string, component: RouteProps['component'] }> = [
        {
            url: 'details',
            name: 'Details',
            component: Details
        },
        {
            url: 'cuisines',
            name: 'Cuisines - ' + ((details && details.counts) ? (details.counts.cuisines || 0) : 0),
            component: Cuisines
        },
        {
            url: 'dishes',
            name: 'Dishes - ' + ((details && details.counts) ? (details.counts.foodDrinks || 0) : 0),
            component: Dishes
        },
        {
            url: 'images',
            name: 'Images - ' + ((details && details.counts) ? (details._pictures.length) : 0),
            component: Images
        },
        {
            url: 'reviews',
            name: 'Reviews - ' + ((details && details.counts) ? (details.counts.reviews || 0) : 0),
            component: Reviews
        },
        {
            url: 'tags',
            name: 'Tags - ' + ((details && details.counts) ? (details.counts.tags || 0) : 0),
            component: Tags
        },
        {
            url: 'Similar',
            name: 'Similar places - ' + ((_.get(details, 'similarEstablishmentIds') || []).length || 0),
            component: Similarplaces
        },
    ]
    const handleStatusFilterChange = (item: string) => {
        var statusList: string[] = filterConfig.status;
        const currentIndex = statusList.findIndex(i => i === item);
        if (currentIndex > -1)
            statusList.splice(currentIndex, 1);
        else
            statusList.push(item);
        setFilterConfig({ ...filterConfig, status: [...statusList] })

    }
    const handleRatingFilterChange = (item: number) => {
        var ratingList: number[] = filterConfig.ratings;
        const currentIndex = ratingList.findIndex(i => i === item);
        if (currentIndex > -1)
            ratingList.splice(currentIndex, 1);
        else
            ratingList.push(item);
        setFilterConfig({ ...filterConfig, ratings: [...ratingList] })

    }
    const handleCityFilterChange = (item: string) => {
        var cityList: string[] = filterConfig.cityIds;
        const currentIndex = cityList.findIndex(i => i === item);
        if (currentIndex > -1)
            cityList.splice(currentIndex, 1);
        else
            cityList.push(item);
        setFilterConfig({ ...filterConfig, cityIds: [...cityList] })

    }
    const handleBaseConfigChange = (item: TFilterConfig) => {
        const filter = filterConfig.baseConfig.map(i => {
            if (i.name === item.name) {
                i.isActive = true;
            } else {
                i.isActive = false;
            }
            return i;
        })
        setFilterConfig({ ...filterConfig, baseConfig: filter });
    }
    const handleFilterSubmit = () => {

        if (pageNumber === 1)
            getData(filterConfig);
        else
            setPageNumber(1);
    }
    const handleFilterReset = () => {

        setFilterConfig(JSON.parse(JSON.stringify(filterInitialState)))
        if (pageNumber === 1)
            getData();
        else
            setPageNumber(1);
        // handleFilterSubmit();
    }

    const getDetails = useAsyncTask(async (id: string) => {
        await dispatch(OPlace.getItemActivity(id, OPlace.Activities.DETAILS, {
            filter:
            {
                include: [
                    { relation: "similarEstablishments" },
                    { relation: "primarySuggesters" },
                ]
            }
        }))
    })
    const getCityList = async () => {
        try {
            await dispatch(OCity.getItemsList({
                filter: {
                    order: 'name ASC'
                }
            }));
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (placeId)
            getDetails.run(placeId);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeId])

    useEffect(() => {
        getCityList();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = (filterConfig: TPlaceFilterConfig = JSON.parse(JSON.stringify(filterInitialState))) => {
        setLoading(true);
        dispatch(getPlaceList(pageNumber, filterConfig))

        setLoading(false);
    }
    const handleSearchResult = (result: Array<TPlace>, queryTerm: string) => {
        setSearchQuery(queryTerm);
        setSearchResult(result);
    }

    const handleAddButtonClick = () => { dispatch({ type: OPlace.Actions.SHOW_PLACE_DETAIL_FORM }); }

    const classes = useStyles();

    useEffect(() => {
        getData(filterConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber])
    const handleSortClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSortMenuEl(e.currentTarget)
    }
    const handleSortingChange = (filter: any) => {
        setFilterConfig(f => ({ ...f, ...filter }))
        if (pageNumber === 1)
            getData({ ...filterConfig, ...filter });
        else
            setPageNumber(1);
    }
    const renderListItem = useCallback((place: TPlace): ReactElement => {
        const secondaryText1 = (place.destination ? place.destination.name : '')
        const secondaryText2 = place.status ? place.status : ''

        const activeSuggestions = place?.suggestions?.filter(i => !i.deleted && i.user) || [];

        const isActive = (match: any, location: any) => {
            const regex = new RegExp(`${BASE_URL}/${place.id}/`, 'ig');
            return regex.test(location.pathname);
        }

        return (
            <span key={place.id}>
                <NavLink className={classes.listItem} to={`${BASE_URL}/${place.id}/details`} isActive={isActive} activeClassName={"selected"} >
                    <ListItem button >
                        <ListItemText
                            style={{ whiteSpace: 'pre' }}
                            secondaryTypographyProps={{ display: 'block' }}
                            primary={`${place.name || ''}`}
                            secondary={secondaryText1.concat(" \n").concat(secondaryText2)}
                        />
                        <ListItemSecondaryAction>
                            {((activeSuggestions && activeSuggestions.length > 0)) ? <i className={classNames('material-icons', classes.userIcon)}>account_circle</i> : ''}

                            {(!place.isPublished) ? <i className={classNames('material-icons', classes.publishIcon)}>lock</i> : null}
                        </ListItemSecondaryAction>
                    </ListItem>

                    <Divider className={classes.divider} />
                </NavLink>
            </span>
        )
    }, [classes]);


    console.log("search query", searchQuery)
    return (
        <>
            <ListDashboard
                baseUrl={BASE_URL}
                csvConfig={
                    {
                        modelName: 'Establishment'
                    }
                }
                renderListItem={renderListItem}
                itemList={searchQuery ? searchResult : list}
                contentConfig={SUB_DASHBOARD_LINK_CONFIG}
                totalItemsCount={totalCount}
                itemsCountPerPage={20}
                currentPageNumber={pageNumber}
                onPageChange={setPageNumber}
                onItemAddRequest={handleAddButtonClick}
                onFilterSubmit={handleFilterSubmit}
                onFilterReset={handleFilterReset}
                filterConfig={[]}
                filterComponent={
                    <FilterListMenu
                        filterConfig={filterConfig}
                        toggleStatus={handleStatusFilterChange}
                        toggleCity={handleCityFilterChange}
                        toggleRating={handleRatingFilterChange}
                        toggleCheck={handleBaseConfigChange}
                    />
                }
                listProps={{
                    searchProps: {
                        type: 'Establishment',
                        onResults: handleSearchResult,
                    },
                    loading: loading,
                }}
                listActionButtonConfig={[
                    {
                        icon: 'sort',
                        id: 'sort',
                        label: 'Sort',
                        onClick: handleSortClick
                    }
                ]}
            />
            <UsersListSortMenu anchorEl={sortMenuEl} onSortingChange={handleSortingChange} handleCloseSortMenu={() => setSortMenuEl(undefined)} />
            {showMainForm && <PlaceForm />}
        </>
    )
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    divider: {
        backgroundColor: theme.palette.grey['500']
    },
    listItem: {
        color: theme.palette.text.primary,
        '&.selected': {
            background: fade(theme.palette.primary.main, 0.4),
            display: 'block'
        }
    },
    subDashboardContainer: {
        marginLeft: 250,
    },
    headLinks: {
        marginBottom: 50
    },
    userIcon: {
        fontSize: 13,
        color: '#032f8f'
    },
    publishIcon: {
        fontSize: 13,
        color: '#B71840'
    }
}))


export default Places;