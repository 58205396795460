import { createStyles, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import TextFieldWMention, { TextFieldWMentionProps } from '../../../Components/Inputs/TextFieldWMention';
import { textFieldWMentionHelpers } from '../../../Components/Inputs/TextFieldWMention/helpers';
import dimensions from '../../../Constants/dimensions';
import { OApp } from '../../../Models/App';
import { ODish } from '../../../Models/Dish';
import { TDish, TDishState } from '../../../Models/Dish/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';

export interface MainFormProps extends RouteComponentProps { }

const MainForm: FC<MainFormProps> = (props) => {
    const classes = useStyles();
    const { editedDish, showMainForm = false } = useSelector<TReduxStore, Pick<TDishState, 'editedDish' | 'showMainForm'>>(({ Dish }) => ({ editedDish: Dish.editedDish, showMainForm: Dish.showMainForm }))
    const [dishState, setDishState] = useState({ name: '', type: 'food', description: '', shortDescription: '' })
    const dispatch = useDispatch<TDispatch>();
    const handleSubmit = async () => {
        try {
            if (editedDish && editedDish.id) {
                await dispatch(ODish.patchItem(editedDish.id, dishState, {}, undefined, { ...editedDish, ...dishState }))
                dispatch(OApp.showToast({ message: 'Edit Dish Successful', variant: 'success' }))
                closeForm();
            } else {
                //await dispatch(ODish.postItem(dishState))
                let res: { data: TDish } = await ODish.requestPostItem(dishState);
                dispatch({ type: ODish.Actions.POST_ITEM_SUCCESS, data: res.data })
                props.history.push('/dashboard/dishes/' + res.data.id + '/history')
                dispatch({ type: OApp.Actions.UPDATE_SUMMARY, activity: OApp.Activities.INC_TOTAL, data: { summaryKey: 'FoodDrink' } })
                dispatch(OApp.showToast({ message: 'Add Dish Successful', variant: 'success' }))
                closeForm();
            }
        } catch (err) {
            console.log(err)
            dispatch(OApp.showToast({ message: 'Add/Edit Dish Unsuccessful', variant: 'error' }))
        }

    }
    const closeForm = () => {
        setDishState({ name: '', type: 'food', description: '', shortDescription: '' })
        dispatch({ type: ODish.Actions.HIDE_DISH_DETAIL_FORM })
    }
    useEffect(() => {
        if (editedDish && editedDish.id) {
            setDishState({
                ...dishState,
                name: editedDish.name || '',
                description: editedDish.description || '',
                type: editedDish.type || '',
                shortDescription: editedDish.shortDescription || ''
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedDish])
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDishState({ ...dishState, type: (event.target as HTMLInputElement).value });
    };

    const handleTextChange = (e: React.ChangeEvent<{ name: unknown, value: unknown }>) => {
        setDishState({ ...dishState, [e.target.name as string]: e.target.value as string })
    }

    const handleDescriptionChange: (name: 'shortDescription' | 'description') => TextFieldWMentionProps['handleChange'] = (name) => (e, newValue, newValueText) => {
        setDishState({ ...dishState, [name as string]: e.target.value as string })
    }
    return (
        <FormDialog
            onSubmit={handleSubmit}
            open={showMainForm}
            heading={editedDish ? 'Edit Food/Drink' : 'Add Food/Drink'}
            onClose={closeForm}
        >
            <Paper className={classes.paper}>
                <div className={classes.wrapper}>
                    <TextValidator
                        fullWidth
                        label='Name of Dish'
                        value={dishState.name}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        name='name'
                        color='primary'
                        className={classes.textField}
                        onChange={handleTextChange}
                    />
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={dishState.type} onChange={handleChange}>
                            <FormControlLabel value="food" control={<Radio color="primary" />} label="Food" />
                            <FormControlLabel value="drink" control={<Radio color="primary" />} label="Drink" />
                        </RadioGroup>
                    </FormControl>
                    <TextFieldWMention
                        BoxProps={{ className: classes.textField }}
                        label="Short Description"
                        fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                        value={dishState.shortDescription || ''}
                        handleChange={handleDescriptionChange('shortDescription')}
                    />
                    <TextFieldWMention
                        BoxProps={{ className: classes.textField }}
                        label="Description"
                        fetchSuggestions={textFieldWMentionHelpers.fetchAutocomplete}
                        value={dishState.description || ''}
                        handleChange={handleDescriptionChange('description')}
                    />
                    {/* <TextValidator
                        fullWidth
                        value={dishState.shortDescription}
                        label='Short Description'
                        name='shortDescription'
                        multiline
                        color='primary'
                        className={classes.textField}
                        onChange={handleTextChange}
                    />
                    <TextValidator
                        fullWidth
                        value={dishState.description}
                        label='Description'
                        name='description'
                        multiline
                        color='primary'
                        className={classes.textField}
                        onChange={handleTextChange}
                    /> */}
                </div>
            </Paper>
        </FormDialog>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            margin: '100px  auto',
            width: dimensions.formPaperMaxWidth,

            height: 'auto',
            padding: '20px 20px 40px'
        },
        wrapper: {
            width: '100%',
            display: 'flex',
            height: 'auto',
            minHeight: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& > div:first-child': {
                width: '100%'
            }
        },
        textField: {
            margin: '15px 0px'
        },
        formControl: {
            marginTop: 30
        }
    }))
})

export default withRouter(MainForm)