import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OCuisine } from '../../Models/Cuisines';
import { TCuisine, TCuisineState } from '../../Models/Cuisines/@types';
import { TDispatch } from '../../Resources/GlobalTypes';
import utilities from "../../Resources/Utils";
import { TReduxStore } from '../../RootReducer';
import MainList from '../Dashboard/MainList';
import FormDialog from '../Dialogs/FormDialog';
interface IProps {
    open: boolean
    closeForm: () => void
    onFormSubmit: (newList: TCuisine[]) => Promise<void>
    cuisines: TCuisine[]
}

const CuisinesForm: React.FC<IProps> = (props) => {

    const { onFormSubmit, cuisines = [], open = false, closeForm } = props
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const { list = [], totalCount = 0 } = useSelector<TReduxStore, Pick<TCuisineState, 'list' | 'totalCount'>>(({ Cuisine }) => ({ list: Cuisine.list, totalCount: Cuisine.totalCount }))
    const dispatch = useDispatch<TDispatch>();
    const [newCuisineList, setNewCuisineList] = useState<TCuisine[]>(cuisines);
    const [searchList, setSearchList] = useState<TCuisine[]>([]);
    const [query, setQuery] = useState('');
    const getData = async () => {
        setLoading(true);
        try {
            dispatch(OCuisine.getItemsList({ filter: { order: 'name ASC', limit: 20, skip: 20 * (currentPage - 1) } }))
            currentPage === 1 && dispatch(OCuisine.getActivity(OCuisine.Activities.COUNT));
        } catch (err) {
            console.log('Error ', err)
        }
        setLoading(false)
    }



    useEffect(() => {
        getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    const onSubmit = async () => {
        onFormSubmit(newCuisineList);
    }


    const handleSearchResult = (searchResult: TCuisine[], query: string) => {
        setQuery(query);
        setSearchList(searchResult)

    }
    const handlePageChange = (pagenumber: number) => {
        setCurrentPage(pagenumber)
    }

    const handleClick = (item: TCuisine) => {

        const templist = utilities.toggleItemFromList(newCuisineList, item);
        setNewCuisineList(templist)
    }

    const listItem = () => {
        return (query ? searchList : list).map((item, index) => {
            return (<ListItem button key={index} onClick={() => handleClick(item)}>
                <ListItemText primary={item.name}  >
                </ListItemText>
                <ListItemSecondaryAction>
                    <Checkbox
                        color='primary'
                        edge="end"
                        onClick={() => handleClick(item)}
                        checked={(newCuisineList.findIndex(i => i['id'] === item['id']) === -1) ? false : true}
                    />
                </ListItemSecondaryAction>
            </ListItem>)
        })
    }


    return (
        <div>
            <FormDialog
                heading={'Add Cuisines'}
                open={open}
                onSubmit={onSubmit}
                onClose={closeForm}
                loading={loading}
            >
                <MainList
                    itemsCountPerPage={20}
                    search
                    totalItemsCount={totalCount}
                    onPageChange={handlePageChange}
                    searchProps={{
                        type: 'Cuisine',
                        onResults: handleSearchResult
                    }}
                    list={listItem()}
                    isform={true}
                />

            </FormDialog>
        </div>
    )
}



export default CuisinesForm