import { Reducer } from "redux";
import { TCouponsState } from './@types';
import { TAction } from 'loop-front';
import utilities from "../../Resources/Utils";
import { OCoupons } from ".";


const initialState: TCouponsState = {
    list: [],
    totalCount: 0,
    showMainForm: false
}


export const RCoupons: Reducer<TCouponsState, TAction> = (state = initialState, action): TCouponsState => {
    const {
        LIST_RECEIVED,
        ACTIVITY_RECEIVED,
        SHOW_COUPON_FORM,
        HIDE_COUPON_FORM,
        POST_ITEM_SUCCESS,
        ITEM_ACTIVITY_POST_SUCCESS,
        SINGLE_ITEM_RECEIVED,
        ITEM_DELETED,
        ITEM_PATCH_SUCCESS,
        ENTITY_OF_ITEM_RECEIVED
    } = OCoupons.Actions;

    switch (action.type) {
        case LIST_RECEIVED: return { ...state, list: action.data }
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OCoupons.Activities.COUNT: return { ...state, totalCount: action.data.count }
                default: return state;
            }
        }
        case ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case OCoupons.Entities.CLAIM: return { ...state, claims: action.data };
                default: return state;
            }
        }
        case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), details: { ...state.details, ...action.additionalDispatchData } }
        case ITEM_DELETED: return { ...state, list: utilities.updateItemList(state.list || [], action.additionalDispatchData, 'DELETE') }
        case SINGLE_ITEM_RECEIVED: return { ...state, details: action.data }
        case ITEM_ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case OCoupons.Activities.PUBLISH: return { ...state, details: { ...state.details, ...action.data }, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE') }
                default: return state
            }
        }
        case SHOW_COUPON_FORM: return { ...state, showMainForm: true, editedCoupon: action.data || undefined }
        case HIDE_COUPON_FORM: return { ...state, showMainForm: false }
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'ADD') };
        default: return state;
    }
}