import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Chip, ClickAwayListener, Fab, Icon, IconButton, List, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import { useDispatch, useSelector } from 'react-redux';
import { TReduxStore } from '../../../RootReducer';
import { TUserReview, TUserReviewState } from '../../../Models/UserReview/@types';
import { OUserReview } from '../../../Models/UserReview';
import { OApp } from '../../../Models/App';
import { useHistory } from 'react-router';
import NewEntitySuggest from '../../../Components/NewEntitySuggest';
import { TUser } from '../../../Models/User/@types';
import dimensions from '../../../Constants/dimensions';
import EntityImages from '../../../Components/EntityImages';
import { TPicture } from '../../../Resources/GlobalTypes';
import ImageLoader from '../../../Components/ImageLoader';
import ImageContainer from '../../../Components/ImageContainer';
import FileInput from '../../../Components/FileInput';
import { uploadPicture } from '../../../Resources/PictureUtils';
import Loader from '../../../Components/Loader';

export interface UserReviewFormProps {

}

const UserReviewForm: FC<UserReviewFormProps> = (props) => {
    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [userReview, setUserReview] = useState<Partial<TUserReview>>({})
    const [selectedUser, setSelectedUser] = useState<TUser | undefined>()
    const history = useHistory();
    const [uploading, setUploading] = useState(false);

    const { showMainForm, editedReview } = useSelector<TReduxStore, Pick<TUserReviewState, 'editedReview' | 'showMainForm'>>(({ Review: { showMainForm, editedReview } }) => ({ showMainForm, editedReview }))
    const dispatch = useDispatch();

    useEffect(() => {
        setUserReview(prev => ({ ...prev, userId: selectedUser?.id }))
    }, [selectedUser])

    useEffect(() => {
        if (editedReview?.id) {
            setUserReview(editedReview)
            setSelectedUser(editedReview.user)
        }
    }, [editedReview])

    const closeForm = () => dispatch({ type: OUserReview.Actions.HIDE_USER_REVIEW_FORM });

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (editedReview && editedReview.id) {
                const { title, subtitle, lgPicture, smPicture } = userReview;
                await dispatch(OUserReview.patchItem(editedReview.id, { title, subtitle, lgPicture, smPicture }));
                dispatch(OApp.showToast({ message: 'Edit Review Successful', variant: 'success' }));
                closeForm();

            }
            else {
                //await dispatch(OCity.postItem(city));
                let res: { data: TUserReview } = await OUserReview.requestPostItem({ ...userReview });
                dispatch({ type: OUserReview.Actions.POST_ITEM_SUCCESS, data: res.data })
                history.push('/dashboard/reviews/' + res.data.id + '/details')
                dispatch(OApp.showToast({ message: 'Add Review Successful', variant: 'success' }));
                closeForm();
            }
        } catch (err) {
            dispatch(OApp.showToast({ message: 'Review Add / Edit Failed', variant: 'error' }));
        }
        setLoading(false);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setUserReview(r => ({ ...r, [e.target.name]: e.target.value }))
    }







    const uploadFiles = (key: keyof TUserReview) => async (files: any[]) => {
        setUploading(true);
        if (files.length === 0) return;
        try {
            const { data } = await uploadPicture(files[0], 'reviews');
            setUserReview(r => ({ ...r, [key]: data }))
        } catch (error) {
            // TODO: handle error
        }
        setUploading(false);
    }

    const removeImage = (key: keyof TUserReview) => async () => {
        setUserReview(r => ({ ...r, [key]: null }))
    }



    return (
        <FormDialog
            open={showMainForm}
            heading={editedReview?.id ? `Update Review` : 'Create New Review'}
            onSubmit={handleSubmit}
            onClose={closeForm}
            loading={loading}
        >
            <Box width={dimensions.formPaperMaxWidth} >
                {uploading && <Loader overlayed />}
                <Paper className={classes.paper} >
                    <TextField className={classes.inputItem} fullWidth multiline label="Title" name='title' value={userReview.title || ''} onChange={handleChange} />
                    <div className={classes.imageInputContainer}>
                        <div className={classes.imageContainer}>
                            <Tooltip title="Click to add new desktop image">
                                <Box position='relative'>
                                    <img src={userReview.lgPicture?.url} />
                                    {!userReview.lgPicture?.url && <Typography className={classes.placeholderText} variant='body2'>Click to add new desktop image</Typography>}
                                    <Typography className={classes.pd} variant='caption'>Desktop Image</Typography>
                                    <FileInput
                                        accept={"image/*"}
                                        multiple={false}
                                        onDone={uploadFiles('lgPicture')}
                                    />
                                </Box>
                            </Tooltip>
                            <Tooltip title='Remove Image'>
                                <IconButton className={classes.imageDeleteButton} onClick={removeImage('lgPicture')}>
                                    <i className='material-icons'>delete</i>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={classes.imageContainer}>
                            <Tooltip title="Click to add new mobile image">
                                <Box position='relative'>
                                    <img src={userReview.smPicture?.url} />
                                    {!userReview.smPicture?.url && <Typography className={classes.placeholderText} variant='body2'>Click to add new mobile image</Typography>}
                                    <Typography className={classes.pd} variant='caption'>Mobile Image</Typography>
                                    <FileInput
                                        accept={"image/*"}
                                        multiple={false}
                                        onDone={uploadFiles('smPicture')}
                                    />
                                </Box>
                            </Tooltip>
                            <Tooltip title='Remove Image'>
                                <IconButton className={classes.imageDeleteButton} onClick={removeImage('smPicture')}>
                                    <i className='material-icons'>delete</i>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    {/* <TextField className={classes.inputItem} fullWidth label="User" name='subtitle' value={userReview.subtitle || ''} onChange={handleChange} /> */}


                </Paper>

            </Box>

        </FormDialog>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({
    paper: {
        marginTop: 48,
        padding: 20,

    },
    pd: {
        padding: 8
    },
    imageInputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16
    },
    userList: {
        position: 'absolute',
        top: 50,
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        shadow: theme.shadows[1],
        height: 300,
        overflow: 'auto'
    },
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            background: '#ddd'
        }
    },
    inputItem: {
        marginBottom: 16
    },
    imageContainer: {
        width: '260px',
        aspectRatio: '3/2',
        backgroundColor: '#ddd',
        position: 'relative',
        '& img': {
            width: '100%',
            aspectRatio: '3/2',
        }
    },
    placeholderText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    }
}));

export default UserReviewForm;