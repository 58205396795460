import { TDispatch } from "../../Resources/GlobalTypes";
import { OApp } from "../App";
import LoopFront from "loop-front";
import { ODish } from ".";
import { TFilterConfig } from "../App/@types";



export function addEntityToDish<T extends { id: string }>(dishId: string, newIds: T[], entity: string) {
    return (async (dispatch: TDispatch) => {
        var data = {
            firstModel: 'FoodDrink',
            secondModel: entity,
            id: dishId,
            newIds: newIds.map((item) => item.id)
        }
        try {

            await OApp.requestPutActivity(OApp.Activities.RELATIONS, data);
            const action: string = 'PUT_' + entity.toUpperCase() + '_TO_DISH_SUCCESS'
            dispatch({ type: action, data: newIds.length, additionalDispatchData: newIds })
        } catch (err) {
            console.log('Error : ', err)
        }

    }
    )
}
const requestDeleteSingleEntityFromDish = (dishId: string, entity: string, itemId: string) => (
    LoopFront.request({
        url: `foodDrinks/${dishId}/${entity}/rel/${itemId}`,
        method: 'DELETE'
    })
)
export function deleteEntityfromDish<T extends { id: string }>(dishId: string, entity: string, items: T[], itemId: string) {
    return (async (dispatch: TDispatch) => {
        try {
            await requestDeleteSingleEntityFromDish(dishId, entity, itemId);
            const action = `DELETE_${entity.toUpperCase()}_FROM_DISH_SUCCESS`;
            dispatch({ type: action, additionalDispatchData: items.filter(i => i.id !== itemId), data: items.length - 1 })
            dispatch(OApp.showToast({ message: entity.toUpperCase() + ' delete Successful', variant: 'success' }))
        } catch (err) {
            console.log(err);
            dispatch(OApp.showToast({ message: entity.toUpperCase() + ' delete Unsuccessful', variant: 'error' }))
        }
    })
}
export function getDishList(pageNumber: number, filterConfig: TFilterConfig[], sortFilter?: any) {
    return (async (dispatch: TDispatch) => {
        const filter = OApp.parseBaseFilter(filterConfig)
        await dispatch(ODish.getItemsList({
            filter: {
                limit: 20,
                skip: 20 * (pageNumber - 1),
                order: 'name ASC',
                includeCount: true,
                include: { "relation": "cuisines", "scope": { "fields": ["name"] } },
                ...filter,
                ...sortFilter
            }
        }))
        if (pageNumber === 1)
            await dispatch(ODish.getActivity(ODish.Activities.COUNT, { ...filter }))
    })
}
