import { createMuiTheme } from "@material-ui/core";

const MuiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#FF6250',
            light: '#bab8ff',
            contrastText: "#fff",

        },
        secondary: {
            main: '#4EBC95',
            light: '#A6DDCA',
            contrastText: "#fff"
        },
        grey: {
            100: '#363535',
            200: '#343434',
            400: '#7E7E7E',
            500: '#F2F2F2',
            600: '#707070',
            700: '#E5E8F2',
            800: '#363535',

        },
        text: {
            primary: "#2a2a2a",
            secondary: "#363535"
        },
        background: {
            default: "#F4F5FE",
            paper: "#fff"
        },
        common: {
            black: '#000',
            white: '#fff'
        },
        action: {
            active: '#000'
        },
        error: {
            main: '#B71840'
        },
    },
    typography: {
        fontFamily: 'lato',
        button: {
            fontWeight: 700,
        },
        h1: {
            fontSize: 96
        },
        h2: {
            fontSize: 60
        },
        h3: {
            fontSize: 48
        },
        h4: {
            fontSize: 34
        },
        h5: {
            fontSize: 24
        },
        h6: {
            fontSize: 20
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 'bold'
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 'bold'
        },
        body1: {
            fontSize: 16
        },
        body2: {
            fontSize: 14
        },
        caption: {
            fontSize: 12,
            fontWeight: 'bold'
        },
        overline: {
            fontSize: 12,
            textTransform: 'unset'
        },
    },
    overrides: {

        MuiFab: {
            colorInherit: {
                backgroundColor: 'white',
                boxShadow: 'none'
            }
        },
        MuiButton: {
            text: {
                '&$disabled': {
                    color: '#FFF',
                }
            }
        },
        MuiTypography: {
            root: {
                whiteSpace: 'pre-wrap'
            },
            colorTextSecondary: {
                color: '#7E7E7E',
            }

        },
        MuiChip: {
            root: {
                // backgroundColor: '#F2F2F2'
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: 'black',
            }
        }
    }
});

export default MuiTheme;