import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { TExploreFilter } from '.';
import { TAppConstants } from '../../../Models/App/@types';
import { TSettings } from '../../../Models/Settings/@types';
import utilities from '../../../Resources/Utils';
import { TReduxStore } from '../../../RootReducer';

export interface PlaceTypeFilterProps {
    onChange: (name: keyof TExploreFilter, value: any) => void;
}
interface IProps {
    constants: TAppConstants | undefined;
    list: TSettings[];
}
const PlaceTypeFilter: FC<PlaceTypeFilterProps> = (props) => {
    const classes = useStyles();
    const { onChange } = props;
    const [filters, setFilters] = useState<Record<string, Array<number | string>>>({
        types: [],
        range: [],
        status: [],
    });
    const { constants, list: settings } = useSelector<TReduxStore, IProps>(({ App: { constants }, Settings: { list } }) => ({ constants, list }));
    const optionsConfig: { label: string; options: { name: string; value: string | number }[]; name: string }[] = [
        {
            name: 'types',
            // options: settings.find((i) => i.key === 'establishmentPlaceType')?.value || [],
            options: constants?.Establishment.types || [],
            label: 'Place Type',
        },
        {
            name: 'range',
            options: [1, 2, 3, 4].map((i) => ({ name: '$'.repeat(i), value: i })),
            label: 'Price',
        },
        {
            name: 'status',
            options: constants?.Establishment.status || [],
            label: 'Status',
        },
    ];
    const handleChange = (name: string, value: any) => {
        const updatedFilter = {
            ...filters,
            [name]: utilities.toggleItemFromList(filters[name], value, '', (a, b) => a === b),
        };
        setFilters(updatedFilter);
        onChange(name as keyof TExploreFilter, updatedFilter[name]);
    };
    return (
        <Paper className={classes.paper}>
            {optionsConfig.map((config, index) => {
                return (
                    <FormControl key={`${index}_${config.name}`} component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">{config.label}</FormLabel>
                        <FormGroup>
                            {config.options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={Boolean(filters[config.name as string].find((i) => i === option.value))}
                                            onChange={() => handleChange(config.name, option.value)}
                                            name="gilad"
                                        />
                                    }
                                    label={option.name}
                                />
                            ))}
                            {/* <FormControlLabel
                                    control={<Checkbox checked={gilad} onChange={handleChange} name="gilad" />}
                                    label="Gilad Gray"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={jason} onChange={handleChange} name="jason" />}
                                    label="Jason Killian"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={antoine} onChange={handleChange} name="antoine" />}
                                    label="Antoine Llorca"
                                /> */}
                        </FormGroup>
                    </FormControl>
                );
            })}
        </Paper>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        paper: {
            width: 450,
            padding: theme.spacing(2, 1.5),
            margin: '40px auto',
            display: 'flex',
            justifyContent: 'space-between',
        },
    });
});

export default PlaceTypeFilter;
