import { TAction } from "loop-front";
import { Reducer } from "redux";
import { OCuisine } from ".";
import utilities from "../../Resources/Utils";
import { TCuisineState } from "./@types";


export const RCuisine: Reducer<TCuisineState, TAction> = (state = { showMainForm: false }, action) => {
    const { SINGLE_ITEM_RECEIVED, ACTIVITY_RECEIVED, LIST_RECEIVED,
        ENTITY_OF_ITEM_RECEIVED,
        PUT_DISH_TO_CUISINE_SUCCESS,
        PUT_ESTABLISHMENT_TO_CUISINE_SUCCESS,
        SINGLE_ENTITY_BY_ITEM_DELETED,
        SHOW_CUISINE_DETAIL_FORM,
        HIDE_CUISINE_DETAIL_FORM,
        POST_ITEM_SUCCESS,
        ITEM_PATCH_SUCCESS,
        ITEM_ACTIVITY_POST_SUCCESS,
        DELETE_DISH_FROM_CUISINE_SUCCESS,
        DELETE_ESTABLISHMENT_FROM_CUISINE_SUCCESS,
        ITEM_DELETED,
        ITEM_ACTIVITY_RECEIVED,
    } = OCuisine.Actions;

    switch (action.type) {
        case ITEM_DELETED: return { ...state, list: utilities.updateItemList(state.list || [], action.additionalDispatchData, 'DELETE') }
        case DELETE_DISH_FROM_CUISINE_SUCCESS: return {
            ...state,
            dishes: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, foodDrinks: action.data } }
        }
        case DELETE_ESTABLISHMENT_FROM_CUISINE_SUCCESS: return {
            ...state,
            places: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, establishments: action.data } }
        }
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'ADD') }
        case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], { ...state.details, ...action.additionalDispatchData }, 'UPDATE'), details: { ...state.details, ...action.additionalDispatchData } }
        case LIST_RECEIVED: return { ...state, list: action.data }

        case SINGLE_ITEM_RECEIVED: return { ...state, details: action.data }
        case ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case OCuisine.Entities.ESTABLISHMENTS: return { ...state, places: action.data }
                case OCuisine.Entities.FOODDRINKS: return { ...state, dishes: action.data }
                default: return state;
            }
        }
        case ITEM_ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OCuisine.Activities.DETAILS: return { ...state, details: action.data }
                default: return state;
            }
        }
        case ITEM_ACTIVITY_POST_SUCCESS: {
            switch (action.activity) {
                case OCuisine.Activities.PUBLISH: return { ...state, list: utilities.updateItemList(state.list || [], { ...state.details, ...action.data }, 'UPDATE'), details: { ...state.details, ...action.data } }
                default: return state;
            }
        }
        case ACTIVITY_RECEIVED: {
            switch (action.activity) {
                case OCuisine.Activities.COUNT: return { ...state, totalCount: action.data.count }
                default: return state;
            }
        }
        case PUT_ESTABLISHMENT_TO_CUISINE_SUCCESS: return {
            ...state,
            places: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, establishments: action.data } }
        }
        case PUT_DISH_TO_CUISINE_SUCCESS: return {
            ...state,
            dishes: action.additionalDispatchData,
            details: (state.details) && { ...state.details, counts: { ...state.details.counts, foodDrinks: action.data } }
        }
        case SINGLE_ENTITY_BY_ITEM_DELETED: {
            switch (action.entity) {
                case OCuisine.Entities.FOODDRINKS: return { ...state, dishes: utilities.updateItemList(state.dishes || [], action.additionalDispatchData, 'DELETE') }
                case OCuisine.Entities.ESTABLISHMENTS: return { ...state, places: utilities.updateItemList(state.places || [], action.additionalDispatchData, 'DELETE') }
                default: return state;
            }
        }
        case SHOW_CUISINE_DETAIL_FORM: return { ...state, showMainForm: true, editedCuisine: action.data }
        case HIDE_CUISINE_DETAIL_FORM: return { ...state, showMainForm: false, editedCuisine: undefined }
        default: return state;
    }

}