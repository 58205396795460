import { createStyles, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { TConstant } from '../Models/App/@types';

export interface StepperProps {
    list: TConstant[]
    selectedValue: string
}

const Stepper: FC<StepperProps> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root} >
            {
                props.list.map((item, i) => {
                    return (
                        <div className={classes.stepContainer} key={i} >
                            <Typography variant='body2' className={(item.value === props.selectedValue) ? classes.activeColor : ''} >{item.name}</Typography>
                            <div className={classes.stepIndicatorContainer} >
                                <div className={(item.value === props.selectedValue) ? classes.active : classes.inActive} />
                            </div>
                        </div>
                    )
                })
            }
            <div className={classes.path} />
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 56,
            width: '100%'
        },
        stepContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: "column"
        },
        stepIndicatorContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
            // height: '100%'
        },
        activeColor: {
            color: '#8AAAC3'
        },
        active: { height: 26, width: 26, borderRadius: '100%', backgroundColor: '#8AAAC3', zIndex: 1 },
        inActive: { height: 8, width: 8, borderRadius: '100%', backgroundColor: '#DBD8D8', },
        path: {
            position: 'absolute',
            left: 17, right: 35, top: 37, height: 2, backgroundColor: '#DBD8D8'
        }
    }))
})

export default Stepper