import { Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

export interface TMenuConfig<T, L = string> {
    label: L;
    id: T;
    filter: any;
}

export type TSortMenuLabel = 'alphabetic' | 'newest_first' | 'oldest_first' | 'modified';

const SORT_MENU_CONFIG: TMenuConfig<TSortMenuLabel>[] = [
    { label: 'A-Z', id: 'alphabetic', filter: { order: 'name ASC' } },
    { label: 'Newest First', id: 'newest_first', filter: { order: 'created DESC' } },
    { label: 'Oldest First', id: 'oldest_first', filter: { order: 'created ASC' } },
    { label: 'Recently Modified', id: 'modified', filter: { order: 'updated DESC' } },
];
export interface UsersListSortMenuProps {
    anchorEl?: HTMLElement;
    handleCloseSortMenu: () => void;
    onSortingChange: (filter: any, label: string) => void;
}

const UsersListSortMenu: FC<UsersListSortMenuProps> = (props) => {
    const classes = useStyles();

    const { anchorEl, handleCloseSortMenu, onSortingChange } = props;

    const handleMenuClick = (item: TMenuConfig<TSortMenuLabel>) => {
        onSortingChange(item.filter, item.label);
        handleCloseSortMenu();
    };

    return (
        <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={handleCloseSortMenu}
            PopoverClasses={{
                paper: classes.orderMenuPaper,
            }}
            PaperProps={{
                elevation: 3,
            }}
        >
            {SORT_MENU_CONFIG.map((x, i) => {
                return (
                    <MenuItem key={i} onClick={() => handleMenuClick(x)}>
                        {x.label}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        orderMenuPaper: {
            marginLeft: 4,
            borderRadius: 8,
        },
    });
});

export default UsersListSortMenu;
