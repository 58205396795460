import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography, TypographyProps } from '@material-ui/core';
import { textFieldWMentionHelpers } from './Inputs/TextFieldWMention/helpers';

export interface TypographyWMentionProps extends TypographyProps {
    renderMentionHTMLStr?: (name: string, meta: string) => string;
}

const TypographyWMention: FC<TypographyWMentionProps> = ({ children, renderMentionHTMLStr, ...props }) => {
    const classes = useStyles({});

    const renderMention = (name: string, meta: string) => {

        return `<a href="#">${name}</a>`
    }

    const __html = typeof children === 'string' ? textFieldWMentionHelpers.getTextNode(children, renderMentionHTMLStr ?? renderMention) : children

    if (typeof children === 'string')
        return (
            <Typography {...props} dangerouslySetInnerHTML={{ __html: __html as string }}>
            </Typography>
        )

    return (
        <Typography {...props} >
            {__html}
        </Typography>
    )
}

const useStyles = makeStyles<Theme, any>((theme) => ({

}));

export default TypographyWMention;