import { Reducer } from "redux";
import { TAction } from 'loop-front';
import utilities from "../../Resources/Utils";
import _ from 'lodash';
import { TQuoteState } from "./@types";
import { OQuote } from ".";


const initialState: TQuoteState = {
    list: [],
    totalCount: 0,
    showMainForm: false,
}


export const RQuote: Reducer<TQuoteState, TAction> = (state = initialState, action): TQuoteState => {
    const {
        LIST_RECEIVED,
        POST_ITEM_SUCCESS,
        SHOW_EATINERARY_FORM,
        HIDE_EATINERARY_FORM,
        SINGLE_ITEM_RECEIVED,
        ITEM_PATCH_SUCCESS
    } = OQuote.Actions;


    switch (action.type) {
        case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), details: { ...state.details, ...action.data, ...action.additionalDispatchData } }
        case SINGLE_ITEM_RECEIVED: return { ...state, details: action.data };;
        case LIST_RECEIVED: return { ...state, list: action.data }
        case POST_ITEM_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'ADD') };
        case SHOW_EATINERARY_FORM: return { ...state, showMainForm: true, editedQuote: action.data || undefined }
        case HIDE_EATINERARY_FORM: return { ...state, showMainForm: false }
        default: return state;
    }
}