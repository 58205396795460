import { combineReducers } from "redux";
import { App } from '../Models/App/Reducer';
import { RCity } from '../Models/City/Reducer';
import { RCoupons } from "../Models/Coupons/Reducer";
import { RCuisine } from "../Models/Cuisines/Reducer";
import { RDestinationPack } from "../Models/DestinationPack/Reducer";
import { RDish } from "../Models/Dish/Reducer";
import { REatinerary } from "../Models/Eatinerraies/Reducer";
import { RExplore } from "../Models/Explore/Reducer";
import { RHomeSection } from "../Models/HomeSection/Reducer";
import { RLandmarks } from "../Models/Landmarks/Reducer";
import { RPack } from "../Models/Pack/Reducer";
import { RPlace } from "../Models/Place/Reducer";
import { RQuote } from "../Models/Quotes/Reducer";
import { RSettings } from "../Models/Settings/Reducer";
import { RTagCategories } from "../Models/Tags/Reducer";
import { RTransactions } from "../Models/Transactions/Reducer";
import { RUser } from '../Models/User/Reducer';
import { RUserReview } from "../Models/UserReview/Reducer";

const RootReducer = combineReducers({
    App,
    User: RUser,
    City: RCity,
    Place: RPlace,
    Dish: RDish,
    Cuisine: RCuisine,
    TagCategories: RTagCategories,
    Landmarks: RLandmarks,
    Coupons: RCoupons,
    Explore: RExplore,
    Transactions: RTransactions,
    Settings: RSettings,
    Packs: RPack,
    Home: RHomeSection,
    Review: RUserReview,
    Eatinerary: REatinerary,
    Quote: RQuote,
    DestinationPack: RDestinationPack
});

export type TReduxStore = ReturnType<typeof RootReducer>

export default RootReducer;