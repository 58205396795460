import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import _, { cloneDeep, omit } from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import FormDialog from '../../../Components/Dialogs/FormDialog';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { OApp } from '../../../Models/App';
import { OEatinerary } from '../../../Models/Eatinerraies';
import { TEatineraryState, TEatinerraies, TEatinerraiesSection } from '../../../Models/Eatinerraies/@types';
import { OExplore } from '../../../Models/Explore';
import { TExplore, TExploreState } from '../../../Models/Explore/@types';
import ExploreModel from '../../../Models/Explore/actions';
import { TPlace } from '../../../Models/Place/@types';
import { TDispatch } from '../../../Resources/GlobalTypes';
import { TReduxStore } from '../../../RootReducer';
import BasicInfo from './BasicInfo';
import Eatinerraies, { TSearchType } from './Eatinerraies';
export interface EatinerraiesFormProps { }

export interface TExploreFilter {
    destinationId?: any
    range?: any
    types?: any
    status?: any
    cuisineIds?: any
    tagIds?: any
}
const ExploreForm: FC<EatinerraiesFormProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch<TDispatch>()
    const history = useHistory();
    const { editedEatinerary } = useSelector<TReduxStore, Pick<TEatineraryState, 'editedEatinerary'>>(({ Eatinerary: { editedEatinerary } }) => ({ editedEatinerary }))

    useEffect(() => {
        if (editedEatinerary?.id) {

        }
    }, [editedEatinerary]);

    const handleSubmit = async (values: any) => {
        try {

            let data = _.cloneDeep(values);

            let _sections = _.cloneDeep(data._sections);
            data._sections = data._sections.map((s: TEatinerraiesSection, index: any) => {
                let tsection = s;
                tsection.placeIds = _.map(tsection.placeIds, (p) => (p as TSearchType).id);
                s.id = index;
                return s;
            });

            if (data.id) {
                await dispatch(OEatinerary.patchItem(data.id, { ...data }, {}, undefined, { ...data, _sections: _sections }))
                dispatch(OApp.showToast({ message: 'Item edited successfully' }))
            } else {
                let res: { data: TEatinerraies } = await OEatinerary.requestPostItem({ ...data })
                dispatch({ type: OExplore.Actions.POST_ITEM_SUCCESS, data: res.data, additionalDispatchData: { _sections: _sections } })
                history.push('/dashboard/eatineraries/' + res.data.id + '/details')
                dispatch(OApp.showToast({ message: 'Item added successfully' }))
            }
            closeForm();
        } catch (err) {
            console.log(err, "error");
        }
    }

    const closeForm = () => {
        dispatch({ type: OEatinerary.Actions.HIDE_EATINERARY_FORM })
    }

    return (
        <Formik<Partial<TEatinerraies>>
            initialValues={editedEatinerary?.id ? { ...editedEatinerary } : {
                name: editedEatinerary?.name || "",
                description: editedEatinerary?.description || "",
                _sections: [
                    {
                        title: "",
                        description: editedEatinerary?.description || "",
                        placeIds: []
                    }
                ]
            }}
            validationSchema={Yup.object({
                destinationId: Yup.string().required('This is required'),
                name: Yup.string().required('This is required'),
                // _sections: Yup.array()
                //     .of(
                //         Yup.object().shape({
                //             title: Yup.string().required('This is required'),
                //             description: Yup.string().required('This is required'),
                //         })
                //     )
            })}
            onSubmit={handleSubmit}
        >
            {
                formikProps =>
                    <FormDialog
                        onClose={closeForm}
                        open={true}
                        heading={editedEatinerary ? 'Edit Eatinery' : 'Add Eatinery'}
                        onSubmit={() => formikProps.handleSubmit()}
                        containerClass={classes.container}
                    >
                        <Box className={classes.root}>
                            <Box flex={1} display='flex' flexDirection='column' alignItems='center' overflow='auto'>
                                <BasicInfo formikProps={formikProps} />
                                <Eatinerraies formikProps={formikProps} />
                            </Box>
                        </Box>
                    </FormDialog>
            }
        </Formik>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        container: {
            width: '100%',
            maxWidth: '100%',
            margin: `0 auto`
        },
        root: {
            display: 'flex',
            width: '100%',
            paddingBottom: 100,
            minHeight: `calc(100vh - 64px)`,
        }
    }))
})

export default ExploreForm