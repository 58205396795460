import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { DialogContentText } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide {...props as SlideProps} direction="up" ref={ref} />;
});

export interface ConfirmationDialogProps extends DialogProps {
    onClose: () => void
    title?: string
    message: string
    onAgree: () => void
    onDisagree: () => void
}


const ConfirmationDialog: FC<ConfirmationDialogProps> = ({ open, onClose, title = 'Confirmation', message, onAgree, onDisagree }) => {
    return (
        <Dialog open={open} keepMounted onClose={onClose} TransitionComponent={Transition} >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent><DialogContentText>{message}</DialogContentText></DialogContent>
            <DialogActions>
                <Button onClick={onAgree} color="primary">Yes</Button>
                <Button onClick={onDisagree} color="secondary">No</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;